/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelOrder
// ====================================================

export interface cancelOrder_cancelOrder_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface cancelOrder_cancelOrder_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: cancelOrder_cancelOrder_timetable_time_slot_timeRange;
}

export interface cancelOrder_cancelOrder_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface cancelOrder_cancelOrder_shipping_method_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface cancelOrder_cancelOrder_shipping_method {
  __typename: "ShippingMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  price_ranges: cancelOrder_cancelOrder_shipping_method_price_ranges[] | null;
  discount_price: number | null;
  max_weight: number | null;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
}

export interface cancelOrder_cancelOrder_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  cost: number;
  is_active: boolean;
  sort: number;
  short_description: string | null;
}

export interface cancelOrder_cancelOrder_order_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface cancelOrder_cancelOrder_order_products_product_empties_images {
  __typename: "ProductImage";
  conversions: cancelOrder_cancelOrder_order_products_product_empties_images_conversions;
}

export interface cancelOrder_cancelOrder_order_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: cancelOrder_cancelOrder_order_products_product_empties_images[] | null;
}

export interface cancelOrder_cancelOrder_order_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface cancelOrder_cancelOrder_order_products_product_images {
  __typename: "ProductImage";
  conversions: cancelOrder_cancelOrder_order_products_product_images_conversions;
}

export interface cancelOrder_cancelOrder_order_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface cancelOrder_cancelOrder_order_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: cancelOrder_cancelOrder_order_products_product_attributes_options[] | null;
}

export interface cancelOrder_cancelOrder_order_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: cancelOrder_cancelOrder_order_products_product_empties | null;
  /**
   * Termék képek
   */
  images: cancelOrder_cancelOrder_order_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: cancelOrder_cancelOrder_order_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface cancelOrder_cancelOrder_order_products {
  __typename: "OrderProduct";
  price: number;
  special_price: number | null;
  default_quantity_name: string;
  default_quantity_piece: number;
  alternative_quantity_name: string | null;
  alternative_quantity_piece: number | null;
  product: cancelOrder_cancelOrder_order_products_product;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface cancelOrder_cancelOrder_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface cancelOrder_cancelOrder_coupon_data {
  __typename: "OrderCouponData";
  name: string;
  code: string;
  product_discount: number | null;
  shipping_discount: number | null;
}

export interface cancelOrder_cancelOrder_linked_orders {
  __typename: "Order";
  id: string;
}

export interface cancelOrder_cancelOrder_parent_order {
  __typename: "Order";
  id: string;
}

export interface cancelOrder_cancelOrder_donation_data {
  __typename: "OrderDonation";
  /**
   *  Az adakozás típusa (kerekített vagy egyedi összeg) 
   */
  type: OrderDonationType;
  /**
   *  Az adomány összege 
   */
  amount: number;
}

export interface cancelOrder_cancelOrder {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  timetable_time_slot: cancelOrder_cancelOrder_timetable_time_slot | null;
  post_point: cancelOrder_cancelOrder_post_point | null;
  shipping_method: cancelOrder_cancelOrder_shipping_method;
  payment_method: cancelOrder_cancelOrder_payment_method;
  payment_method_cost: number;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  billing_name: string;
  billing_postcode: string;
  billing_city: string;
  billing_street: string;
  billing_house_number: string;
  vat_number: string | null;
  is_company: boolean | null;
  comment: string | null;
  erp_comment: string | null;
  lyoness_card_number: string | null;
  order_products: cancelOrder_cancelOrder_order_products[];
  status: cancelOrder_cancelOrder_status;
  coupon_data: cancelOrder_cancelOrder_coupon_data | null;
  linked_orders: (cancelOrder_cancelOrder_linked_orders | null)[] | null;
  parent_order: cancelOrder_cancelOrder_parent_order | null;
  created_at: any;
  donation_data: cancelOrder_cancelOrder_donation_data | null;
}

export interface cancelOrder {
  cancelOrder: cancelOrder_cancelOrder;
}

export interface cancelOrderVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeMyPassword
// ====================================================

export interface changeMyPassword_changeMyPassword_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface changeMyPassword_changeMyPassword_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface changeMyPassword_changeMyPassword_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface changeMyPassword_changeMyPassword_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface changeMyPassword_changeMyPassword_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface changeMyPassword_changeMyPassword_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface changeMyPassword_changeMyPassword {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: changeMyPassword_changeMyPassword_default_billing_address | null;
  default_shipping_address: changeMyPassword_changeMyPassword_default_shipping_address | null;
  shipping_addresses: changeMyPassword_changeMyPassword_shipping_addresses[] | null;
  billing_addresses: changeMyPassword_changeMyPassword_billing_addresses[] | null;
  saved_credit_cards: changeMyPassword_changeMyPassword_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: changeMyPassword_changeMyPassword_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface changeMyPassword {
  changeMyPassword: changeMyPassword_changeMyPassword;
}

export interface changeMyPasswordVariables {
  input: UserPasswordChangeInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePaymentMethod
// ====================================================

export interface changePaymentMethod_changePaymentMethod_order_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface changePaymentMethod_changePaymentMethod_order_payment_method {
  __typename: "PaymentMethod";
  id: string;
}

export interface changePaymentMethod_changePaymentMethod_order {
  __typename: "Order";
  id: string;
  status: changePaymentMethod_changePaymentMethod_order_status;
  payment_method: changePaymentMethod_changePaymentMethod_order_payment_method;
}

export interface changePaymentMethod_changePaymentMethod {
  __typename: "CreateOrderResult";
  order: changePaymentMethod_changePaymentMethod_order;
  rewrite_url: string | null;
  transaction_id: string | null;
}

export interface changePaymentMethod {
  changePaymentMethod: changePaymentMethod_changePaymentMethod;
}

export interface changePaymentMethodVariables {
  input: ChangePaymentMethodInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCart
// ====================================================

export interface createCart_createCart_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface createCart_createCart_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: createCart_createCart_cart_products_product_empties_images_conversions;
}

export interface createCart_createCart_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: createCart_createCart_cart_products_product_empties_images[] | null;
}

export interface createCart_createCart_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface createCart_createCart_cart_products_product_images {
  __typename: "ProductImage";
  conversions: createCart_createCart_cart_products_product_images_conversions;
}

export interface createCart_createCart_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface createCart_createCart_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: createCart_createCart_cart_products_product_attributes_options[] | null;
}

export interface createCart_createCart_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: createCart_createCart_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: createCart_createCart_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: createCart_createCart_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface createCart_createCart_cart_products {
  __typename: "CartProduct";
  product: createCart_createCart_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface createCart_createCart_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface createCart_createCart_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: createCart_createCart_timetable_time_slot_timeRange;
}

export interface createCart_createCart_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type createCart_createCart_shipping_method_ShippingMethodGPoint_errors = createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | createCart_createCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface createCart_createCart_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (createCart_createCart_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type createCart_createCart_shipping_method_ShippingMethodMpl_errors = createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | createCart_createCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface createCart_createCart_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: createCart_createCart_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (createCart_createCart_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type createCart_createCart_shipping_method_ShippingMethodGRoby_errors = createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | createCart_createCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: createCart_createCart_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: createCart_createCart_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface createCart_createCart_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (createCart_createCart_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (createCart_createCart_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type createCart_createCart_shipping_method_ShippingMethodPostPoint_errors = createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | createCart_createCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface createCart_createCart_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: createCart_createCart_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (createCart_createCart_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type createCart_createCart_shipping_method = createCart_createCart_shipping_method_ShippingMethodGPoint | createCart_createCart_shipping_method_ShippingMethodMpl | createCart_createCart_shipping_method_ShippingMethodGRoby | createCart_createCart_shipping_method_ShippingMethodPostPoint;

export interface createCart_createCart_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface createCart_createCart_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface createCart_createCart_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface createCart_createCart_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface createCart_createCart_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface createCart_createCart_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: createCart_createCart_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type createCart_createCart_payment_method = createCart_createCart_payment_method_PaymentMethodCreditCardBorgun | createCart_createCart_payment_method_PaymentMethodCreditCardSimple | createCart_createCart_payment_method_PaymentMethodBankTransfer | createCart_createCart_payment_method_PaymentMethodCashOnDelivery | createCart_createCart_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface createCart_createCart_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface createCart_createCart_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface createCart_createCart_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface createCart_createCart_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface createCart_createCart_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface createCart_createCart_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface createCart_createCart_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: createCart_createCart_linked_order_timetable_time_slot_timeRange;
}

export interface createCart_createCart_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: createCart_createCart_linked_order_shipping_method;
  timetable_time_slot: createCart_createCart_linked_order_timetable_time_slot | null;
}

export interface createCart_createCart_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface createCart_createCart_warnings {
  __typename: "CartProductWarning";
  product: createCart_createCart_warnings_product;
  message: string;
}

export interface createCart_createCart {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: createCart_createCart_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: createCart_createCart_timetable_time_slot | null;
  post_point: createCart_createCart_post_point | null;
  shipping_method: createCart_createCart_shipping_method | null;
  payment_method: createCart_createCart_payment_method | null;
  shipping_address: createCart_createCart_shipping_address | null;
  billing_address: createCart_createCart_billing_address | null;
  payment_method_data: createCart_createCart_payment_method_data | null;
  contact_data: createCart_createCart_contact_data | null;
  linked_order: createCart_createCart_linked_order | null;
  warnings: createCart_createCart_warnings[] | null;
}

export interface createCart {
  createCart: createCart_createCart | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMyUserBillingAddress
// ====================================================

export interface createMyUserBillingAddress_createMyUserBillingAddress {
  __typename: "UserBillingAddress";
  id: string;
}

export interface createMyUserBillingAddress {
  createMyUserBillingAddress: createMyUserBillingAddress_createMyUserBillingAddress;
}

export interface createMyUserBillingAddressVariables {
  addressInput: UserBillingAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMyUserShippingAddress
// ====================================================

export interface createMyUserShippingAddress_createMyUserShippingAddress {
  __typename: "UserShippingAddress";
  id: string;
  city: string;
}

export interface createMyUserShippingAddress {
  createMyUserShippingAddress: createMyUserShippingAddress_createMyUserShippingAddress;
}

export interface createMyUserShippingAddressVariables {
  addressInput: UserShippingAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createOrder
// ====================================================

export interface createOrder_createOrder_order_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface createOrder_createOrder_order_payment_method {
  __typename: "PaymentMethod";
  id: string;
}

export interface createOrder_createOrder_order_order_products_product {
  __typename: "Product";
  id: string;
}

export interface createOrder_createOrder_order_order_products {
  __typename: "OrderProduct";
  product: createOrder_createOrder_order_order_products_product;
}

export interface createOrder_createOrder_order {
  __typename: "Order";
  id: string;
  status: createOrder_createOrder_order_status;
  payment_method: createOrder_createOrder_order_payment_method;
  total_amount: number;
  order_products: createOrder_createOrder_order_order_products[];
  user_type: OrderUserType | null;
}

export interface createOrder_createOrder_registration_result_user_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface createOrder_createOrder_registration_result_user_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface createOrder_createOrder_registration_result_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface createOrder_createOrder_registration_result_user_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface createOrder_createOrder_registration_result_user_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface createOrder_createOrder_registration_result_user_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface createOrder_createOrder_registration_result_user {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: createOrder_createOrder_registration_result_user_default_billing_address | null;
  default_shipping_address: createOrder_createOrder_registration_result_user_default_shipping_address | null;
  shipping_addresses: createOrder_createOrder_registration_result_user_shipping_addresses[] | null;
  billing_addresses: createOrder_createOrder_registration_result_user_billing_addresses[] | null;
  saved_credit_cards: createOrder_createOrder_registration_result_user_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: createOrder_createOrder_registration_result_user_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface createOrder_createOrder_registration_result {
  __typename: "AuthenticationResult";
  token: string | null;
  user: createOrder_createOrder_registration_result_user | null;
}

export interface createOrder_createOrder {
  __typename: "CreateOrderResult";
  order: createOrder_createOrder_order;
  rewrite_url: string | null;
  /**
   *  Ha a vásárló rendeléskor sikeresen regisztrált 
   */
  registration_result: createOrder_createOrder_registration_result | null;
  transaction_id: string | null;
}

export interface createOrder {
  createOrder: createOrder_createOrder;
}

export interface createOrderVariables {
  orderInput: OrderInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMe
// ====================================================

export interface deleteMe_deleteMe {
  __typename: "User";
  id: string;
}

export interface deleteMe {
  deleteMe: deleteMe_deleteMe;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMyUserBillingAddress
// ====================================================

export interface deleteMyUserBillingAddress_deleteMyUserBillingAddress {
  __typename: "User";
  id: string;
}

export interface deleteMyUserBillingAddress {
  deleteMyUserBillingAddress: deleteMyUserBillingAddress_deleteMyUserBillingAddress;
}

export interface deleteMyUserBillingAddressVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMyUserShippingAddress
// ====================================================

export interface deleteMyUserShippingAddress_deleteMyUserShippingAddress {
  __typename: "User";
  id: string;
}

export interface deleteMyUserShippingAddress {
  deleteMyUserShippingAddress: deleteMyUserShippingAddress_deleteMyUserShippingAddress;
}

export interface deleteMyUserShippingAddressVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSavedCreditCard
// ====================================================

export interface deleteSavedCreditCard_deleteSavedCreditCard {
  __typename: "UserSavedCreditCard";
  id: string;
}

export interface deleteSavedCreditCard {
  deleteSavedCreditCard: deleteSavedCreditCard_deleteSavedCreditCard;
}

export interface deleteSavedCreditCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login_user_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface login_login_user_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface login_login_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface login_login_user_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface login_login_user_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface login_login_user_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface login_login_user {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: login_login_user_default_billing_address | null;
  default_shipping_address: login_login_user_default_shipping_address | null;
  shipping_addresses: login_login_user_shipping_addresses[] | null;
  billing_addresses: login_login_user_billing_addresses[] | null;
  saved_credit_cards: login_login_user_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: login_login_user_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface login_login_cart_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface login_login_cart_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: login_login_cart_cart_products_product_empties_images_conversions;
}

export interface login_login_cart_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: login_login_cart_cart_products_product_empties_images[] | null;
}

export interface login_login_cart_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface login_login_cart_cart_products_product_images {
  __typename: "ProductImage";
  conversions: login_login_cart_cart_products_product_images_conversions;
}

export interface login_login_cart_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface login_login_cart_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: login_login_cart_cart_products_product_attributes_options[] | null;
}

export interface login_login_cart_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: login_login_cart_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: login_login_cart_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: login_login_cart_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface login_login_cart_cart_products {
  __typename: "CartProduct";
  product: login_login_cart_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface login_login_cart_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface login_login_cart_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: login_login_cart_timetable_time_slot_timeRange;
}

export interface login_login_cart_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type login_login_cart_shipping_method_ShippingMethodGPoint_errors = login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | login_login_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface login_login_cart_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (login_login_cart_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface login_login_cart_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type login_login_cart_shipping_method_ShippingMethodMpl_errors = login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | login_login_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface login_login_cart_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: login_login_cart_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (login_login_cart_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type login_login_cart_shipping_method_ShippingMethodGRoby_errors = login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | login_login_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface login_login_cart_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: login_login_cart_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: login_login_cart_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface login_login_cart_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (login_login_cart_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (login_login_cart_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface login_login_cart_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type login_login_cart_shipping_method_ShippingMethodPostPoint_errors = login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | login_login_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface login_login_cart_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: login_login_cart_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (login_login_cart_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type login_login_cart_shipping_method = login_login_cart_shipping_method_ShippingMethodGPoint | login_login_cart_shipping_method_ShippingMethodMpl | login_login_cart_shipping_method_ShippingMethodGRoby | login_login_cart_shipping_method_ShippingMethodPostPoint;

export interface login_login_cart_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface login_login_cart_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface login_login_cart_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface login_login_cart_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface login_login_cart_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface login_login_cart_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: login_login_cart_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type login_login_cart_payment_method = login_login_cart_payment_method_PaymentMethodCreditCardBorgun | login_login_cart_payment_method_PaymentMethodCreditCardSimple | login_login_cart_payment_method_PaymentMethodBankTransfer | login_login_cart_payment_method_PaymentMethodCashOnDelivery | login_login_cart_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface login_login_cart_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface login_login_cart_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface login_login_cart_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface login_login_cart_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface login_login_cart_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface login_login_cart_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface login_login_cart_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: login_login_cart_linked_order_timetable_time_slot_timeRange;
}

export interface login_login_cart_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: login_login_cart_linked_order_shipping_method;
  timetable_time_slot: login_login_cart_linked_order_timetable_time_slot | null;
}

export interface login_login_cart_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface login_login_cart_warnings {
  __typename: "CartProductWarning";
  product: login_login_cart_warnings_product;
  message: string;
}

export interface login_login_cart {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: login_login_cart_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: login_login_cart_timetable_time_slot | null;
  post_point: login_login_cart_post_point | null;
  shipping_method: login_login_cart_shipping_method | null;
  payment_method: login_login_cart_payment_method | null;
  shipping_address: login_login_cart_shipping_address | null;
  billing_address: login_login_cart_billing_address | null;
  payment_method_data: login_login_cart_payment_method_data | null;
  contact_data: login_login_cart_contact_data | null;
  linked_order: login_login_cart_linked_order | null;
  warnings: login_login_cart_warnings[] | null;
}

export interface login_login {
  __typename: "AuthenticationResult";
  token: string | null;
  user: login_login_user | null;
  cart: login_login_cart | null;
}

export interface login {
  login: login_login;
}

export interface loginVariables {
  loginData: LoginDataInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout_logout_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface logout_logout_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: logout_logout_cart_products_product_empties_images_conversions;
}

export interface logout_logout_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: logout_logout_cart_products_product_empties_images[] | null;
}

export interface logout_logout_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface logout_logout_cart_products_product_images {
  __typename: "ProductImage";
  conversions: logout_logout_cart_products_product_images_conversions;
}

export interface logout_logout_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface logout_logout_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: logout_logout_cart_products_product_attributes_options[] | null;
}

export interface logout_logout_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: logout_logout_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: logout_logout_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: logout_logout_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface logout_logout_cart_products {
  __typename: "CartProduct";
  product: logout_logout_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface logout_logout_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface logout_logout_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: logout_logout_timetable_time_slot_timeRange;
}

export interface logout_logout_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type logout_logout_shipping_method_ShippingMethodGPoint_errors = logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | logout_logout_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface logout_logout_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (logout_logout_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface logout_logout_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type logout_logout_shipping_method_ShippingMethodMpl_errors = logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | logout_logout_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface logout_logout_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: logout_logout_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (logout_logout_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type logout_logout_shipping_method_ShippingMethodGRoby_errors = logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | logout_logout_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface logout_logout_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: logout_logout_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: logout_logout_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface logout_logout_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (logout_logout_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (logout_logout_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface logout_logout_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type logout_logout_shipping_method_ShippingMethodPostPoint_errors = logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | logout_logout_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface logout_logout_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: logout_logout_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (logout_logout_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type logout_logout_shipping_method = logout_logout_shipping_method_ShippingMethodGPoint | logout_logout_shipping_method_ShippingMethodMpl | logout_logout_shipping_method_ShippingMethodGRoby | logout_logout_shipping_method_ShippingMethodPostPoint;

export interface logout_logout_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface logout_logout_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface logout_logout_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface logout_logout_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface logout_logout_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface logout_logout_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: logout_logout_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type logout_logout_payment_method = logout_logout_payment_method_PaymentMethodCreditCardBorgun | logout_logout_payment_method_PaymentMethodCreditCardSimple | logout_logout_payment_method_PaymentMethodBankTransfer | logout_logout_payment_method_PaymentMethodCashOnDelivery | logout_logout_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface logout_logout_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface logout_logout_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface logout_logout_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface logout_logout_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface logout_logout_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface logout_logout_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface logout_logout_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: logout_logout_linked_order_timetable_time_slot_timeRange;
}

export interface logout_logout_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: logout_logout_linked_order_shipping_method;
  timetable_time_slot: logout_logout_linked_order_timetable_time_slot | null;
}

export interface logout_logout_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface logout_logout_warnings {
  __typename: "CartProductWarning";
  product: logout_logout_warnings_product;
  message: string;
}

export interface logout_logout {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: logout_logout_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: logout_logout_timetable_time_slot | null;
  post_point: logout_logout_post_point | null;
  shipping_method: logout_logout_shipping_method | null;
  payment_method: logout_logout_payment_method | null;
  shipping_address: logout_logout_shipping_address | null;
  billing_address: logout_logout_billing_address | null;
  payment_method_data: logout_logout_payment_method_data | null;
  contact_data: logout_logout_contact_data | null;
  linked_order: logout_logout_linked_order | null;
  warnings: logout_logout_warnings[] | null;
}

export interface logout {
  logout: logout_logout | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: modifyOrderComment
// ====================================================

export interface modifyOrderComment_modifyOrderComment_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface modifyOrderComment_modifyOrderComment_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: modifyOrderComment_modifyOrderComment_timetable_time_slot_timeRange;
}

export interface modifyOrderComment_modifyOrderComment_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface modifyOrderComment_modifyOrderComment_shipping_method_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface modifyOrderComment_modifyOrderComment_shipping_method {
  __typename: "ShippingMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  price_ranges: modifyOrderComment_modifyOrderComment_shipping_method_price_ranges[] | null;
  discount_price: number | null;
  max_weight: number | null;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
}

export interface modifyOrderComment_modifyOrderComment_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  cost: number;
  is_active: boolean;
  sort: number;
  short_description: string | null;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_empties_images {
  __typename: "ProductImage";
  conversions: modifyOrderComment_modifyOrderComment_order_products_product_empties_images_conversions;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: modifyOrderComment_modifyOrderComment_order_products_product_empties_images[] | null;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_images {
  __typename: "ProductImage";
  conversions: modifyOrderComment_modifyOrderComment_order_products_product_images_conversions;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: modifyOrderComment_modifyOrderComment_order_products_product_attributes_options[] | null;
}

export interface modifyOrderComment_modifyOrderComment_order_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: modifyOrderComment_modifyOrderComment_order_products_product_empties | null;
  /**
   * Termék képek
   */
  images: modifyOrderComment_modifyOrderComment_order_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: modifyOrderComment_modifyOrderComment_order_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface modifyOrderComment_modifyOrderComment_order_products {
  __typename: "OrderProduct";
  price: number;
  special_price: number | null;
  default_quantity_name: string;
  default_quantity_piece: number;
  alternative_quantity_name: string | null;
  alternative_quantity_piece: number | null;
  product: modifyOrderComment_modifyOrderComment_order_products_product;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface modifyOrderComment_modifyOrderComment_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface modifyOrderComment_modifyOrderComment_coupon_data {
  __typename: "OrderCouponData";
  name: string;
  code: string;
  product_discount: number | null;
  shipping_discount: number | null;
}

export interface modifyOrderComment_modifyOrderComment_linked_orders {
  __typename: "Order";
  id: string;
}

export interface modifyOrderComment_modifyOrderComment_parent_order {
  __typename: "Order";
  id: string;
}

export interface modifyOrderComment_modifyOrderComment_donation_data {
  __typename: "OrderDonation";
  /**
   *  Az adakozás típusa (kerekített vagy egyedi összeg) 
   */
  type: OrderDonationType;
  /**
   *  Az adomány összege 
   */
  amount: number;
}

export interface modifyOrderComment_modifyOrderComment {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  timetable_time_slot: modifyOrderComment_modifyOrderComment_timetable_time_slot | null;
  post_point: modifyOrderComment_modifyOrderComment_post_point | null;
  shipping_method: modifyOrderComment_modifyOrderComment_shipping_method;
  payment_method: modifyOrderComment_modifyOrderComment_payment_method;
  payment_method_cost: number;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  billing_name: string;
  billing_postcode: string;
  billing_city: string;
  billing_street: string;
  billing_house_number: string;
  vat_number: string | null;
  is_company: boolean | null;
  comment: string | null;
  erp_comment: string | null;
  lyoness_card_number: string | null;
  order_products: modifyOrderComment_modifyOrderComment_order_products[];
  status: modifyOrderComment_modifyOrderComment_status;
  coupon_data: modifyOrderComment_modifyOrderComment_coupon_data | null;
  linked_orders: (modifyOrderComment_modifyOrderComment_linked_orders | null)[] | null;
  parent_order: modifyOrderComment_modifyOrderComment_parent_order | null;
  created_at: any;
  donation_data: modifyOrderComment_modifyOrderComment_donation_data | null;
}

export interface modifyOrderComment {
  modifyOrderComment: modifyOrderComment_modifyOrderComment;
}

export interface modifyOrderCommentVariables {
  orderId: string;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: passwordReset
// ====================================================

export interface passwordReset {
  passwordReset: boolean;
}

export interface passwordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: productPriceSubscribe
// ====================================================

export interface productPriceSubscribe {
  productPriceSubscribe: boolean;
}

export interface productPriceSubscribeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: productPriceUnsubscribe
// ====================================================

export interface productPriceUnsubscribe {
  productPriceUnsubscribe: boolean;
}

export interface productPriceUnsubscribeVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: productVote
// ====================================================

export interface productVote_productVote {
  __typename: "ProductVoteCalculated";
  vote_count_one: number;
  vote_count_two: number;
  vote_count_three: number;
  vote_count_four: number;
  vote_count_five: number;
  /**
   * A termékre leadott szavazatok átlaga
   */
  vote_avg: number;
}

export interface productVote {
  productVote: productVote_productVote;
}

export interface productVoteVariables {
  input: ProductVoteInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: registration
// ====================================================

export interface registration_registration_user_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface registration_registration_user_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface registration_registration_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface registration_registration_user_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface registration_registration_user_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface registration_registration_user_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface registration_registration_user {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: registration_registration_user_default_billing_address | null;
  default_shipping_address: registration_registration_user_default_shipping_address | null;
  shipping_addresses: registration_registration_user_shipping_addresses[] | null;
  billing_addresses: registration_registration_user_billing_addresses[] | null;
  saved_credit_cards: registration_registration_user_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: registration_registration_user_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface registration_registration {
  __typename: "AuthenticationResult";
  token: string | null;
  user: registration_registration_user | null;
}

export interface registration {
  registration: registration_registration;
}

export interface registrationVariables {
  registrationData: RegistrationDataInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replaceMyCartProducts
// ====================================================

export interface replaceMyCartProducts_replaceMyCartProducts_cart_products_product {
  __typename: "Product";
  id: string;
}

export interface replaceMyCartProducts_replaceMyCartProducts_cart_products {
  __typename: "CartProduct";
  product: replaceMyCartProducts_replaceMyCartProducts_cart_products_product;
  piece: number;
}

export interface replaceMyCartProducts_replaceMyCartProducts {
  __typename: "Cart";
  id: string;
  cart_products: replaceMyCartProducts_replaceMyCartProducts_cart_products[];
}

export interface replaceMyCartProducts {
  replaceMyCartProducts: replaceMyCartProducts_replaceMyCartProducts | null;
}

export interface replaceMyCartProductsVariables {
  products: CartProductDataInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: restartPayment
// ====================================================

export interface restartPayment_restartPayment_order {
  __typename: "Order";
  id: string;
}

export interface restartPayment_restartPayment {
  __typename: "CreateOrderResult";
  order: restartPayment_restartPayment_order;
  rewrite_url: string | null;
}

export interface restartPayment {
  restartPayment: restartPayment_restartPayment;
}

export interface restartPaymentVariables {
  orderId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setPassword
// ====================================================

export interface setPassword_setPassword_user_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface setPassword_setPassword_user_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface setPassword_setPassword_user_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface setPassword_setPassword_user_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface setPassword_setPassword_user_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface setPassword_setPassword_user_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface setPassword_setPassword_user {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: setPassword_setPassword_user_default_billing_address | null;
  default_shipping_address: setPassword_setPassword_user_default_shipping_address | null;
  shipping_addresses: setPassword_setPassword_user_shipping_addresses[] | null;
  billing_addresses: setPassword_setPassword_user_billing_addresses[] | null;
  saved_credit_cards: setPassword_setPassword_user_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: setPassword_setPassword_user_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface setPassword_setPassword {
  __typename: "AuthenticationResult";
  token: string | null;
  user: setPassword_setPassword_user | null;
}

export interface setPassword {
  setPassword: setPassword_setPassword;
}

export interface setPasswordVariables {
  input: SetPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateCart
// ====================================================

export interface updateCart_updateCart_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface updateCart_updateCart_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: updateCart_updateCart_cart_products_product_empties_images_conversions;
}

export interface updateCart_updateCart_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: updateCart_updateCart_cart_products_product_empties_images[] | null;
}

export interface updateCart_updateCart_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface updateCart_updateCart_cart_products_product_images {
  __typename: "ProductImage";
  conversions: updateCart_updateCart_cart_products_product_images_conversions;
}

export interface updateCart_updateCart_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface updateCart_updateCart_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: updateCart_updateCart_cart_products_product_attributes_options[] | null;
}

export interface updateCart_updateCart_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: updateCart_updateCart_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: updateCart_updateCart_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: updateCart_updateCart_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface updateCart_updateCart_cart_products {
  __typename: "CartProduct";
  product: updateCart_updateCart_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface updateCart_updateCart_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface updateCart_updateCart_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: updateCart_updateCart_timetable_time_slot_timeRange;
}

export interface updateCart_updateCart_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors = updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface updateCart_updateCart_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (updateCart_updateCart_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type updateCart_updateCart_shipping_method_ShippingMethodMpl_errors = updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | updateCart_updateCart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface updateCart_updateCart_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: updateCart_updateCart_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (updateCart_updateCart_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors = updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: updateCart_updateCart_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: updateCart_updateCart_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (updateCart_updateCart_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (updateCart_updateCart_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors = updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface updateCart_updateCart_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: updateCart_updateCart_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (updateCart_updateCart_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type updateCart_updateCart_shipping_method = updateCart_updateCart_shipping_method_ShippingMethodGPoint | updateCart_updateCart_shipping_method_ShippingMethodMpl | updateCart_updateCart_shipping_method_ShippingMethodGRoby | updateCart_updateCart_shipping_method_ShippingMethodPostPoint;

export interface updateCart_updateCart_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface updateCart_updateCart_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface updateCart_updateCart_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface updateCart_updateCart_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface updateCart_updateCart_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface updateCart_updateCart_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: updateCart_updateCart_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type updateCart_updateCart_payment_method = updateCart_updateCart_payment_method_PaymentMethodCreditCardBorgun | updateCart_updateCart_payment_method_PaymentMethodCreditCardSimple | updateCart_updateCart_payment_method_PaymentMethodBankTransfer | updateCart_updateCart_payment_method_PaymentMethodCashOnDelivery | updateCart_updateCart_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface updateCart_updateCart_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface updateCart_updateCart_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface updateCart_updateCart_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface updateCart_updateCart_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface updateCart_updateCart_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface updateCart_updateCart_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface updateCart_updateCart_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: updateCart_updateCart_linked_order_timetable_time_slot_timeRange;
}

export interface updateCart_updateCart_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: updateCart_updateCart_linked_order_shipping_method;
  timetable_time_slot: updateCart_updateCart_linked_order_timetable_time_slot | null;
}

export interface updateCart_updateCart_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface updateCart_updateCart_warnings {
  __typename: "CartProductWarning";
  product: updateCart_updateCart_warnings_product;
  message: string;
}

export interface updateCart_updateCart {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: updateCart_updateCart_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: updateCart_updateCart_timetable_time_slot | null;
  post_point: updateCart_updateCart_post_point | null;
  shipping_method: updateCart_updateCart_shipping_method | null;
  payment_method: updateCart_updateCart_payment_method | null;
  shipping_address: updateCart_updateCart_shipping_address | null;
  billing_address: updateCart_updateCart_billing_address | null;
  payment_method_data: updateCart_updateCart_payment_method_data | null;
  contact_data: updateCart_updateCart_contact_data | null;
  linked_order: updateCart_updateCart_linked_order | null;
  warnings: updateCart_updateCart_warnings[] | null;
}

export interface updateCart {
  updateCart: updateCart_updateCart | null;
}

export interface updateCartVariables {
  input: CartDataInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMe
// ====================================================

export interface updateMe_updateMe_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface updateMe_updateMe_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface updateMe_updateMe_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface updateMe_updateMe_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface updateMe_updateMe_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface updateMe_updateMe_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface updateMe_updateMe {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: updateMe_updateMe_default_billing_address | null;
  default_shipping_address: updateMe_updateMe_default_shipping_address | null;
  shipping_addresses: updateMe_updateMe_shipping_addresses[] | null;
  billing_addresses: updateMe_updateMe_billing_addresses[] | null;
  saved_credit_cards: updateMe_updateMe_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: updateMe_updateMe_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface updateMe {
  updateMe: updateMe_updateMe;
}

export interface updateMeVariables {
  input: UserDataInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMyUserBillingAddress
// ====================================================

export interface updateMyUserBillingAddress_updateMyUserBillingAddress {
  __typename: "UserBillingAddress";
  id: string;
}

export interface updateMyUserBillingAddress {
  updateMyUserBillingAddress: updateMyUserBillingAddress_updateMyUserBillingAddress;
}

export interface updateMyUserBillingAddressVariables {
  id: string;
  addressInput: UserBillingAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateMyUserShippingAddress
// ====================================================

export interface updateMyUserShippingAddress_updateMyUserShippingAddress {
  __typename: "UserShippingAddress";
  id: string;
}

export interface updateMyUserShippingAddress {
  updateMyUserShippingAddress: updateMyUserShippingAddress_updateMyUserShippingAddress;
}

export interface updateMyUserShippingAddressVariables {
  id: string;
  addressInput: UserShippingAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSavedCreditCard
// ====================================================

export interface updateSavedCreditCard_updateSavedCreditCard {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface updateSavedCreditCard {
  updateSavedCreditCard: updateSavedCreditCard_updateSavedCreditCard;
}

export interface updateSavedCreditCardVariables {
  input: UserSavedCreditCardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: activeDonation
// ====================================================

export interface activeDonation_activeDonation {
  __typename: "Donation";
  id: string;
  name: string;
  cms_url: string;
  active_from: any;
  active_to: any;
}

export interface activeDonation {
  activeDonation: activeDonation_activeDonation | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allProductCategories
// ====================================================

export interface allProductCategories_productCategories_children_children_children {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
}

export interface allProductCategories_productCategories_children_children {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
  children: allProductCategories_productCategories_children_children_children[] | null;
}

export interface allProductCategories_productCategories_children {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
  children: allProductCategories_productCategories_children_children[] | null;
}

export interface allProductCategories_productCategories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
  children: allProductCategories_productCategories_children[] | null;
}

export interface allProductCategories {
  productCategories: allProductCategories_productCategories[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allProductCategoryURLs
// ====================================================

export interface allProductCategoryURLs_productCategories_children_children_children {
  __typename: "ProductCategory";
  id: string;
  url_key: string;
}

export interface allProductCategoryURLs_productCategories_children_children {
  __typename: "ProductCategory";
  id: string;
  url_key: string;
  children: allProductCategoryURLs_productCategories_children_children_children[] | null;
}

export interface allProductCategoryURLs_productCategories_children {
  __typename: "ProductCategory";
  id: string;
  url_key: string;
  children: allProductCategoryURLs_productCategories_children_children[] | null;
}

export interface allProductCategoryURLs_productCategories {
  __typename: "ProductCategory";
  id: string;
  url_key: string;
  children: allProductCategoryURLs_productCategories_children[] | null;
}

export interface allProductCategoryURLs {
  productCategories: allProductCategoryURLs_productCategories[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: banners
// ====================================================

export interface banners_bannersByPlaceId {
  __typename: "Banner";
  name: string;
  url: string;
  url_target: BannerUrlTarget;
  image_url_mobile: string;
  image_url_desktop: string;
}

export interface banners {
  bannersByPlaceId: banners_bannersByPlaceId[];
}

export interface bannersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cartHash
// ====================================================

export interface cartHash_cart {
  __typename: "Cart";
  hash: string;
}

export interface cartHash {
  cart: cartHash_cart | null;
}

export interface cartHashVariables {
  cartId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkPaymentResult
// ====================================================

export interface checkPaymentResult_checkPaymentResult_order {
  __typename: "Order";
  id: string;
  total_amount: number;
}

export interface checkPaymentResult_checkPaymentResult_result {
  __typename: "PaymentGatewayResult";
  transaction_id: string;
  result_code: string | null;
  result_message: string | null;
  anum: string | null;
  order_id: string | null;
  user_id: string | null;
  provider_transaction_id: string | null;
  auto_commit: string | null;
  commit_state: string | null;
}

export interface checkPaymentResult_checkPaymentResult {
  __typename: "CheckPaymentResult";
  order: checkPaymentResult_checkPaymentResult_order;
  result: checkPaymentResult_checkPaymentResult_result;
}

export interface checkPaymentResult {
  checkPaymentResult: checkPaymentResult_checkPaymentResult | null;
}

export interface checkPaymentResultVariables {
  transaction_id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsContent
// ====================================================

export interface cmsContent_cmsContent {
  __typename: "CmsContent";
  title: string;
  content: string | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
}

export interface cmsContent {
  cmsContent: cmsContent_cmsContent | null;
}

export interface cmsContentVariables {
  url_key: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: emailAvailability
// ====================================================

export interface emailAvailability {
  emailAvailability: boolean;
}

export interface emailAvailabilityVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllUrlAlias
// ====================================================

export interface getAllUrlAlias_getAllUrlAlias {
  __typename: "UrlAlias";
  id: string;
  source_url: string;
  target_url: string;
}

export interface getAllUrlAlias {
  getAllUrlAlias: getAllUrlAlias_getAllUrlAlias[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCart
// ====================================================

export interface getCart_cart_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface getCart_cart_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: getCart_cart_cart_products_product_empties_images_conversions;
}

export interface getCart_cart_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: getCart_cart_cart_products_product_empties_images[] | null;
}

export interface getCart_cart_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface getCart_cart_cart_products_product_images {
  __typename: "ProductImage";
  conversions: getCart_cart_cart_products_product_images_conversions;
}

export interface getCart_cart_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface getCart_cart_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: getCart_cart_cart_products_product_attributes_options[] | null;
}

export interface getCart_cart_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: getCart_cart_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: getCart_cart_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: getCart_cart_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface getCart_cart_cart_products {
  __typename: "CartProduct";
  product: getCart_cart_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface getCart_cart_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface getCart_cart_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: getCart_cart_timetable_time_slot_timeRange;
}

export interface getCart_cart_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type getCart_cart_shipping_method_ShippingMethodGPoint_errors = getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | getCart_cart_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface getCart_cart_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCart_cart_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface getCart_cart_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type getCart_cart_shipping_method_ShippingMethodMpl_errors = getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | getCart_cart_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface getCart_cart_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: getCart_cart_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCart_cart_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type getCart_cart_shipping_method_ShippingMethodGRoby_errors = getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | getCart_cart_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface getCart_cart_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: getCart_cart_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: getCart_cart_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface getCart_cart_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCart_cart_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (getCart_cart_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface getCart_cart_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type getCart_cart_shipping_method_ShippingMethodPostPoint_errors = getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | getCart_cart_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface getCart_cart_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: getCart_cart_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCart_cart_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type getCart_cart_shipping_method = getCart_cart_shipping_method_ShippingMethodGPoint | getCart_cart_shipping_method_ShippingMethodMpl | getCart_cart_shipping_method_ShippingMethodGRoby | getCart_cart_shipping_method_ShippingMethodPostPoint;

export interface getCart_cart_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCart_cart_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCart_cart_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCart_cart_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCart_cart_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface getCart_cart_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: getCart_cart_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type getCart_cart_payment_method = getCart_cart_payment_method_PaymentMethodCreditCardBorgun | getCart_cart_payment_method_PaymentMethodCreditCardSimple | getCart_cart_payment_method_PaymentMethodBankTransfer | getCart_cart_payment_method_PaymentMethodCashOnDelivery | getCart_cart_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface getCart_cart_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface getCart_cart_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface getCart_cart_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface getCart_cart_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface getCart_cart_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface getCart_cart_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface getCart_cart_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: getCart_cart_linked_order_timetable_time_slot_timeRange;
}

export interface getCart_cart_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: getCart_cart_linked_order_shipping_method;
  timetable_time_slot: getCart_cart_linked_order_timetable_time_slot | null;
}

export interface getCart_cart_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface getCart_cart_warnings {
  __typename: "CartProductWarning";
  product: getCart_cart_warnings_product;
  message: string;
}

export interface getCart_cart {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: getCart_cart_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: getCart_cart_timetable_time_slot | null;
  post_point: getCart_cart_post_point | null;
  shipping_method: getCart_cart_shipping_method | null;
  payment_method: getCart_cart_payment_method | null;
  shipping_address: getCart_cart_shipping_address | null;
  billing_address: getCart_cart_billing_address | null;
  payment_method_data: getCart_cart_payment_method_data | null;
  contact_data: getCart_cart_contact_data | null;
  linked_order: getCart_cart_linked_order | null;
  warnings: getCart_cart_warnings[] | null;
}

export interface getCart {
  cart: getCart_cart | null;
}

export interface getCartVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCartSkeleton
// ====================================================

export interface getCartSkeleton_cart_cart_products_product {
  __typename: "Product";
  id: string;
}

export interface getCartSkeleton_cart_cart_products {
  __typename: "CartProduct";
  product: getCartSkeleton_cart_cart_products_product;
  piece: number;
}

export interface getCartSkeleton_cart {
  __typename: "Cart";
  id: string;
  cart_products: getCartSkeleton_cart_cart_products[];
}

export interface getCartSkeleton {
  cart: getCartSkeleton_cart | null;
}

export interface getCartSkeletonVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCheckoutInfo
// ====================================================

export interface getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors = getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface getCheckoutInfo_shippingMethods_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCheckoutInfo_shippingMethods_ShippingMethodGPoint_errors | null)[] | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors = getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorWeight | getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface getCheckoutInfo_shippingMethods_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: getCheckoutInfo_shippingMethods_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCheckoutInfo_shippingMethods_ShippingMethodMpl_errors | null)[] | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors = getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCheckoutInfo_shippingMethods_ShippingMethodGRoby_errors | null)[] | null;
  data: (getCheckoutInfo_shippingMethods_ShippingMethodGRoby_data | null)[];
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors = getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface getCheckoutInfo_shippingMethods_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (getCheckoutInfo_shippingMethods_ShippingMethodPostPoint_errors | null)[] | null;
}

export type getCheckoutInfo_shippingMethods = getCheckoutInfo_shippingMethods_ShippingMethodGPoint | getCheckoutInfo_shippingMethods_ShippingMethodMpl | getCheckoutInfo_shippingMethods_ShippingMethodGRoby | getCheckoutInfo_shippingMethods_ShippingMethodPostPoint;

export interface getCheckoutInfo_paymentMethods_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCheckoutInfo_paymentMethods_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCheckoutInfo_paymentMethods_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type getCheckoutInfo_paymentMethods = getCheckoutInfo_paymentMethods_PaymentMethodCreditCardBorgun | getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimple | getCheckoutInfo_paymentMethods_PaymentMethodBankTransfer | getCheckoutInfo_paymentMethods_PaymentMethodCashOnDelivery | getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved;

export interface getCheckoutInfo {
  shippingMethods: (getCheckoutInfo_shippingMethods | null)[];
  paymentMethods: (getCheckoutInfo_paymentMethods | null)[];
}

export interface getCheckoutInfoVariables {
  cartId?: string | null;
  postcode?: number | null;
  user_billing_address_id?: string | null;
  orderId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: linkableOrders
// ====================================================

export interface linkableOrders_linkableOrders_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface linkableOrders_linkableOrders_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: linkableOrders_linkableOrders_timetable_time_slot_timeRange;
}

export interface linkableOrders_linkableOrders_shipping_method_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface linkableOrders_linkableOrders_shipping_method {
  __typename: "ShippingMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  price_ranges: linkableOrders_linkableOrders_shipping_method_price_ranges[] | null;
  discount_price: number | null;
  max_weight: number | null;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
}

export interface linkableOrders_linkableOrders_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  short_description: string | null;
}

export interface linkableOrders_linkableOrders {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  timetable_time_slot: linkableOrders_linkableOrders_timetable_time_slot | null;
  shipping_method: linkableOrders_linkableOrders_shipping_method;
  payment_method: linkableOrders_linkableOrders_payment_method;
}

export interface linkableOrders {
  linkableOrders: linkableOrders_linkableOrders[] | null;
}

export interface linkableOrdersVariables {
  cartId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface me_me_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface me_me_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface me_me_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface me_me_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface me_me_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface me_me {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: me_me_default_billing_address | null;
  default_shipping_address: me_me_default_shipping_address | null;
  shipping_addresses: me_me_shipping_addresses[] | null;
  billing_addresses: me_me_billing_addresses[] | null;
  saved_credit_cards: me_me_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: me_me_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

export interface me {
  me: me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myOrderedProductCounts
// ====================================================

export interface myOrderedProductCounts_me_all_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface myOrderedProductCounts_me_all {
  __typename: "OrderedProductPaginator";
  paginatorInfo: myOrderedProductCounts_me_all_paginatorInfo;
}

export interface myOrderedProductCounts_me_discounted_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface myOrderedProductCounts_me_discounted {
  __typename: "OrderedProductPaginator";
  paginatorInfo: myOrderedProductCounts_me_discounted_paginatorInfo;
}

export interface myOrderedProductCounts_me {
  __typename: "User";
  all: myOrderedProductCounts_me_all | null;
  discounted: myOrderedProductCounts_me_discounted | null;
}

export interface myOrderedProductCounts {
  me: myOrderedProductCounts_me | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myOrderedProductIDs
// ====================================================

export interface myOrderedProductIDs_me_ordered_products_data_product {
  __typename: "Product";
  id: string;
}

export interface myOrderedProductIDs_me_ordered_products_data {
  __typename: "OrderedProduct";
  created_at: any;
  default_quantity_piece: number;
  default_quantity_name: string;
  product: myOrderedProductIDs_me_ordered_products_data_product;
}

export interface myOrderedProductIDs_me_ordered_products {
  __typename: "OrderedProductPaginator";
  data: myOrderedProductIDs_me_ordered_products_data[];
}

export interface myOrderedProductIDs_me {
  __typename: "User";
  ordered_products: myOrderedProductIDs_me_ordered_products | null;
}

export interface myOrderedProductIDs {
  me: myOrderedProductIDs_me | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myOrderedProducts
// ====================================================

export interface myOrderedProducts_me_ordered_products_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface myOrderedProducts_me_ordered_products_data_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface myOrderedProducts_me_ordered_products_data_product_empties_images {
  __typename: "ProductImage";
  conversions: myOrderedProducts_me_ordered_products_data_product_empties_images_conversions;
}

export interface myOrderedProducts_me_ordered_products_data_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: myOrderedProducts_me_ordered_products_data_product_empties_images[] | null;
}

export interface myOrderedProducts_me_ordered_products_data_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface myOrderedProducts_me_ordered_products_data_product_images {
  __typename: "ProductImage";
  conversions: myOrderedProducts_me_ordered_products_data_product_images_conversions;
}

export interface myOrderedProducts_me_ordered_products_data_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface myOrderedProducts_me_ordered_products_data_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: myOrderedProducts_me_ordered_products_data_product_attributes_options[] | null;
}

export interface myOrderedProducts_me_ordered_products_data_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: myOrderedProducts_me_ordered_products_data_product_empties | null;
  /**
   * Termék képek
   */
  images: myOrderedProducts_me_ordered_products_data_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: myOrderedProducts_me_ordered_products_data_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface myOrderedProducts_me_ordered_products_data {
  __typename: "OrderedProduct";
  product: myOrderedProducts_me_ordered_products_data_product;
}

export interface myOrderedProducts_me_ordered_products {
  __typename: "OrderedProductPaginator";
  paginatorInfo: myOrderedProducts_me_ordered_products_paginatorInfo;
  data: myOrderedProducts_me_ordered_products_data[];
}

export interface myOrderedProducts_me {
  __typename: "User";
  ordered_products: myOrderedProducts_me_ordered_products | null;
}

export interface myOrderedProducts {
  me: myOrderedProducts_me | null;
}

export interface myOrderedProductsVariables {
  page: number;
  isSpecial?: boolean | null;
  sortBy?: OrderedProductSort | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myOrders
// ====================================================

export interface myOrders_me_orders_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface myOrders_me_orders_data_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface myOrders_me_orders_data {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  created_at: any;
  status: myOrders_me_orders_data_status;
}

export interface myOrders_me_orders {
  __typename: "OrderPaginator";
  paginatorInfo: myOrders_me_orders_paginatorInfo;
  data: myOrders_me_orders_data[];
}

export interface myOrders_me {
  __typename: "User";
  orders: myOrders_me_orders | null;
}

export interface myOrders {
  me: myOrders_me | null;
}

export interface myOrdersVariables {
  count: number;
  page: number;
  filters?: OrderFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: order
// ====================================================

export interface order_me_orders_data_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface order_me_orders_data_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: order_me_orders_data_timetable_time_slot_timeRange;
}

export interface order_me_orders_data_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface order_me_orders_data_shipping_method_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface order_me_orders_data_shipping_method {
  __typename: "ShippingMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  price_ranges: order_me_orders_data_shipping_method_price_ranges[] | null;
  discount_price: number | null;
  max_weight: number | null;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
}

export interface order_me_orders_data_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  cost: number;
  is_active: boolean;
  sort: number;
  short_description: string | null;
}

export interface order_me_orders_data_order_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface order_me_orders_data_order_products_product_empties_images {
  __typename: "ProductImage";
  conversions: order_me_orders_data_order_products_product_empties_images_conversions;
}

export interface order_me_orders_data_order_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: order_me_orders_data_order_products_product_empties_images[] | null;
}

export interface order_me_orders_data_order_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface order_me_orders_data_order_products_product_images {
  __typename: "ProductImage";
  conversions: order_me_orders_data_order_products_product_images_conversions;
}

export interface order_me_orders_data_order_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface order_me_orders_data_order_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: order_me_orders_data_order_products_product_attributes_options[] | null;
}

export interface order_me_orders_data_order_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: order_me_orders_data_order_products_product_empties | null;
  /**
   * Termék képek
   */
  images: order_me_orders_data_order_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: order_me_orders_data_order_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface order_me_orders_data_order_products {
  __typename: "OrderProduct";
  price: number;
  special_price: number | null;
  default_quantity_name: string;
  default_quantity_piece: number;
  alternative_quantity_name: string | null;
  alternative_quantity_piece: number | null;
  product: order_me_orders_data_order_products_product;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface order_me_orders_data_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface order_me_orders_data_coupon_data {
  __typename: "OrderCouponData";
  name: string;
  code: string;
  product_discount: number | null;
  shipping_discount: number | null;
}

export interface order_me_orders_data_linked_orders {
  __typename: "Order";
  id: string;
}

export interface order_me_orders_data_parent_order {
  __typename: "Order";
  id: string;
}

export interface order_me_orders_data_donation_data {
  __typename: "OrderDonation";
  /**
   *  Az adakozás típusa (kerekített vagy egyedi összeg) 
   */
  type: OrderDonationType;
  /**
   *  Az adomány összege 
   */
  amount: number;
}

export interface order_me_orders_data {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  timetable_time_slot: order_me_orders_data_timetable_time_slot | null;
  post_point: order_me_orders_data_post_point | null;
  shipping_method: order_me_orders_data_shipping_method;
  payment_method: order_me_orders_data_payment_method;
  payment_method_cost: number;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  billing_name: string;
  billing_postcode: string;
  billing_city: string;
  billing_street: string;
  billing_house_number: string;
  vat_number: string | null;
  is_company: boolean | null;
  comment: string | null;
  erp_comment: string | null;
  lyoness_card_number: string | null;
  order_products: order_me_orders_data_order_products[];
  status: order_me_orders_data_status;
  coupon_data: order_me_orders_data_coupon_data | null;
  linked_orders: (order_me_orders_data_linked_orders | null)[] | null;
  parent_order: order_me_orders_data_parent_order | null;
  created_at: any;
  donation_data: order_me_orders_data_donation_data | null;
}

export interface order_me_orders {
  __typename: "OrderPaginator";
  data: order_me_orders_data[];
}

export interface order_me {
  __typename: "User";
  orders: order_me_orders | null;
}

export interface order {
  me: order_me | null;
}

export interface orderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: paymentMethods
// ====================================================

export interface paymentMethods_paymentMethods_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface paymentMethods_paymentMethods_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface paymentMethods_paymentMethods_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface paymentMethods_paymentMethods_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface paymentMethods_paymentMethods_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface paymentMethods_paymentMethods_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: paymentMethods_paymentMethods_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type paymentMethods_paymentMethods = paymentMethods_paymentMethods_PaymentMethodCreditCardBorgun | paymentMethods_paymentMethods_PaymentMethodCreditCardSimple | paymentMethods_paymentMethods_PaymentMethodBankTransfer | paymentMethods_paymentMethods_PaymentMethodCashOnDelivery | paymentMethods_paymentMethods_PaymentMethodCreditCardSimpleSaved;

export interface paymentMethods {
  paymentMethods: (paymentMethods_paymentMethods | null)[];
}

export interface paymentMethodsVariables {
  billingAddressId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: postcodes
// ====================================================

export interface postcodes_postcodes {
  __typename: "Postcode";
  postcode: number;
  city: string;
  street: string | null;
}

export interface postcodes {
  postcodes: postcodes_postcodes[];
}

export interface postcodesVariables {
  filters: FilterPostcodeDataInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: postPoints
// ====================================================

export interface postPoints_postPoints_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
}

export interface postPoints_postPoints_data {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface postPoints_postPoints {
  __typename: "PostPointPaginator";
  paginatorInfo: postPoints_postPoints_paginatorInfo;
  data: postPoints_postPoints_data[];
}

export interface postPoints {
  postPoints: postPoints_postPoints | null;
}

export interface postPointsVariables {
  count: number;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: product
// ====================================================

export interface product_product_categories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
}

export interface product_product_vote_calculated {
  __typename: "ProductVoteCalculated";
  vote_count_one: number;
  vote_count_two: number;
  vote_count_three: number;
  vote_count_four: number;
  vote_count_five: number;
  /**
   * A termékre leadott szavazatok átlaga
   */
  vote_avg: number;
}

export interface product_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface product_product_empties_images {
  __typename: "ProductImage";
  conversions: product_product_empties_images_conversions;
}

export interface product_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: product_product_empties_images[] | null;
}

export interface product_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface product_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: product_product_attributes_options[] | null;
}

export interface product_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface product_product_images {
  __typename: "ProductImage";
  conversions: product_product_images_conversions;
}

export interface product_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  categories: product_product_categories[] | null;
  default_category_id: number | null;
  price: number;
  /**
   * Vonalkódok
   */
  barcodes: string[] | null;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Internetes ár
   */
  webshop_only_price: boolean | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * Leírás
   */
  description: string | null;
  /**
   * Összetevők leírása
   */
  components: string | null;
  /**
   * Tápanyaginformációk
   */
  nutrition_data: string | null;
  /**
   * A termékre leadott szavazatok aggregálva
   */
  vote_calculated: product_product_vote_calculated | null;
  /**
   * Prefixboxtól kapott terméknépszerűség szám
   */
  prefixbox_score: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  is_subscribed: boolean;
  /**
   * Göngyöleg
   */
  empties: product_product_empties | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: product_product_attributes[] | null;
  /**
   * Termék képek
   */
  images: product_product_images[] | null;
}

export interface product {
  product: product_product | null;
}

export interface productVariables {
  getBy: getProductBy;
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productAttribute
// ====================================================

export interface productAttribute_productAttribute_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface productAttribute_productAttribute {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: productAttribute_productAttribute_options[] | null;
}

export interface productAttribute {
  productAttribute: productAttribute_productAttribute | null;
}

export interface productAttributeVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productCounts
// ====================================================

export interface productCounts_bannerProductCount {
  __typename: "BannerProductCount";
  all: number | null;
  mpl: number | null;
}

export interface productCounts {
  bannerProductCount: productCounts_bannerProductCount;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: products
// ====================================================

export interface products_products_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface products_products_aggregations_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
  count: number | null;
}

export interface products_products_aggregations_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  /**
   *  Az attribútum típusa
   */
  type: ProductAttributeType;
  is_active: boolean | null;
  options: products_products_aggregations_attributes_options[] | null;
  count: number | null;
}

export interface products_products_aggregations_categories {
  __typename: "ProductCategoryAggregation";
  id: string;
  name: string;
  product_count: number;
}

export interface products_products_aggregations {
  __typename: "ProductAggregations";
  min_price: number | null;
  max_price: number | null;
  attributes: products_products_aggregations_attributes[] | null;
  categories: products_products_aggregations_categories[] | null;
}

export interface products_products_products_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface products_products_products_empties_images {
  __typename: "ProductImage";
  conversions: products_products_products_empties_images_conversions;
}

export interface products_products_products_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: products_products_products_empties_images[] | null;
}

export interface products_products_products_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface products_products_products_images {
  __typename: "ProductImage";
  conversions: products_products_products_images_conversions;
}

export interface products_products_products_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface products_products_products_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: products_products_products_attributes_options[] | null;
}

export interface products_products_products {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: products_products_products_empties | null;
  /**
   * Termék képek
   */
  images: products_products_products_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: products_products_products_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface products_products {
  __typename: "AggregatedProductPaginator";
  paginatorInfo: products_products_paginatorInfo;
  aggregations: products_products_aggregations;
  products: products_products_products[] | null;
}

export interface products_productListAds_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface products_productListAds_product_empties_images {
  __typename: "ProductImage";
  conversions: products_productListAds_product_empties_images_conversions;
}

export interface products_productListAds_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: products_productListAds_product_empties_images[] | null;
}

export interface products_productListAds_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface products_productListAds_product_images {
  __typename: "ProductImage";
  conversions: products_productListAds_product_images_conversions;
}

export interface products_productListAds_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface products_productListAds_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: products_productListAds_product_attributes_options[] | null;
}

export interface products_productListAds_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: products_productListAds_product_empties | null;
  /**
   * Termék képek
   */
  images: products_productListAds_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: products_productListAds_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface products_productListAds {
  __typename: "ProductListAd";
  id: string;
  name: string;
  type: ProductListAdType;
  position: number;
  product: products_productListAds_product | null;
  image_url_desktop: string | null;
  image_url_mobile: string | null;
  banner_url: string | null;
}

export interface products {
  products: products_products;
  /**
   *  Csak az éppen aktívakat adja vissza 
   */
  productListAds: products_productListAds[] | null;
}

export interface productsVariables {
  filters?: ProductFilters | null;
  search?: string | null;
  sortBy?: ProductOrder | null;
  count?: number | null;
  page?: number | null;
  pageURL: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: searchProduct
// ====================================================

export interface searchProduct_products_products_categories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
}

export interface searchProduct_products_products_vote_calculated {
  __typename: "ProductVoteCalculated";
  vote_count_one: number;
  vote_count_two: number;
  vote_count_three: number;
  vote_count_four: number;
  vote_count_five: number;
  /**
   * A termékre leadott szavazatok átlaga
   */
  vote_avg: number;
}

export interface searchProduct_products_products_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface searchProduct_products_products_empties_images {
  __typename: "ProductImage";
  conversions: searchProduct_products_products_empties_images_conversions;
}

export interface searchProduct_products_products_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: searchProduct_products_products_empties_images[] | null;
}

export interface searchProduct_products_products_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface searchProduct_products_products_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: searchProduct_products_products_attributes_options[] | null;
}

export interface searchProduct_products_products_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface searchProduct_products_products_images {
  __typename: "ProductImage";
  conversions: searchProduct_products_products_images_conversions;
}

export interface searchProduct_products_products {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  categories: searchProduct_products_products_categories[] | null;
  default_category_id: number | null;
  price: number;
  /**
   * Vonalkódok
   */
  barcodes: string[] | null;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Internetes ár
   */
  webshop_only_price: boolean | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * Leírás
   */
  description: string | null;
  /**
   * Összetevők leírása
   */
  components: string | null;
  /**
   * Tápanyaginformációk
   */
  nutrition_data: string | null;
  /**
   * A termékre leadott szavazatok aggregálva
   */
  vote_calculated: searchProduct_products_products_vote_calculated | null;
  /**
   * Prefixboxtól kapott terméknépszerűség szám
   */
  prefixbox_score: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  is_subscribed: boolean;
  /**
   * Göngyöleg
   */
  empties: searchProduct_products_products_empties | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: searchProduct_products_products_attributes[] | null;
  /**
   * Termék képek
   */
  images: searchProduct_products_products_images[] | null;
}

export interface searchProduct_products {
  __typename: "AggregatedProductPaginator";
  products: searchProduct_products_products[] | null;
}

export interface searchProduct {
  products: searchProduct_products;
}

export interface searchProductVariables {
  page: number;
  count: number;
  ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PromotionCategories
// ====================================================

export interface PromotionCategories_promotionCategories_products_products_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface PromotionCategories_promotionCategories_products_products_empties_images {
  __typename: "ProductImage";
  conversions: PromotionCategories_promotionCategories_products_products_empties_images_conversions;
}

export interface PromotionCategories_promotionCategories_products_products_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: PromotionCategories_promotionCategories_products_products_empties_images[] | null;
}

export interface PromotionCategories_promotionCategories_products_products_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface PromotionCategories_promotionCategories_products_products_images {
  __typename: "ProductImage";
  conversions: PromotionCategories_promotionCategories_products_products_images_conversions;
}

export interface PromotionCategories_promotionCategories_products_products_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface PromotionCategories_promotionCategories_products_products_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: PromotionCategories_promotionCategories_products_products_attributes_options[] | null;
}

export interface PromotionCategories_promotionCategories_products_products {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: PromotionCategories_promotionCategories_products_products_empties | null;
  /**
   * Termék képek
   */
  images: PromotionCategories_promotionCategories_products_products_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: PromotionCategories_promotionCategories_products_products_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface PromotionCategories_promotionCategories_products_paginatorInfo {
  __typename: "PaginatorInfo";
  /**
   * Total items available in the collection.
   */
  total: number;
}

export interface PromotionCategories_promotionCategories_products {
  __typename: "AggregatedProductPaginator";
  products: PromotionCategories_promotionCategories_products_products[] | null;
  paginatorInfo: PromotionCategories_promotionCategories_products_paginatorInfo;
}

export interface PromotionCategories_promotionCategories_children {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
}

export interface PromotionCategories_promotionCategories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  promotion_place: PromotionPlace | null;
  products: PromotionCategories_promotionCategories_products;
  children: PromotionCategories_promotionCategories_children[] | null;
}

export interface PromotionCategories {
  promotionCategories: PromotionCategories_promotionCategories[] | null;
}

export interface PromotionCategoriesVariables {
  promotion_places?: PromotionPlace[] | null;
  filters?: ProductFilters | null;
  sortBy: ProductOrder;
  count: number;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: shippingMethods
// ====================================================

export interface shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type shippingMethods_shippingMethods_ShippingMethodGPoint_errors = shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | shippingMethods_shippingMethods_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface shippingMethods_shippingMethods_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (shippingMethods_shippingMethods_ShippingMethodGPoint_errors | null)[] | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type shippingMethods_shippingMethods_ShippingMethodMpl_errors = shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorWeight | shippingMethods_shippingMethods_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface shippingMethods_shippingMethods_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: shippingMethods_shippingMethods_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (shippingMethods_shippingMethods_ShippingMethodMpl_errors | null)[] | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type shippingMethods_shippingMethods_ShippingMethodGRoby_errors = shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | shippingMethods_shippingMethods_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: shippingMethods_shippingMethods_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: shippingMethods_shippingMethods_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface shippingMethods_shippingMethods_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (shippingMethods_shippingMethods_ShippingMethodGRoby_errors | null)[] | null;
  data: (shippingMethods_shippingMethods_ShippingMethodGRoby_data | null)[];
}

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type shippingMethods_shippingMethods_ShippingMethodPostPoint_errors = shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | shippingMethods_shippingMethods_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface shippingMethods_shippingMethods_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: shippingMethods_shippingMethods_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (shippingMethods_shippingMethods_ShippingMethodPostPoint_errors | null)[] | null;
}

export type shippingMethods_shippingMethods = shippingMethods_shippingMethods_ShippingMethodGPoint | shippingMethods_shippingMethods_ShippingMethodMpl | shippingMethods_shippingMethods_ShippingMethodGRoby | shippingMethods_shippingMethods_ShippingMethodPostPoint;

export interface shippingMethods {
  shippingMethods: (shippingMethods_shippingMethods | null)[];
}

export interface shippingMethodsVariables {
  cartId?: string | null;
  postcode?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BillingAddressFragment
// ====================================================

export interface BillingAddressFragment {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartBillingAddressFragment
// ====================================================

export interface CartBillingAddressFragment {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartFragment
// ====================================================

export interface CartFragment_cart_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface CartFragment_cart_products_product_empties_images {
  __typename: "ProductImage";
  conversions: CartFragment_cart_products_product_empties_images_conversions;
}

export interface CartFragment_cart_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: CartFragment_cart_products_product_empties_images[] | null;
}

export interface CartFragment_cart_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface CartFragment_cart_products_product_images {
  __typename: "ProductImage";
  conversions: CartFragment_cart_products_product_images_conversions;
}

export interface CartFragment_cart_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface CartFragment_cart_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: CartFragment_cart_products_product_attributes_options[] | null;
}

export interface CartFragment_cart_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: CartFragment_cart_products_product_empties | null;
  /**
   * Termék képek
   */
  images: CartFragment_cart_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: CartFragment_cart_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface CartFragment_cart_products {
  __typename: "CartProduct";
  product: CartFragment_cart_products_product;
  piece: number;
  alternative_quantity_piece: number | null;
  price: number;
  total_price: number;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface CartFragment_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface CartFragment_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  id: string;
  range_date: any;
  price: number;
  timeRange: CartFragment_timetable_time_slot_timeRange;
}

export interface CartFragment_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type CartFragment_shipping_method_ShippingMethodGPoint_errors = CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | CartFragment_shipping_method_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface CartFragment_shipping_method_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (CartFragment_shipping_method_ShippingMethodGPoint_errors | null)[] | null;
}

export interface CartFragment_shipping_method_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type CartFragment_shipping_method_ShippingMethodMpl_errors = CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorWeight | CartFragment_shipping_method_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface CartFragment_shipping_method_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: CartFragment_shipping_method_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (CartFragment_shipping_method_ShippingMethodMpl_errors | null)[] | null;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type CartFragment_shipping_method_ShippingMethodGRoby_errors = CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | CartFragment_shipping_method_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface CartFragment_shipping_method_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: CartFragment_shipping_method_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: CartFragment_shipping_method_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface CartFragment_shipping_method_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (CartFragment_shipping_method_ShippingMethodGRoby_errors | null)[] | null;
  data: (CartFragment_shipping_method_ShippingMethodGRoby_data | null)[];
}

export interface CartFragment_shipping_method_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type CartFragment_shipping_method_ShippingMethodPostPoint_errors = CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | CartFragment_shipping_method_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface CartFragment_shipping_method_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: CartFragment_shipping_method_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (CartFragment_shipping_method_ShippingMethodPostPoint_errors | null)[] | null;
}

export type CartFragment_shipping_method = CartFragment_shipping_method_ShippingMethodGPoint | CartFragment_shipping_method_ShippingMethodMpl | CartFragment_shipping_method_ShippingMethodGRoby | CartFragment_shipping_method_ShippingMethodPostPoint;

export interface CartFragment_payment_method_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface CartFragment_payment_method_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface CartFragment_payment_method_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface CartFragment_payment_method_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface CartFragment_payment_method_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface CartFragment_payment_method_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: CartFragment_payment_method_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type CartFragment_payment_method = CartFragment_payment_method_PaymentMethodCreditCardBorgun | CartFragment_payment_method_PaymentMethodCreditCardSimple | CartFragment_payment_method_PaymentMethodBankTransfer | CartFragment_payment_method_PaymentMethodCashOnDelivery | CartFragment_payment_method_PaymentMethodCreditCardSimpleSaved;

export interface CartFragment_shipping_address {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

export interface CartFragment_billing_address {
  __typename: "CartBillingAddressData";
  id: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number: string | null;
}

export interface CartFragment_payment_method_data {
  __typename: "CartPaymentMethodData";
  user_saved_credit_card_id: string;
}

export interface CartFragment_contact_data {
  __typename: "ContactPersonData";
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface CartFragment_linked_order_shipping_method {
  __typename: "ShippingMethod";
  id: string;
}

export interface CartFragment_linked_order_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface CartFragment_linked_order_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: CartFragment_linked_order_timetable_time_slot_timeRange;
}

export interface CartFragment_linked_order {
  __typename: "Order";
  id: string;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  created_at: any;
  shipping_method: CartFragment_linked_order_shipping_method;
  timetable_time_slot: CartFragment_linked_order_timetable_time_slot | null;
}

export interface CartFragment_warnings_product {
  __typename: "Product";
  id: string;
  name: string;
}

export interface CartFragment_warnings {
  __typename: "CartProductWarning";
  product: CartFragment_warnings_product;
  message: string;
}

export interface CartFragment {
  __typename: "Cart";
  id: string;
  hash: string;
  cart_products: CartFragment_cart_products[];
  total_price: number;
  discount_price: number | null;
  coupon_code: string | null;
  timetable_time_slot: CartFragment_timetable_time_slot | null;
  post_point: CartFragment_post_point | null;
  shipping_method: CartFragment_shipping_method | null;
  payment_method: CartFragment_payment_method | null;
  shipping_address: CartFragment_shipping_address | null;
  billing_address: CartFragment_billing_address | null;
  payment_method_data: CartFragment_payment_method_data | null;
  contact_data: CartFragment_contact_data | null;
  linked_order: CartFragment_linked_order | null;
  warnings: CartFragment_warnings[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartShippingAddressFragment
// ====================================================

export interface CartShippingAddressFragment {
  __typename: "CartShippingAddressData";
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  note: string | null;
  telephone: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartSkeletonFragment
// ====================================================

export interface CartSkeletonFragment_cart_products_product {
  __typename: "Product";
  id: string;
}

export interface CartSkeletonFragment_cart_products {
  __typename: "CartProduct";
  product: CartSkeletonFragment_cart_products_product;
  piece: number;
}

export interface CartSkeletonFragment {
  __typename: "Cart";
  id: string;
  cart_products: CartSkeletonFragment_cart_products[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryDataFragment
// ====================================================

export interface CategoryDataFragment {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
  content: string | null;
  keywords: string | null;
  description: string | null;
  is_promotion: boolean;
  is_homepage_prohibited: boolean;
  promotion_place: PromotionPlace | null;
  product_count: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderFragment
// ====================================================

export interface OrderFragment_timetable_time_slot_timeRange {
  __typename: "TimetableTimeRange";
  time_from: string;
  time_to: string;
}

export interface OrderFragment_timetable_time_slot {
  __typename: "TimetableTimeSlot";
  range_date: any;
  timeRange: OrderFragment_timetable_time_slot_timeRange;
}

export interface OrderFragment_post_point {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

export interface OrderFragment_shipping_method_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface OrderFragment_shipping_method {
  __typename: "ShippingMethod";
  id: string;
  name: string;
  is_active: boolean;
  sort: number;
  price_ranges: OrderFragment_shipping_method_price_ranges[] | null;
  discount_price: number | null;
  max_weight: number | null;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
}

export interface OrderFragment_payment_method {
  __typename: "PaymentMethod";
  id: string;
  name: string;
  cost: number;
  is_active: boolean;
  sort: number;
  short_description: string | null;
}

export interface OrderFragment_order_products_product_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface OrderFragment_order_products_product_empties_images {
  __typename: "ProductImage";
  conversions: OrderFragment_order_products_product_empties_images_conversions;
}

export interface OrderFragment_order_products_product_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: OrderFragment_order_products_product_empties_images[] | null;
}

export interface OrderFragment_order_products_product_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface OrderFragment_order_products_product_images {
  __typename: "ProductImage";
  conversions: OrderFragment_order_products_product_images_conversions;
}

export interface OrderFragment_order_products_product_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface OrderFragment_order_products_product_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: OrderFragment_order_products_product_attributes_options[] | null;
}

export interface OrderFragment_order_products_product {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: OrderFragment_order_products_product_empties | null;
  /**
   * Termék képek
   */
  images: OrderFragment_order_products_product_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: OrderFragment_order_products_product_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

export interface OrderFragment_order_products {
  __typename: "OrderProduct";
  price: number;
  special_price: number | null;
  default_quantity_name: string;
  default_quantity_piece: number;
  alternative_quantity_name: string | null;
  alternative_quantity_piece: number | null;
  product: OrderFragment_order_products_product;
  sliced: boolean | null;
  additional_information: string | null;
}

export interface OrderFragment_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface OrderFragment_coupon_data {
  __typename: "OrderCouponData";
  name: string;
  code: string;
  product_discount: number | null;
  shipping_discount: number | null;
}

export interface OrderFragment_linked_orders {
  __typename: "Order";
  id: string;
}

export interface OrderFragment_parent_order {
  __typename: "Order";
  id: string;
}

export interface OrderFragment_donation_data {
  __typename: "OrderDonation";
  /**
   *  Az adakozás típusa (kerekített vagy egyedi összeg) 
   */
  type: OrderDonationType;
  /**
   *  Az adomány összege 
   */
  amount: number;
}

export interface OrderFragment {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  timetable_time_slot: OrderFragment_timetable_time_slot | null;
  post_point: OrderFragment_post_point | null;
  shipping_method: OrderFragment_shipping_method;
  payment_method: OrderFragment_payment_method;
  payment_method_cost: number;
  shipping_name: string;
  shipping_postcode: string;
  shipping_city: string;
  shipping_street: string;
  shipping_house_number: string;
  shipping_telephone: string;
  shipping_note: string | null;
  billing_name: string;
  billing_postcode: string;
  billing_city: string;
  billing_street: string;
  billing_house_number: string;
  vat_number: string | null;
  is_company: boolean | null;
  comment: string | null;
  erp_comment: string | null;
  lyoness_card_number: string | null;
  order_products: OrderFragment_order_products[];
  status: OrderFragment_status;
  coupon_data: OrderFragment_coupon_data | null;
  linked_orders: (OrderFragment_linked_orders | null)[] | null;
  parent_order: OrderFragment_parent_order | null;
  created_at: any;
  donation_data: OrderFragment_donation_data | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderListFragment
// ====================================================

export interface OrderListFragment_status {
  __typename: "OrderStatus";
  title: string;
  code: OrderStatusCode;
}

export interface OrderListFragment {
  __typename: "Order";
  id: string;
  total_amount: number;
  shipping_cost: number;
  created_at: any;
  status: OrderListFragment_status;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaginatorInfoFragment
// ====================================================

export interface PaginatorInfoFragment {
  __typename: "PaginatorInfo";
  /**
   * Total count of available items in the page.
   */
  count: number;
  /**
   * Current pagination page.
   */
  currentPage: number;
  /**
   * Index of first item in the current page.
   */
  firstItem: number | null;
  /**
   * If collection has more pages.
   */
  hasMorePages: boolean;
  /**
   * Index of last item in the current page.
   */
  lastItem: number | null;
  /**
   * Last page number of the collection.
   */
  lastPage: number;
  /**
   * Number of items per page in the collection.
   */
  perPage: number;
  /**
   * Total items available in the collection.
   */
  total: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterfacePaymentMethodFragment
// ====================================================

export interface InterfacePaymentMethodFragment {
  __typename: "PaymentMethodBankTransfer" | "PaymentMethodCashOnDelivery" | "PaymentMethodCreditCardBorgun" | "PaymentMethodCreditCardSimple" | "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PaymentMethodFragment
// ====================================================

export interface PaymentMethodFragment_PaymentMethodCreditCardBorgun {
  __typename: "PaymentMethodCreditCardBorgun";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface PaymentMethodFragment_PaymentMethodCreditCardSimple {
  __typename: "PaymentMethodCreditCardSimple";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface PaymentMethodFragment_PaymentMethodBankTransfer {
  __typename: "PaymentMethodBankTransfer";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface PaymentMethodFragment_PaymentMethodCashOnDelivery {
  __typename: "PaymentMethodCashOnDelivery";
  short_description_mpl_type: string | null;
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
}

export interface PaymentMethodFragment_PaymentMethodCreditCardSimpleSaved_data {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface PaymentMethodFragment_PaymentMethodCreditCardSimpleSaved {
  __typename: "PaymentMethodCreditCardSimpleSaved";
  id: string;
  name: string;
  is_available: boolean;
  cost: number;
  short_description: string | null;
  data: PaymentMethodFragment_PaymentMethodCreditCardSimpleSaved_data[] | null;
}

export type PaymentMethodFragment = PaymentMethodFragment_PaymentMethodCreditCardBorgun | PaymentMethodFragment_PaymentMethodCreditCardSimple | PaymentMethodFragment_PaymentMethodBankTransfer | PaymentMethodFragment_PaymentMethodCashOnDelivery | PaymentMethodFragment_PaymentMethodCreditCardSimpleSaved;

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostPointFragment
// ====================================================

export interface PostPointFragment {
  __typename: "PostPoint";
  id: string;
  name: string;
  ext_id: string;
  postcode: number;
  city: string;
  street_name: string | null;
  street_type: string | null;
  house_number: string | null;
  latitude: number;
  longitude: number;
  service_point_type: string | null;
  working_hours: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductAllData
// ====================================================

export interface ProductAllData_categories {
  __typename: "ProductCategory";
  id: string;
  name: string;
  url_key: string;
}

export interface ProductAllData_vote_calculated {
  __typename: "ProductVoteCalculated";
  vote_count_one: number;
  vote_count_two: number;
  vote_count_three: number;
  vote_count_four: number;
  vote_count_five: number;
  /**
   * A termékre leadott szavazatok átlaga
   */
  vote_avg: number;
}

export interface ProductAllData_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface ProductAllData_empties_images {
  __typename: "ProductImage";
  conversions: ProductAllData_empties_images_conversions;
}

export interface ProductAllData_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: ProductAllData_empties_images[] | null;
}

export interface ProductAllData_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface ProductAllData_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: ProductAllData_attributes_options[] | null;
}

export interface ProductAllData_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface ProductAllData_images {
  __typename: "ProductImage";
  conversions: ProductAllData_images_conversions;
}

export interface ProductAllData {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  categories: ProductAllData_categories[] | null;
  default_category_id: number | null;
  price: number;
  /**
   * Vonalkódok
   */
  barcodes: string[] | null;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Internetes ár
   */
  webshop_only_price: boolean | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * Leírás
   */
  description: string | null;
  /**
   * Összetevők leírása
   */
  components: string | null;
  /**
   * Tápanyaginformációk
   */
  nutrition_data: string | null;
  /**
   * A termékre leadott szavazatok aggregálva
   */
  vote_calculated: ProductAllData_vote_calculated | null;
  /**
   * Prefixboxtól kapott terméknépszerűség szám
   */
  prefixbox_score: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  is_subscribed: boolean;
  /**
   * Göngyöleg
   */
  empties: ProductAllData_empties | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: ProductAllData_attributes[] | null;
  /**
   * Termék képek
   */
  images: ProductAllData_images[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductAttributeFragment
// ====================================================

export interface ProductAttributeFragment_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface ProductAttributeFragment {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: ProductAttributeFragment_options[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductListData
// ====================================================

export interface ProductListData_empties_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface ProductListData_empties_images {
  __typename: "ProductImage";
  conversions: ProductListData_empties_images_conversions;
}

export interface ProductListData_empties {
  __typename: "Product";
  id: string;
  name: string;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Tömeg grammban
   */
  weight: number;
  /**
   * Termék képek
   */
  images: ProductListData_empties_images[] | null;
}

export interface ProductListData_images_conversions {
  __typename: "ProductImageConversions";
  thumbnail: string | null;
  list: string | null;
  full: string | null;
}

export interface ProductListData_images {
  __typename: "ProductImage";
  conversions: ProductListData_images_conversions;
}

export interface ProductListData_attributes_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
}

export interface ProductListData_attributes {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  is_active: boolean | null;
  options: ProductListData_attributes_options[] | null;
}

export interface ProductListData {
  __typename: "Product";
  id: string;
  /**
   *  ERP-ben használt id, az olyan termékeknél null, amik nincsenek meg az ERP-ben, de a rendelésekkel áthoztuk 
   */
  ext_id: number | null;
  /**
   * Régi webshop termék id
   */
  previous_id: string | null;
  name: string;
  /**
   * Termék státusza/elérhetősége
   */
  status: ProductStatus;
  /**
   * Új url
   */
  url_key: string | null;
  /**
   * Régi url
   */
  smarturl: string | null;
  default_category_id: number | null;
  price: number;
  /**
   * Az egységár egysége
   */
  price_unit: string | null;
  /**
   * Az egységár szorzója
   */
  price_per_unit: number | null;
  /**
   * Egységár
   */
  unit_price: number | null;
  /**
   * Akciós egységár
   */
  special_unit_price: number | null;
  /**
   * ÁFA
   */
  vat: number;
  /**
   * Akciós ár, bruttó
   */
  special_price: number | null;
  /**
   * Az akciós ár ettől a dátumtól érvényes
   */
  special_from_date: any | null;
  /**
   * Az akciós ár eeddig a dátumig érvényes
   */
  special_to_date: any | null;
  /**
   * Ettől a dátumtól "új" a termék
   */
  news_from_date: any | null;
  /**
   * Eddig a dátumig "új" a termék
   */
  news_to_date: any | null;
  /**
   *  Felhasználói egyedi kedvezményes ár 
   */
  personal_discount_price: number | null;
  /**
   *  Felhasználói egyedi kedvezményes egységár 
   */
  personal_discount_unit_price: number | null;
  /**
   * Alternatív mennyiség neve
   */
  alternative_quantity_name: ProductAlternativeQuantityName | null;
  /**
   * Alternatív mennyiség legkisebb választható egsége
   */
  alternative_quantity_minimum: number | null;
  /**
   * Alternatív mennyiség léptetési egysége
   */
  alternative_quantity_scale: number | null;
  /**
   * Eredeti/alternatív egység átváltási arány
   */
  alternative_quantity_exchange_rate: number | null;
  /**
   * Alapértelmezett mennyiség neve
   */
  default_quantity_name: ProductDefaultQuantityName | null;
  /**
   * Alapértelmezett mennyiség legkisebb választható egysége
   */
  default_quantity_minimum: number | null;
  /**
   * Alapértelmezett mennyiség léptetési egysége
   */
  default_quantity_scale: number | null;
  /**
   * Ajándékkártyával rendelhető
   */
  gift_message_available: boolean;
  /**
   * Csomagfutárral (MPL) szállítható
   */
  is_mpl: boolean;
  /**
   * Minimum szavatosság: x nap
   */
  min_expiry_days: number | null;
  /**
   * A termék tényleges tömege
   */
  package_official_g: number | null;
  /**
   * A termék tényleges űrtartalma
   */
  package_official_ml: number | null;
  /**
   * Szeletelve rendelhető
   */
  sliceable: boolean | null;
  /**
   * Göngyöleg
   */
  empties: ProductListData_empties | null;
  /**
   * Termék képek
   */
  images: ProductListData_images[] | null;
  /**
   *  A termékhez rendelt attribútumok és értékeik
   */
  attributes: ProductListData_attributes[] | null;
  /**
   * Tömeg grammban
   */
  weight: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SavedCreditCardFragment
// ====================================================

export interface SavedCreditCardFragment {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingAddressFragment
// ====================================================

export interface ShippingAddressFragment {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InterfaceShippingMethodFragment
// ====================================================

export interface InterfaceShippingMethodFragment_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface InterfaceShippingMethodFragment_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface InterfaceShippingMethodFragment_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface InterfaceShippingMethodFragment_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: InterfaceShippingMethodFragment_errors_ShippingMethodErrorProducts_data[];
}

export type InterfaceShippingMethodFragment_errors = InterfaceShippingMethodFragment_errors_ShippingMethodErrorPostcode | InterfaceShippingMethodFragment_errors_ShippingMethodErrorWeight | InterfaceShippingMethodFragment_errors_ShippingMethodErrorProducts;

export interface InterfaceShippingMethodFragment {
  __typename: "ShippingMethodGPoint" | "ShippingMethodPostPoint" | "ShippingMethodMpl" | "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (InterfaceShippingMethodFragment_errors | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ShippingMethodFragment
// ====================================================

export interface ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorProducts_data[];
}

export type ShippingMethodFragment_ShippingMethodGPoint_errors = ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorPostcode | ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorWeight | ShippingMethodFragment_ShippingMethodGPoint_errors_ShippingMethodErrorProducts;

export interface ShippingMethodFragment_ShippingMethodGPoint {
  __typename: "ShippingMethodGPoint";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (ShippingMethodFragment_ShippingMethodGPoint_errors | null)[] | null;
}

export interface ShippingMethodFragment_ShippingMethodMpl_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorProducts_data[];
}

export type ShippingMethodFragment_ShippingMethodMpl_errors = ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorPostcode | ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorWeight | ShippingMethodFragment_ShippingMethodMpl_errors_ShippingMethodErrorProducts;

export interface ShippingMethodFragment_ShippingMethodMpl {
  __typename: "ShippingMethodMpl";
  price_ranges: ShippingMethodFragment_ShippingMethodMpl_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (ShippingMethodFragment_ShippingMethodMpl_errors | null)[] | null;
}

export interface ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorProducts_data[];
}

export type ShippingMethodFragment_ShippingMethodGRoby_errors = ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorPostcode | ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorWeight | ShippingMethodFragment_ShippingMethodGRoby_errors_ShippingMethodErrorProducts;

export interface ShippingMethodFragment_ShippingMethodGRoby_data_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface ShippingMethodFragment_ShippingMethodGRoby_data_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface ShippingMethodFragment_ShippingMethodGRoby_data {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: ShippingMethodFragment_ShippingMethodGRoby_data_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: ShippingMethodFragment_ShippingMethodGRoby_data_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

export interface ShippingMethodFragment_ShippingMethodGRoby {
  __typename: "ShippingMethodGRoby";
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (ShippingMethodFragment_ShippingMethodGRoby_errors | null)[] | null;
  data: (ShippingMethodFragment_ShippingMethodGRoby_data | null)[];
}

export interface ShippingMethodFragment_ShippingMethodPostPoint_price_ranges {
  __typename: "ShippingMethodPriceRange";
  from: number;
  price: number;
}

export interface ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode {
  __typename: "ShippingMethodErrorPostcode";
}

export interface ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight {
  __typename: "ShippingMethodErrorWeight";
  message: string | null;
}

export interface ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data {
  __typename: "Product";
  id: string;
}

export interface ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts {
  __typename: "ShippingMethodErrorProducts";
  message: string | null;
  data: ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts_data[];
}

export type ShippingMethodFragment_ShippingMethodPostPoint_errors = ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorPostcode | ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorWeight | ShippingMethodFragment_ShippingMethodPostPoint_errors_ShippingMethodErrorProducts;

export interface ShippingMethodFragment_ShippingMethodPostPoint {
  __typename: "ShippingMethodPostPoint";
  price_ranges: ShippingMethodFragment_ShippingMethodPostPoint_price_ranges[] | null;
  id: string;
  name: string;
  price: number;
  discount_price: number | null;
  max_weight: number | null;
  is_available: boolean;
  description: string | null;
  short_description: string | null;
  delivery_time: string | null;
  errors: (ShippingMethodFragment_ShippingMethodPostPoint_errors | null)[] | null;
}

export type ShippingMethodFragment = ShippingMethodFragment_ShippingMethodGPoint | ShippingMethodFragment_ShippingMethodMpl | ShippingMethodFragment_ShippingMethodGRoby | ShippingMethodFragment_ShippingMethodPostPoint;

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TimetableSlotFragment
// ====================================================

export interface TimetableSlotFragment_sector {
  __typename: "TimetableSector";
  id: string;
  name: string;
}

export interface TimetableSlotFragment_timeRange {
  __typename: "TimetableTimeRange";
  id: string;
  time_from: string;
  time_to: string;
}

export interface TimetableSlotFragment {
  __typename: "TimetableTimeSlot";
  id: string;
  sector: TimetableSlotFragment_sector;
  order_limit: number;
  weight_limit: number;
  price: number;
  range_date: any;
  timeRange: TimetableSlotFragment_timeRange;
  visible_until: any;
  status: TimetableTimeSlotStatusType;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_default_billing_address {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface UserFragment_default_shipping_address {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface UserFragment_shipping_addresses {
  __typename: "UserShippingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_goodid: boolean;
  note: string | null;
  telephone: string | null;
}

export interface UserFragment_billing_addresses {
  __typename: "UserBillingAddress";
  id: string;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  vat_number: string | null;
  is_company: boolean;
  is_goodid: boolean;
  bank_transfer_enabled: boolean;
}

export interface UserFragment_saved_credit_cards {
  __typename: "UserSavedCreditCard";
  id: string;
  card_title: string | null;
  card_type: string | null;
  card_pan: string | null;
  card_exp: any | null;
  is_default: boolean;
}

export interface UserFragment_good_id_diff {
  __typename: "UserGoodIdDiff";
  /**
   * email, name, telephone kulcsok
   */
  diff: any | null;
}

export interface UserFragment {
  __typename: "User";
  id: string;
  ext_id: string;
  last_login: any | null;
  email: string;
  name: string;
  telephone: string | null;
  default_billing_address: UserFragment_default_billing_address | null;
  default_shipping_address: UserFragment_default_shipping_address | null;
  shipping_addresses: UserFragment_shipping_addresses[] | null;
  billing_addresses: UserFragment_billing_addresses[] | null;
  saved_credit_cards: UserFragment_saved_credit_cards[] | null;
  /**
   * A GoodId-nál tárolt, eltérő adatok
   */
  good_id_diff: UserFragment_good_id_diff | null;
  disable_donations: boolean | null;
  created_at: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductAggregationAttribute
// ====================================================

export interface ProductAggregationAttribute_options {
  __typename: "ProductAttributeOption";
  id: string;
  label: string;
  position: number | null;
  is_active: boolean;
  is_default: boolean;
  count: number | null;
}

export interface ProductAggregationAttribute {
  __typename: "ProductAttribute";
  id: string;
  name: string;
  code: string;
  /**
   *  Az attribútumra lehet-e szűrni a termékeket
   */
  filterable: boolean | null;
  /**
   *  Az attribútum típusa
   */
  type: ProductAttributeType;
  is_active: boolean | null;
  options: ProductAggregationAttribute_options[] | null;
  count: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthenticationType {
  facebook = "facebook",
  goodid = "goodid",
  google = "google",
  okoslista = "okoslista",
  password = "password",
}

export enum BannerUrlTarget {
  _blank = "_blank",
  _self = "_self",
}

export enum CheckoutType {
  EXPRESS = "EXPRESS",
  NORMAL = "NORMAL",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OrderDonationType {
  ROUND = "ROUND",
  UNIQUE = "UNIQUE",
}

export enum OrderStatusCode {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  COMPLETE = "COMPLETE",
  GPOINT_SHIPPED = "GPOINT_SHIPPED",
  GROBY_SHIPPED = "GROBY_SHIPPED",
  INVOICED = "INVOICED",
  MARKED_TO_CANCEL = "MARKED_TO_CANCEL",
  MPL_SHIPPED = "MPL_SHIPPED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
  PAYMENT_REFUSED = "PAYMENT_REFUSED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}

export enum OrderUserType {
  GUEST = "GUEST",
  NEW = "NEW",
  RETURNING = "RETURNING",
}

export enum OrderedProductOrderField {
  CREATED_AT = "CREATED_AT",
  NAME = "NAME",
  PREFIXBOX_SCORE = "PREFIXBOX_SCORE",
  PRICE = "PRICE",
  UNIT_PRICE = "UNIT_PRICE",
}

export enum ProductAlternativeQuantityName {
  db = "db",
  karton = "karton",
}

export enum ProductAttributeType {
  MULTIPLE = "MULTIPLE",
  SIMPLE = "SIMPLE",
}

export enum ProductDefaultQuantityName {
  db = "db",
  kg = "kg",
}

export enum ProductListAdType {
  BANNER = "BANNER",
  PRODUCT = "PRODUCT",
}

export enum ProductOrderField {
  ID = "ID",
  NAME = "NAME",
  PREFIXBOX_SCORE = "PREFIXBOX_SCORE",
  PRICE = "PRICE",
  SEARCH_SCORE = "SEARCH_SCORE",
  SORT = "SORT",
  UNIT_PRICE = "UNIT_PRICE",
}

export enum ProductStatus {
  AVAILABLE = "AVAILABLE",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  PERMANENTLY_ENDED = "PERMANENTLY_ENDED",
  SEASONAL = "SEASONAL",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum PromotionPlace {
  PROMO_1 = "PROMO_1",
  PROMO_2 = "PROMO_2",
  PROMO_3 = "PROMO_3",
}

export enum TimetableTimeSlotStatusType {
  ACTIVE = "ACTIVE",
  FULL = "FULL",
  INACTIVE = "INACTIVE",
}

export enum VoteType {
  FIVE = "FIVE",
  FOUR = "FOUR",
  ONE = "ONE",
  THREE = "THREE",
  TWO = "TWO",
}

export enum getProductBy {
  ID = "ID",
  URL = "URL",
}

export enum numericFields {
  PRICE = "PRICE",
  UNIT_PRICE = "UNIT_PRICE",
}

export interface CartBillingAddressInput {
  id?: string | null;
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_company: boolean;
  vat_number?: string | null;
}

export interface CartDataInput {
  id: string;
  products?: CartProductDataInput[] | null;
  coupon_code?: string | null;
  timetable_time_slot_id?: number | null;
  post_point_id?: number | null;
  shipping_method_id?: string | null;
  payment_method_id?: string | null;
  shipping_address?: CartShippingAddressInput | null;
  billing_address?: CartBillingAddressInput | null;
  payment_method_data?: CartPaymentMethodDataInput | null;
  linked_order_id?: string | null;
  contact_data?: ContactPersonDataInput | null;
}

export interface CartPaymentMethodDataInput {
  user_saved_credit_card_id?: string | null;
}

export interface CartProductDataInput {
  product_id: string;
  piece: number;
  alternative_quantity_piece?: number | null;
  sliced?: boolean | null;
  additional_information?: string | null;
}

export interface CartShippingAddressInput {
  name: string;
  postcode?: number | null;
  city?: string | null;
  street?: string | null;
  house_number?: string | null;
  note?: string | null;
  telephone: string;
}

export interface ChangePaymentMethodInput {
  order_id: string;
  payment_method_id: string;
  payment_method_data?: CartPaymentMethodDataInput | null;
}

export interface ContactPersonDataInput {
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
}

export interface FilterPostcodeDataInput {
  postcode: number;
}

export interface LoginDataInput {
  authType: AuthenticationType;
  email?: string | null;
  password: string;
  cart_id?: string | null;
}

export interface OrderDonationInput {
  type: OrderDonationType;
  amount: number;
}

export interface OrderFilters {
  id?: string | null;
  user_id?: string | null;
  user_ext_id?: string | null;
  timetable_time_slot_id?: string | null;
  contact_name?: string | null;
  status_id?: string | null;
  shipping_method_id?: string | null;
  payment_method_id?: string | null;
  create_at_from?: any | null;
  create_at_to?: any | null;
  is_admin_checked?: boolean | null;
}

export interface OrderInput {
  cart_id: string;
  lyoness_card_number?: string | null;
  comment?: string | null;
  password?: string | null;
  gamp_cid?: string | null;
  gamp_session_id?: string | null;
  gamp_checkout_type?: CheckoutType | null;
  donation_data?: OrderDonationInput | null;
}

export interface OrderedProductSort {
  field: OrderedProductOrderField;
  direction: OrderDirection;
}

export interface ProductAttributeFilter {
  attribute_id: string;
  options: string[];
}

export interface ProductFilters {
  ids?: string[] | null;
  category?: string[] | null;
  attributes?: ProductAttributeFilter[] | null;
  productStatus?: ProductStatus[] | null;
  is_special?: boolean | null;
  is_new?: boolean | null;
  numericRange?: ProductNumericFieldRangeFilter[] | null;
  postcode?: number | null;
  remove_homepage_prohibited?: boolean | null;
}

export interface ProductNumericFieldRangeFilter {
  field: numericFields;
  from: number;
  to: number;
}

export interface ProductOrder {
  field: ProductOrderField;
  direction: OrderDirection;
}

export interface ProductVoteInput {
  product_id: string;
  vote: VoteType;
}

export interface RegistrationDataInput {
  name: string;
  email: string;
  password: string;
  cart_id?: string | null;
}

export interface SetPasswordInput {
  token: string;
  email: string;
  password: string;
  cart_id?: string | null;
}

export interface UserBillingAddressInput {
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  is_company: boolean;
  vat_number?: string | null;
}

export interface UserDataInput {
  id: string;
  name?: string | null;
  email?: string | null;
  telephone?: string | null;
  default_billing_address?: string | null;
  default_shipping_address?: string | null;
  lyoness_card_number?: string | null;
  disable_donations?: boolean | null;
}

export interface UserPasswordChangeInput {
  previous: string;
  new: string;
}

export interface UserSavedCreditCardInput {
  id: string;
  card_title?: string | null;
  is_default: boolean;
}

export interface UserShippingAddressInput {
  name: string;
  postcode: string;
  city: string;
  street: string;
  house_number: string;
  is_default: boolean;
  note?: string | null;
  telephone: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
