import { brandSlug } from './categoryDetailsHelper'
import { ProductListData, ProductAttributeFragment, ProductAttributeFragment_options, ProductAllData_vote_calculated, ProductAllData_images_conversions, ProductListData_images_conversions, ProductAttributeFilter, ProductStatus } from '../api/gql/types'
import { parse, format, endOfDay, startOfDay } from 'date-fns'

export type AttributeOptionDescriptor = {
    attribute: ProductAttributeFragment,
    option: ProductAttributeFragment_options
}

export const GROBY_IMAGE_PLACEHOLDER = '/static/placeholder_grobylogo.svg'

export function isDiscounted(product: Pick<ProductListData, 'special_price' | 'special_from_date' | 'special_to_date' | 'price'>): boolean {
    const now = new Date()
    return !!(
        product.special_price                   &&
        (!product.special_from_date || startOfDay(parse(product.special_from_date)) <= now) &&
        (!product.special_to_date || endOfDay(parse(product.special_to_date)) >= now)
    )
}

export function isPersonallyDiscounted(product: Pick<ProductListData, 'special_price' | 'special_from_date' | 'special_to_date' | 'price' | 'personal_discount_price'>): boolean {
    return !isDiscounted(product) && !!product.personal_discount_price
}

export function getSaleInfo(product: ProductListData) {
    if (isDiscounted(product)) {
        return {
            amount: product.special_price
                ? Math.round(100 * (1 - (product.special_price / product.price)))
                : 0,
            info: format(product.special_to_date, 'MM. DD')
        }
    } else {
        return {
            amount: 0,
            info: null
        }
    }
}

export function getAttribute(code: string, attributes: ProductAttributeFragment[]): ProductAttributeFragment | undefined {
    return attributes.find(attribute => attribute.code === code)
}

export function getBrandAttributeInput(product: Pick<ProductListData, 'attributes'>): ProductAttributeFilter | null {
    const brandAttribute = getAttribute('brand', product.attributes || [])
    if (brandAttribute && brandAttribute.options) {
        return {
            attribute_id: brandAttribute.id,
            options: brandAttribute.options.map(option => option.id)
        }
    }

    return null
}

export function getBrandName(product: Pick<ProductListData, 'attributes'>): string | null {
    const brandAttribute = getAttribute('brand', product.attributes || [])
    if (brandAttribute && brandAttribute.options && brandAttribute.options.length) {
        return brandAttribute.options[0].label
    }
    return null
}

export function productHasAttribute(attributeCode: string, optionLabel: string, product: Pick<ProductListData, 'attributes'>): boolean {
    const attribute = getAttribute(attributeCode, product.attributes || [])
    if (!attribute) {
        return false
    }

    return attribute.options
        ? attribute.options.some(option => option.label === optionLabel)
        : false
}

export function productIngredientInfo(product: Pick<ProductListData, 'attributes'>) {
    return {
        gluten: productHasAttribute('ingredients', 'gluténmentes', product),
        lactose: productHasAttribute('ingredients', 'laktózmentes', product),
        sugar: productHasAttribute('ingredients', 'cukormentes', product)
    }
}

export function isProductNew(product: Pick<ProductListData, 'news_from_date' | 'news_to_date'>): boolean {
    return product.news_to_date && parse(product.news_to_date) >= new Date() &&
        (!product.news_from_date || parse(product.news_from_date) <= new Date())
}

export function getCurrentPrice(product: Pick<ProductListData, 'price' | 'special_price' | 'special_from_date' | 'special_to_date' | 'personal_discount_price'>): number {
    if (isDiscounted(product)) {
        return product.special_price!
    }
    if (isPersonallyDiscounted(product)) {
        return product.personal_discount_price!
    }
    return product.price
}

export function getOriginalPrice(product: Pick<ProductListData, 'price' | 'special_price' | 'special_from_date' | 'special_to_date' | 'personal_discount_price'>): number | undefined {
    return (isDiscounted(product) || isPersonallyDiscounted(product)) ? product.price : undefined
}

export function getNetPrice(product: Pick<ProductListData, 'price' | 'special_price' | 'special_from_date' | 'special_to_date' | 'vat' | 'personal_discount_price'>): number {
    return Math.round(getCurrentPrice(product) / (1 + product.vat / 100))
}

export function getUnitPrice(product: Pick<ProductListData, 'price_per_unit' | 'price' | 'special_price' | 'special_from_date' | 'special_to_date' | 'personal_discount_price'>): number {
    return Math.round((product.price_per_unit || 1) * getCurrentPrice(product))
}

export function findBrandBySlug(slug: string, attributes: ProductAttributeFragment[]): AttributeOptionDescriptor | null {
    const brandAttribute = getAttribute('brand', attributes)
    if (!brandAttribute) {
        return null
    }

    for (const option of (brandAttribute.options || [])) {
        if (brandSlug(option) === slug) {
            return {
                attribute: brandAttribute,
                option
            }
        }
    }

    return null
}

export function voteCount(voteCalculated: ProductAllData_vote_calculated): number {
    const v = voteCalculated
    if (v) {
        return v.vote_count_one
            + v.vote_count_two
            + v.vote_count_three
            + v.vote_count_four
            + v.vote_count_five
    }

    return 0
}

export function getImageForSizeNullable(
    product:    Pick<ProductListData, 'images'>,
    imageId:    number | null,
    size:       Exclude<keyof ProductListData_images_conversions, '__typename'>
): string | null {
    if (imageId === null || !product.images || !product.images[imageId]) {
        return null
    }
    const image = product.images[imageId]
    const conversion = image.conversions[size]
    return conversion || image.conversions.full || image.conversions.list || image.conversions.thumbnail || null
}

export function getImageForSizeOrPlaceholder(
    product:    Pick<ProductListData, 'images'>,
    imageId:    number | null,
    size:       Exclude<keyof ProductAllData_images_conversions, '__typename'>
): string {
    return getImageForSizeNullable(product, imageId, size) || GROBY_IMAGE_PLACEHOLDER
}

export function getProductFullURL(product: Pick<ProductListData, 'smarturl' | 'url_key'>): string {
    return `${process.env.GWS_WEB_URL}/termekek/${product.url_key || product.smarturl}`
}

export function getProductBrand(product: Pick<ProductListData, 'attributes'>): string | null {
    const brandAttribute = getAttribute('brand', product.attributes || [])
    const brand = brandAttribute && brandAttribute.options && brandAttribute.options.length > 0
      ? brandAttribute.options[0].label
      : null

    return brand
}

export const StatusMessages: { [s in ProductStatus]: string | null } = {
  'AVAILABLE': null,
  'OUT_OF_STOCK': 'Átmeneti készlethiány',
  'SEASONAL': 'Szezonális termék, átmenetileg nem kapható',
  'UNAVAILABLE': 'A termék jelenleg nem elérhető',
  'PERMANENTLY_ENDED': 'A termék megszűnt'
}
