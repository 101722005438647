import gql from 'graphql-tag'
import UserFragment from '../fragments/UserFragment'
import CartFragment from '../fragments/CartFragment';

export default gql`
mutation login($loginData: LoginDataInput!) {
    login(credentials: $loginData) {
        token
        user {
            ...UserFragment
        }
        cart {
            ...CartFragment
        }
    }
}
${UserFragment}
${CartFragment}
`
