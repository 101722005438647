import React, { ReactNode } from 'react'

// import cn from 'classnames'
// import cg from 'Scss/app.scss'
import cl from './Feedback.scss'

type PropType = {
  renderIcon?: ReactNode
}

class Feedback extends React.PureComponent<PropType> {
  render() {
    const { children, renderIcon } = this.props

    return (
      <div className={cl.feedbackReveal}>
        {renderIcon && <div className={cl.feedbackReveal__iconWrapper}>{renderIcon}</div>}
        <div>{children}</div>
      </div>
    )
  }
}

export default Feedback
