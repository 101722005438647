import gql from 'graphql-tag'
import { TimetableSlotFragment } from './TimetableSlotFragment'

const InterfaceShippingMethodFragment = gql`
fragment InterfaceShippingMethodFragment on InterfaceShippingMethod {
    id
    name
    price
    discount_price
    max_weight
    is_available
    description
    short_description
    delivery_time
    errors {
      ... on ShippingMethodErrorWeight {
        __typename
        message
      }
      ... on ShippingMethodErrorProducts {
        __typename
        message
        data {
          id
        }
      }
    }
}
`

export const ShippingMethodFragment = gql`
fragment ShippingMethodFragment on WebshopShippingMethod {
  ... on ShippingMethodGPoint {
    __typename
    ...InterfaceShippingMethodFragment
  }
  ... on ShippingMethodMpl {
    __typename
    price_ranges {
      from
      price
    }
    ...InterfaceShippingMethodFragment
  }
  ... on ShippingMethodGRoby {
    __typename
    ...InterfaceShippingMethodFragment
    data {
      ...TimetableSlotFragment
    }
  }
  ... on ShippingMethodPostPoint {
    __typename
    price_ranges {
      from
      price
    }
    ...InterfaceShippingMethodFragment
  }
}
${InterfaceShippingMethodFragment}
${TimetableSlotFragment}
`
