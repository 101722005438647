import gql from 'graphql-tag'
import CartFragment from '../fragments/CartFragment'

export const getCartQuery = gql`
query getCart($id: ID) {
    cart (id: $id) {
        ...CartFragment
    }
}
${CartFragment}
`
