import { getCartVariables, CartFragment, getCart, createCart, getCheckoutInfo, getCheckoutInfoVariables, linkableOrders_linkableOrders, linkableOrders, linkableOrdersVariables } from "./types"
import { ApolloClient, NormalizedCacheObject } from "apollo-boost"
import { getCartQuery } from "./queries/getCart"
import { createCartMutation } from "./mutations/createCart"
import { getCheckoutInfoQuery } from "./queries/getCheckoutInfo"
import { linkableOrdersQuery } from "./queries/linkableOrders";
import SentryLogger, { LoggerSeverity } from "Utils/helpers/sentryLogger";
import has from 'lodash/has'

const createEmptyCart = async (apolloClient:ApolloClient<NormalizedCacheObject>) => {
    try {
        const result = await apolloClient.mutate<createCart>({ mutation: createCartMutation, fetchPolicy: 'no-cache' })
        if (result.data && result.data.createCart) {
            return result.data.createCart
        }
        return null
    } catch {
        return null
    }
}

/*

ha a result.cart === null, az akkor lehet ha
a) guest juzerkent nincs letarolva cart_id cookieban
b) regelt juzerkent meg nincs kosarad

ha validations.id hibat kapunk, akkor:
a) mar nem letezik a kosar (pl leadtal egy megrendelest, de masik tabon meg ott van az elozo kosar)
b) ervenytelen a kosar id (belemokolt a juzer a cookieba)

ezekben az esetekben MINDIG akarunk uj kosarat.

a tobbi esetben (network error, backend internal server error, stb) viszont
SOHA nem akarunk uj kosarat, mert nem tudhatjuk, hogy tortenne-e kosarvesztes, ha ujat kerunk

*/

export async function getOrCreateCart (apolloClient: ApolloClient<NormalizedCacheObject>, cartId?: string): Promise<CartFragment> {
    let getCartVariables: getCartVariables | undefined = cartId
        ? { id: cartId }
        : undefined

    try {
        return await apolloClient.query<getCart, getCartVariables>({
            query: getCartQuery,
            variables: getCartVariables,
            fetchPolicy: 'no-cache'
        }).then(async (result) => {
            if (result.data.cart) {
                return result.data.cart as CartFragment
            } else if (result.data.cart === null) {
                if (cartId) {
                    SentryLogger.logMessage(`Received null for cart ${cartId}`, LoggerSeverity.Critical, {
                        extras: {
                            cartId
                        }
                    })
                }
                const result = await createEmptyCart(apolloClient)
                if (result !== null) {
                    return result
                }
                throw ({
                    message: 'Could not create cart',
                    result
                })
            } else {
                throw ({
                    message: 'Could not get cart (unexpected)',
                    result
                })
            }
        }).catch(async (e) => {
            if (has(e, 'graphQLErrors.0.extensions.validation.id')) {   
                if (cartId) {
                    SentryLogger.logMessage(`Invalid cartId: ${cartId}`, LoggerSeverity.Warning, {
                        extras: {
                            cartId,
                            seralizedErrorObject: JSON.stringify(e)
                        }
                    })
                }                                                       // Ha érvénytelen a kosár id, amivel bekérdezünk, MINDENKÉPP új kosarat kérünk,
                const result = await createEmptyCart(apolloClient)      // mert biztos, hogy nem fog "megjavulni", szemben mondjuk network errorral, vagy egyéb backend exceptionnel
                if (result !== null) {                                  // Ha viszont egyéb hiba van (network error, exception, stb), az FATAL! NEM kérünk új kosarat, akkor se, ha getOnly === false
                    return result as CartFragment
                }
                throw ({
                    message: 'Could not create cart',
                    result
                })
            }
            SentryLogger.logException(`Unknown getCart error: ${cartId}`, LoggerSeverity.Warning, {
                extras: {
                    cartId,
                    seralizedErrorObject: JSON.stringify(e)
                }
            })
            throw ({
                message: 'Could not get cart',
                undefined
            })
        })
    } catch (e) {
        SentryLogger.logException(e, LoggerSeverity.Fatal, { fingerPrint:['getOrCreateCart'], extras: {
            serializedError: JSON.stringify(e),
            graphQLErrors: e.graphQLErrors,
            cartId,
            getCartVariables
        }})
        throw e
    }
}

export async function fetchCheckoutInfo(apolloClient: ApolloClient<NormalizedCacheObject>, deliveryAddress: Redux.IDeliveryAddress | undefined, cartId: string | null | undefined, user_billing_address_id?: string | null): Promise<getCheckoutInfo | null> {
    try {
        const result = await apolloClient.query<getCheckoutInfo, getCheckoutInfoVariables>({
            query: getCheckoutInfoQuery,
            variables: {
                postcode: deliveryAddress && deliveryAddress.value.zip ? Number(deliveryAddress.value.zip) : undefined,
                cartId: cartId || undefined,
                user_billing_address_id: user_billing_address_id || undefined
            },
            fetchPolicy: 'no-cache'
        })

        return result.data
    } catch(e) {
        return null
    }
}

export async function fetchLinkableOrders(apolloClient: ApolloClient<NormalizedCacheObject>, cartId: string | null | undefined): Promise<linkableOrders_linkableOrders[] | null> {
    try {
        const result = await apolloClient.query<linkableOrders, linkableOrdersVariables>({
            query: linkableOrdersQuery,
            variables: {
                cartId: cartId || ''
            },
            fetchPolicy: 'no-cache'
        })

        return result.data.linkableOrders
    } catch (e) {
        return null
    }
}
