export function isDevelopment (): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

export function isServer (): boolean {
  return typeof window === 'undefined'
}

export function isBrowser (): boolean {
  return !isServer()
}

export const fetchDebugger = (fetch: any, logger: any) => (...params: any[]) => {
  let debugInfo: any = params
  let operationName = 'unknown'
  try {
    const body = JSON.parse(params[1].body)
    debugInfo = { operation: body.operationName, variables: body.variables }
    operationName = body.operationName
    logger.info(`Fetch REQ <${operationName}> started`)
  } catch (e) {
    logger.warning('Fetch REQ (non-GQL?): ', debugInfo)
  }
  return new Promise((resolve, reject) => {
    const started = Date.now()
    fetch(...params)
      .then((result: any) => { 
        logger.info(`Fetch REQ <${operationName}> took ${Date.now() - started} ms.`)
        resolve(result) 
      })
      .catch((error: any) => { reject(error) })
  })
}
