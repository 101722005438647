import gql from 'graphql-tag'
import ProductListData from './ProductListData'
import { PostPointFragment } from './PostPointFragment';
import { ShippingMethodFragment } from './ShippingMethodFragments';
import { PaymentMethodFragment } from './PaymentMethodFragments';
import { CartShippingAddressFragment } from './CartShippingAddressFragment';
import { CartBillingAddressFragment } from './CartBillingAddressFragment';

export default gql`
fragment CartFragment on Cart {
    id
    hash
    cart_products {
        product {
            ...ProductListData
        }
        piece
        alternative_quantity_piece
        price
        total_price
        sliced
        additional_information
    }
    total_price
    discount_price

    coupon_code

    timetable_time_slot {
      id
      range_date
      price
      timeRange {
        id
        time_from
        time_to
      }
    }

    post_point {
      ...PostPointFragment
    }

    shipping_method {
      ...ShippingMethodFragment
    }

    payment_method {
      ...PaymentMethodFragment
    }

    shipping_address {
      ...CartShippingAddressFragment
    }

    billing_address {
      ...CartBillingAddressFragment
    }

    payment_method_data {
      user_saved_credit_card_id
    }

    contact_data {
      contact_name
      contact_email
      contact_telephone
    }

    linked_order {
      id
      shipping_name
      shipping_postcode
      shipping_city
      shipping_street
      shipping_house_number
      shipping_telephone
      shipping_note

      created_at

      shipping_method {
        id
      }

      timetable_time_slot {
          range_date
          timeRange {
              time_from
              time_to
          }
      }
    }
    warnings {
      product {
        id
        name
      }
      message
    }
}
${ProductListData}
${PostPointFragment}
${ShippingMethodFragment}
${PaymentMethodFragment}
${CartShippingAddressFragment}
${CartBillingAddressFragment}
`
