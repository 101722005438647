import React from 'react'
import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Box.scss'
import { CustomSVGElement, SVGAccPlus, SVGAccMinus } from 'Components/SVGCollection'


type PropType<T> = {
    title: string
    price?: string
    value: T
    Icon?: CustomSVGElement
    disabled?: boolean
    selected?: boolean
    tooltip?: string
    onSelect: (value: T) => void
}

type StateType = {
    textOpen: boolean
}

class Box<T> extends React.PureComponent<PropType<T>, StateType> {
    state = {
        textOpen: false
    }
    
    componentDidUpdate(prevProps: PropType<T>) {
        if (prevProps.selected != this.props.selected) {
            this.setState({ textOpen: !!this.props.selected })
        }
    }

    render() {
        const { title, price, children, Icon, onSelect, selected, disabled, value, tooltip } = this.props
        return <div className={cn(cg.cell, cl.radioOptions)}>
            <label title={tooltip}>
                <input type="radio" onChange={() => { onSelect(value) }} checked={selected} disabled={disabled} />
                <div className={cn(cl.height100)}>
                    <div className={cn(cg.gridX)}>
                        <div className={cn(cg.cell)}>
                            <div className={cn(cg.gridX, cl.height100, cg.alignMiddle)}>
                            {Icon &&
                                <div className={cn(cg.cell, cl.radioIcon, cg.showForMedium)}>
                                    <Icon />
                                </div>
                            }
                            <div className={cn(cg.cell, cl.radioInfo)}>
                                <div className={cn(cg.gridX, cg.alignMiddle)}>
                                    <div className={cn(cg.cell, cg.auto)}>
                                        <h3>{title}</h3>
                                        {price && <p className={cn(cl.radioPrice)}>{price}</p>}
                                    </div>
                                    <div className={cn(cg.cell, cg.shrink, cg.hideForMedium)}  onClick={e => { this.setState({ textOpen: !this.state.textOpen}); e.preventDefault(); e.stopPropagation(); }} >
                                        <span className={`${cl.openedIcon} ${!this.state.textOpen ? cg.hide : ""}`}><SVGAccMinus width="24" height="24" /></span>
                                        <span className={`${cl.closedIcon} ${this.state.textOpen ? cg.hide : ""}`}><SVGAccPlus width="24" height="24" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${cg.cell} ${cg.small12} ${cl.radioText} ${this.state.textOpen ? cl.textOpen : ""}`}>
                                <div className={cn(cg.gridX)}>
                                    
                                    {Icon &&
                                        <div className={cn(cg.cell, cl.radioIcon, cg.hideForMedium)}>
                                            <Icon />
                                        </div>
                                    }

                                    <div className={cn(cg.cell, cg.auto)}>
                                        <div className={cl.boxContent}>
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>    
                </div> 
            </label>  
        </div>
    }
}

export default Box
