const { Router } = require('Utils/routes')
import { ServerResponse, IncomingMessage } from "http"
import { setCookie } from "Utils/cookie";
import { REDIRECTBACK_COOKIE } from "./constants";
import { AppContext } from "Utils/withReduxStore";

export const goBack = (() => {
    if (typeof window === 'undefined') {
        return () => {}
    }

    let isFirstPageload: boolean = true
    let goBackInvokedOnCurrentPage = false

    Router.events.on('routeChangeComplete', () => {
        isFirstPageload = false
        goBackInvokedOnCurrentPage = false
    })

    return () => {
        if (!goBackInvokedOnCurrentPage) {
            goBackInvokedOnCurrentPage = true
            if (isFirstPageload) {
                redirect('/')
            } else {
                Router.back()
            }
        }
    }
})()

export const redirect = (toPath = '/', res?: ServerResponse, statusCode = 302) => {
    // console.log('redir: ' + toPath, res)
    if (!process.browser && res) {
        const headers = res.getHeaders()
        res.writeHead(statusCode, {
            ...headers,
            Location: toPath
        })
        res.end()
        res.finished = true
    }

    if (typeof window !== 'undefined') {
        // Valamiert a replaceRoute az elozo pushRoute-ot is felulirja, ezert kettovel visszabb megy history.back()-re a browser
        // Lasd: GRBY-888
        // Egyelore ugy tunik, vallalhato igy a mukodes, bar vannak kompromisszumok (pl login/reg utan "beragad" a vissza gomb)
        // Router.replaceRoute(toPath)
        Router.pushRoute(toPath)
    }

    // if on server but no 'res' provided, do nothing
}

export const goToLogin = (ctx?: AppContext) => {
    const pathname = ctx && typeof window === 'undefined'
        ? ctx.asPath
        : window.location.pathname
    setCookie(REDIRECTBACK_COOKIE, pathname, ctx && ctx.res)
    redirect('/belepes', ctx && ctx.res)
}

export const pushRoute = (toPath: string) => {
    // console.log('pushroute: ' + toPath)

    Router.pushRoute(toPath)
}
