
export default [
    {id: 3, image: 'ProdMilk'},
    {id: 4, image: 'ProdWater'},
    {id: 37, image: 'ProdCoffee'},
    {id: 41, image: 'ProdFruit'},
    {id: 47, image: 'ProdBread'},
    {id: 55, image: 'ProdAlapvetoelelmiszer'},
    {id: 71, image: 'ProdFagyasztott'},
    {id: 80, image: 'ProdHusaru'},
    {id: 90, image: 'ProdSpecFood'},
    {id: 98, image: 'ProdSutemeny'},
    {id: 109, image: 'ProdGift'},
    {id: 110, image: 'ProdHaztartas'},
    {id: 120, image: 'ProdBaba'},
    {id: 126, image: 'ProdDrogeria'},
    {id: 136, image: 'ProdKutya'},
    {id: 142, image: 'ProdIroda'},
    {id: 436, image: 'ProdAlcoholicDrinks'}
]
