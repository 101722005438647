import gql from 'graphql-tag'
import UserFragment from '../fragments/UserFragment'

export default gql`
mutation registration($registrationData: RegistrationDataInput!) {
    registration(registrationData: $registrationData) {
        token
        user {
            ...UserFragment
        }
    }
}
${UserFragment}
`
