import React from 'react'
import { call, put, putResolve, take, takeLatest, all, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { QueryOptions, ApolloQueryResult, FetchResult } from 'apollo-boost'

import {
  updateSession,
  loginError,
  registrationError,
  logout,
  setCart,
  login,
  loginStarted,
  registrationStarted,
  Actions,
  cartError,
  destroySessionAndCookie,
  updateCheckoutInfo,
  refreshCheckoutInfo,
  updateOrder,
  acquireRedirectLock,
  releaseRedirectLock,
  blockRedirects,
  unblockRedirects,
  registrationSucceeded,
  updateLinkableOrders,
  setShoppingLists,
  setShoppingListsFetching,
  setOrderedProductIDs,
  setDeliveryAddress,
} from "Utils/redux/actions";

import App from '../../pages/_app'
import loginMutation from 'Utils/api/gql/mutations/login'
import {
  emailAvailabilityVariables,
  emailAvailability,
  CartFragment,
  updateCart,
  updateCartVariables,
  login as loginQueryType,
  getCheckoutInfo,
  logout as logoutType,
  loginVariables,
  createOrder,
  CartProductDataInput,
  linkableOrders_linkableOrders,
  cartHashVariables,
  cartHash,
  createOrderVariables,
  CheckoutType,
} from "Utils/api/gql/types";
import registration from 'Utils/api/gql/mutations/registration'
import me from 'Utils/api/gql/queries/me'
import { emailAvailabilityQuery } from 'Utils/api/gql/queries/emailAvailability'
import { updateCartMutation } from 'Utils/api/gql/mutations/updateCart'

import {
  setTokenCookie,
  deleteTokenCookie,
  setCartOpenedCookie,
  getGuestCartIdFromCookie,
  setDeliveryAddressCookie,
  setGuestCartIdCookie,
  deleteCartIdCookie,
  setListClientToken
} from "Utils/cookie";
import { parseErrors, collectAllErrorsForModal } from 'Utils/helpers/errorHelper'
import gtmClient, { gtmClientInstance } from 'Utils/gtmClient'
import Logger from 'Utils/Logging'
import { showMessage, showRegistrationModal, asyncDeleteDialog } from 'Utils/helpers/modalHelper'
import CartHelper from 'Utils/helpers/cartHelper'
import { logoutMutation } from 'Utils/api/gql/mutations/logout'
import { getOrCreateCart, fetchCheckoutInfo, fetchLinkableOrders } from 'Utils/api/gql/helpers'
import CheckoutHelper from 'Utils/helpers/checkoutHelper'
import { redirect, goBack, pushRoute } from 'Utils/helpers/routerHelper'
import { defaultOrderData } from './store'
import { sendAnalyticsForCartUpdate, sendAnalyticsForSuccessfulOrder } from 'Utils/helpers/prefixboxHelper'
import { createOrderMutation } from 'Utils/api/gql/mutations/createOrder'
import ListClient from "Utils/api/ListClient";
import {convertFetchResultToShoppingLists, setProductDetailsInList} from "Utils/helpers/shoppingListHelpers";
import {productsByIdQuery} from "Utils/api/gql/queries/productsById";
import get from 'lodash/get'

import SentryLogger, { LoggerSeverity } from 'Utils/helpers/sentryLogger';
import { ORDER_DATA_KEY, ShippingMethodId } from 'Utils/helpers/constants';
import { cartHashQuery } from 'Utils/api/gql/queries/cartHash';
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter';
import { findUserAddressByPostcode, getDeliveryAddressUpdateFromUserAddress } from 'Utils/helpers/addressHelper';
import { fetchOrderedProducts } from 'Utils/helpers/apiHelper';
import { filterFalsy } from "../helpers/misc";
import { cartHandleProductErrors } from "./actions";
import { ProductQuantityLimits } from 'Utils/data/productQuantityLimits';

export function* grobySaga () {
    // SERVER-SIDE SAGAS
    if (typeof window === 'undefined') {
        return
    }

    yield all([
                                            /* USER */
        takeLeading(    Actions.LOGIN,                              loginSaga),
        takeLeading(    Actions.LOGOUT,                             logoutSaga),
        takeEvery(      Actions.DESTROY_SESSION_AND_COOKIE,         destroySessionAndCookieSaga),
        takeLeading(    Actions.REGISTER,                           registrationSaga),
        takeLeading(    Actions.TOKEN_LOGIN,                        tokenLoginSaga),
        takeLatest(     Actions.CHECK_EMAIL,                        checkEmailSaga),
        takeLeading(    Actions.REFRESH_USER,                       refreshUser),

                                            /* CART */

        takeEvery(      Actions.TOGGLE_CART_PANEL,                  togglePanelSaga),
        takeLeading(    Actions.REQUEST_CART_PRODUCT_UPDATE,        cartProductUpdateSaga),
        takeLeading(    Actions.REQUEST_CART_DELETE_ALL_ITEMS,      cartDeleteAllSaga),
        takeLatest(     Actions.CART_ERROR,                         cartErrorSaga),
        takeEvery(      Actions.SET_CART,                           setCartSaga),
        takeLeading(    Actions.REFRESH_CART,                       refreshCart),
        takeLeading(    Actions.CART_HANDLE_PRODUCT_WARNINGS,       cartHandleProductWarningsSaga),

                                            /* CHECKOUT */

        takeEvery(      Actions.UPDATE_ORDER,                       updateOrderSaga),
        takeLeading(    Actions.CREATE_ORDER,                       createOrderSaga),
        takeLatest(     Actions.SET_DELIVERY_ADDRESS,               deliveryAddressSaga),
        takeEvery(      Actions.REFRESH_CHECKOUT_INFO,              refreshCheckoutInfoSaga),

                                            /* SAFE REDIRECT */
        takeEvery(      Actions.REDIRECT,                           redirectSaga),
        takeEvery(      Actions.PUSH_ROUTE,                         pushRouteSaga),
        takeEvery(      Actions.GO_BACK,                            goBackSaga),

                                            /* SHOPPING LISTS */
        takeLatest(     Actions.REFRESH_SHOPPINGLISTS,              refreshShoppingLists)
    ])
}


function* loginSaga (action: Redux.IAction) {
    if (action.type !== Actions.LOGIN) throw 'Invalid action type'
    const { email, password, authType, stayLoggedIn } = action.payload

    const { session, cart }: Redux.IReduxState = yield select()
    if (session.loggedIn || session.isFetching) {
        Logger.error('Attempted login while being logged in or during login')
        return
    }

    yield call(awaitRedirectLocks)
    yield put(loginStarted())
    yield put(acquireRedirectLock())

    try {
        const result: FetchResult<loginQueryType> = yield call(App.apolloClient.mutate, {
            mutation: loginMutation,
            variables: { loginData: { email, password, authType, cart_id: cart && cart.id || undefined } } as loginVariables
        })

        const data = result.data && result.data.login
        if (data && data.token && data.user) {
            yield call(deleteCartIdCookie)      // GRBY-762 throw away guest cart when logging in
            yield call(setTokenCookie, data.token, stayLoggedIn)
            yield call(setListClientToken, data.token)
            yield putResolve(updateSession(data.user, data.token))
            if (data.cart) {
                yield put(setCart(data.cart, true))
                yield call(refreshCheckoutInfoSaga)
            } else {
                yield call(refreshCart)
            }

            gtmClient.customerData = data.user
            if (action.showRegSuccessModal) {
                showRegistrationModal()
            }
            if (typeof action.callback === 'function') {
                action.callback()
            }
        } else {
            throw 'Ismeretlen hiba történt!'
        }
    } catch (e) {
        yield put(loginError(parseErrors(e)))
    }

    yield put(releaseRedirectLock())
}

function* destroySessionAndCookieSaga(): IterableIterator<void> {
    deleteTokenCookie()
}

function* refreshCheckoutInfoSaga () {
    const { cart, deliveryAddress, session: { loggedIn }, orderData: { billing_address } } = (yield select()) as Redux.IReduxState
    if (!cart) return

    try {
        const checkoutInfo = (yield call(fetchCheckoutInfo, App.apolloClient, deliveryAddress, cart.id, billing_address && billing_address.id)) as getCheckoutInfo | null
        if (checkoutInfo) {
            yield put(updateCheckoutInfo(checkoutInfo))
            const isPersonalDisabled = checkoutInfo.shippingMethods && !CheckoutHelper.getMethodStatus("ShippingMethodGPoint", checkoutInfo.shippingMethods.filter(filterFalsy)).isAvailable
            if (deliveryAddress && deliveryAddress.selected.type === "personal" && isPersonalDisabled) {
                yield put(setDeliveryAddress(undefined, true))
            }
        }

        if (loggedIn) {
            const linkableOrders = (yield call(fetchLinkableOrders, App.apolloClient, cart.id)) as linkableOrders_linkableOrders[] | null
            if (linkableOrders) {
                yield put(updateLinkableOrders(linkableOrders))
            }
        } else {
            yield put(updateLinkableOrders([]))
        }

    } catch (e) {
        showMessage('Ajjaj. Valami baj történt a szállítási adatok letöltése közben.')
    }
}

function* refreshOrderedProducts () {
    const result = (yield call(fetchOrderedProducts, App.apolloClient)) as Redux.OrderedProductEntry[] | null
    if (result){
        yield put(setOrderedProductIDs(result))
    }
}

function* refreshCart () {
    const { session: { loggedIn }, cart: prevCart, orderedProductIds } = (yield select()) as Redux.IReduxState

    Logger.debug('refreshCart loggedIn: ', loggedIn)

    try {
        const id = !loggedIn
            ? getGuestCartIdFromCookie()
            : undefined

        if (prevCart) {
            try {
                const hashResult = (yield call(App.apolloClient.query, {
                    fetchPolicy: 'no-cache',
                    query: cartHashQuery,
                    variables: {
                        cartId: id
                    }
                } as QueryOptions<cartHashVariables>)) as FetchResult<cartHash>

                if (hashResult.data && hashResult.data.cart && hashResult.data.cart.hash === prevCart.hash) {
                    if (loggedIn && !orderedProductIds) {
                        yield call(refreshOrderedProducts)
                    }
                    const { checkoutInfo } = (yield select()) as Redux.IReduxState
                    if (!checkoutInfo.shippingMethods.length) {
                        yield put(refreshCheckoutInfo())
                    }
                    return
                }
            } catch (e) { }
        }

        Logger.debug('refreshCart prevCartId: ', prevCart && prevCart.id)
        Logger.debug('refreshCart getOrCreateCart Id: ', id)

        const nextCart: CartFragment = yield call(getOrCreateCart, App.apolloClient, id) // getOrCreateCart okositva lett, ugyhogy a getOnly param kivezetésre került
        if (!loggedIn) {
            yield call(setGuestCartIdCookie, nextCart.id)
        }

        Logger.debug('refreshCart nextCart.id: ', nextCart.id)

        yield put(setCart(nextCart))
        yield put(refreshCheckoutInfo())
        if (loggedIn && !orderedProductIds) {
            yield call(refreshOrderedProducts)
        }
    } catch (e) {
        Logger.error(e)
        const session = yield select(state => state.session)
        SentryLogger.logException(e, LoggerSeverity.Critical, { fingerPrint: ['refreshCart'], extras: {
            serializedError: JSON.stringify(e),
            prevCart,
            session,
            loggedInAtStart: loggedIn
        }})
        yield put(cartError('Hiba történt a kosár szinkronizálása közben!'))
    }
}

function* logoutSaga (action: Redux.IAction) {
    if (action.type !== Actions.LOGOUT) return
    if (!(yield select((state: Redux.IReduxState) => state.session.loggedIn))) return

    uiEventEmitter.emit(UIEvents.START_LOADING)

    yield call(awaitRedirectLocks)  // wait for any critical operation to finish
    yield put(acquireRedirectLock())

    try {
        const result: FetchResult<logoutType> = yield call(App.apolloClient.mutate, { mutation: logoutMutation })
        if (result.data && result.data.logout) {    // logout is type CartFragment
            yield put(setCart(result.data.logout))
            yield call(setGuestCartIdCookie, result.data.logout.id)
        }
    } catch (e) {
        SentryLogger.logException(e, LoggerSeverity.Error, { fingerPrint:['logout'], extras: { serializedError: JSON.stringify(e)}})
        window.location.reload()
        return
    }

    yield call(setListClientToken, null)
    yield put(destroySessionAndCookie())
    yield put(updateOrder({ ...defaultOrderData, __hydrated: true }))

    const deliveryAddress: Redux.IDeliveryAddress | undefined = yield select((state: Redux.IReduxState) => state.deliveryAddress)
    yield put(setDeliveryAddress(
        !deliveryAddress
            ? undefined
            : {
                ...deliveryAddress,
                selected: deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'savedAddress'
                    ? { type: 'delivery', deliveryType: 'postcode' }
                    : deliveryAddress.selected,
                value: { ...deliveryAddress.value, addressId: null }
            }
    ))

    yield put(setOrderedProductIDs([]))

    Logger.debug('afterRefreshCart')
    yield put(releaseRedirectLock())

    if (action.callback) {
        yield call(action.callback)
    }

    window.location.href = '/'
}

function* tokenLoginSaga (action: Redux.IAction) {
    if (action.type !== Actions.TOKEN_LOGIN) return
    const { token } = action.payload

    setTokenCookie(token, true)
    yield call(refreshUser)
}

function* refreshUser (action?: Redux.IAction) {
    yield call(awaitRedirectLocks)
    yield put(acquireRedirectLock())
    const loggedIn = yield select((state: Redux.IReduxState) => state.session.loggedIn)
    Logger.debug('refreshUser loggedIn: ', loggedIn)
    try {
        if (loggedIn) {
            const customerData = (yield call(App.apolloClient.query, { query: me, fetchPolicy: 'no-cache' } )).data.me
            yield putResolve(updateSession(customerData))
        }

        const shouldSkipCheckoutInfo = action && action.type === Actions.REFRESH_USER && action.skipCheckoutInfo
        if (!shouldSkipCheckoutInfo) {
            yield call(refreshCart)
        }

        if (action && action.type === Actions.REFRESH_USER && action.callback) {
            yield call(action.callback)
        }
    } catch (e) {
        if (loggedIn) {
            try {
                const sessionData = (yield select()).session
                const isAuthenticationError = e.graphQLErrors.some((error: any) => error.extensions && (error.extensions.category === 'authentication' || error.extensions.category === 'authorization'))

                if (isAuthenticationError) {
                    SentryLogger.logException(e, LoggerSeverity.Warning, { fingerPrint: ['refreshUserSaga', 'authenticationError'], extras: {
                        sessionData,
                        serializedError: JSON.stringify(e)
                    }})

                    showMessage('Lejárt a munkamenet, vagy egy másik oldalon kijelentkeztél. Kérünk, lépj be újra!', null)
                    yield put(logout())
                } else {
                    SentryLogger.logException(e, LoggerSeverity.Critical, { fingerPrint: ['refreshUserSaga', 'miscError'], extras: {
                        sessionData,
                        serializedError: JSON.stringify(e)
                    }})
                    // showMessage('Hiba történt a hálózati művelet közben', null)
                }
            } catch (e) { } // do nothing with other error types
        }
    }
    yield put(releaseRedirectLock())
}

function* checkEmailSaga (action: Redux.IAction) {
    if (action.type !== Actions.CHECK_EMAIL) return

    try {
        const result = (yield call(App.apolloClient.query, {
            query: emailAvailabilityQuery,
            variables: {
                email: action.payload
            }
        } as QueryOptions<emailAvailabilityVariables>)) as ApolloQueryResult<emailAvailability>

        const regErrors: Redux.IFormErrors<Redux.RegistrationForm> = yield select((state: Redux.IReduxState) => state.session.registrationErrors)

        if (!result.data.emailAvailability) {
            yield put(registrationError({ ...regErrors, fieldErrors: { ...regErrors.fieldErrors, email: 'Ezzel az e-mail címmel már regisztráltak nálunk! Kérünk, jelentkezz be vagy adj meg egy másik címet!' } }))
        } else {
            yield put(registrationError({ ...regErrors, fieldErrors: { ...regErrors.fieldErrors, email: undefined } }))
        }
    } catch (e) { }
}

function* registrationSaga (action: Redux.IAction) {
    if (action.type !== Actions.REGISTER) return
    const { email, password } = action.payload

    const session = yield select((state: Redux.IReduxState) => state.session)
    if (session.loggedIn || session.fetching) {
        return
    }

    yield put(registrationStarted())

    try {
        yield call(App.apolloClient.mutate, { mutation: registration, variables: { registrationData: action.payload } })
        yield put(registrationSucceeded())
        yield put(login(email, password, undefined, undefined, true))
    } catch (e) {
        yield put(registrationError(parseErrors(e)))
    }
}

// function* pingMeSaga () {
//     if (typeof window !== 'object') return
//     const state: Redux.IReduxState = yield select(state => state)

//     yield delay(FETCH_ME_INITIAL_DELAY)
//     while (true) {
//         if (state.session.loggedIn) {
//             yield call(refreshUser)
//         }
//         yield delay(FETCH_ME_DELAY)
//     }
// }

function* togglePanelSaga () {
    const cartPanelCookie = yield select((state: Redux.IReduxState) => state.cartPanelCookie)
    setCartOpenedCookie(cartPanelCookie)
}

function* setCartSaga(action: Redux.IAction) {
    if (action.type !== Actions.SET_CART) return

    const cart = action.payload
    const deliveryAddress: Redux.IDeliveryAddress | undefined = yield select((state: Redux.IReduxState) => state.deliveryAddress)

    if (cart && cart.linked_order && (!deliveryAddress || deliveryAddress.value.zip !== cart.linked_order.shipping_postcode)) {
        const deliveryType: Redux.DeliveryType = cart.linked_order.shipping_method.id === ShippingMethodId.GPOINT
            ? { type: 'personal' }
            : { type: 'delivery', deliveryType: 'postcode' }

      yield put(setDeliveryAddress({
        selected: deliveryType,
        value: { zip: cart.linked_order.shipping_postcode, city: cart.linked_order.shipping_city, addressId: null }
      }))
    }

    if (action.findAddressIdByPostcode) {
        const { session, deliveryAddress } = (yield select(state => state)) as Redux.IReduxState
        if (session.loggedIn && deliveryAddress && deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'postcode' && session.customerData.shipping_addresses) {
            const foundAddress = findUserAddressByPostcode(session.customerData.shipping_addresses, deliveryAddress.value.zip)
            if (foundAddress){
                yield put(setDeliveryAddress(getDeliveryAddressUpdateFromUserAddress(foundAddress), false))
            }
        }
    }
}

function* cartHandleProductWarningsSaga (action: Redux.IAction) {
    if (action.type !== Actions.CART_HANDLE_PRODUCT_WARNINGS) return

    const cart: CartFragment | null = yield select((state: Redux.IReduxState) => state.cart)
    if (cart && cart.warnings && cart.warnings.length) {
        const additionalContent = <><h1></h1>{cart.warnings.map(warning => <p key={warning.product.id}><b>{warning.product.name}</b>: {warning.message}</p>)}</>;
        if (!uiEventEmitter.modalReady) {
            yield call(() => new Promise(resolve => uiEventEmitter.on(UIEvents.MODAL_READY, resolve)));
        }
        const result = yield call(asyncDeleteDialog, "A megrendelés leadásához a következő termékeket el kell távolítani:", "Termékek eltávolítása", additionalContent);
        if (result === 'yes') {
            let { products } = CartHelper.cartToCartProductInput(cart)
            products = products.filter(product => !cart.warnings!.find(warning => warning.product.id === product.product_id))
            try {
                const result: FetchResult<updateCart> = yield call(App.apolloClient.mutate, {
                    mutation: updateCartMutation,
                    variables: {
                        input: { id: cart.id, products }
                    } as updateCartVariables
                })
                if (result.data && result.data.updateCart) {
                    yield put(setCart(result.data.updateCart))
                    yield put(refreshCheckoutInfo())
                    showMessage("A nem rendelhető termékeket sikeresen eltávolítottuk!")
                }
            } catch {
                showMessage("Hiba történt a termékek eltávolítása közben!")
            }
        }
    }
}

function* cartProductUpdateSaga (action: Redux.IAction) {
    if (action.type !== Actions.REQUEST_CART_PRODUCT_UPDATE) return

    const cart: CartFragment | null = yield select((state: Redux.IReduxState) => state.cart)
    const categories = yield select((state: Redux.IReduxState) => state.productCategories)
    if (!cart) {
        return
    }

    sendAnalyticsForCartUpdate(action, cart, categories)
    gtmClientInstance.sendAnalyticsForCartUpdate(action, cart)

    const cartInput = CartHelper.cartToCartProductInput(cart)
    const isProductInCart = !!CartHelper.getInCartItem(action.payload.product.id, cart)

    if (action.payload.newQuantity > 0) {
        const newProductInput = ProductQuantityLimits.validateProductInput(CartHelper.convertProductUpdatePayloadToProductDataInput(action.payload))
        if (isProductInCart) {
            cartInput.products = CartHelper.replaceProductInCartInputProducts(cartInput.products, newProductInput)
        } else {
            cartInput.products.push(newProductInput)
        }
    } else { // Remove product from cart
        cartInput.products = cartInput.products.filter(productInput => productInput.product_id !== action.payload.product.id)
    }

    if (action.payload.additional_info) {
        cartInput.products = cartInput.products
            .map<CartProductDataInput>(cartProduct =>
                cartProduct.product_id === action.payload.product.id
                    ? { ...cartProduct, additional_information: action.payload.additional_info }
                    : cartProduct)
    }

    try {
        const result: FetchResult<updateCart> = yield call(App.apolloClient.mutate, {
            mutation: updateCartMutation,
            variables: {
                input: cartInput
            } as updateCartVariables
        })

        if (result && result.data && result.data.updateCart) {
            yield putResolve(setCart(result.data.updateCart))
            yield put(refreshCheckoutInfo())
            yield put(cartHandleProductErrors())
            if (typeof action.payload.additional_info === 'string') {
                showMessage('Az ajándékkártya szövegét és a masni színét sikeresen elmentettük!')
            }
        } else {
            throw new Error('cartSetProductSaga: Received no data from updateCart')
        }
    } catch (e) {
        if (get(e, 'graphQLErrors.0.extensions.category') === 'discount') {       //GRBY-1055
            const result = yield call(asyncDeleteDialog, 'A termék eltávolításával érvénytelenné válik a kuponos kedvezményed. Biztos törlöd?', 'Törlöm a kupont')
            if (result === 'yes') {
                try {
                    const result: FetchResult<updateCart> = yield call(App.apolloClient.mutate, {
                        mutation: updateCartMutation,
                        variables: {
                            input: {
                                ...cartInput,
                                coupon_code: null
                            }
                        } as updateCartVariables
                    })
                    if (result && result.data && result.data.updateCart) {
                        yield put(setCart(result.data.updateCart))
                    }
                } catch (e) {
                    showMessage('Hiba történt a kuponkód törlése közben')
                } finally {
                    return
                }
            }
        }

        Logger.error(e)
        SentryLogger.logException(e, LoggerSeverity.Error, { fingerPrint: ['cartProductUpdateSaga'], extras: {
            serializedError: JSON.stringify(e)
        }})

        const message = collectAllErrorsForModal(e)
        yield put(cartError(<>Hiba történt a kosár frissítése közben: {message}</>))
    }
}

function* cartDeleteAllSaga() {
    const cart = (yield select(state => state.cart)) as CartFragment | null
    if (!cart) {
        return
    }

    try {
        const result: FetchResult<updateCart> = yield call(App.apolloClient.mutate, {
            mutation: updateCartMutation,
            variables: {
                input: {
                    id: cart.id,
                    products: []
                }
            } as updateCartVariables
        })

        if (result && result.data && result.data.updateCart) {
            yield put(setCart(result.data.updateCart))
            yield put(refreshCheckoutInfo())
        } else {
            throw 'No data'
        }
    } catch (e) {
        yield put(cartError('Hiba a kosár kiürítése közben'))
    }
}

function* cartErrorSaga(action: Redux.IAction): IterableIterator<void> {
    if (action.type !== Actions.CART_ERROR) return

    const message = action.payload instanceof Error
        ? action.payload.message
        : action.payload

    showMessage(message, 10000)
}

function* updateOrderSaga(action: Redux.IAction) {
    if (action.type !== Actions.UPDATE_ORDER) return

    const { orderData } = (yield select()) as Redux.IReduxState
    sessionStorage.setItem(ORDER_DATA_KEY, JSON.stringify(orderData))
}

function* deliveryAddressSaga (action: Redux.IAction) {
    if (action.type !== Actions.SET_DELIVERY_ADDRESS) return

    setDeliveryAddressCookie(action.payload)
    yield put(refreshCheckoutInfo())
}

function* createOrderSaga(action: Redux.IAction) {
    const { cart, productCategories, deliveryAddress, session }: Redux.IReduxState = yield select()
    if (!cart || action.type !== Actions.CREATE_ORDER) return

    yield put(acquireRedirectLock())
    yield put(blockRedirects())    // block incidental redirects (like from componentDidUpdate) from queueing. acquireRedirectLock only postpones redirects until all locks are released

    try {
        // Prepare create shopping list actions before the cart was modified
        const shoppingListActions = cart.cart_products.map((product) => {
            return { listId: null, productId: parseInt(product.product.id, 10), quantity: product.piece, syncStarted: null, action: "SET_PRODUCT_QUANTITY"}
        })

        const sessionCookie = document.cookie.split(";").find((c) => c.includes("_ga_"));
        const gampSessionId = sessionCookie ? sessionCookie.split("=")[1].split(".")[2] : null;

        const result: FetchResult<createOrder> = yield call(App.apolloClient.mutate, {
          mutation: createOrderMutation,
          variables: {
            orderInput: {
              cart_id: cart.id,
              comment: action.payload.comment,
              password: action.payload.password,
              donation_data: action.payload.donationData || undefined,
              gamp_cid: gtmClient.getClientId() || undefined,
              gamp_session_id: gampSessionId,
              gamp_checkout_type: CheckoutHelper.isExpressCheckout(deliveryAddress, session, cart)
                ? CheckoutType.EXPRESS
                : CheckoutType.NORMAL
            }
          } as createOrderVariables
        })
        if (result.data) {
          const { createOrder: order } = result.data
          sendAnalyticsForSuccessfulOrder(cart, productCategories)
          gtmClient.orderRevenue(order.order.id, order.order.total_amount, order.order.order_products.map(op => op.product.id), order.order.user_type);

          if (order.registration_result && order.registration_result.token && order.registration_result.user) {
            yield put(updateSession(order.registration_result.user, order.registration_result.token))
            yield call(setTokenCookie, order.registration_result.token, true)
          }

          const newCart: CartFragment = yield call(getOrCreateCart, App.apolloClient)
          yield put(setCart(newCart, !!cart.linked_order))

          const loggedIn = (yield select((state: Redux.IReduxState) => state.session.loggedIn))
          if (loggedIn) {
            yield call(deleteCartIdCookie)
          } else {
            yield call(setGuestCartIdCookie, newCart.id)
          }

          yield put(updateOrder({ ...defaultOrderData, __hydrated: true }))
          //   yield put(setDeliveryAddress(undefined))

          // Save shopping lists
          if (action.payload.saveToShoppingList === true && action.payload.shoppingListName) {
              // Create shopping list
              const results = yield call(() => new Promise(resolve => {
                ListClient.getInstance().createList(action.payload.shoppingListName!).then(resolve)
              }));
              const listId = results.data.listId
              shoppingListActions.forEach(action => { action.listId = listId })
              yield call(() => new Promise(resolve => {
                ListClient.getInstance().syncListActions({ actions: shoppingListActions }).then(resolve)
              }));
          }

          if (order.rewrite_url) {
            window.location.href = order.rewrite_url
          } else {
            redirect(CheckoutHelper.getRedirectURLForOrder(order))
          }

          yield put(releaseRedirectLock())
        } else {
          throw 'no data:('
        }
      } catch (e) {
        yield put(releaseRedirectLock())
        yield put(unblockRedirects())

        const errors = collectAllErrorsForModal(e)

        yield put(cartError(<>Hiba történt a megrendelés leadása közben: {errors}</>))
        Logger.debug(e)
        Logger.debug(JSON.stringify(e))
        SentryLogger.logException(e, LoggerSeverity.Critical, { fingerPrint: ['createOrderSaga'], extras: {
            serializedError: JSON.stringify(e),
            graphQLErrors: e.graphQLErrors,
            cart
        }})
      }
  }

function* awaitRedirectLocks() {
  while((yield select((state: Redux.IReduxState) => state.redirectLockCount)) > 0) {
      Logger.debug('redirects locked')
      yield take(Actions.RELEASE_REDIRECT_LOCK)
  }
}

function* redirectSaga(action: Redux.IAction) {
    if (action.type !== Actions.REDIRECT) return

    if (yield select((state: Redux.IReduxState) => state.redirecting)) {
        Logger.debug('REDIRECTSAGABLOCKED', action.payload)
        return
    }

    Logger.debug('REDIRECTSAGAWAIT', action.payload)

    yield put(blockRedirects())    // unblocked in _app.tsx, onRouteChangeCompleted handler
    yield call(awaitRedirectLocks)

    Logger.debug('REDIRECTSAGAREDIRECTING', action.payload)
    if (action.isExternal) {
        window.location.replace(action.payload)
    } else {
        yield call(redirect, action.payload)
    }
}

function* pushRouteSaga(action: Redux.IAction) {
    if (action.type !== Actions.PUSH_ROUTE) return

    if (yield select((state: Redux.IReduxState) => state.redirecting)) {
        return
    }

    yield put(blockRedirects())    // unblocked in _app.tsx, onRouteChangeCompleted handler
    yield call(awaitRedirectLocks)

    Logger.debug('pushRoute')
    if (action.isExternal) {
        window.location.assign(action.payload)
    } else {
        yield call(pushRoute, action.payload)
    }
}

function* goBackSaga(action: Redux.IAction) {
    if (action.type !== Actions.GO_BACK) return
    if (yield select((state: Redux.IReduxState) => state.redirecting)) {
        return
    }

    yield put(blockRedirects())    // unblocked in _app.tsx, onRouteChangeCompleted handler
    yield call(awaitRedirectLocks)
    yield call(goBack)
}

export function* refreshShoppingLists(action: Redux.IAction) {
  if (action.type !== Actions.REFRESH_SHOPPINGLISTS) return

  const { session: { loggedIn }, shoppingLists: { isFetching }} = (yield select()) as Redux.IReduxState
  const fetchProductDetailsInList = action.payload
  if (isFetching) {
      return
  }
  if (loggedIn) {
    yield put(setShoppingListsFetching(true))
    const results = yield call(() => new Promise(resolve => {
      ListClient.getInstance().getUserLists().then(resolve)
    }));
    let shoppingLists = convertFetchResultToShoppingLists(results)
    if (fetchProductDetailsInList.length > 0) {
      // Collect product Ids
      const filteredLists = shoppingLists.filter(list => fetchProductDetailsInList.includes(list.listId))
      // Fetch data
      const collectedProductIds = Array.from(new Set(filteredLists.reduce((prevValue, currentList) => { return prevValue.concat(currentList.products.map(product => product.productId.toString())) }, new Array<string>())))
      if (collectedProductIds.length > 0) {
        const productResult = yield call(App.apolloClient.query, { query: productsByIdQuery, variables: { ids: collectedProductIds, page: 1, count: collectedProductIds.length } } )
        filteredLists.forEach(list => setProductDetailsInList(list, get(productResult, 'data.products.products', [])))
      }
      yield put(setShoppingLists(shoppingLists))
      return
    }
    yield put(setShoppingLists(shoppingLists))
  }
}
