import gql from 'graphql-tag'

export const CartShippingAddressFragment = gql`
fragment CartShippingAddressFragment on CartShippingAddressData {
    name
    postcode
    city
    street
    house_number
    note
    telephone
}
`