import gql from 'graphql-tag'
import UserFragment from '../fragments/UserFragment';

export const createOrderMutation = gql`
mutation createOrder($orderInput: OrderInput!) {
    createOrder(input: $orderInput){
        order {
            id
            # TODO rest fields
            status {
                title
                code
            }
            payment_method {
                id
            }
            total_amount
            order_products {
                product {
                    id
                }
            }
            user_type
        }
        rewrite_url
        registration_result {
            token
            user {
                ...UserFragment
            }
        }
        transaction_id
    }
}
${UserFragment}
`
