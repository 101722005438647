import ListClient from "Utils/api/ListClient";
import {searchProduct_products_products} from "Utils/api/gql/types";
import orderBy from 'lodash/orderBy'
import parse from 'date-fns/parse'

export const avatarColors = ["#00A3E0","#87BCDE","#FE717E","#414C94","#A9E1BB","#EDD03D","#6A5ACD","#EEE8AA","#778899","#32CD32","#FF6347","#48D1CC","#B8860B"];
export const avatarTextColors = ["#4A4A4A","#4A4A4A","#4A4A4A","#FFFFFF","#4A4A4A","#4A4A4A","#FFFFFF","#4A4A4A","#FFFFFF","#4A4A4A","#4A4A4A","#4A4A4A","#FFFFFF"]
const emailValidationRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

export enum ShoppingListType {
  SmartList = "smartlist",
  ShoppingList = "shoppinglist"
}

export enum ShoppingListUserType {
  Owner = "owner",
  Shared = "shared"
}

export const convertFetchResultToShoppingLists = (result: any): ShoppingList.IShoppingList[] => {
  const lists = result.data.map((d: any) => {
    let lastActionAt = parse(d.created_at)
    if (d.events && d.events.length > 0) {
      lastActionAt = parse(d.events[d.events.length - 1].created_at || '') || new Date()
    }
    return {
      listId: d.listId,
      name: d.name,
      type: d.type,
      createdAt: parse(d.created_at),
      lastActionAt: lastActionAt,
      products: d.products.map((p: any) => {
        return {
          productId: p.productId,
          quantity: p.quantity,
          created_at: p.created_at,
          productDetails: null
        }
      }),
      users: d.users.map((u: any) => {
        return {
          userId: u.userId,
          name: u.name,
          email: u.email,
          type: u.type,
          sharedAt: Date.parse(u.shared_at) || null,
          acceptedAt: Date.parse(u.accepted_at) || null
        }
      })
    }
  }) as ShoppingList.IShoppingList[]

  return orderBy(lists, ['type', (o) => { return o.lastActionAt.getTime() }], ['desc', 'desc'])
}

export const filterMyLists = (userId: string, lists: ShoppingList.IShoppingList[]) => {
  return lists.filter(list => { return isListOwner(userId, list) || isSharingAccepted(userId, list) })
}

export const setProductDetailsInList = (list: ShoppingList.IShoppingList, productDetails: searchProduct_products_products[]) => {
  const productDetailIds = productDetails.map(detail => parseInt(detail.id))
  list.products.forEach(product => {
    if (productDetailIds.includes(product.productId)) {
      product.productDetails = productDetails.find(detail => detail.id === product.productId.toString())!
    }
  })
}

export const isWaitingForShareDecision = (userId: string, list:ShoppingList.IShoppingList) => {
  return typeof list.users.find((u) => u.userId === userId && u.type === ShoppingListUserType.Shared && u.acceptedAt === null) !== 'undefined'
}

export const isListOwner = (userId: string, list: ShoppingList.IShoppingList) => {
  return typeof list.users.find((u) => u.userId === userId && u.type === ShoppingListUserType.Owner) !== 'undefined'
}

export const isSharingAccepted = (userId: string, list: ShoppingList.IShoppingList) => {
  return typeof list.users.find((u) => u.userId === userId && u.type === ShoppingListUserType.Shared && u.acceptedAt !== null) !== 'undefined'
}

export const getProductQuantityByProductId = (list: ShoppingList.IShoppingList, productId: number) => {
  return (list.products.find(product => product.productId === productId) || { quantity: 0}).quantity
}

export const getInitials = (value: string, maxCount: number = 2) => {
  if (typeof value === 'undefined') { return '' }
  if(value.trim() == "") {
    return "";
  }
  let words = value.trim().split(" ")
  if(words.length == 0) {
    return "";
  }
  let initials = words.map((word) => word.length > 0 ? word[0].toUpperCase() : "");
  return initials.join("").substring(0,Math.max(initials.length,maxCount));
}

export const userNameToColor = (userName: string) => {
  let hash = 0;
  for (var i = 0; i < userName.length; i++) {
      hash = userName.charCodeAt(i) + ((hash << 5) - hash);
  }

  return avatarColors[Math.abs(hash) % avatarColors.length];
}

export const userNameToTextColor = (userName: string) => {
  let hash = 0;
  for (var i = 0; i < userName.length; i++) {
      hash = userName.charCodeAt(i) + ((hash << 5) - hash);
  }

  return avatarTextColors[Math.abs(hash) % avatarColors.length];
}

export const isEmailValid = (email: string) => {
  return emailValidationRegexp.test(email)
}