import { differenceInSeconds } from "date-fns";
import { ShippingMethodFragment_ShippingMethodPostPoint_price_ranges } from "../api/gql/types";

export const weightFormatter = (weight: number): string => {
    if (weight < 1000) {
        return `${weight} g`
    }

    return `${Math.round(weight/10)/100} kg`
}

export const numberFormatter = (n: number, precision = 0, removeTailingZeros: boolean = false): string => {
    n = Number(n)
    if (isNaN(n) || !isFinite(n)) return '0'
    let isInt = false
    if (n === (n|0)) { isInt = true }

    let str = n.toFixed(precision)
    let [intPart, fractPart] = str.split('.')
    let tripletArray = []

    while (intPart) {
        tripletArray.push(intPart.substr(-3, 3))
        intPart = intPart.substr(0, Math.max(intPart.length - 3, 0))
    }

    intPart = tripletArray.reverse().join(' ')
    if (isInt && removeTailingZeros) {
      return intPart
    }
    if (removeTailingZeros && typeof fractPart !== 'undefined') {
      fractPart = fractPart.replace(/0+$/, '')
    }
    return fractPart
        ? `${intPart}.${fractPart}`
        : intPart
}
// Node on prod server apparently doesn't support hu-HU numbers. Returns "1,234" instead of "1 234"
//export const numberFormatter = new Intl.NumberFormat('hu-HU').format


export const getRelativeTime = (time: string) => {
    const diffInSec = differenceInSeconds(new Date(), time)
    if (diffInSec < 60) {
      return 'Éppen most'
    }
    if (diffInSec <= 60*60) {
      return `${Math.floor(diffInSec / (60))} perce`
    }
    if (diffInSec <= 60*60*24) {
      return `${Math.floor(diffInSec / (60*60))} órája`
    }
    if (diffInSec <= 60*60*24*30) {
        return `${Math.floor(diffInSec / (60*60*24))} napja`
    }
    if (diffInSec <= 60*60*24*365) {
        return `${Math.floor(diffInSec / (60*60*24*30)) || 1} hónapja` //kb...
    }
    return `${Math.floor(diffInSec / (60*60*24*365))} éve`
  }

export const priceIntervalFormatter = (price: number) => {
    if (price > 0) {
      return `0-${numberFormatter(price)} Ft`
    }
    return 'Ingyenes'
}

export const priceRangeFormatter = (priceRange: ShippingMethodFragment_ShippingMethodPostPoint_price_ranges): string => {
  return `${numberFormatter(priceRange.from)} Ft-tól ${numberFormatter(priceRange.price)} Ft`
}
