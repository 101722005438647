import React, { ReactElement } from 'react'

import { SVGArrow } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProfileNavItemContainer.scss'

type StateType = {
  height: number | string
}

type ResizableParentProp = {
  forceResizeParent: () => void
}

type PropType = {
  children?: React.ReactChild,
  disabled?: boolean,
  opened?: boolean,
  isEmphasized?: boolean,
  isOffcanvas?: boolean,
  noArrow?: boolean,
  renderHeader: React.ReactNode,
  renderIcon?: React.ReactNode,
  renderSide?: React.ReactNode,
  onClick: () => void
}

export default class ProfileNavItemContainer extends React.Component<PropType, StateType> {
  foldableElement: HTMLDivElement | null = null

  constructor(props: PropType) {
    super(props)
    this.state = {
      height: 0
      // opened: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    const height = this.foldableElement && this.foldableElement.scrollHeight || 0
    this.setState({ height })
  }

  handleClick() {
    this.props.onClick()
  }

  render() {
    const { children, disabled, isEmphasized, isOffcanvas, noArrow, renderHeader, renderIcon, renderSide, opened } = this.props
    const { height } = this.state

    return (
      <div className={cn(isOffcanvas ? cl.profileNavItemContainerIsOffcanvas : cl.profileNavItemContainer, isEmphasized && cl.profileNavItemContainerIsEmphasized, opened && cl.profileNavItemContainerIsOpened, disabled && cl.profileNavItemContainerIsDisabled)}>
        <div className={cn(cg.gridX, cg.alignMiddle, cl.navItem__header)} onClick={this.handleClick}>
          {renderIcon &&
            <div className={cn(cg.cell, cg.shrink, cl.iconWrapper)}>
              {renderIcon}
            </div>
          }

          <div className={cn(cg.cell, cg.auto)}>
            {renderHeader}
            {/* {height}px */}
          </div>

          {!noArrow && (
            <div className={cn(cg.cell, cg.shrink, cl.arrowWrapper)} aria-hidden={true}>
              <SVGArrow width={24} height={24} />
            </div>
          )}
        </div>

        <div
          className={cn(cg.gridX, cl.navItem__content)}
          ref={(foldableElement) => { this.foldableElement = foldableElement }}
          style={{ height: opened ? height : 0 }}
        >
          {renderSide && (
            <div className={cn(cg.cell, cl.navItem__sideContent)}>
              {renderSide}
            </div>
          )}

          {children && (
            <div className={cn(cg.cell, cg.medium8, cg.large9, cl.navItem__absoluteContent, { [cl.opened]: opened })}>
              {children}
            </div>
          )}
        </div>
      </div>
    )
  }
}
