/* eslint-disable */
import { format as formatDate } from 'date-fns'
import { isDevelopment, isServer } from './debugUtils'

function assembleOutput (format) {
  // eslint-disable-next-line
  return new Function('timestamp', 'title', 'level', 'message', 'file', 'line', 'pos', 'path', 'method', 'stack', 'return `' + format + '`;')
}

// Stack trace format :
// https://github.com/v8/v8/wiki/Stack%20Trace%20API
const stackReg = /at\s+(.*)\s+\((.*):(\d*):(\d*)\)/i
const stackReg2 = /at\s+()(.*):(\d*):(\d*)/i

function Logging (options) {
  const defaultOptions = {
      // eslint-disable-next-line
      format: '${timestamp} <${title}> ${file}:${line} ${method} ${message}',
      dateformat: 'YYYY.MM.DD\THH:mm:ss.SSSZZ',
      preprocess: function (data) {
      },
      transport: function (data) {
          console.log(data.output, data.css)
      },
      filters: [],
      level: 'debug',
      methods: ['debug', 'info', 'notice', 'warning', 'error', 'critical', 'alert', 'emergency'],
      stackIndex: 0
  }

  // union options and defaultOptions
  options = Object.assign({}, defaultOptions, options)

  // RFC 5424 levels
  // http://tools.ietf.org/html/rfc5424
  // debug, info, notice, warning, error, critical, alert, emergency

  // set and get the level, default 0 meaning debug (array index)
  // internal the level is always a number
  this.getLevel = function () {
      return options.methods[_level]
  }
  this.setLevel = function (level) {
      if (typeof level === 'string') {
          let ind = options.methods.indexOf(level)
          if (ind !== -1) {
              _level = ind
          } else {
              _level = 0
          }
      } else if (typeof level === 'number') {
          _level = Math.min(Math.max(level, 0), options.methods.length)
      } else {
          _level = 0
      }
      return that.getLevel()
  }

  this.close = function () {
      _level = Number.MAX_VALUE
  }

  // private variable
  var _level = 0
  // for access in inner functions
  var that = this

  // set private variable _level
  this.setLevel(options.level)

  // make sure the following options are arrays
  if (!Array.isArray(options.format)) {
      options.format = [options.format]
  }
  if (!Array.isArray(options.filters)) {
      options.filters = [options.filters]
  }
  if (!Array.isArray(options.transport)) {
      options.transport = [options.transport]
  }

  let filterLength = options.filters.length
  let lastFilter
  if (filterLength > 0) {
      filterLength -= 1
      if (Object.prototype.toString.call(options.filters[filterLength]) === '[object Object]') {
          lastFilter = options.filters[filterLength]
          options.filters = options.filters.slice(0, filterLength)
      }
  }

  for (let i = 0; i < options.methods.length; i++) {
      let title = options.methods[i]
      if (i < _level) {
          this[title] = function () { }
      } else {
          // get format, either the method specific or the default
          let format = options.format[0]
          if (options.format.length === 2 && options.format[1][title]) {
              format = options.format[1][title]
          }

          // check if stack must be analysed
          let needstack = /\${(method|path|line|pos|file)}/i.test(format)

          // get filter, either the method specific or the default
          let filters
          if (lastFilter && lastFilter[title]) {
              filters = Array.isArray(lastFilter[title]) ? lastFilter[title] : [lastFilter[title]]
          } else {
              filters = options.filters
          }

          this[title] = function (...args) {
              return log(options, i, title, format, needstack, filters, args)
          }
      }
  }

  function log (config, level, title, format, needstack, filters, args) {
      if (level < _level) {
          return
      }

      var data = {
          timestamp: formatDate(new Date(), config.dateformat),
          title: title,
          level: level,
          message: '',
          args: args
      }
      data.file = data.line = data.pos = data.path = data.method = data.stack = data.css = ''

      if (needstack) {
          // get call stack, and analyze it
          // get all file, method and line number
          var stacklist = (new Error()).stack.split('\n').slice(3)
          var s = stacklist[config.stackIndex] || stacklist[0]
          var sp = stackReg.exec(s) || stackReg2.exec(s)
          if (sp && sp.length === 5) {
              data.method = sp[1]
              data.path = sp[2]
              data.line = sp[3]
              data.pos = sp[4]
              data.file = data.path.split(/[\\/]/).pop()
              data.stack = stacklist.join('\n')
          }
      }

      // call preprocess function
      config.preprocess(data)
      // join all arguments for message
      let argsProcessed = []
      args.forEach((arg) => {
        if (typeof arg === 'object') {
          argsProcessed.push(JSON.stringify(arg, undefined, 2))
        } else {
          argsProcessed.push(arg)
        }
      })
      data.message = argsProcessed.join(' + ')
      let template = assembleOutput(format)
      data.output = template(data.timestamp, data.title, data.level, data.message, data.file, data.line, data.pos, data.path, data.method, data.stack)

      if (isServer()) {
        console.log(data.output)
        return data
      } else {
        // process every filter, can be a function or color string
        for (let i = 0; i < filters.length; i++) {
            if (typeof filters[i] === 'string') {
                data.output = '%c' + data.output
                data.css = 'color: ' + filters[i]
            } else {
                data.output = filters[i](data.output)
            }
            if (!data.output) { // cancel next process if return a false(include null, undefined)
                return data
            }
        }

        // trans the final result
        config.transport.forEach(function (tras) {
            tras(data)
        })
      }

      return data
  }
}

class LoggerInternal {
  static configureLogger () {
    LoggerInternal.logger = new Logging({
      filters: {
        debug: 'Gray',
        info: 'Black',
        notice: 'Green',
        warning: 'Blue',
        error: 'Red',
        critical: 'Orange',
        alert: 'Cyan',
        emergency: 'Magenta'
      },
      level: isDevelopment() ? 'debug' : 'info',
      // eslint-disable-next-line
      format: '${timestamp} <${title}> ${message}',
      transport: function (data) {
        data.args.forEach(arg => {
          if (typeof arg === 'object') {
            console.dir(arg)
          } else {
            console.log(data.output, data.css)
          }
        })
      }
    })
    return LoggerInternal.logger
  }

  static getLogger () {
    if (typeof LoggerInternal.logger === 'undefined') {
      LoggerInternal.configureLogger()
    }
    return LoggerInternal.logger
  }
}

export default LoggerInternal.getLogger()
/* eslint-enable */
