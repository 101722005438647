import React from 'react'
import { showMessage } from "./modalHelper"
import { AppContext } from 'Utils/withReduxStore'
import { redirect } from './routerHelper'
import { getCheckoutInfo_paymentMethods, getCheckoutInfo_shippingMethods, getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved_data, CartFragment, createOrder_createOrder, changePaymentMethod_changePaymentMethod } from 'Utils/api/gql/types'
import { DispatchProp } from 'react-redux'
import { redirectSafe, updateOrder } from 'Utils/redux/actions'
import { isEqual } from './misc'
import { format } from 'date-fns'
import { CheckoutStage } from './constants'

import { isCartShippingAddressEmpty, convertCartShippingAddressToCheckoutShippingAddressInput, emptyShippingAddress, convertShippingAddressFragmentToCheckoutShippingAddressInput, formatOrderOneLine } from './addressHelper';
import Box from 'Components/Box';
import { SVGBankCard, SVGBankTransfer, SVGSavedCard, SVGNewCard, SVGCashOnDelivery } from 'Components/SVGCollection';

export type ShippingErrorDialogButtonValues = 'toCart' | 'toDelivery' | 'grobyModal'

type CheckoutRedirectProps = {
    deliveryAddress: Redux.IDeliveryAddress | undefined
    session: Redux.IStoreSessionData
    cart: CartFragment | null
} & DispatchProp

export type CheckoutStepType = {
    id: number
    ariaLabel: string
    href: string
    stage: CheckoutStage
    validation?: (cart: CartFragment, deliveryAddress: Redux.IDeliveryAddress | undefined, session: Redux.IStoreSessionData) => boolean
    toStepButtonText: string
}

const normalSteps: CheckoutStepType[] = [
    {
      id: 0,
      ariaLabel: 'Kosár',
      href: '/kosar',
      stage: CheckoutStage.CART,
      toStepButtonText: "a kosárhoz"
    },
    {
      id: 1,
      ariaLabel: 'Kapcsolattartási adatok',
      href: '/megrendeles',
      stage: CheckoutStage.LOGIN,
      validation: (cart, deliveryAddress) => !!cart.shipping_method && !!deliveryAddress && (!cart.shipping_method.errors || !cart.shipping_method.errors.length),
      toStepButtonText: 'a kapcsolattartási adatokhoz'
    },
    {
      id: 2,
      ariaLabel: 'Szállítási adatok',
      href: '/megrendeles/kiszallitas',
      stage: CheckoutStage.SHIPPING,
      validation: cart => !!cart.shipping_method && !!cart.contact_data,
      toStepButtonText: 'a szállítási adatokhoz'
    },
    {
      id: 3,
      ariaLabel: 'Számlázási és fizetési adatok',
      href: '/megrendeles/szamlazas',
      stage: CheckoutStage.BILLING,
      validation: cart => !!cart.shipping_address,
      toStepButtonText: "a számlázási és fizetési adatokhoz"
    },
    {
      id: 4,
      ariaLabel: 'Összesítés',
      href: '/megrendeles/osszesites',
      stage: CheckoutStage.SUMMARY,
      validation: cart => !!cart.payment_method,
      toStepButtonText: "az összesítéshez"
    }
  ]

  const expressSteps: CheckoutStepType[] = [
    {
      id: 0,
      ariaLabel: 'Kosár',
      href: '/kosar',
      stage: CheckoutStage.CART,
      toStepButtonText: "a kosárhoz"
    },
    {
      id: 1,
      ariaLabel: 'Megrendelés adatai',
      href: '/megrendeles/expressz',
      stage: CheckoutStage.EXPRESS,
      validation: (cart, deliveryAddress) => !!cart.shipping_method && !!deliveryAddress  && (!cart.shipping_method.errors || !cart.shipping_method.errors.length),
      toStepButtonText: "a megrendelés adataihoz"
    },
    {
      id: 2,
      ariaLabel: 'Összesítés',
      href: '/megrendeles/osszesites',
      stage: CheckoutStage.SUMMARY,
      validation: cart => !!cart.shipping_address && !!cart.billing_address && !!cart.payment_method,
      toStepButtonText: "az összesítéshez"
    }
  ]

const iconForPaymentMethod = (pm: Redux.PaymentMethodOption) => {
    switch(pm.type) {
        case 'PaymentMethodBankTransfer':
            return SVGBankTransfer
        case 'PaymentMethodCreditCardSimple':
        case 'PaymentMethodCreditCardBorgun':
            return SVGBankCard
        case 'PaymentMethodCreditCardSimpleSaved':
            return pm.cardId ? SVGSavedCard : SVGNewCard
        case 'PaymentMethodCashOnDelivery':
            return SVGCashOnDelivery
    }
}

export default class CheckoutHelper {
    static ShippingMethodErrorDictionary: { [s in Redux.ShippingMethodErrorType]: string } = {
        ShippingMethodErrorProducts: 'A kiválasztott szállítási móddal bizonyos kosaradban lévő termékek nem szállíthatóak.',
        ShippingMethodErrorWeight: 'A kosarad össztömege meghaladja a választott szállítási móddal kiszállítható maximális tömeget.',
        ShippingMethodErrorUnavailable: 'A kiválasztott szállítási mód jelenleg nem elérhető!',
        ShippingMethodErrorPostcode: 'Erre az irányítószámra a választott szállítási mód nem elérhető!'
    }

    static ShippingMethodSelectErrorDictionary: { [s in Redux.ShippingMethodErrorType]: string } = {
        ShippingMethodErrorProducts: 'A szállítási móddal bizonyos kosaradban lévő termékek nem szállíthatóak.',
        ShippingMethodErrorWeight: 'A kosarad össztömege meghaladja a kiszállítható maximális tömeget.',
        ShippingMethodErrorUnavailable: 'A szállítási mód jelenleg nem elérhető!',
        ShippingMethodErrorPostcode: 'Erre az irányítószámra a GRoby kiszállítás nem elérhető!'
    }

    static getCheckoutSteps = (deliveryAddress: Redux.IDeliveryAddress | undefined, loggedIn: boolean, cart: CartFragment | null) => {
        return CheckoutHelper.isExpressCheckoutByLoggedIn(deliveryAddress, loggedIn, cart) ? expressSteps : normalSteps
    }

    static getPreviousCheckoutStep = (currentStage: CheckoutStage, checkoutSteps: CheckoutStepType[]) => {
        const index = checkoutSteps.findIndex(step => step.stage === currentStage)
        if (index === -1 || index === 0) {
            return null
        }
        return checkoutSteps[index - 1]
    }

    static getNextCheckoutStep = (currentStage: CheckoutStage, checkoutSteps: CheckoutStepType[]) => {
        const index = checkoutSteps.findIndex(step => step.stage === currentStage)
        if (index === -1) {
            return null
        }
        if (index === checkoutSteps.length - 1) {
            return null
        }
        return checkoutSteps[index + 1]
    }

    static getNextCheckoutButtonText = (currentStage: CheckoutStage, { deliveryAddress, session, cart }: {deliveryAddress: Redux.IDeliveryAddress | undefined, session: Redux.IStoreSessionData, cart: CartFragment | null}) => {
        const steps = CheckoutHelper.getCheckoutSteps(deliveryAddress, session.loggedIn, cart)
        const nextStep = CheckoutHelper.getNextCheckoutStep(currentStage, steps)
        return nextStep
            ? `Tovább ${nextStep.toStepButtonText}`
            : 'Tovább'
    }

    static getPreviousCheckoutButtonText = (currentStage: CheckoutStage, { deliveryAddress, session, cart }: {deliveryAddress: Redux.IDeliveryAddress | undefined, session: Redux.IStoreSessionData, cart: CartFragment | null}) => {
        const steps = CheckoutHelper.getCheckoutSteps(deliveryAddress, session.loggedIn, cart)
        const nextStep = CheckoutHelper.getPreviousCheckoutStep(currentStage, steps)
        return nextStep
            ? `Vissza ${nextStep.toStepButtonText}`
            : 'Vissza'
    }

    static isExpressCheckoutByLoggedIn = (deliveryAddress: Redux.IDeliveryAddress | undefined, loggedIn: boolean, cart: CartFragment | null): boolean => {
        return (!!cart && !!cart.linked_order) || !!deliveryAddress && loggedIn && ((deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'savedAddress' && deliveryAddress.value.addressId != null) || deliveryAddress.selected.type === 'personal')
    }

    static isExpressCheckout = (deliveryAddress: Redux.IDeliveryAddress | undefined, session: Redux.IStoreSessionData, cart: CartFragment | null): boolean => {
        return (!!cart && !!cart.linked_order) || !!deliveryAddress && session.loggedIn && ((deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'savedAddress' && deliveryAddress.value.addressId != null) || deliveryAddress.selected.type === 'personal')
    }

    static getRedirectURL(currentStage: CheckoutStage, deliveryAddress: Redux.IDeliveryAddress | undefined, session: Redux.IStoreSessionData, cart: CartFragment | null): string | false {
        if (!cart) {
            return '/'
        }

        const checkoutSteps = CheckoutHelper.isExpressCheckout(deliveryAddress, session, cart)
            ? expressSteps
            : normalSteps

        let lastValidStep: CheckoutStepType | null = null

        for (let step of checkoutSteps) {
            // console.log('current step', step)
            if (step.validation && !step.validation(cart, deliveryAddress, session)) {
                // console.log('validation failed. lastValdstep: ', lastValidStep)
                return lastValidStep ? lastValidStep.href : '/'
            }
            if (step.stage === currentStage) {
                // console.log('curr', currentStage)
                return false
            }
            lastValidStep = step
        }

        return lastValidStep
            ? lastValidStep.href
            : '/'
    }

    static initialRedirectIfNeeded(stage: CheckoutStage, ctx: AppContext) {
        const { deliveryAddress, cart, session } = ctx.reduxStore.getState()
        const redirectURL = CheckoutHelper.getRedirectURL(stage, deliveryAddress, session, cart)
        if (redirectURL) {
          redirect(redirectURL, ctx.res)
        }
    }

    static clientRedirectIfNeeded(stage: CheckoutStage, { cart, deliveryAddress, session, dispatch }: CheckoutRedirectProps, prevProps?: CheckoutRedirectProps) {
        if (prevProps && isEqual(cart, prevProps.cart) && isEqual(deliveryAddress, prevProps.deliveryAddress) && isEqual(session, prevProps.session)) {
            return
        }
        const redirectURL = CheckoutHelper.getRedirectURL(stage, deliveryAddress, session, cart)
        if (redirectURL) {
            dispatch(redirectSafe(redirectURL))
        }
    }

    static isPaymentMethodAvailable(methodCode: Redux.PaymentMethodCode, paymentMethods: (getCheckoutInfo_paymentMethods | null)[]): boolean {
        const method = paymentMethods.find(method => !!method && method.__typename === methodCode)
        if (!method) {
            return false
        }
        return method.is_available
    }

    static getMethod(methodCode: Redux.ShippingMethodCode | null, shippingMethods: getCheckoutInfo_shippingMethods[]): getCheckoutInfo_shippingMethods | undefined {
        return shippingMethods.find(method => method.__typename === methodCode)
    }

    static getProhibitedItems(method: getCheckoutInfo_shippingMethods): Array<string> {
        if (!method.errors) {
            return []
        }

        const prohibitedError = method.errors.find(error => !!error && error.__typename === 'ShippingMethodErrorProducts')
        if (!prohibitedError || prohibitedError.__typename !== 'ShippingMethodErrorProducts') {
            return []
        }

        return prohibitedError.data.map(item => item.id)
    }

    static getMethodStatus(methodCode: Redux.ShippingMethodCode, shippingMethods: getCheckoutInfo_shippingMethods[]): Redux.ShippingMethodStatus {
        const method = shippingMethods.find(method => method.__typename === methodCode)

        if (method && method.is_available) {
            return { isAvailable: true }
        } else {
            if (!method || !method.errors || !method.errors.length) {
                return {
                    isAvailable: false,
                    errors: ['ShippingMethodErrorUnavailable']
                }
            }

            const errors = new Set<Redux.ShippingMethodErrorType>()
            method.errors.forEach(error => error && errors.add(error.__typename))

            return {
                isAvailable: false,
                errors: errors.size
                    ? Array.from(errors)
                    : ['ShippingMethodErrorUnavailable']
            }
        }
    }

    // TODO: nem használjuk sehol
    static showErrorDialogForShippingErrors(errors: Redux.ShippingMethodErrorType[]) {
        if (errors.length === 1) {
            showMessage(CheckoutHelper.ShippingMethodErrorDictionary[errors[0]], 10000)
        } else {
            showMessage(<><h2>A kiválasztott szállítási móddal a következő problémák adódtak: </h2>
                    {errors.map(error => <p key={error} style={{fontWeight: 'normal', color: "#FF0000"}}>{CheckoutHelper.ShippingMethodErrorDictionary[error]}</p>)}
                </>, 10000)
        }
    }

    static formatCard(card: getCheckoutInfo_paymentMethods_PaymentMethodCreditCardSimpleSaved_data): React.ReactNode {
        return <>
            {card.card_type ? <>{card.card_type}<br /></> : null}
            **** **** **** {card.card_pan}<br />
            ({format(card.card_exp, 'MM/YY')})
        </>
    }

    static methodHasAnyError(method: getCheckoutInfo_shippingMethods): boolean {
        return (method && method.errors && method.errors.length > 0) || false
    }

    static methodHasWeightProblem(method: getCheckoutInfo_shippingMethods): boolean {
        if (method.errors && method.errors.some(error => !!(error && error.__typename === 'ShippingMethodErrorWeight'))) {
            return true
        }
        return false
    }

    static methodHasProductsProblem(method: getCheckoutInfo_shippingMethods): boolean {
        if (method.errors && method.errors.some(error => !!(error && error.__typename === 'ShippingMethodErrorProducts'))) {
            return true
        }
        return false
    }

    static getPreferredShippingMethod(deliveryType: Redux.DeliveryType, shippingMethods: getCheckoutInfo_shippingMethods[]): Redux.ShippingMethodCode {
        if (deliveryType.type === 'personal') {
            return 'ShippingMethodGPoint'
        }

        const grobyDeliveryStatus = CheckoutHelper.getMethodStatus('ShippingMethodGRoby', shippingMethods)
        if (grobyDeliveryStatus.isAvailable || grobyDeliveryStatus.errors.every(error => error !== 'ShippingMethodErrorPostcode')) {
            return 'ShippingMethodGRoby'
        }

        return 'ShippingMethodMpl'
    }

    // TODO: nem fog kelleni, a deliveryből is ki kell venni
    static setBestAvailableDeliveryIfNeeded({ deliveryAddress, orderData, shippingMethods, dispatch}: { deliveryAddress: Redux.IDeliveryAddress | undefined, orderData: Redux.IOrderData, shippingMethods: getCheckoutInfo_shippingMethods[] } & DispatchProp) {
        if (orderData.__hydrated && !orderData.selected_method && deliveryAddress && shippingMethods.length) {
            const bestMethod = CheckoutHelper.getBestAvailableShippingMethodCode(deliveryAddress, shippingMethods)
            dispatch(updateOrder({ selected_method: bestMethod }, true))
        }
    }

    static setOrderDataShippingAddressIfEmpty({ deliveryAddress, orderData, dispatch, cart }: { deliveryAddress: Redux.IDeliveryAddress | undefined, orderData: Redux.IOrderData, cart: CartFragment | null } & DispatchProp) {
        if (isCartShippingAddressEmpty(orderData.shipping_address)) {
            if (deliveryAddress && deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'savedAddress') {
                dispatch(updateOrder({ shipping_address_id: deliveryAddress.value.addressId }, true))
                return
            }
            if (cart && cart.shipping_address && (!deliveryAddress || deliveryAddress.value.zip === cart.shipping_address.postcode)) {
                dispatch(updateOrder({ shipping_address: convertCartShippingAddressToCheckoutShippingAddressInput(cart.shipping_address) }, true))
                return
            }
            if (deliveryAddress && deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'postcode') {
                dispatch(updateOrder({ shipping_address: convertShippingAddressFragmentToCheckoutShippingAddressInput({ ...emptyShippingAddress(), postcode: deliveryAddress.value.zip || '' }) }, true))
                return
            }
        }
    }

    static getBestAvailableShippingMethod(deliveryType: Redux.DeliveryType, shippingMethods: getCheckoutInfo_shippingMethods[]): getCheckoutInfo_shippingMethods | null {
        const personalDelivery = shippingMethods.find(method => method.__typename === 'ShippingMethodGPoint')
        if (deliveryType.type === 'personal' && personalDelivery) {
            return personalDelivery
        }
        const grobyDelivery = shippingMethods.find(method => method.__typename === 'ShippingMethodGRoby')
        if (grobyDelivery && grobyDelivery.is_available) {
            return grobyDelivery
        }

        const mplDelivery = shippingMethods.find(method => method.__typename === 'ShippingMethodMpl')
        if (mplDelivery && mplDelivery.is_available) {
            return mplDelivery
        }

        const postPointDelivery = shippingMethods.find(method => method.__typename === 'ShippingMethodPostPoint')
        if (postPointDelivery && postPointDelivery.is_available) {
            return postPointDelivery
        }

        if (personalDelivery) {
            return personalDelivery
        }

        return null
    }

    static getBestAvailableShippingMethodCode(deliveryAddress: Redux.IDeliveryAddress | null | undefined, shippingMethods: getCheckoutInfo_shippingMethods[]): Redux.ShippingMethodCode {
        if (!deliveryAddress) {
            return 'ShippingMethodGPoint'
        }
        const method = CheckoutHelper.getBestAvailableShippingMethod(deliveryAddress.selected, shippingMethods)
        if (method) {
            return method.__typename
        }

        return 'ShippingMethodGPoint'
    }

    static paymentMethodOptionFromCart = (cart: CartFragment | null): Redux.PaymentMethodOption | null => {
        if (!cart || !cart.payment_method) {
            return null
        }
        if (cart.payment_method.__typename === 'PaymentMethodCreditCardSimpleSaved') {
            if (!cart.payment_method_data) {
                return { type: 'PaymentMethodCreditCardSimpleSaved', cardId: null }
            }
            return {
                type: cart.payment_method.__typename,
                cardId: cart.payment_method_data.user_saved_credit_card_id
            }
        } else {
            return {
                type: cart.payment_method.__typename
            }
        }
    }

    static getPaymentMethodBoxes(paymentMethods: getCheckoutInfo_paymentMethods[], onSelect: (value: Redux.PaymentMethodOption) => void, current: Redux.PaymentMethodOption | null): JSX.Element[] {
        const paymentBox = (title: string, type: Redux.PaymentMethodOption, method: getCheckoutInfo_paymentMethods, content?: React.ReactNode) =>
            <Box
                key={JSON.stringify(type)}
                title={title}
                value={type}
                onSelect={onSelect}
                selected={isEqual(type, current)}
                Icon={iconForPaymentMethod(type)}
            >
                {method.__typename === 'PaymentMethodCashOnDelivery' && !!method.cost && <b>Az utánvét kezeléséért {method.cost} Ft díjat számolunk fel.<br /></b>}
                {typeof content === 'undefined' ? method.short_description : content}
                {(method.__typename === 'PaymentMethodCreditCardSimple' || method.__typename === 'PaymentMethodCreditCardSimpleSaved') &&
                    <div>
                        {isEqual(type, current) ?
                            <>
                                <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><img src="/static/simple_logo-color.svg" /></a>
                                <a href="https://paymentgateway.hu/" target="_blank"><img src="/static/bf_pmgw_small.svg" /></a>
                            </>
                        :
                            <>
                                <img src="/static/simple_logo-color.svg" />
                                <img src="/static/bf_pmgw_small.svg" />
                            </>
                        }
                    </div>}
              {method.__typename === 'PaymentMethodCreditCardBorgun' && (
                  <div>
                    {isEqual(type, current) ?
                      <>
                        <a href="https://www.borgun.com/hu/" target="_blank"><img src="/static/borgun_logo-color.svg" /></a>
                        <a href="https://paymentgateway.hu/" target="_blank"><img src="/static/bf_pmgw_small.svg" /></a>
                      </>
                      :
                      <>
                        <img src="/static/borgun_logo-color.svg" />
                        <img src="/static/bf_pmgw_small.svg" />
                      </>
                    }
                  </div>
              )}
            </Box>

        const paymentMethodBoxes: JSX.Element[]  = []

        for (const paymentMethod of paymentMethods) {
            if (!paymentMethod.is_available) {
                continue
            }

            if (paymentMethod.__typename === 'PaymentMethodCreditCardSimpleSaved') {
                const type: Redux.PaymentMethodOption = { type: 'PaymentMethodCreditCardSimpleSaved', cardId: null }
                if (paymentMethod.data) {
                    for (const savedCard of paymentMethod.data) {
                        const type: Redux.PaymentMethodOption = { type: 'PaymentMethodCreditCardSimpleSaved', cardId: savedCard.id }
                        paymentMethodBoxes.push(paymentBox(savedCard.card_title || 'Tárolt kártya', type, paymentMethod, CheckoutHelper.formatCard(savedCard)))
                    }
                }
                paymentMethodBoxes.push(paymentBox("Új mentett kártya", type, paymentMethod))
            } else {
                const type = { type: paymentMethod.__typename }
                paymentMethodBoxes.push(paymentBox(paymentMethod.name, type, paymentMethod))

            }
        }
        return paymentMethodBoxes
    }

    static getRedirectURLForOrder(result: Pick<changePaymentMethod_changePaymentMethod, 'order' | 'transaction_id'>): string {
        if (result.transaction_id) {
            return `/megrendeles/statusz/${result.transaction_id}`
        } else {
            return `/megrendeles/statusz/sikeres/${result.order.id}`
        }
    }

    static formatLinkedOrder(cart: CartFragment | null): React.ReactNode {
        if (!cart || !cart.linked_order) {
            return null
        }

        return formatOrderOneLine(cart.linked_order)
    }

    static isDeliveryAddressValid(deliveryAddress: Redux.IDeliveryAddress | undefined): boolean {
        return !!deliveryAddress && (deliveryAddress.selected.type === "personal" || !!deliveryAddress.value.zip || !!deliveryAddress.value.addressId);
    }

    static isPaymentMethod(x: getCheckoutInfo_paymentMethods | null): x is getCheckoutInfo_paymentMethods {
        return !!x && x.is_available
    }
}
