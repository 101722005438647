import React from 'react'
import { initializeStore } from 'Utils/redux/store'
import { getTokenFromCookie, getCartOpenedCookie, getDeliveryAddressCookie, getGuestCartIdFromCookie, setGuestCartIdCookie, setDeliveryAddressCookie } from 'Utils/cookie'
import { parseUserAgent } from './BrowserDetect'
import Head from 'next/head'
import { NextContext } from 'next'
import gtmClient from './gtmClient'
import initApollo from './api/gql/initApollo'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { IncomingMessage } from 'http'
import { Store } from 'redux'
import meQuery from './api/gql/queries/me'
import { me, CartFragment, allProductCategories } from './api/gql/types'
import { updateSession, setCart, destroySessionAndCookie, setOrderedProductIDs, rehydrateOrder, updateOrder, setDeliveryAddress } from './redux/actions'
import { getDataFromTree } from 'react-apollo'
import Logger from 'Utils/Logging'
import allProductCategoriesQuery from './api/gql/queries/allProductCategories'
import { processCategories } from './helpers/categoryDetailsHelper'
import { getOrCreateCart } from './api/gql/helpers'
import SentryLogger, { LoggerSeverity } from './helpers/sentryLogger';
import { ORDER_DATA_KEY, ShippingMethodId } from './helpers/constants';

export const __APOLLO_CACHE__ = '__APOLLO_CACHE__'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

const isServer = typeof window === 'undefined'
declare var window: any;

type CustomContext = {
  initialState: Redux.IReduxState,
  reduxStore: Store<Redux.IReduxState>,
  req: IncomingMessage & { [s: string]: any }
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export type AppContext = NextContext & CustomContext

function getOrCreateStore (initialState?: Redux.IReduxState): Store<Redux.IReduxState> {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Store in global variable if client
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }

  return window[__NEXT_REDUX_STORE__]
}

function isMobileView (userAgent: string, MobileDetect: any) {
  if (!userAgent) { return false }
  const md = new MobileDetect(userAgent);
  return md.mobile() !== null
}

function isTabletView (userAgent: string, MobileDetect: any) {
  if (!userAgent) { return false }
  const md = new MobileDetect(userAgent);
  return md.tablet() !== null
}

type AppInitialPropsContext = {
  Component: React.Component
  router: any
  ctx: AppContext
}

let currentPath: string = "/";

export default (App: any) => {
  return class WithData extends React.Component {
    reduxStore: Store<Redux.IReduxState>
    apolloClient: ApolloClient<NormalizedCacheObject>

    static apolloClient: ApolloClient<NormalizedCacheObject>
    static displayName = `WithRedux(${App.displayName})`
    static propTypes = {}


    static getCurrentPath = () => {
      if (typeof document !== "undefined") {
        return decodeURI(document.location.pathname)
      }
      return currentPath;
    }

    static async getInitialProps (ctx: AppInitialPropsContext) {
      const { Component, router, ctx: { req, res } } = ctx

      let reduxStore = getOrCreateStore()
      let initialState = JSON.parse(JSON.stringify(reduxStore.getState())) as Redux.IReduxState

      // Things to do anyway
      const token = getTokenFromCookie(req)
      initialState.session.token = token
      initialState.deliveryAddress = getDeliveryAddressCookie(req)

      const initialApolloState = isServer
        ? req.initialApolloCache
        : window[__APOLLO_CACHE__]

      const apolloClient = initApollo(initialApolloState, {
        getToken: () => reduxStore.getState().session.token
      })

      let isBot = false

      // Things to do in server env
      if (isServer) {
        currentPath = router.asPath || "/";
        // Workaround for /static varnish cookie deletion where 404 images in /static cause new cart cookie to be set
        if (router.pathname === "/_error" && /^\/static/.test(currentPath)) {
          return {};
        }
        const uaData = parseUserAgent(req.headers['user-agent'] || '')
        if (uaData !== null && uaData.name === 'bot') {
          isBot = true
        }
        // Set all data to initial redux state
        if (req && req.scheduledData) {
          initialState.scheduledData = {...req.scheduledData}
        }
        initialState.productCategories = req.scheduledData.categories
        initialState.promotionCategories = req.scheduledData.promotionCategories
        initialState.footerBlocks = req.scheduledData.footerBlocks
        initialState.blogEntries = req.scheduledData.blogEntries
        const MobileDetect = require('mobile-detect/mobile-detect')
        initialState.isMobile = isMobileView(req.headers['user-agent'] || '', MobileDetect)
        initialState.isTablet = isTabletView(req.headers['user-agent'] || '', MobileDetect)
        delete initialState.scheduledData.blogEntries
        delete initialState.scheduledData.categories
        delete initialState.scheduledData.footerBlocks
        delete initialState.scheduledData.promotionCategories
        delete initialState.scheduledData.productAttributes
        delete initialState.scheduledData.urlAliases
      } else {
        // Things to do in browser environment
        if (initialState.productCategories.length === 0) {
          try {
            const result = await apolloClient.query<allProductCategories>({ query: allProductCategoriesQuery })
            if (result.data.productCategories) {
              initialState.productCategories = processCategories(result.data.productCategories)
            }
          } catch (e) {
            //@TODO error handling?
            throw('withReduxStore: category fetch on client failed')
          }
        }
        initialState.isMobile = window && window.matchMedia && window.matchMedia('(max-width: 639px)').matches
        initialState.isTablet = window && window.matchMedia && window.matchMedia('(min-width: 640px) and (max-width: 1024px)').matches
      }

      // Common methods
      initialState.cartPanelCookie = getCartOpenedCookie(req)
      // Initial state complete, set up store

      reduxStore = getOrCreateStore(initialState)

      if (token && !reduxStore.getState().session.customerData) {
        try {
          const result = await apolloClient.query<me>({ query: meQuery, fetchPolicy: 'no-cache' })
          if (result.data.me) {
            reduxStore.dispatch(updateSession(result.data.me, token))
          } else throw new Error('No user data received')
        } catch (e) {
          const isAuthorizationError = Array.isArray(e.graphQLErrors) && e.graphQLErrors.some((error: any) => error.extensions && (error.extensions.category === 'authentication' || error.extensions.category === 'authorization'))
          if (isAuthorizationError) {    // Token expired or was destroyed
            SentryLogger.logException(e, LoggerSeverity.Info, { fingerPrint:['withRedux', 'forcedLogout'], extras: {
              token,
              serializedError: JSON.stringify(e),
              session: reduxStore.getState().session,
              graphQLErrors: e.graphQLErrors
            }})
            reduxStore.dispatch(destroySessionAndCookie())
          } else {
            SentryLogger.logException(e, LoggerSeverity.Fatal, { fingerPrint: ['withRedux', 'fetchMeError'], extras: {
              token,
              serializedError: JSON.stringify(e),
              session: reduxStore.getState().session,
              graphQLErrors: e.graphQLErrors
            }})
            throw new Error('Fatal error, cannot authenticate')
          }
        }
      }

      if (!token && reduxStore.getState().session.customerData) { // We have no token but user data remains in the store. This happens when the user logs out in another tab.
        SentryLogger.logMessage('Logged out on other tab', LoggerSeverity.Warning, { fingerPrint: ['withRedux', 'loggedOutElsewhere'], extras: {
          token,
          session: reduxStore.getState().session,
        }})
        reduxStore.dispatch(destroySessionAndCookie())
      }

      gtmClient.customerData = reduxStore.getState().session.customerData

      const loggedIn = reduxStore.getState().session.loggedIn
      const cartId = !loggedIn
        ? getGuestCartIdFromCookie(req)
        : undefined

      const { orderedProductIds } = reduxStore.getState()
      if (!reduxStore.getState().session.loggedIn && orderedProductIds && orderedProductIds.length) {
        reduxStore.dispatch(setOrderedProductIDs([]))
      }

      let cart: CartFragment | null = reduxStore.getState().cart
      if (!cart) {
        try {
          cart = await getOrCreateCart(apolloClient, cartId)
        } catch (e) {
          SentryLogger.logException(e, LoggerSeverity.Fatal, { fingerPrint:['withRedux', 'getOrCreateCart'], extras: { serializedError: JSON.stringify(e) }})
          Logger.error('Cannot get or create cart', e)
          throw new Error('Fatal error, cannot get or create cart')
        }

        reduxStore.dispatch(setCart(cart))
        if (!loggedIn) {
          setGuestCartIdCookie(cart.id, res)
        }
      }

      if (cart.linked_order) {
        const deliveryType: Redux.DeliveryType = cart.linked_order.shipping_method.id === ShippingMethodId.GPOINT
            ? { type: 'personal' }
            : { type: 'delivery', deliveryType: 'postcode' }

        reduxStore.dispatch(setDeliveryAddress({
          selected: deliveryType,
          value: { zip: cart.linked_order.shipping_postcode, city: cart.linked_order.shipping_city, addressId: null }
        }))
        setDeliveryAddressCookie(reduxStore.getState().deliveryAddress, res)
      }

      // Set up context

      ctx.ctx.reduxStore = reduxStore
      ctx.ctx.apolloClient = apolloClient
      ctx.ctx.initialState = reduxStore.getState()

      // This should be the last in client side
      let appProps = {}
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx)
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {}
      }

      // Prepare SSR Query objects
      if (isServer && isBot) {
        try {
          // Run all REST queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              reduxStore={getOrCreateStore(reduxStore.getState())}
              apolloClient={apolloClient}
            />
          )
        } catch (error) {
          // Prevent REST errors from crashing SSR.
          console.error('Error while running `getDataFromTree`', error)
        }
        Head.rewind()
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
        apolloState: apolloClient.cache.extract(),
        token
      }
    }

    constructor (props: any) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
      this.apolloClient = initApollo(props.apolloState, {
        getToken: () => this.reduxStore.getState().session.token
      })
      WithData.apolloClient = this.apolloClient
      // Rehydrate orderData
      if (!isServer && this.reduxStore.getState().session.loggedIn) {
        const serializedOrderData = sessionStorage.getItem(ORDER_DATA_KEY)
        if (serializedOrderData) {
          try {
            const orderData = JSON.parse(serializedOrderData)
            this.reduxStore.dispatch(rehydrateOrder({ ...orderData, __hydrated: true }))
          } catch (e) {
            this.reduxStore.dispatch(updateOrder({ __hydrated: true }))
          }
        } else {
          this.reduxStore.dispatch(updateOrder({ __hydrated: true }))
        }
      }
    }

    render () {
      return <App {...this.props} reduxStore={this.reduxStore} apolloClient={this.apolloClient} />
    }
  }
}
