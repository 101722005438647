
import slugify from 'slugify'
import { ProductAttributeFragment_options } from "../api/gql/types"
import categoryIcons from '../data/categoryImages'

export function processCategories<T extends Webshop.RawCategory | Webshop.RawPromotionCategory = Webshop.RawCategory>(categories: Webshop.IRawCategoryOptionalChildren<T>[], urlFragment = '', parent_id: string | null = null): Webshop.ICategory<T>[] {
    return categories.map(category => {
      const url = `${urlFragment}/${category.url_key}`
      const newCategory: Webshop.ICategory<T> = {
        ...category,
        url,
        parent_id,
        iconName: (categoryIcons.find(iconEntry => iconEntry.id === Number(category.id)) || {image: ''}).image,
        children: []
      }
      newCategory.children = category.children
        ? processCategories(category.children, url, newCategory.id)
        : []

      const aggregatedCounts = newCategory.children.reduce((sum, child) => sum + (child.product_count || 0), 0)
      newCategory.product_count = aggregatedCounts + (category.product_count || 0)
      delete newCategory.__typename
      if (newCategory.parent_id !== null) {
        delete newCategory.iconName
      }
      if (newCategory.parent_id === null) {
        delete newCategory.parent_id
      }
      if (newCategory.keywords === null) {
        delete newCategory.keywords
      }
      if (newCategory.content === null) {
        delete newCategory.content
      }
      if (newCategory.description === null) {
        delete newCategory.description
      }
      if (newCategory.promotion_place === null) {
        delete newCategory.promotion_place
      }
      if (newCategory.is_promotion === false) {
        delete newCategory.is_promotion
      }

      return newCategory
    })
  }

export const brandSlugFromString = (brandName: string) => slugify(brandName.toLowerCase(), { remove: /[*+~.()'"!:@]/g })
export const brandSlug = (brand: ProductAttributeFragment_options) => brandSlugFromString(brand.label)
