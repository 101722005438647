import React, { ReactNode } from 'react'
// import PropTypes from 'prop-types'
import { SVGArrow, SVGAccPlus, SVGAccMinus } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Accordion.scss'

// ARIA: https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/accordion/accordion.html

type PropType = {
  ariaId?: string
  ariaLevel?: number
  children?: ReactNode
  disabled?: boolean
  isActive?: boolean
  renderHeader?: ReactNode
  iconComponent?: React.ComponentType
  opened?: boolean
  defaultOpened?: boolean
  className?: string
  onOpenChange?: (opened: boolean) => void
  useMaxHeight?: boolean
  plusSVG?: boolean
}

type StateType = {
  opened: boolean,
  height: number | 'auto'
}

class Accordion extends React.Component<PropType, StateType> {
  foldableElement?: HTMLDivElement | null

  constructor(props: PropType) {
    super(props)
    this.state = {
      opened: props.defaultOpened || false,
      height: 0
    }

    this.setHeight = this.setHeight.bind(this)
  }

  get isControlled (): boolean {
    return typeof this.props.opened !== 'undefined' && typeof this.props.onOpenChange !== 'undefined'
  }

  get opened (): boolean {
    return this.isControlled
      ? this.props.opened || false
      : this.state.opened
  }

  get onOpenChange () {
    return this.isControlled
      ? this.props.onOpenChange || (() => {})
      : this.handleOpenChange
  }

  handleOpenChange = (opened: boolean) => {
    this.setState({ opened })
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  componentDidUpdate() {
    this.setHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight() {
    const height = (this.foldableElement && this.foldableElement.scrollHeight) || 0

    if (this.state.height !== height) {
      this.setState({ height: height })
    }
  }

  render() {
    const {
      ariaId,
      ariaLevel,
      children,
      disabled,
      renderHeader,
      iconComponent,
      isActive,
      className,
      opened,
      onOpenChange,
      defaultOpened,
      useMaxHeight,
      plusSVG,
      ...otherProps
    } = this.props
    const Icon = iconComponent || (children ? SVGArrow : null)

    return (
      <dl
        id={`${ariaId}Group`}
        className={cn(cl.accordion, {[cl.accordionIsDisabled]: disabled, [cl.accordionIsOpened]: this.opened, [cl.accordionUseMaxHeight]: useMaxHeight, [cl.active]: isActive }, className|| '')}
        role="presentation"
        {...otherProps}
      >
        <dt
          id={`${ariaId}Heading`}
          className={cl['accordion-heading']}
          onClick={() => {this.onOpenChange(!this.opened)}}
          role="heading"
          aria-level={ariaLevel}
          aria-expanded={this.opened}
          aria-controls={ariaId}
          aria-disabled={disabled}
        >
          <div className={`${cg.gridX} ${cl.gridX} ${cg.alignMiddle}`}>
            <div className={`${cg.cell} ${plusSVG ? `${cg.small6} ${cg.mediumAuto}` : cg.auto}`}>
              {renderHeader}
            </div>

            <div className={`${cg.cell} ${plusSVG ? `${cg.small6} ${cg.mediumShrink} ${cg.textRight}` : cg.shrink}`}>
              <div className={`${cl.accordionHeadingIconWrapper} ${plusSVG ? cl.noRotate : ""}`} aria-hidden="true">
                {plusSVG ? 
                (
                  <>
                    <span className={cn(cl.openedIcon)}><span>Termékek elrejtése</span> <span><SVGAccMinus width="24" height="24" /></span></span>
                    <span className={cn(cl.closedIcon)}><span>Termékek megjelenítése</span> <span><SVGAccPlus width="24" height="24" /></span></span>
                  </>
                ):(
                  <SVGArrow width="24" height="24" />
                )}
              </div>
            </div>
          </div>
        </dt>

        <dd
          id={ariaId}
          className={cl.accordionRegion}
          role="region"
          aria-labelledby={`${ariaId}Heading`}
        >
          <div
            className={`${cl.foldable} ${this.opened ? `${cl.opened}` : ''} ${ariaLevel && ariaLevel >= 3 ? cl.isInnerAccordion : ''}`}
            style={useMaxHeight ? {} : { height: this.opened ? this.state.height : 0 }}
            aria-hidden={!this.opened}
          >
            <div ref={(foldableElement) => { this.foldableElement = foldableElement }}>
              {children}
            </div>
          </div>
        </dd>
      </dl>
    )
  }
}

export default Accordion
