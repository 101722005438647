import React from 'react'
import cl from './BodyStyler.scss'

export default class BodyStyler extends React.Component {
    componentDidMount() {
        document.body.classList.add(cl.mobileOverflow)
    }

    componentWillUnmount() {
        document.body.classList.remove(cl.mobileOverflow)
    }

    render () {
        return null
    }
}
