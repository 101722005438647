import React, { ReactNode } from 'react'
import Markdown from 'react-markdown'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Footer.scss'
import { FooterLinkRenderer } from 'Utils/helpers/markDownRenderers'

type OwnProps = {
  block: Redux.IFooterBlock
  content?: undefined
} | {
  block?: undefined
  content: ReactNode
}
type State = {
  isActive: boolean
}

type PropTypes = OwnProps

export class FooterBlock extends React.Component<PropTypes, State> {
  state: State = {isActive: false}

  toggleActive = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  render () {
    const { block, content } = this.props
    const classNames = [cg.cell, cg.medium3, cl.mobileAccordion]
    if (this.state.isActive){
      classNames.push(cl.active)
    }

    return (
      <div className={cn(classNames)} onClick={this.toggleActive}>
        {block &&
          <>
            <h3>{block.title}</h3>
            <div>
              <Markdown
                source={block.content}
                renderers={{
                  link: FooterLinkRenderer
                }}
              />
            </div>
          </>
        }
        {content}
        </div>
    )
  }
}
export default FooterBlock
