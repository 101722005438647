import Markdown from 'react-markdown'

import PageLink from "Components/PageLink"
import Accordion from "Components/Accordion"

export const CodeRenderer = (props: {value: string, children: string}) => {
    const rawText = props.value || ''
    const [title, ...rest] = rawText.split('\n')
    const body = rest.join('\n')

    return (
        <Accordion
            renderHeader={title}
        >
            <Markdown
                source={body}
                renderers={{
                    link: LinkRenderer
                }}
            />
        </Accordion>
    )
}

export const LinkRenderer = (props: { href: string, children: React.ReactElement<any>, target?: string }) => {
    if (props.href.indexOf('http://') === 0 || props.href.indexOf('https://') === 0) {
        return (<a href={props.href} target={props.target || '_blank'}>{props.children}</a>)
    } else {
        return (<PageLink toPage={props.href}>{props.children}</PageLink>)
    }
}


export const FooterLinkRenderer = (props: { href: string, children: React.ReactElement<any>, target?: string }) => {
    if (props.href.indexOf('http://') === 0 || props.href.indexOf('https://') === 0) {
        return (<a href={props.href} target={props.target || '_blank'}>{props.children}</a>)
    } else {
        return (<PageLink toPage={props.href} stopPropagation={true}>{props.children}</PageLink>)
    }
}