import { showContentMessage } from "Utils/helpers/modalHelper";
import { CartProductDataInput } from "Utils/api/gql/types";

const quantityLimits: {
    product_id: string;
    product_name: string;
    limit: number;
}[] = [];

export const ProductQuantityLimits = {
    validateProductInput(newProductInput: CartProductDataInput): CartProductDataInput {
        const foundProduct = quantityLimits.find(item => item.product_id === newProductInput.product_id);
        if (!foundProduct) {
            return newProductInput;
        }
        if (newProductInput.piece > foundProduct.limit) {
            newProductInput.piece = foundProduct.limit;
            showContentMessage("Ebből a termékből elérted a maximálisan vásárolható mennyiséget!", `A(z) ${foundProduct.product_name} darabszáma nem lehet nagyobb, mint ${foundProduct.limit}.`);
        }
        return newProductInput;
    }
}
