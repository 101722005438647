import React from "react";

const Spinner: React.FunctionComponent<{overlay?: boolean}> = ({ overlay }) => {
  if (overlay) {
    return (
      <div style={{ position: "absolute", textAlign: "center", top: "-20px", left: "0", right: "0", bottom: "0", backgroundColor: "#FFFFFF", opacity: 0.85, zIndex: 1 }}>
        <img src="/static/groby_loader.gif" width={64} height={64} style={{ position: "absolute", top: "calc(50% - 32px)", left: "calc(50% - 32px)" }} />
      </div>
    )
  }
  return (
    <img src="/static/groby_loader.gif" width={64} height={64} />
  )
}

export default Spinner
