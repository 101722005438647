import gql from 'graphql-tag'

export default gql`
fragment ProductAttributeFragment on ProductAttribute {
    id
    name
    code
    
    filterable

    is_active

    options {
        id
        label
        position
        is_active
        is_default
    }
}
`
