import gql from 'graphql-tag'
import ProductAttributeFragment from './ProductAttributeFragment'

/*
    id

# Régi webshop termék id
    previous_id
    name

# Termék státusza/elérhetősége
    status

# Új url
    url_key

# Régi url
    smarturl

    categories {
        id
        name
        url_key
    }

    default_category_id
    price

    barcodes

# Az egységár egysége
    price_unit

# Az egységár szorzója
    price_per_unit

# Egységár
    unit_price

# Akciós egységár
    special_unit_price

# ÁFA
    vat

# Akciós ár, bruttó
    special_price

# Az akciós ár ettől a dátumtól érvényes
    special_from_date

# Az akciós ár eeddig a dátumig érvényes
    special_to_date

    personal_discount_price
    personal_discount_unit_price

# Ettől a dátumtól "új" a termék
    news_from_date

# Eddig a dátumig "új" a termék
    news_to_date

# Leírás
    description

# Összetevők leírása
    components

# Tápanyaginformációk
    nutrition_data

# A termékre leadott szavazatok darabszáma
    vote_calculated {
        vote_count_one
        vote_count_two
        vote_count_three
        vote_count_four
        vote_count_five

    # A termékre leadott szavazatok átlaga
        vote_avg
    }

# Prefixboxtól kapott terméknépszerűség szám
    prefixbox_score

# Vonalkódok (TODO: Backend elszall tole)
#    barcodes

# Alternatív mennyiség neve
    alternative_quantity_name

# Alternatív mennyiség legkisebb választható egsége
    alternative_quantity_minimum

# Alternatív mennyiség léptetési egysége
    alternative_quantity_scale

# Eredeti/alternatív egység átváltási arány
    alternative_quantity_exchange_rate

# Alapértelmezett mennyiség neve
    default_quantity_name

# Alapértelmezett mennyiség legkisebb választható egysége
    default_quantity_minimum

# Alapértelmezett mennyiség léptetési egysége
    default_quantity_scale

# Ajándékkártyával rendelhető
    gift_message_available

# Csomagfutárral (MPL) szállítható
    is_mpl
    meta_title
    meta_description
    meta_keywords

# Minimum szavatosság
    min_expiry_days

# A termék tényleges tömege
    package_official_g

# A termék tényleges űrtartalma
    package_official_ml

# Szeletelve rendelhető
    sliceable

# Tömeg grammban
    weight

    is_subscribed

# Göngyöleg
    empties {
        id
        name
        price
        price_unit
        price_per_unit
        unit_price
        special_unit_price
        special_price
        special_from_date
        special_to_date
        personal_discount_price
        personal_discount_unit_price
        news_to_date
        news_to_date
        vat
        alternative_quantity_name
        alternative_quantity_minimum
        alternative_quantity_exchange_rate
        alternative_quantity_scale
        default_quantity_name
        default_quantity_minimum
        default_quantity_scale

        package_official_g
        package_official_ml
        sliceable
        weight

        images {
            conversions {
                thumbnail
                list
                full
            }
        }
    }

#  A termékhez rendelt attribútumok és értékeik
    attributes {
        ...ProductAttributeFragment
    }

    images {
        conversions {
            thumbnail
            list
            full
        }
    }
# TODO backend elszall ezektol
#    created_at
#    updated_at
 */

export default gql`
fragment ProductAllData on Product {
    id
    ext_id
    previous_id
    name
    status
    url_key
    smarturl
    categories {
        id
        name
        url_key
    }
    default_category_id
    price
    barcodes
    price_unit
    price_per_unit
    unit_price
    special_unit_price
    vat
    special_price
    special_from_date
    special_to_date
    personal_discount_price
    personal_discount_unit_price
    webshop_only_price
    news_from_date
    news_to_date
    description
    components
    nutrition_data
    vote_calculated {
        vote_count_one
        vote_count_two
        vote_count_three
        vote_count_four
        vote_count_five
        vote_avg
    }
    prefixbox_score
    alternative_quantity_name
    alternative_quantity_minimum
    alternative_quantity_scale
    alternative_quantity_exchange_rate
    default_quantity_name
    default_quantity_minimum
    default_quantity_scale
    gift_message_available
    is_mpl
    meta_title
    meta_description
    meta_keywords
    min_expiry_days
    package_official_g
    package_official_ml
    sliceable
    weight
    is_subscribed
    empties {
        id
        name
        price
        price_unit
        price_per_unit
        unit_price
        special_unit_price
        special_price
        special_from_date
        special_to_date
        personal_discount_price
        personal_discount_unit_price
        news_to_date
        news_to_date
        vat
        alternative_quantity_name
        alternative_quantity_minimum
        alternative_quantity_exchange_rate
        alternative_quantity_scale
        default_quantity_name
        default_quantity_minimum
        default_quantity_scale

        package_official_g
        package_official_ml
        sliceable
        weight

        images {
            conversions {
                thumbnail
                list
                full
            }
        }
    }

    attributes {
        ...ProductAttributeFragment
    }

    images {
        conversions {
            thumbnail
            list
            full
        }
    }
}
${ProductAttributeFragment}
`
