import gql from 'graphql-tag'

export const CartBillingAddressFragment = gql`
fragment CartBillingAddressFragment on CartBillingAddressData {
    id
    name
    postcode
    city
    street
    house_number
    is_company
    vat_number
}
`
