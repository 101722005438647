import { ShippingMethodFragment_ShippingMethodMpl_price_ranges } from "../api/gql/types";
export const ORDER_DATA_KEY = 'grobyOrderData'

export enum ShippingMethodId  {
    GROBY = "1",
    MPL = "2",
    POSTPOINT = "3",
    GPOINT = "4"
}

export enum CheckoutStage {
    CART = 0,
    LOGIN = 1,
    SHIPPING = 2,
    BILLING = 3,
    EXPRESS = 4,
    SUMMARY = 5,
}

export const FREE_SHIPPING_LIMIT = 29999
export const SHOW_FREE_SHIPPING_LIMIT = 15000
export const GOODID_TOKEN_LENGTH = 40
export const SALE_BADGE_MINIMUM_AMOUNT = 5
export const REDIRECTBACK_COOKIE = 'login_redirectback'

export const freeShippingPriceRange: ShippingMethodFragment_ShippingMethodMpl_price_ranges = { __typename: "ShippingMethodPriceRange" as "ShippingMethodPriceRange", from: FREE_SHIPPING_LIMIT, price: 0 }
