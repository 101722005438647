import Button from 'Components/Button'

import { SVGIconSmileHappy } from 'Components/SVGCollection'

import cn from 'classnames'
import btnCss from 'Components/Button/Button.scss'
import cl from './RegistrationModal.scss'
import { pushRoute } from 'Utils/helpers/routerHelper'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'

const RegistrationModal: React.SFC = () => {

  return (
    <div className={cl.registrationModal}>
      <div className={cl.registrationModalIllustrationWrapper} aria-hidden={true}>
        <SVGIconSmileHappy width={80} height={80} />
      </div>

      <h1 className={cl.h1}>
        KÖSZÖNJÜK A REGISZTRÁCIÓD!
      </h1>

      <p className={cl.lead}>
        Hamarosan visszaigazoló e-mailt kapsz
        tőlünk, azokkal az adatokkal,
        amikkel regisztráltál.
      </p>

      <div className={cl.registrationModalButtonContainer}>
        <Button
          className={cn(btnCss.expanded, cl.expanded)}
          label="FOLYTATOM A VÁSÁRLÁST"
          onClick={() => { uiEventEmitter.emit(UIEvents.CLOSE_MODAL) }}
        />
      </div>
      <div className={cl.registrationModalButtonContainer}>
        <Button
          className={cn(btnCss.expanded, btnCss.hollow, cl.expanded)}
          label="SZEMÉLYES MENÜ"
          onClick={() => { uiEventEmitter.emit(UIEvents.CLOSE_MODAL); pushRoute('/profil') }}
        />
      </div>
    </div>
  )
}

export default RegistrationModal
