import React, { KeyboardEvent } from 'react'
import { SVGNavSearch } from 'Components/SVGCollection'
import cg from 'Scss/app.scss'
import cl from './SearchInput.scss'
const { Router } = require('Utils/routes')

type PropType = {
  id: string
  onChange: (s: string) => void
  searchTerm: string
}

class SearchInput extends React.PureComponent<PropType> {
  input: HTMLInputElement | null = null
  handleSearch = () => {
    let searchTerm = this.props.searchTerm

    if (this.input) {
      searchTerm = this.input.value   // Prefixbox autocomplete doesn't trigger onChange events, so we need to manually extract value...
    }

    Router.pushRoute('search', { searchTerm })
  }
  handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.handleSearch()
    }
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.props.onChange(e.currentTarget.value) }

  render = () => (
    <div className={cl.searchInput}>
      <form autoComplete="off" autoCorrect="off" autoSave="off">
        <input ref={ref => this.input = ref} type="search" id={this.props.id} placeholder="Keresés" onChange={this.handleChange} onKeyDown={this.handleKeyDown} value={this.props.searchTerm} />
      </form>
      <button type="submit" className={cl.searchButton} onClick={this.handleSearch}>
        <span className={cg.showForSr}>Keresés</span>

        <span aria-hidden={true}>
          <SVGNavSearch width={30} height={30} />
        </span>
      </button>
    </div>
  )
}

export default SearchInput
