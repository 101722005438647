import React from 'react'
import PageLink from 'Components/PageLink'
import ExternalLink from 'Components/ExternalLink'
import {
  SVGSocialFacebook, SVGSocialInstagram, SVGSocialYoutube, SVGBfLogo
} from 'Components/SVGCollection'

import { connect } from 'react-redux'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Footer.scss'
import WeightProblems from 'Components/WeightProblems'
import { FooterBlock } from 'Components/Footer/FooterBlock'
import { getDeploymentEnv } from 'Utils/helpers/misc';

type ReduxProps = {
  footerBlocks: Array<Redux.IFooterBlock>,
  blogEntries: Array<Webshop.IBlogEntry>,
}

type OwnProps = {
  loading?: boolean,
  className?: string,
  hideOnMobile?: boolean
}

type PropTypes = ReduxProps & OwnProps

export class Footer extends React.Component<PropTypes> {
  render () {
    const { className, footerBlocks, blogEntries } = this.props
    const hideOnMobileClass = this.props.hideOnMobile === true && cg.showForMedium
    return (
      <div className={`${cl.footer} ${hideOnMobileClass} ${className ? className : ""}`}>
        <WeightProblems />
        <div className={cn(cl.footerTop)}>
          <div className={cn(cg.gridContainer, cl.gridContainer)}>
            <div className={cn(cg.gridX, cl.gridX)}>
              {footerBlocks.map((block, i) => (
                <FooterBlock key={`footer-block-${i}`} block={block} />
              ))}
              <FooterBlock
                content={
                  <>
                    <h3>GROBY BLOG</h3>
                    <div>
                      <ul className={cn(cg.blogList, cl.blogList)}>
                        {blogEntries.map(blogEntry => {
                          return (
                            <li key={blogEntry.link}>
                              <ExternalLink href={blogEntry.link}>
                                <p dangerouslySetInnerHTML={{ __html: blogEntry.title }} />
                                <p className={cn(cl.blogDate)}>{blogEntry.date}</p>
                              </ExternalLink>
                            </li>
                          )
                        })}
                        <li><ExternalLink className={cn(cl.pageLink)} href="https://blog.groby.hu">További hírek a GRoby Blog-on</ExternalLink></li>
                      </ul>
                    </div>
                  </>
                }
              />
              </div>
          </div>
        </div>
        <div className={cn(cl.footerMiddle)}>
          <div className={cn(cg.gridContainer)}>
            <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
              <div className={cn(cg.cell, cg.large5, cg.xlarge6)} style={{ width: "100%" }}>
                <div className={cn(cl.social)}>
                  <div className={cn(cg.gridX, cg.alignMiddle, cl.mobileCenter)}>
                    <div className={cn(cg.cell, cg.shrink, cg.textCenter, cg.showForLarge)} style={{marginRight: '10px'}}>Kövess minket!</div>
                    <div className={cn(cg.cell, cg.shrink, cg.textCenter)}>
                      <ExternalLink href="https://www.facebook.com/grobyshop/">
                        <SVGSocialFacebook width={32} height={32} style={{color: '#1A1E2D', marginLeft: '10px', marginRight: '10px'}} />
                      </ExternalLink>
                    </div>
                    <div className={cn(cg.cell, cg.shrink, cg.textCenter)}>
                      <ExternalLink href="https://www.instagram.com/grobyszupermarket/">
                        <SVGSocialInstagram width={32} height={32} style={{color: '#1A1E2D', marginLeft: '10px', marginRight: '10px'}} />
                      </ExternalLink>
                    </div>
                    <div className={cn(cg.cell, cg.shrink, cg.textCenter)}>
                      <ExternalLink href="https://www.youtube.com/user/grobyshopbp">
                        <SVGSocialYoutube width={32} height={32} style={{color: '#1A1E2D', marginLeft: '10px', marginRight: '10px'}} />
                      </ExternalLink>
                    </div>
                    <div className={cn(cg.cell, cg.mediumAuto, cl.mobileCenter, cg.textRight, cl.arukereso)}>
                      {/*<!-- ÁRUKERESŐ.HU - PLEASE DO NOT MODIFY THE LINES BELOW -->*/}
                      <div>
                        <a title="Árukereső.hu" href="https://www.arukereso.hu" target="_blank">
                          <img src="https://image.arukereso.hu/trustedbadge/36617-150x74-6c0b94b0a9b073ce66af4e4e70f5bc1d.png" alt="Árukereső.hu" />
                        </a>
                      </div>
                      {/*<!-- ÁRUKERESŐ.HU CODE END -->*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cn(cl.footerBottom)}>
          <div className={cn(cg.gridContainer, cl.gridContainer)}>
            <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
              <div className={cn(cg.cell, cg.large6, cg.textCenter, cg.largeTextleft)}>
                <ul className={cn(cl.footerMenu)}>
                  <li>
                    <PageLink toPage="/cikk/aszf">ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK</PageLink>
                  </li>
                  <li>
                    <PageLink toPage="/cikk/adatkezelesi-tajekoztato">ADATKEZELÉSI TÁJÉKOZTATÓ</PageLink>
                  </li>
                  <li>
                    <PageLink toPage="/cikk/impresszum">IMPRESSZUM</PageLink>
                  </li>
                </ul>
              </div>
              <div className={cn(cg.cell, cg.large6, cg.textCenter, cg.largeTextRight)}>

                <ExternalLink href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"><span className={cn(cl.simple)}></span></ExternalLink>
                <ExternalLink href="https://paymentgateway.hu/"><span className={cn(cl.pmgw)}></span></ExternalLink>

                <p className={cn(cl.copyright)}>
                  Készítette: <ExternalLink href="http://www.bigfish.hu"><SVGBfLogo width={32} height={22} /></ExternalLink>
                  &copy; G&apos;Roby Szupermarketek, {new Date().getFullYear()}
                  {getDeploymentEnv() === 'testing' && <p>DEV version: {process.env.CI_COMMIT_TAG}</p>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Redux.IReduxState): ReduxProps => ({
  footerBlocks: state.footerBlocks,
  blogEntries: state.blogEntries
})

export default connect(mapStateToProps)(Footer)
