import {Dispatch, AnyAction} from "redux";
import axios from 'axios'

export enum ListType {
  SmartList = 'smartlist',
  ShoppingList = 'shoppinglist'
}

export default class ListClient {
  static instance:ListClient | null = null
  static authToken: string | null = null
  static dispatch: Dispatch<AnyAction>

  static getInstance() {
    if (ListClient.instance === null) {
      ListClient.instance = new ListClient()
    }
    return ListClient.instance
  }

  getUserLists = async () => {
    return await axios.get(`${process.env.GWS_LIST_API_URL}/lists`, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  createList = async (name: string) => {
    const data = {
      name: name,
      type: ListType.ShoppingList
    }
    return await axios.post(`${process.env.GWS_LIST_API_URL}/list`, data, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  updateListNameByListId = async (listId: string, listName: string) => {
    const data = {
      name: listName
    }
    return await axios.put(`${process.env.GWS_LIST_API_URL}/list/${listId}`, data, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  removeListById = async (listId: string) => {
    return await axios.delete(`${process.env.GWS_LIST_API_URL}/list/${listId}`, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  leaveListById = async (listId: string) => {
    return await axios.get(`${process.env.GWS_LIST_API_URL}/leaveList/${listId}`, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  syncListActions = async (actions: {}) => {
    return await axios.post(`${process.env.GWS_LIST_API_URL}/sync`, actions, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  acceptShare = async (listId: string) => {
    return await axios.post(`${process.env.GWS_LIST_API_URL}/acceptShare`, { listId }, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  declineShare = async (listId: string) => {
    return await axios.post(`${process.env.GWS_LIST_API_URL}/declineShare`, { listId }, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  shareList = async (listId: string, email: string) => {
    const data = {
      listId,
      email
    }
    return await axios.post(`${process.env.GWS_LIST_API_URL}/shareList`, data, { headers: { 'groby-authorization': ListClient.authToken }})
  }

  removeSharing = async (listId: string, email: string) => {
    return await axios.post(`${process.env.GWS_LIST_API_URL}/removeShare`, { listId, email }, { headers: { 'groby-authorization': ListClient.authToken }})
  }
}
