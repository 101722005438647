import TinyEmitter from 'tiny-emitter'

export enum UIEvents {
  SHOW_MODAL = 'show_modal',
  CLOSE_MODAL = 'close_modal',
  SET_MODAL_LOADING = 'set_modal_loading',
  MODAL_READY = 'MODAL_READY',
  SHOW_MODAL_FOR_TIME = 'showmodalfortmime',
  UPDATE_MODAL_CONTENT = 'UPDATE_MODAL_CONTENT',
  CARTPANEL_TRANSITION_START = 'cartPanelTransitionStart',
  CARTPANEL_TRANSITION_END = 'cartPanelTransitionEnd',
  START_LOADING = 'START_LOADING',
  END_LOADING = 'END_LOADING',
  REPOSITION_BASKETBAR = 'REPOSITION_BASKETBAR',
  UPDATE_PROFILE_HEIGHT = 'UPDATE_PROFILE_HEIGHT'
}

const emitter: TinyEmitter<string | symbol> & { modalReady?: boolean } = new TinyEmitter<string | symbol>()

export default emitter
