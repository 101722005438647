import gql from 'graphql-tag'

export const SavedCreditCardFragment = gql`
fragment SavedCreditCardFragment on UserSavedCreditCard {
  id
  card_title
  card_type
  card_pan
  card_exp
  is_default
}
`
