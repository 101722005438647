import React, { ChangeEvent, KeyboardEvent, ReactNode } from 'react'

import cn from 'classnames'
import cl from './DropdownSelect.scss'

import { SVGArrowDown } from 'Components/SVGCollection'
import { uniformIndexOf, isEqual } from 'Utils/helpers/misc'
import memoize from 'memoizee'

export type OptionType<T> = {
  title: string,
  value: T,
  displayElement?: ReactNode
  selectable?: boolean
  key?: string
}

type StateType = {
  height: number | string
  highlightId: number
  draftText: string | null
  opened: boolean
}

type PropType<T, N> = {
  maxListHeight?: number
  disabled?: boolean
  noResultsMessage?: string
  items: Array<OptionType<T>>
  name: N
  editingDisabled?: boolean
  placeholder?: string
  value?: T | null
  matchAnywhere?: boolean   // while filtering, match filtertext anywhere, not just the beginning of items
} & ({
  isNullable: true
  onChange?: (name: N, value: T | null) => void
} | {
  isNullable: false
  onChange?: (name: N, value: T) => void
})

const MAX_DISPLAYED_ITEMS = 250
const DISPLAY_TRUNCATED_WARNING_ITEM = { title: <i>Szűkítse a keresést! Maximum {MAX_DISPLAYED_ITEMS} elemet tudunk megjeleníteni</i> as unknown as string, value: null }

// TODO: ARIA https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/combobox/aria1.0pattern/combobox-autocomplete-list.html
export default class DropdownSelect<T = string, N extends string = string> extends React.Component<PropType<T, N>, StateType> {
  foldableElement: HTMLUListElement | null = null
  listItemRefs: Array<HTMLLIElement | null> = []
  containerRef: HTMLDivElement | null = null

  constructor(props: PropType<T, N>) {
    super(props)
    this.state = {
      height: 0,
      highlightId: 0,
      draftText: null,
      opened: false
    }
  }

  scrollHighlightedElementIntoViewIfNecessary = () => {
    const li = this.listItemRefs[this.state.highlightId]
    const ul = this.foldableElement
    if (!ul || !li) {
      return
    }

    const isHighlightedElementOutOfView =
      (li.offsetTop + li.offsetHeight) > (ul.offsetHeight + ul.scrollTop) ||
      li.offsetTop < ul.scrollTop

    if (isHighlightedElementOutOfView && typeof ul.scroll === 'function') {
      ul.scroll({ behavior: 'smooth', top: li.offsetTop - Math.round(ul.offsetHeight / 2) })
    }
  }

  componentDidUpdate(_: PropType<T, N>, prevState: StateType) {
    if (this.state.draftText !== prevState.draftText) {
      requestAnimationFrame(this.setHeight)
    }

    if (this.state.highlightId !== prevState.highlightId) {
      this.scrollHighlightedElementIntoViewIfNecessary()
    }

    if (this.state.draftText === '' && prevState.draftText !== '' && this.state.highlightId !== -1 && this.props.isNullable) {
      this.setState({ highlightId: -1 })
    }
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight = () => {
    let height = this.foldableElement && this.foldableElement.scrollHeight + 2 || 0
    if (this.props.maxListHeight && this.props.maxListHeight < height) {
      height = this.props.maxListHeight
    }
    this.setState({ height })
  }

  open = () => {
    if (this.state.opened || this.props.disabled) return

    const selectedIndex = this.props.items.findIndex(item => isEqual(item.value, this.props.value))
    this.setState({ opened: true, draftText: null, highlightId: selectedIndex })
    this.setHeight()
    
    if (this.foldableElement && this.props.editingDisabled) {
      this.foldableElement.focus()
    }
  }

  close = () => {
    this.setHeight()
    this.setState({ opened: false, draftText: null })
  }

  toggle = () => {
    if (this.state.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  memoizedFilteredItems = memoize((items: OptionType<T>[], filterText: string, matchAnywhere?: boolean) => {
    return matchAnywhere
      ? items.filter(item => filterText.split(' ').every(word => uniformIndexOf(item.title, word) !== -1)) // all searchtext words must match somewhere
      : items.filter(item => uniformIndexOf(item.title, filterText) === 0)
  })

  filteredItems(filterText?: string | null) {
    if (typeof filterText === 'undefined') {
      filterText = this.state.draftText
    }
    return filterText === null
      ? this.props.items
      : this.memoizedFilteredItems(this.props.items, filterText, this.props.matchAnywhere)
  }
  
  moveHighlight = (direction: 'up' | 'down') => {
    const displayLength = Math.min(this.filteredItems().length, MAX_DISPLAYED_ITEMS)
    const increment = ({'up': -1, 'down': 1})[direction]
    const limit = ({'up': 0, 'down': displayLength - 1})[direction]

    let currentId = this.state.highlightId
    if (currentId === limit) {
      return
    }

    do {
      currentId += increment
      const currentItem = this.filteredItems()[currentId]
      if (!currentItem) {
        break
      }
      if (currentItem.selectable !== false) {
        this.setState({ highlightId: currentId })
        return
      }
    } while (currentId !== limit) 
  }

  handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!this.state.opened) {
      if (!['Tab', 'Shift', 'Meta', 'Alt', 'Control', 'Enter'].includes(e.key)) {
        this.open()
      } else {
        return
      }
    }

    switch(e.key) {
      case 'ArrowUp':
        this.moveHighlight('up')
        e.preventDefault()
        break
      case 'ArrowDown':
        this.moveHighlight('down')
        e.preventDefault()
        break
      case 'Enter':
        this.commit()
        break
      case 'Escape':
      case 'Tab':
        this.close()
        break
      default:
        if (this.state.draftText === null && /^\w$/.test(e.key) && !e.metaKey && !e.altKey && !e.ctrlKey) {
          // Nothing has been typed yet, but the input contains the selected item's title as text
          // and we want to reset the text instead of appending to it, like handleChange would do
          this.setState({ draftText: e.key })
          e.preventDefault()
        }
        break
    }
  }

  commit = () => {
    const filteredItems = this.filteredItems()

    if (filteredItems.length && filteredItems[this.state.highlightId]) {
      this.props.onChange && this.props.onChange(this.props.name, filteredItems[this.state.highlightId].value)
      this.close()
    }
    if (this.props.isNullable && this.state.highlightId === -1) {
      this.props.onChange && this.props.onChange(this.props.name, null)
      this.close()
    }
  }

  constrainHighlightId = (highlightId: number, listLength: number) => Math.max(Math.min(highlightId, listLength - 1), 0)

  handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value
    const newListLength = this.filteredItems(newText).length
    this.setState(state => ({ draftText: newText, highlightId: this.constrainHighlightId(state.highlightId, newListLength) }))
  }

  render() {
    const { disabled, value, items, editingDisabled } = this.props
    const { height, opened } = this.state

    const selectedItem = items.find(item => isEqual(item.value, value))
    const displayTitle = (selectedItem && selectedItem.title) || ''

    const filteredItems = this.filteredItems()
    const displayedItems = filteredItems.slice(0, MAX_DISPLAYED_ITEMS)
    if (filteredItems.length > MAX_DISPLAYED_ITEMS) {
      //@ts-ignore
      displayedItems.push(DISPLAY_TRUNCATED_WARNING_ITEM)
    }

    const ulStyle: React.CSSProperties = { height: (opened || disabled) ? height : 0 }
    if (this.props.maxListHeight) {
      ulStyle.maxHeight = this.props.maxListHeight!
    }

    return (
      <div ref={ref => this.containerRef = ref} className={cn(cl.dropdownContainer, cl.dropdownSelect, opened && cl.dropdownContainerIsOpened)} onKeyDown={this.handleKeyDown}>
        <div className={cl.dropdown__toggler}>
          <input
            type="text"
            className={cn({ [cl.editingDisabled]: editingDisabled })}
            disabled={disabled || editingDisabled}
            placeholder={this.props.placeholder || ''}
            role="combobox"
            aria-autocomplete="list"
            aria-expanded="false"
            aria-haspopup="true"
            aria-owns="cb1-listbox"
            value={this.state.draftText !== null ? this.state.draftText : displayTitle}
            onMouseDownCapture={e => {
              if (this.state.opened) {
                e.stopPropagation()
              } else {
                this.open()
              }
            }}
            onClickCapture={e => { e.preventDefault(); e.stopPropagation() }}
            onFocus={this.open}
            onChange={this.handleTextChange}
          />
          { editingDisabled && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} onClick={this.open}></div> }

          <button
            className={cl.arrowContainer}
            onClick={() => { this.toggle() }}
            aria-label="Open"
            tabIndex={-1}
          >
            <span aria-hidden="true">
              <SVGArrowDown width="14" height="14" />
            </span>
          </button>
        </div>

        <ul
          className={cn(cl.foldable, cl.dropdown__pane)}
          ref={(foldableElement) => { this.foldableElement = foldableElement }}
          style={ulStyle}
          id="cb1-listbox"
          role="listbox"
          aria-label="Phone numbers"
          tabIndex={-1}
        >
          {displayedItems.length > 0
            ? displayedItems.map((item, i) => (
              <li
                key={item.key || item.title}
                ref={ref => this.listItemRefs[i] = ref}
                className={cn({
                  [cl.isSelected]: isEqual(item.value, value),
                  [cl.highlighted]: i === this.state.highlightId,
                  [cl.notSelectable]: item.selectable === false
                })}
                onClick={(e) => {
                  if (item.selectable !== false) {
                    this.setState({ highlightId: i }, this.commit)
                  }
                  e.preventDefault(); e.stopPropagation()
                }}
              >
                {item.displayElement || item.title}
              </li>
            ))
            : <li><i>{this.props.noResultsMessage || ''}</i></li>
          }
        </ul>

        {opened && <div className={cl.dropdown__overlay} onClick={e => { this.close(); e.preventDefault(); e.stopPropagation(); }} />}
      </div>
    )
  }
}
