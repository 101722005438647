import React, { ChangeEvent } from "react"
import Cleave from 'cleave.js/react'

type PropType = {
    options: { [key: string]: any }
    value: string
    [key: string]: any
}

type CleaveInstance = React.ReactInstance & {
    setRawValue: (val: string) => void
}

function remove36(str: string): string {
    return str.indexOf('36') === 0
        ? str.substr(2)
        : str
}

function add36(str: string): string {
    if (!str) {
        return ''
    }
    return `36${str}`.replace(/\s+/g, '')
}

export default class PhoneInput extends React.Component<PropType> {
    instance: CleaveInstance | null = null
    valueWith36: string

    constructor(props: PropType) {
        super(props)
        this.valueWith36 = props.value
    }

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget) e.currentTarget = e.target
        if (e.currentTarget.value.indexOf('+') !== -1) return

        const processedValue = add36(e.currentTarget.value)
        this.valueWith36 = processedValue

        if (e.target.name && this.props.onChange) {
            const _e = {
                ...e,
                currentTarget: {
                    ...e.currentTarget,
                    name: e.target.name,
                    value: processedValue
                },
                target: {
                    ...e.target,
                    name: e.target.name,
                    value: processedValue
                }
            }
            this.props.onChange(_e)
        }
    }
    componentDidMount() {
        if (this.instance) {
            this.instance.setRawValue(remove36(this.props.value))
        }
    }
    componentDidUpdate (prevProps: PropType) {
        if (this.instance && prevProps.value !== this.props.value && this.props.value !== this.valueWith36) {
            this.instance.setRawValue(remove36(this.props.value))
            this.valueWith36 = this.props.value
        }
    }
    handleInit = (instance: React.ReactInstance) => {
        this.instance = instance as CleaveInstance
        this.props.onInit && this.props.onInit(instance)
    }
    render = () =>
        <Cleave {...this.props} value={remove36(this.props.value)} onInit={this.handleInit} onChange={this.handleChange} />
}
