import gql from 'graphql-tag'

export default gql`
mutation createMyUserShippingAddress($addressInput: UserShippingAddressInput!) {
    createMyUserShippingAddress(address: $addressInput) {
        id
        city
    }
}
`
