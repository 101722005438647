import { UserFragment, ProductListData, CartFragment, CartProductDataInput, order_me_orders_data_order_products, OrderUserType } from "./api/gql/types"
import SentryLogger, { LoggerSeverity } from "./helpers/sentryLogger"
import { useEffect, useState } from "react";
import { getCurrentPrice, getProductBrand } from "./helpers/productAttributeHelper";
import { getCategoryPath } from "./helpers/categoryHelper"
import differenceWith from 'lodash/differenceWith'
import { Actions } from "./redux/actions";
import CartHelper from "./helpers/cartHelper";

export type ProductCardGA4Type = {
  position: number;
  listName: string;
  listId: string;
}
export type ProductListGA4Type = {
  listName: string;
  listId: string;
}

export type GtmPageType = 'Home' | 'Product' | 'List' | 'Checkout' | 'Search' | 'Profile'

interface ProductImpressionProduct {
  product: ProductListData
  position: number
}

type GA4OptionsType = { quantity: number } | { price: number } | { index: number; } | { item_list_id: string; item_list_name: string } | { discount: number };
class GtmClient {
  clientId: string | null = null
  categories: Webshop.ICategory[] = []
  customerData?: UserFragment
  prevUrl = ''

  getGA4Item = (product: ProductListData, options?: GA4OptionsType) => {
    const item_category = getCategoryPath(String(product.default_category_id), this.categories).reduce((acc, cat, currentIndex) => {
      const key = currentIndex ? `${currentIndex + 1}` : "";
      return { ...acc, [`item_category${key}`]: cat.name };
    }, {});
    const currentPrice = getCurrentPrice(product);
    const discount =  currentPrice < product.price ? (product.price - currentPrice) : undefined;
    const result = {
      item_id: product.id,
      item_name: product.name,
      affiliation: "Groby",
      currency: "HUF",
      item_brand: getProductBrand(product),
      discount,
      ...item_category,
      price: getCurrentPrice(product),
      ...options,
    }
    return result;
  }

  waitForGtmLoad = async () => {
    while (!window.dataLayer || typeof window.dataLayer.push !== 'function') {
      await new Promise(resolve => setTimeout(resolve, 500))
    }
  }

  adsBlocked () {
    if (process.env.GWS_GOOGLE_ADS_ID) {
      return !document.getElementById(process.env.GWS_GOOGLE_ADS_ID)
    } else {
      SentryLogger.logMessage('GWS_GOOGLE_ADS_ID env var not set!', LoggerSeverity.Warning)
      return false
    }
  }

  async sendEvent(eventType: string, params = {}) {
    await this.waitForGtmLoad()

    const userId = (this.customerData && this.customerData.id) || undefined

    window.dataLayer.push({
      event: eventType,
      adsBlocked: this.adsBlocked(),
      userId,
      ...params
    })
  }

  async sendGA4Event(eventType: string, params = {}) {
    await this.waitForGtmLoad()
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventType,
      ecommerce: {
        ...params
      }
    })
  }

  getClientId = (): string | null => {
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
      const tracker = ga.getAll()[0]    // GRBY-1164
      if (tracker && typeof tracker.get === 'function') {
        return tracker.get('clientId')
      }
    }

    return null
  }

  sendAnalyticsForCartUpdate = (action: Redux.IAction, cart: CartFragment): void => {
    if (action.type !== Actions.REQUEST_CART_PRODUCT_UPDATE) return

    const product = action.payload.product
    const productInCart = cart.cart_products.find(cp => cp.product.id === action.payload.product.id)
    const piece = CartHelper.convertProductUpdatePayloadToProductDataInput(action.payload).piece
    const diff = productInCart
        ? piece - productInCart.piece
        : piece

    const path = getCategoryPath(String(product.default_category_id), this.categories).map(c => c.name).join(' / ')

    const data = {
        id: product.id,
        name: product.name,
        brand: getProductBrand(product),
        currency: 'HUF',
        price: getCurrentPrice(product),
        quantity: Math.abs(diff),
        category: path
    }

    if (diff !== 0) {
      this.sendEvent(diff > 0 ? 'addToCart' : 'removeFromCart', {
        ecommerce: {
          currencyCode: 'HUF',
          add: data
        }
      })
      this.sendGA4Event(diff > 0 ? "add_to_cart" : "remove_from_cart", {
        currency: "HUF",
        value: getCurrentPrice(product) * Math.abs(diff),
        items: [this.getGA4Item(product, { quantity: diff })]
      });
    }
  }

  addOrderedProductsToCart = (products: order_me_orders_data_order_products[], cart: CartFragment) => {
    for (const product of products) {
      this.sendEvent('addToCart', {
        ecommerce: {
          currencyCode: 'HUF',
          add: {
            id: product.product.id,
            name: product.product.name,
            brand: getProductBrand(product.product),
            currency: 'HUF',
            price: getCurrentPrice(product.product),
            quantity: product.default_quantity_piece,
            category: getCategoryPath(String(product.product.default_category_id), this.categories).map(c => c.name).join(" / "),
        }
        }
      })

      const value = CartHelper.getCartTotalPrice(cart);
      const items = products.map(pr => this.getGA4Item(pr.product, { quantity: pr.default_quantity_piece }));
      this.sendGA4Event("add_to_cart", { currency: "HUF", value, items});
    }
  }

  addShoppingListProductsToCart = (products: ShoppingList.ShoppingListProduct[], cart: CartFragment) => {
    for (const product of products) {
      this.sendEvent('addToCart', {
        ecommerce: {
          currencyCode: 'HUF',
          add: {
            id: product.productId,
            name: product.productDetails && product.productDetails.name || undefined,
            brand: product.productDetails && getProductBrand(product.productDetails) || undefined,
            currency: 'HUF',
            price: product.productDetails && getCurrentPrice(product.productDetails) || undefined,
            quantity: product.quantity,
            category: product.productDetails && getCategoryPath(String(product.productDetails.default_category_id), this.categories).map(c => c.name).join(" / ") || undefined,
        }
        }
      })

      const value = CartHelper.getCartTotalPrice(cart);
      const items = products.filter(prod => !!prod.productDetails).map(pr => this.getGA4Item(pr.productDetails!, { quantity: pr.quantity }));
      this.sendGA4Event("add_to_cart", { currency: "HUF", value, items })
    }
  }

  sendViewItemList = (product: ProductListData, ga4Options: ProductCardGA4Type) => {
    const items = [this.getGA4Item(product, { index: ga4Options.position })];
    this.sendGA4Event("view_item_list", { item_list_id: ga4Options.listId, item_list_name: ga4Options.listName, items })
  }

  sendViewItemListNew = (products: ProductListData[], ga4Options: ProductListGA4Type) => {
    const items = products.map((product, index) => this.getGA4Item(product, { index }));
    this.sendGA4Event("view_item_list", { item_list_id: ga4Options.listId, item_list_name: ga4Options.listName, items })
  }

  sendViewItem = (product: ProductListData) => {
    const items = [this.getGA4Item(product)];
    this.sendGA4Event("view_item", { currency: "HUF", value: getCurrentPrice(product), items });
  }

  sendShippingInfo = (cart: CartFragment) => {
    const items = cart.cart_products.map(cp => this.getGA4Item(cp.product, { quantity: cp.piece, price: cp.price }));
    this.sendGA4Event("add_shipping_info", { currency: "HUF", value: CartHelper.getCartDeliveryFee(cart), shipping_tier: cart.shipping_method!.name, items });
  }

  sendPaymentInfo = (cart: CartFragment) => {
    const value = CartHelper.getCartTotalWithShippingAndPayment(cart);
    const items = cart.cart_products.map(cp => this.getGA4Item(cp.product, { quantity: cp.piece, price: cp.price }));
    this.sendGA4Event("add_payment_info", { currency: "HUF", value, payment_type: cart.payment_method!.name, items });
  }

  sendProductClick = (product: ProductListData, position: number) => {
    const categoryPath = getCategoryPath(String(product.default_category_id), this.categories)

    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [{
            name: product.name,
            id: product.id,
            brand: getProductBrand(product),
            category: categoryPath.map(c => c.name).join(' / '),
            position,
            price: getCurrentPrice(product)
          }]
        }
      }
    })
  }

  sendCheckoutStep = async (step: number, option: string | undefined, cart: CartFragment, isExpress?: boolean) => {
    await this.waitForGtmLoad()

    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step,
            option: isExpress ? `expressz ${option || ''}` : option,
            shipping: CartHelper.getCartDeliveryFee(cart),
            coupon: cart.coupon_code
          },
          products: cart.cart_products.map(({ product, price, piece }) => ({
            name: product.name,
            id: product.id,
            brand: getProductBrand(product),
            category: getCategoryPath(String(product.default_category_id), this.categories).map(c => c.name).join(' / '),
            price,
            quantity: piece
          }))
        }
      }
    })
  }

  cartPage = async (cart: CartFragment | null) => {
    if (!cart) {
      return;
    }
    const items = cart.cart_products.map((cp, index) => this.getGA4Item(cp.product, { quantity: cp.piece, index }));
    this.sendGA4Event("view_cart", { currency: "HUF", value: cart.total_price, items }) // GROBY-555
    this.sendGA4Event("begin_checkout", { currency: "HUF", value: cart.total_price, coupon: cart.coupon_code, items }) // GROBY-552
  }

  sendProductImpressions = async (products: ProductImpressionProduct[], list: string) => {
    await this.waitForGtmLoad()

    const impressions = products.map(({ product, position }, i) => {
      const categoryPath = getCategoryPath(String(product.default_category_id), this.categories)
      return {
        name: product.name,
        id: product.id,
        list,
        brand: getProductBrand(product),
        category: categoryPath.map(c => c.name).join(' / '),
        categoryId: categoryPath.map(c => c.id).join('/'),
        position,
        price: getCurrentPrice(product)
      }
    })

    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'HUF',
        impressions,
        clientId: this.getClientId()
      }
    })
  }

  virtualPageView = async (pageType: GtmPageType, breadcrumb: string, deliveryAddress: Redux.IDeliveryAddress | undefined, isOrderExpress: boolean) => {
    await this.waitForGtmLoad()
    const url = window.location.href

    const clientId = await this.getClientId()

    if (url !== this.prevUrl) {
      const isLoggedIn = !!this.customerData
      this.sendEvent(
        'virtualPageView',
        {
          virtualPageURL: url,
          clientId,
          pageType,
          breadcrumb,
          isLoggedIn,
          customerType: isLoggedIn ? 'User' : 'Guest',
          userId: this.customerData ? this.customerData.ext_id : null,
          recommendationsUsed: url.includes('fbclid='), //TODO
          shippingPostalCode: deliveryAddress && deliveryAddress.value.zip || undefined,
          isOrderExpress
        }
      )
      this.prevUrl = url
    }
  }

  orderRevenue = async (orderId: string, revenue: number, content_ids: string[], user_type: OrderUserType | null | undefined) => {
    await this.waitForGtmLoad()
    window.dataLayer.push({
      ecommerce: {
        id: orderId,
        revenue,
        content_ids,
        new_customer: user_type === OrderUserType.NEW ? true : user_type === OrderUserType.RETURNING ? false : undefined,
      },
    })

  }
}

export const gtmClientInstance = new GtmClient()

interface ProductImpressionProps {
  list: string
  products: ProductListData[]
}

export const gtmProductsToProductImpressions = (products: ProductListData[]): ProductImpressionProduct[] => {
  return products.map((product, i) => ({ product, position: i + 1 }))
}

export const GtmProductListImpression: React.FunctionComponent<ProductImpressionProps> = ({ list, products }) => {
  const [sentProducts, setSentProducts] = useState<ProductImpressionProduct[]>([])

  useEffect(() => {
    setSentProducts([])
  }, [list])

  useEffect(() => {
    const productsWithPos = gtmProductsToProductImpressions(products)
    const productsToSend = differenceWith(productsWithPos, sentProducts, (a, b) => a.product.id === b.product.id )
    if (productsToSend.length) {
      gtmClientInstance.sendProductImpressions(productsToSend, list)
      setSentProducts(productsWithPos)
    }
  }, [products])

  return null
}

interface PageViewProps { pageType: GtmPageType, breadcrumb: string, deliveryAddress: Redux.IDeliveryAddress | undefined, isExpress: boolean }

export const GtmPageView: React.FunctionComponent<PageViewProps> = ({ pageType, breadcrumb, deliveryAddress, isExpress }) => {
  useEffect(() => {
    gtmClientInstance.virtualPageView(pageType, breadcrumb, deliveryAddress, isExpress)
  }, [pageType, breadcrumb])

  return null
}

interface GtmCheckoutStepProps { step: number, option?: string, isExpress?: boolean, cart: CartFragment }

export const GtmCheckoutStep: React.FC<GtmCheckoutStepProps> = ({ step, option, cart, isExpress }) => {
  useEffect(() => {
    gtmClientInstance.sendCheckoutStep(step, option, cart, isExpress)
  }, [])

  return null
}

// interface GtmOrderRevenueProps { orderId: string, revenue: number, content_ids: string[] }

// export const GtmOrderRevenue: React.FC<GtmOrderRevenueProps> = ({ orderId, revenue, content_ids }) => {
//   useEffect(() => {
//     gtmClientInstance.orderRevenue(orderId, revenue, content_ids);
//   }, [])

//   return null
// }

export default gtmClientInstance
