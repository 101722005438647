import gql from 'graphql-tag'

export const myOrderedProductIDsQuery = gql`
query myOrderedProductIDs {
    me {
        ordered_products(first: 2000, page: 1) {
            data {
                created_at
                default_quantity_piece
                default_quantity_name
                product {
                    id
                }
            }
        }
    }
}
`
