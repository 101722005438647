import { CartFragment, ProductListData } from "Utils/api/gql/types";
import { Actions } from "Utils/redux/actions";
import CartHelper from "./cartHelper";
import { getCategoryPath } from "./categoryHelper";
import { getProductFullURL } from "./productAttributeHelper";
import Logger from "Utils/Logging";

export function getCategoryStringForPrefixbox(product: Pick<ProductListData, "default_category_id">, allCategories: Webshop.ICategory[]): string {
  return product.default_category_id
    ? getCategoryPath(product.default_category_id.toString(), allCategories)
        .map((category) => category.name)
        .join(" > ")
    : "";
}

export function getItemCountForPrefixbox(payload: Redux.CartSetProductPayload, diff: number): number {
  if (payload.quantityType === "default") {
    if (payload.product.default_quantity_name === "db") {
      return diff;
    }
    return diff / (payload.product.alternative_quantity_exchange_rate || 1);
  }
  return diff;
}

export function sendAnalyticsForSuccessfulOrder(cart: CartFragment, allCategories: Webshop.ICategory[]): void {
  if (typeof window === "undefined" || typeof window.prefixboxAnalytics !== "function") return;
  try {
    for (const { product, piece } of cart.cart_products) {
      const inputParams: PrefixboxOrderItemParams = {
        productId: product.id,
        title: product.name,
        category: getCategoryStringForPrefixbox(product, allCategories),
        url: getProductFullURL(product),
        itemPrice: product.price,
        currency: "HUF",
        itemCount: piece,
      };

      Logger.debug("[PB] orderItem", inputParams);
      window.prefixboxAnalytics("orderitem", "serp", inputParams);
    }

    const inputParams: PrefixboxOrderParams = { revenue: CartHelper.getCartTotalPrice(cart), currency: "HUF" };
    Logger.debug("[PB] order", inputParams);
    window.prefixboxAnalytics("order", "serp", inputParams);
  } catch (e) {
    console.warn(e);
  }
}

export function sendAnalyticsForCartUpdate(action: Redux.IAction, cart: CartFragment, allCategories: Webshop.ICategory[]): void {
  if (action.type !== Actions.REQUEST_CART_PRODUCT_UPDATE || typeof window == "undefined" || typeof window.prefixboxAnalytics !== "function") return;

  try {
    const product = action.payload.product;
    const productInCart = cart.cart_products.find((cp) => cp.product.id === action.payload.product.id);

    const productDataInput = CartHelper.convertProductUpdatePayloadToProductDataInput(action.payload);
    const piece = action.payload.quantityType === "default" || productDataInput.alternative_quantity_piece === null || productDataInput.alternative_quantity_piece === undefined ? productDataInput.piece : productDataInput.alternative_quantity_piece;

    const diff = productInCart ? piece - (action.payload.quantityType === "default" ? productInCart.piece : productInCart.alternative_quantity_piece || 0) : piece;

    const path = getCategoryStringForPrefixbox(product, allCategories);

    const { analytics } = action.payload;

    const inputParams: PrefixboxCartParams = {
      productId: product.id,
      title: product.name,
      url: getProductFullURL(product),
      currency: "HUF",
      price: product.price,
      itemCount: parseFloat(getItemCountForPrefixbox(action.payload, diff).toFixed(5)),
      category: path,
      ...action.payload.analytics,
      ...analytics,
      position: analytics && analytics.position !== undefined && analytics.position !== null ? analytics.position + 1 : undefined,
    };

    if (diff > 0) {
      Logger.debug("[PB] cart", inputParams);
      window.prefixboxAnalytics("cart", "serp", inputParams);
    } else if (diff < 0) {
      Logger.debug("[PB] cartremove", inputParams);
      window.prefixboxAnalytics("cartremove", "serp", inputParams);
    }
  } catch (e) {
    console.warn(e);
  }
}

export function prefixboxResult(inputParams: PrefixboxResultParams): void {
  if (typeof window !== "undefined" && typeof window.prefixboxAnalytics === "function") {
    window.prefixboxAnalytics("result", "serp", inputParams);
  }
}

export function prefixboxClick(inputParams: PrefixboxClickParams): void {
  if (typeof window !== "undefined" && typeof window.prefixboxAnalytics === "function") {
    window.prefixboxAnalytics("click", "serp", inputParams);
  }
}
