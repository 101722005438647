import React from 'react'
import PageLink from './PageLink'

type PropType = {
  category: Webshop.ICategory,
  onClick?: () => void
}

export const CategoryLink: React.SFC<PropType> = (props) => {
  return (
    <PageLink toPage={props.category.url} onCustomClick={props.onClick}>
      {props.children || props.category.name}
    </PageLink>
  )
}