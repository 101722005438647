import gql from 'graphql-tag'

export const TimetableSlotFragment = gql`
fragment TimetableSlotFragment on TimetableTimeSlot {
  id
  sector {
    id
    name
  }
  order_limit
  weight_limit
  price
  range_date
  timeRange {
    id
    time_from
    time_to
  }
  visible_until
  status
}
`
