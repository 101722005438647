import App, { Container, AppProps } from 'next/app'
import Head from 'next/head'
import * as React from 'react'
import { Provider } from 'react-redux'
import withReduxStore from 'Utils/withReduxStore'
import { Store } from 'redux';
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'
import ModalWrapper from 'Components/Modal/ModalWrapper'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { unblockRedirects, refreshUser, refreshCart } from 'Utils/redux/actions'
import Router from 'next/router';
import ListClient from "Utils/api/ListClient";
import { isDevelopment } from 'Utils/debugUtils';
import Logger from 'Utils/Logging'
import animateScrollTo from 'animated-scroll-to';

interface MyAppProps extends AppProps {
  reduxStore: Store
  apolloClient: ApolloClient<NormalizedCacheObject>
}

interface ModalOptions {
  hideCloseButton: boolean
  preventClose: boolean
}

interface ModalStyleProp {
  style?: React.CSSProperties
}

interface State {
  modalOptions: ModalOptions
}

function logStartupMessage () {
  let lastBuildTimeBanner = null
  const isFirefox = /Firefox/.test(navigator.userAgent || '')
  const style = `background-color:#141B4D;color:white;font-weight:bold;white-space:pre;${isFirefox && 'font-size:12px;line-height:10px;'}`
  if (process.env.APP_LAST_BUILD_TIME) {
    lastBuildTimeBanner = `Last build: ${process.env.APP_LAST_BUILD_TIME}  
                                   `
  }
  console.log(`%c
                                   
       ___ ___     _               
      / __| _ \\___| |__ _  _       
     | (_ |   / _ \\ '_ \\ || |      
      \\___|_|_\\___/_.__/\\_, |      
                        |__/       
                                   
  ${lastBuildTimeBanner}`,style)
  if (isDevelopment() && typeof console.table !== 'undefined') {
    console.log('Environment variables:')
    console.table(process.env)
  }
}

class MyApp extends App<MyAppProps, State> {
  timeout: number | null = null
  modal?: ModalWrapper | null
  modalContent?: React.ReactNode
  public state = {
    modalOptions: {
      hideCloseButton: false,
      preventClose: false
    }
  }

  static async getInitialProps({ Component, router, ctx }: {Component: any, router: any, ctx: any}) {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    return { pageProps, router };
  }

  constructor (props: any) {
    super(props)
    ListClient.authToken = this.props.reduxStore.getState().session.token
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete)
    uiEventEmitter.on(UIEvents.SHOW_MODAL, this.openModal.bind(this))
    uiEventEmitter.on(UIEvents.CLOSE_MODAL, this.closeModal)
    uiEventEmitter.on(UIEvents.SHOW_MODAL_FOR_TIME, this.showModalForTime)
    uiEventEmitter.on(UIEvents.SET_MODAL_LOADING, this.setModalLoadingState)
    uiEventEmitter.emit(UIEvents.MODAL_READY)
    uiEventEmitter.modalReady = true

    this.props.reduxStore.dispatch(refreshCart())
    
    logStartupMessage()
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete)
    uiEventEmitter.off(UIEvents.SHOW_MODAL, this.openModal.bind(this))
    uiEventEmitter.off(UIEvents.CLOSE_MODAL, this.closeModal)
    uiEventEmitter.off(UIEvents.SHOW_MODAL_FOR_TIME, this.showModalForTime)
    uiEventEmitter.off(UIEvents.SET_MODAL_LOADING, this.setModalLoadingState)
  }

  handleRouteChangeComplete = () => {
    animateScrollTo(0)
    Logger.debug('routeChangeCompleteUnblock')
    this.props.reduxStore.dispatch(unblockRedirects())

    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = window.setTimeout(() => {  // Wating a few seconds after pageload to refresh user increases the performance of pages which fetch data on mount
      this.props.reduxStore.dispatch(refreshUser())
      this.timeout = null
    }, 2000)
  }

  setModalLoadingState = (isLoading: boolean) => {
    this.modal && this.modal.setLoading(isLoading)
  }

  closeModal = (result: any) => {
    if (this.modal) {
      this.modal.close(result)
    }
  }

  showModalForTime = (content: JSX.Element, forTime?: number) => {
    this.setState({ modalOptions: { hideCloseButton: false, preventClose: false } }, () => {
      if (this.modal) {
        this.modal.showForTime(forTime, content)
      }
    })
  }

  openModal(content: JSX.Element, callback?: (result: any) => void, options?: ModalOptions & ModalStyleProp) {
    this.setState({
      modalOptions: {
        hideCloseButton: options ? !!options.hideCloseButton : false,
        preventClose: options ? !!options.preventClose : false,
      }
    }, () => {
      if (this.modal) {
        this.modal.open(content, callback, options ? { style: options.style } : undefined)
      }
    })
  }

  render() {
    const { Component, pageProps, reduxStore, apolloClient } = this.props
    return (
      <Container>
        <Head>
          <title>GRoby - az Online Szupermarket - élelmiszer házhozszállítás</title>
        </Head>
        <Provider store={reduxStore}>
          <ApolloProvider client={apolloClient}>
            <React.Fragment>
              {/* <PageTransition timeout={300} classNames="page-transition"> */}
                <Component {...pageProps} key={this.props.router.route} />
              {/* </PageTransition> */}
              <ModalWrapper
                type='isReveal'
                ref={(modal) => { this.modal = modal }}
                preventClose={this.state.modalOptions.preventClose}
                hideCloseButton={this.state.modalOptions.hideCloseButton}
              />
            </React.Fragment>
          </ApolloProvider>
        </Provider>
      </Container>
    );
  }
}

export default withReduxStore(MyApp)
