import React, { Component } from 'react'
/* TODO: storybook miatt kilőve import MobileDetect from 'mobile-detect' */
import PageLink from 'Components/PageLink'
import Button from 'Components/Button'
const { Router } = require('Utils/routes')
import debounce from 'lodash/debounce'

import { SVGSocialFacebook, SVGSocialInstagram, SVGSocialYoutube, SVGGoogleGLogo, SVGTelephoneTiny, SVGArrow } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './MegaMenu.scss'

import { connect } from 'react-redux'
import { CategoryLink } from 'Components/CategoryLink'
import { getCategoryIcon } from 'Utils/helpers/productCategoryHelper'
import ProfileButton from 'Components/AppBar/components/ProfileButton'
import BodyStyler from 'Components/BodyStyler/BodyStyler';
import AddressDisplay from 'Components/Cart/AddressDisplay';
import { goToLogin } from 'Utils/helpers/routerHelper';

interface MegaMenuOwnProps {
  onLinkClick: () => void
}

type ReduxProps = {
  isMobile: boolean
  productCategories: Webshop.ICategory[]
  session: Redux.IStoreSessionData
}

type PropType = MegaMenuOwnProps & ReduxProps

interface StateType {
  hoveredItem: number | null
}

class MegaMenu extends Component<PropType, StateType> {
  state = {
    hoveredItem: this.props.isMobile ? null : 0
  }
  renderSubCategories(subCategories: Array<Webshop.ICategory>)
  {
    if (!subCategories.length) {
      return <div className={cn(cg.cell, cl.cell, cg.large4)} />
    }

    return (
      <div className={cn(cg.cell, cl.cell, cg.large4)}>
        <ul>
          { subCategories.map(subCategory => (
            <li key={subCategory.id}>
              <CategoryLink category={subCategory} onClick={this.props.onLinkClick} />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  private onProfileButtonClick = () => {
    const { session } = this.props
    if (session.loggedIn) {
      Router.pushRoute('/profil')
    } else {
      goToLogin()
    }
    this.props.onLinkClick()
  }

  setHover = debounce((index: number) => {
    this.setState({ hoveredItem: index })
  }, 400)

  renderCategory(category: Webshop.ICategory, index: number, eventListeners: Function) {
    const { hoveredItem } = this.state
    return (
      <li className={`${cg['grid-x']} ${cl.category} ${hoveredItem === index ? cl.opened : ''}`} {...eventListeners(index)} key={category.id}>
        <span className={cn(cg.cell, cg.large3)}>
          {getCategoryIcon(category)}
          {category.name}
          <SVGArrow />
        </span>
        <div className={cn(cl.subList, cg.cell, cg.large9, cg.largeOffset3)}>
          <h3>
            <CategoryLink category={category} onClick={this.props.onLinkClick} >
              <span className={cn(cg.showForLarge)}>{category.name} <SVGArrow /></span>
              <span className={cn(cg.hideForLarge)}>Összes</span>
            </CategoryLink>
          </h3>
          <div className={cn(cg.gridX, cl.gridX)}>
            {this.renderSubCategories(category.children.slice(0, 7))}
            {this.renderSubCategories(category.children.slice(7, 14))}
            {this.renderSubCategories(category.children.slice(14, 21))}
          </div>
        </div>
      </li>
    )
  }

  render() {
    const { isMobile, productCategories } = this.props
    const { session: { customerData }} = this.props

    const eventListeners = (index: number) => {
      if (isMobile) {
        return {
          onClick: () => { this.setState(({ hoveredItem }: {hoveredItem: number}): any => ({ hoveredItem: hoveredItem === index ? null : index })) }
        }
      }
      return {
        onMouseEnter: () => { this.setHover(index) },
        onClick: () => { this.setHover.flush() }
      }
    }

    return (
      <div className={cn(cl.megamenu)}>
        <BodyStyler />
        <div className={cn(cg.gridX, cl.gridX)}>
          <div className={cn(cg.cell, cl.cell)}>
            {isMobile ? (<ProfileButton className={cg.hideForLarge} customerData={customerData} onClick={this.onProfileButtonClick} />):(null)}
            {isMobile ? (<div className={cn(cg.hideForLarge, cl.mobileAddress)}><AddressDisplay view='AppBar' /></div>):(null)}
            <ul className={cn(cl.menuList)}>
              {productCategories.map((category, index) => this.renderCategory(category, index, eventListeners))}
              <li className={cn(cl.firstPageLink)}>
                <PageLink toPage="/ajanlatok" className={cn(cl.pageLink)} onCustomClick={this.props.onLinkClick}>
                  Ajánlatok
                </PageLink>
              </li>
              <li>
                <PageLink toPage="/ujdonsagok" className={cn(cl.pageLink)}>
                  Újdonságok
                </PageLink>
              </li>
              <li>
                <PageLink toPage="/termekvalogatasok" className={cn(cl.pageLink)}>
                  Ajánlott termékválogatások
                </PageLink>
              </li>
            </ul>

            {/* Mobile Content */}
            <div className={cn(cg.cell, cg.hideForLarge)}>
              <Button
                label="Ügyfélszolgálat hívása"
                className={`${cg.primary} ${cg.expanded} ${cl.expanded}`}
                disabled={false}
                renderIcon={{ alignIcon: null, ariaLabel: 'ariaLabel', Icon: <SVGTelephoneTiny /> }}
              />
              <div className={cn(cl.social)}>
                <div className={cn(cg.gridX)}>
                  <div className={cn(cg.cell, cg.small3, cg.textCenter)}>
                    <PageLink toPage="#">
                      <SVGSocialFacebook />
                    </PageLink>
                  </div>
                  <div className={cn(cg.cell, cg.small3, cg.textCenter)}>
                    <PageLink toPage="#">
                      <SVGSocialInstagram />
                    </PageLink>
                  </div>
                  <div className={cn(cg.cell, cg.small3, cg.textCenter)}>
                    <PageLink toPage="#">
                      <SVGSocialYoutube />
                    </PageLink>
                  </div>
                  <div className={cn(cg.cell, cg.small3, cg.textCenter)}>
                    <PageLink toPage="#">
                      <SVGGoogleGLogo />
                    </PageLink>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <PageLink toPage="/cikk/aruhazunk-mukodese/" className={cn(cl.pageLink)}>
                    ÁRUHÁZUNK MŰKÖDÉSE
                  </PageLink>
                </li>
                <li>
                  <PageLink toPage="/uzletek" className={cn(cl.pageLink)}>
                    ÜZLETEINK
                  </PageLink>
                </li>
                <li>
                  <PageLink toPage="/kapcsolat" className={cn(cl.pageLink)}>
                    KAPCSOLAT
                  </PageLink>
                </li>
                <li>
                  <PageLink toPage="/termekvalogatasok" className={cn(cl.pageLink)}>
                    GROBY MAGAZIN
                  </PageLink>
                </li>
                <li>
                  <PageLink toPage="/cikk/fizetesi-modok" className={cn(cl.pageLink)}>
                    FIZETÉSSEL KAPCSOLATOS INFORMÁCIÓK
                  </PageLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapToStateProps({ session, productCategories, isMobile, isTablet }: Redux.IReduxState) {
  return {
    session,
    productCategories,
    isMobile: isMobile || isTablet
  }
}

export default connect(mapToStateProps)(MegaMenu)
