import { ApolloClient, NormalizedCacheObject } from "apollo-boost";
import { myOrderedProductIDs } from "Utils/api/gql/types";
import { myOrderedProductIDsQuery } from "Utils/api/gql/queries/myOrderedProductIDs";

// Omit fields from type
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function removeTypenameFromObject<T extends { __typename: string }>(obj: T): Omit<T, '__typename'> {
    const newObj = { ...obj }
    delete newObj.__typename
    return newObj
}

export async function fetchOrderedProducts(apolloClient: ApolloClient<NormalizedCacheObject>): Promise<Redux.OrderedProductEntry[] | null> {
    try {
        const result = await apolloClient.query<myOrderedProductIDs>({ query: myOrderedProductIDsQuery })
        if (result.data.me && result.data.me.ordered_products) {
        return result.data.me.ordered_products.data
            .map<Redux.OrderedProductEntry>(ordered_product => [ordered_product.product.id, ordered_product.default_quantity_piece, ordered_product.default_quantity_name, ordered_product.created_at])
        }
    } catch (e) { } // no need to handle errors, convenience feature only

    return null
}

/*
function typingTest() {
    const hasTypename = { a: 5, __typename: 'a' }
    const noTypename = { a: 5 }

    const shouldntHaveTypeName = removeTypenameFromObject(hasTypename)  // should be ok
    shouldntHaveTypeName.a                  // should be ok
    shouldntHaveTypeName.__typename         // should be error
    removeTypenameFromObject(noTypename)    // should be error
}
*/
