import gql from 'graphql-tag'
import { SavedCreditCardFragment } from './SavedCreditCardFragment'

const InterfacePaymentMethodFragment = gql`
fragment InterfacePaymentMethodFragment on InterfacePaymentMethod {
  id
  name
  is_available
  cost
  short_description
}
`

export const PaymentMethodFragment = gql`
fragment PaymentMethodFragment on WebshopPaymentMethod {
  ... on PaymentMethodCreditCardBorgun {
    __typename
    ...InterfacePaymentMethodFragment
  }
  ... on PaymentMethodCreditCardSimple {
    __typename
    ...InterfacePaymentMethodFragment
  }
  ... on PaymentMethodBankTransfer {
    __typename
    ...InterfacePaymentMethodFragment
  }
  ... on PaymentMethodCashOnDelivery {
    __typename
    short_description_mpl_type
    ...InterfacePaymentMethodFragment
  }
  ... on PaymentMethodCreditCardSimpleSaved {
    __typename
    ...InterfacePaymentMethodFragment
    data {
      ...SavedCreditCardFragment
    }
  }
}
${InterfacePaymentMethodFragment}
${SavedCreditCardFragment}
`
