const routes = require('next-routes')

module.exports = routes()
    // .add('profile', '/profil', 'profile')
    // .add('profile-subpage', '/profil/:subpage', 'profile')
    // .add('profile-subpage-with-param', '/profil/:subpage/:param', 'profile')
    // New profile
    .add('profile', '/profil', 'nprofile')
    .add('profile-personal', '/profil/szemelyes-adatok', 'profile/personal')
    .add('profile-shipping', '/profil/szallitasi-cimeim', 'profile/shippingaddress')
    .add('profile-billing', '/profil/szamlazasi-cimeim', 'profile/billingaddress')
    .add('profile-savedcards', '/profil/mentett-kartyaim', 'profile/savedcards')
    .add('profile-shoppinglists', '/profil/bevasarlolistaim', 'profile/shoppinglists')
    .add('profile-shoppinglists-detail', '/profil/bevasarlolistaim/:listId', 'profile/shoppinglists')
    .add('profile-orderhistory', '/profil/eddigi-megrendeleseim', 'profile/orderhistory')
    .add('profile-orderhistory-detail', '/profil/eddigi-megrendeleseim/:param', 'profile/orderhistory')
    .add('profile-previousorders', '/profil/korabban-rendelt', 'profile/previousorders')
    .add('profile-previousorders-details', '/profil/korabban-rendelt/:param', 'profile/previousorders')

    .add('product', '/termekek/:url_key', 'product')
    .add('search', '/kereses/:searchTerm', 'category')
    .add('delivery-information', '/szallitas', 'delivery-information')
    .add('delivery-information-withopened', '/szallitas/:opened', 'delivery-information')
    .add('article', '/cikk/:url_key', 'article')
    .add('brand', '/brands/:brandSlug', 'category')
    .add('stores', '/uzletek', 'stores')
    .add('store', '/uzletek/:id', 'store')
    .add('promotion', '/termekvalogatasok', 'promotion')
    .add('promotion-category', '/termekvalogatasok/:promotion_url', 'category')
    .add('forgotten-password', '/elfelejtett-jelszo', 'password')
    .add('forgotten-password-subpages', '/elfelejtett-jelszo/:subpage', 'password')
    .add('forgotten-password-reset', '/elfelejtett-jelszo/:email/:rpToken', 'password')
    .add('login', '/belepes', 'login')
    .add('token-login', '/token-login/:token', 'token-login')
    .add('registration', '/regisztracio', 'login')
    .add('cart', '/kosar', 'cart')
    .add('checkout', '/megrendeles', 'checkout')
    .add('checkout-delivery', '/megrendeles/kiszallitas', 'checkout/delivery')
    .add('checkout-billing', '/megrendeles/szamlazas', 'checkout/billing')
    .add('checkout-summary', '/megrendeles/osszesites', 'checkout/summary')
    .add('checkout-express', '/megrendeles/expressz', 'checkout/express')
    // .add('checkout-result-oneclick', '/megrendeles/statusz/oneclick/:OrderId', 'checkout/result')
    .add('checkout-result-successful', '/megrendeles/statusz/sikeres/:OrderId', 'checkout/result')
    .add('checkout-result-oneclick', '/megrendeles/statusz/:TransactionId', 'checkout/result')
    .add('checkout-result-pmgw', '/megrendeles/statusz', 'checkout/result')
    .add('thankyou', '/sikeres-feliratkozas', 'thankyou')
    // .add('category-discounted', '/akcios-termekek', 'category')
    .add('category-discounted', '/ajanlatok', 'category')
    .add('category-news', '/ujdonsagok', 'category')
    .add('contact', '/kapcsolat', 'contact')
    .add('goodid-login', '/goodid-belepes', 'goodid-login')
    .add('category-or-redirect', '/:path+', 'category')
