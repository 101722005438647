import React from 'react'
import PageLink from 'Components/PageLink'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Breadcrumb.scss'

export interface BreadcrumbData {
  href?: string
  title: string
  gtmName: string
}

type BreadcrumbProps = {
  data: Array<BreadcrumbData>
}

export const formatBreadcrumbForGtm = (breadcrumb: Array<BreadcrumbData>): string => {
  return breadcrumb.map(bc => bc.gtmName).join(' / ')
}

const Breadcrumb: React.SFC<BreadcrumbProps> = ({ data }) => {
  const renderItems = data.map((item: BreadcrumbData, i: number) => (
    <li key={`breadcrumb-${item.title}`} className={`${cg.cell} ${i + 1 < data.length ? cn(cg.shrink, cl.shrink) : cg.auto}`}>
      {
        item.href
          ? <PageLink toPage={item.href} className={cn(cl.breadcrumbLink, { [cl.breadcrumbLink__isActive]: i + 1 === data.length })} aria-current={i + 1 === data.length ? "page" : undefined} href={item.href}><span dangerouslySetInnerHTML={{ __html: item.title }} /></PageLink>
          : <span dangerouslySetInnerHTML={{ __html: item.title }} className={cn(cl.breadcrumbLink, { [cl.breadcrumbLink__isActive]: i + 1 === data.length })} />
      }
    </li>
  ))

  return (
    <nav aria-label="Breadcrumb" className={cn(cl.breadcrumb)}>
      <ol className={cn(cg.gridX)}>
        {renderItems}
      </ol>
    </nav>
  )
}

export default Breadcrumb
