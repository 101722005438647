import React, { ReactNode, ReactElement } from 'react'

import Button from 'Components/Button'

import { SVGClose, SVGArrow } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import btnCss from 'Components/Button/Button.scss'
import cl from './_reveal.scss'
import { delay } from 'Utils/helpers/misc'
import BodyStyler from 'Components/BodyStyler/BodyStyler';
import Spinner from 'Components/Spinner';

type StateType = {
  opened: boolean
  opening: boolean
  closing: boolean
  content: ReactElement<any>
  modalProps: {}
  isLoading: boolean
  callback: (result: any) => void
}

type PropType = {
  callback?: (result: any) => void
  type?: string
  hideCloseButton?: boolean
  preventClose?: boolean
  overlayIsTransparent?: boolean
}

const ANIMATION_TIMEOUT = 100

const defaultContent = <div />
const defaultCallback = (result: any) => {}

export default class ModalWrapper extends React.Component<PropType, StateType> {
  timeout: number | null = null
  container: HTMLDivElement | null = null

  state: StateType = {
    opened: false,
    opening: false,
    closing: false,
    content: defaultContent,
    callback: defaultCallback,
    isLoading: false,
    modalProps: {}
  }

  clearTimeout = () => {
    if (this.timeout !== null) {
      window.clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  showForTime = (time: number = 5000, content?: JSX.Element) => {
    this.open(content || this.state.content || defaultContent)
    this.timeout = window.setTimeout(this.close, time)
  }

  open = async (content: ReactElement<any>, callback: (result: any) => void = defaultCallback, props?: {}) => {
    const newContent = React.cloneElement(content ? content : this.state.content, {onClose: this.close});
    this.clearTimeout()
    this.setState({ opening: true, opened: true, content: newContent, callback, isLoading: false, modalProps: props || {} })
    await delay(10) // TODO fix this?
    this.setState({ opening: false })
  }

  close = async (result?: any) => {
    if (!this.state.opened && !this.state.opening) {
      return
    }
    this.clearTimeout()
    this.setState({ closing: true })
    await delay(ANIMATION_TIMEOUT)
    this.state.callback(result)
    this.setState({ closing: false, opened: false, content: <div />, callback: (result: any) => {} })
  }

  toggle = async () => {
    this.state.opened
      ? await this.close()
      : await this.open(this.state.content, this.state.callback)
  }

  setLoading = (isLoading: boolean) => {
    this.setState({
      isLoading
    })
  }

  setClasses () {
    // if (!document.documentElement || true) return
    // this.state.opened
    //   ? document.documentElement.classList.add(cl.isRevealOpen)
    //   : document.documentElement.classList.remove(cl.isRevealOpen)
  }

  handleClick = () => {
    if (!this.props.preventClose) {
      this.close()
    }
  }

  renderLoading = () => {
    return (
      <div className={cl.revealLoading}>
        <Spinner/>
      </div>
    )
  }

  renderModal () {
    const { type } = this.props
    const { content, modalProps } = this.state

    switch (type) {
      case 'isReveal':
        return (
          <div ref={ref => this.container = ref} className={cl.reveal} {...modalProps}>
            {this.state.isLoading && this.renderLoading()}
            <div className={cl.mobileCenter}>
              {content}
            </div>
            {!this.props.hideCloseButton &&
              <div className={cl.reveal__closeButtonContainer}>
                <Button
                  className={cn(btnCss.hollow, btnCss.flat, cl.reveal__closeButton)}
                  renderIcon={{
                    ariaLabel: 'Bezárás',
                    Icon: <SVGClose width={32} height={32} />
                  }}
                  onClick={this.handleClick}
                />
              </div>
            }
          </div>
        )
      default:
        return <div>{content}</div>
    }
  }

  componentDidMount () {
    this.setClasses()
  }

  componentDidUpdate (prevProps: PropType, prevState: StateType) {
    this.setClasses()
    if (!prevState.opened && this.state.opened && this.container) {
      this.container.scrollTop = 0
    }
  }

  render() {
    const { overlayIsTransparent } = this.props
    const { closing, opened, opening } = this.state

    return (
      <div className={cn(cl.revealOverlay, overlayIsTransparent ? cl.revealOverlayIsTransparent : {}, closing ? cl.revealOverlayIsClosing : {}, opening ? cl.revealOverlayIsOpening : {})} style={opened ? { display: 'flex' } : { }} >
        {this.renderModal()}
        {!this.props.preventClose &&
          <button className={cl.revealOverlay__closeButton} onClick={this.handleClick}>
            <span className={cg.showForSr}>Bezárás</span>
          </button>
        }
        {opened && <BodyStyler />}

      </div>
    )
  }
}
