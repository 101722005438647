import { ReactNode } from "react"
import PhoneInput from './PhoneInput'
import "cleave.js/dist/addons/cleave-phone.hu"
import InputWrapper from "./InputWrapper"

type PropType = {
    label?: string,
    hideLabel?: boolean
    name?: string,
    type: string,
    value?: string | number,
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    error?: ReactNode
    textOnly?: boolean
    noMargin?: boolean
    [key: string]: any
}

const input = ({ label, error, hideLabel, name, type, value, onChange, options, textOnly, noMargin, ...rest }: PropType) => {
    if (textOnly) {
        if (type === 'phone' && typeof value === 'string') {
            return value.substr(2)
        }
        return value
    }
    switch(type) {
        case 'phone': return <PhoneInput name={name} value={(value || '').toString()} onChange={onChange} options={{ phone: true, phoneRegionCode: 'HU' }} {...rest} />
        case 'textarea': return <textarea name={name} value={value || ''} onChange={onChange} {...rest} />
        default: return <input name={name} type={type} value={value || ''} onChange={onChange} {...rest} />
    }
}

const Input: React.SFC<PropType> = (props: PropType) =>
    <InputWrapper label={props.label} hideLabel={props.hideLabel} phone={props.type === 'phone'} error={props.error} noMargin={props.noMargin} >
        {input(props)}
    </InputWrapper>

export default Input
