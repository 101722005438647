import React, { SyntheticEvent } from 'react'
import PropTypes from 'prop-types'
import cg from 'Scss/app.scss'
import cl from './Button.scss'

export interface ButtonRenderIcon {
  alignIcon?: 'left' | 'right' | null
  ariaLabel: string
  Icon: React.ReactElement<any>
}

export type ButtonProps = {
  className?: string
  label?: string
  titleName?: string
  renderIcon?: ButtonRenderIcon
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void
}

const Button = (props: ButtonProps) => {
  const {
    className,
    label,
    titleName,
    renderIcon,
    ...otherProps
  } = props
  let classNames = `${cl.button} ${className || cl.primary}`.trim()

  // Button with Icon and without label
  if (renderIcon && label === 'Button') {
    classNames += ` ${cl.hasIcon}`

    return (
      // If there is a "type" prop in otherProps, it will overwrite the default "button" value
      <button type="button" className={classNames} {...otherProps}>
        <span className={cg.showForSr}>{renderIcon.ariaLabel}</span>

        <span className={cl.iconwrapper} aria-hidden="true">{renderIcon.Icon}</span>
      </button>
    )
  }

  // Button with Icon and label
  if (renderIcon) {
    classNames += ` ${cl.hasIcon} ${renderIcon.alignIcon || cl.left}`

    return (
      <button type="button" className={classNames} title={titleName} {...otherProps}>
        <span className={`${cg.gridX} ${cg.alignCenter} ${cg.alignMiddle}`}>
          <span className={cg.showForSr}>{renderIcon.ariaLabel}</span>

          {(!renderIcon.alignIcon || renderIcon.alignIcon === 'left') && (
            <span className={`${cg.cell} ${cg.shrink}`}>
              <span className={`${cl.iconWrapper}`} aria-hidden="true">{renderIcon.Icon}</span>
            </span>
          )}

          <span className={`${cg.cell} ${cg.shrink} ${cl.label}`}>{label}</span>

          {renderIcon.alignIcon === 'right' && (
            <span className={`${cg.cell} ${cg.shrink}`}>
              <span className={`${cl.iconWrapper}`} aria-hidden="true">{renderIcon.Icon}</span>
            </span>
          )}
        </span>
      </button>
    )
  }

  // Simple button
  return (
    <button type="button" className={classNames} {...otherProps}>
      {label}
    </button>
  )
}


Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  renderIcon: PropTypes.shape({
    alignIcon: PropTypes.oneOf(['left', 'right']),
    ariaLabel: PropTypes.string.isRequired,
    Icon: PropTypes.element.isRequired,
  }),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
}
Button.defaultProps = {
  label: 'Button',
  className: `${cl.primary}`,
  renderIcon: null,
  type: 'button',
}

export default Button
