import gql from 'graphql-tag'

export default gql`
fragment CategoryDataFragment on ProductCategory {
    id
    name
    url_key
    content
    keywords
    description
    is_promotion
    is_homepage_prohibited
    promotion_place
    product_count
}
`
