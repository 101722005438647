import React from 'react'
import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from "Components/Profile/ProfileNavContainer/ProfileNavContainer.scss";
import {
  SVGUser,
  SVGSavedCard,
  SVGShoppingList,
  SVGNavicon2,
  SVGLogout,
  SVGShippingAddress, SVGBillingAddress, SVGHistory, SVGPreviousOrder
} from "Components/SVGCollection";
import ProfileNavItemContainer from "Components/Profile/ProfileNavItemContainer";
import {logout} from "Utils/redux/actions";
import {connect, DispatchProp} from "react-redux";
const  { Router } = require('Utils/routes')

export enum MenuItem {
  Personal = 'Personal',
  SavedCards = 'SavedCards',
  ShippingAddress = 'ShippingAddress',
  BillingAddress = 'BillingAddress',
  ShoppingLists = 'ShoppingLists',
  OrderHistory = 'OrderHistory',
  PreviousOrders = 'PreviousOrders'
}

type MenuConfigItem = {
  title: string,
  slug: string,
  icon: JSX.Element
}

type MenuConfigType = {
  [P in MenuItem]: MenuConfigItem;
}

export const menuConfigs: MenuConfigType = {
  Personal: { title: 'Személyes adatok', icon: <SVGUser width="24" height="24" />, slug: 'szemelyes-adatok' },
  SavedCards: { title: 'Mentett kártyáim', icon: <SVGSavedCard width="24" height="24" />, slug: 'mentett-kartyaim' },
  ShippingAddress: { title: 'Szállítási címeim', icon: <SVGShippingAddress width="24" height="24" />, slug: 'szallitasi-cimeim' },
  BillingAddress: { title: 'Számlázási címeim', icon: <SVGBillingAddress width="24" height="24" />, slug: 'szamlazasi-cimeim' },
  ShoppingLists: { title: 'Bevásárlólistáim', icon: <SVGNavicon2 width="24" height="24" />, slug: 'bevasarlolistaim' },
  OrderHistory: { title: 'Eddigi megrendeléseim', icon: <SVGHistory width="24" height="24" />, slug: 'eddigi-megrendeleseim'},
  PreviousOrders: { title: 'Korábban rendelt termékek', icon: <SVGPreviousOrder width="24" height="24" />, slug: 'korabban-rendelt' }
}

type PropType = {
  activeItem?: MenuItem
}

class ProfileMenu extends React.Component<PropType & DispatchProp> {

  logout = () => { this.props.dispatch(logout()) }

  render () {
    return (
      <div className={cn(cl.profileNavContainer, this.props.activeItem && cg.showForMedium)}>
        {Object.keys(menuConfigs).map(configKey => {
          const config = menuConfigs[configKey as MenuItem]
          return (
            <ProfileNavItemContainer key={config.slug} renderHeader={<h2 dangerouslySetInnerHTML={{ __html: config.title }}/>} renderIcon={config.icon} onClick={() => { Router.pushRoute(`/profil/${config.slug}`)}} opened={this.props.activeItem === configKey} isOffcanvas={true}/>
          )
        })}
        <ProfileNavItemContainer
          isEmphasized
          noArrow
          renderHeader={
            <h2>Kijelentkezés a fiókból</h2>
          }
          renderIcon={
            <SVGLogout width="24" height="24" />
          }
          onClick={this.logout}
          opened={false}
          isOffcanvas
        />
      </div>
    )
  }
}

export default connect()(ProfileMenu)
