import uiEventEmitter, { UIEvents } from "Utils/uiEventEmitter"
import cn from 'classnames'
import cg from 'Scss/app.scss'
import btn from 'Components/Button/Button.scss'
import Button, { ButtonRenderIcon } from "Components/Button"
import { SVGTrashSmall, SVGIconSmileSad } from "Components/SVGCollection"
import { ReactNode } from "react"
import RegistrationModal from "Components/Profile/RegistrationModal"
import ModalDeliveryType from "Components/Modal/components/ModalDeliveryType";
import LinkingOrders from "Components/Modal/components/LinkingOrders";
import { CartFragment } from "Utils/api/gql/types";
import Feedback from "Components/Modal/components/Feedback";

export interface DialogButton<V extends string = string> {
    label: string
    value: V
    className?: string
    style?: React.CSSProperties
    renderIcon?: ButtonRenderIcon
}

export interface Dialog<U extends string = string> {
    message?: React.ReactNode
    additionalContent?: ReactNode
    buttons: DialogButton<U>[]
}

export const asyncDeleteDialog = async (prompt: React.ReactNode, deleteBtnText = 'Törlés', additionalContent?: React.ReactNode): Promise<'yes' | 'no'> => {
    const deleteDialog: Dialog<'yes' | 'no'> = {
        message: prompt,
        additionalContent,
        buttons: [
            { label: deleteBtnText, value: 'yes', className: btn.delete, renderIcon: { Icon: <SVGTrashSmall />, ariaLabel: 'Törlés', alignIcon: 'left' } },
            { label: 'Mégsem', value: 'no', className: btn.hollow }
        ]
    }

    return await asyncDialog(deleteDialog)
}

export const asyncDialog = <V extends string>(dialog: Dialog<V>): Promise<V> => {
    const { message, additionalContent, buttons } = dialog
    return new Promise(resolve => {
        const Dialog = () =>
            <div>
                <h3>{ message }</h3>
                <br />
                { additionalContent }
                <div className={cn(cg.gridX, cg.gridMarginX, cg.alignCenter, cg.largeAlignRight)}>
                {buttons.map(button =>
                    <div className={cn(cg.cell, cg.shrink)} key={button.value}>
                        <Button
                            className={cn(btn.expanded, button.className)}
                            onClick={() => {
                                uiEventEmitter.emit(UIEvents.CLOSE_MODAL)
                                resolve(button.value)
                            }}
                            label={button.label}
                            style={button.style}
                            renderIcon={button.renderIcon}
                        />
                    </div>
                )}
                </div>
            </div>

        uiEventEmitter.emit(UIEvents.SHOW_MODAL, <Dialog />, () => {}, { hideCloseButton: true, preventClose: true })
    })
}

export const showMessage = (text: ReactNode, timeout: number | null = 5000) => {
    const modal =
        <div>
            <h3>{text}</h3>
        </div>

    if (timeout) {
        uiEventEmitter.emit(UIEvents.SHOW_MODAL_FOR_TIME, modal, timeout)
    } else {
        uiEventEmitter.emit(UIEvents.SHOW_MODAL, modal, () => { }, { hideCloseButton: false, preventClose: false })
    }
}

export const showContentMessage = (text: ReactNode, content: string | ReactNode, timeout: number | null = 5000) => {
    const modal =
        <div>
            <h3>{text}</h3>
            <p>{content}</p>
        </div>

    if (timeout) {
        uiEventEmitter.emit(UIEvents.SHOW_MODAL_FOR_TIME, modal, timeout)
    } else {
        uiEventEmitter.emit(UIEvents.SHOW_MODAL, modal, () => { }, { hideCloseButton: false, preventClose: false })
    }
}

export const showErrorModal = (errorText: ReactNode = 'Hiba történt, kérünk, próbáld újra!') => {
    uiEventEmitter.emit(
        UIEvents.SHOW_MODAL_FOR_TIME,
        <Feedback renderIcon={<SVGIconSmileSad height={26} width={26}/>}>
            {errorText}
        </Feedback>,
        10000
    )
}

export const confirmUnsaved: Dialog<'discard' | 'cancel' | 'save'> = {
    message: 'Biztos, hogy mentés nélkül elhagyja az oldalt?',
    buttons: [
        { label: 'Módosítások elvetése', value: 'discard', className: cn(btn.secondary, btn.hollow) },
        { label: 'Mégse', value: 'cancel', className: cn(btn.secondary, btn.hollow) },
        { label: 'Mentés', value: 'save', className: cn(btn.secondary) }
    ]
}

export const closeModal = () => {
    uiEventEmitter.emit(UIEvents.CLOSE_MODAL)
}

export const showModal = (element: React.ReactNode, preventClose = true, cb = () => {}) => {
    uiEventEmitter.emit(UIEvents.SHOW_MODAL, element, cb, { hideCloseButton: preventClose, preventClose })
}

export const showDeliveryTypeModal = (preventClose = true, cb = () => {}) => {
    uiEventEmitter.emit(UIEvents.SHOW_MODAL, <ModalDeliveryType />, cb, { hideCloseButton: preventClose, preventClose })
}

export const showRegistrationModal = () => {
    uiEventEmitter.emit(UIEvents.SHOW_MODAL, <RegistrationModal />)
}

export const showLinkingOrdersModal = (cb: (newCart: CartFragment | null) => void) => {
    showModal(<LinkingOrders onFinished={cb} />, true)
}
