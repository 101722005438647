import gql from 'graphql-tag'

export default gql`
fragment BillingAddressFragment on UserBillingAddress {
    id
    name
    postcode
    city
    street
    house_number
    is_default
    vat_number
    is_company
    is_goodid
    bank_transfer_enabled
}
`
