import React, { SyntheticEvent } from 'react'
import PropTypes from 'prop-types'
// import Link from 'next/link'
import { UrlObject, Url } from 'url'
const { Link } = require('Utils/routes')

interface PropTypes {
  children?: React.ReactNode,
  toPage?: string | UrlObject | Url,
  as?: string,
  href?: string,  // for external links
  disabled?: boolean
  [s: string]: any
}

class OnClickableLink extends React.Component<{ onCustomClick?: (e: SyntheticEvent) => void, onClick?: (e: SyntheticEvent) => void, stopPropagation?: boolean, [s: string]: any }> {
  render () {
    const { onCustomClick, children, disabled, stopPropagation, ...props } = this.props
     return <a {...props} onClick={this.handleClick}>
          {children}
        </a>
  }

  handleClick = (event: SyntheticEvent) => {
    if (this.props.onClick) {
      this.props.onClick(event)
      if (this.props.stopPropagation === true) { event.stopPropagation() }
    }

    if (this.props.onCustomClick) {
      this.props.onCustomClick(event)
    }
  }
}

const PageLink: React.SFC<PropTypes> = ({ toPage, as, ...other }) =>  (
    <Link route={as || toPage || ''} passHref>
      <OnClickableLink {...other} />
    </Link>
  )

PageLink.defaultProps = {
  children: '',
  toPage: '',
}

export default PageLink
