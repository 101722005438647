import 'isomorphic-unfetch'
import { ApolloClient, InMemoryCache, NormalizedCacheObject, defaultDataIdFromObject, IntrospectionFragmentMatcher } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import introspectionQueryResultData from './fragmentTypes'
import Logger from '../../Logging'
import { isDevelopment, isServer, fetchDebugger } from '../../debugUtils';

type InitApolloOptions = {
  getToken?: () => string
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null
const isServerRender = isServer()

// Polyfill fetch() on the server (used by apollo-client)
if (isServerRender) {
    const fetch = require('isomorphic-unfetch')
    if (isDevelopment()) {
      //@ts-ignore
      global.fetch = fetchDebugger(fetch, Logger) //fetch
    } else {
      //@ts-ignore
      global.fetch = fetch
    }
}

export function getLink({ getToken }: InitApolloOptions) {
  const uri = process.env.GWS_GQL_API_URL || 'https://beta.groby.dev.bigfish.hu/graphql'
  let link = createHttpLink({
    uri
  })

  if (getToken) {
    const authLink = setContext(
      (_, { headers }) => {
        const token = getToken()
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
          }
        }
      })
    link = authLink.concat(link)
  }

  if (!isServerRender) {
    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: 5000
      },
      attempts: {
        max: 4
      }
    })

    link = retryLink.concat(link)

    const { showMessage } = require('../../helpers/modalHelper')
    const errorLink = onError(({ networkError }) => {
      if (networkError) {
        showMessage('Hálózati hiba! Lehet, hogy nálunk van a baj, de lehet, hogy az internetszolgáltatódnál')
      }
    })
    link = errorLink.concat(link)
  }

  return link
}

function create(initialState: NormalizedCacheObject, { getToken }: InitApolloOptions) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData })

  return new ApolloClient({
    //@ts-ignore
    connectToDevTools: (isServerRender || process.env.NODE_ENV !== 'development') ? undefined : process.browser,
    ssrMode: isServerRender, // Disables forceFetch on the server (so queries are only run once)
    link: getLink({ getToken }),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject:
        object => {
          switch (object.__typename) {
            case 'ProductAttribute':
              return null
            case 'ProductAttributeOption':
              return null
            case 'ProductCategoryAggregation':
              return null
            case 'ProductAggregations':
              return null
            default:
              return defaultDataIdFromObject(object)
          }
        }
    }).restore(initialState || {})
  })
}

export default function initApollo(initialState: NormalizedCacheObject = {}, options: InitApolloOptions = {}) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (isServerRender) {
    return create(initialState, options)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options)
  }

  return apolloClient
}

export function getApolloInstance(): ApolloClient<NormalizedCacheObject> | null {
    return apolloClient
}
