import React, { ChangeEvent, MouseEvent } from 'react'
import PageLink from 'Components/PageLink'
import Button from 'Components/Button'
import Input from 'Components/Input'
import DropdownSelect from 'Components/Dropdown/DropdownSelect'
import {
  SVGIconLink,
  SVGThickWhite
} from 'Components/SVGCollection'
import { DispatchProp, connect } from 'react-redux'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ModalDeliveryType.scss'
import { setDeliveryAddress, SetDeliveryAddressType, setCart } from 'Utils/redux/actions'
import { formatAddress, processPostcodes, formatAddressWithName } from 'Utils/helpers/addressHelper'
import _app from 'Pages/_app'
import postCodesQuery from 'Utils/api/gql/queries/postCodes'
import { postcodes, postcodesVariables, UserFragment_shipping_addresses, CartFragment, updateCart, updateCartVariables } from 'Utils/api/gql/types'
import { isEqual } from 'Utils/helpers/misc'
import { updateCartMutation } from 'Utils/api/gql/mutations/updateCart';
import SentryLogger, { LoggerSeverity } from 'Utils/helpers/sentryLogger';
import CheckoutHelper from 'Utils/helpers/checkoutHelper';

type StateProps = {
  deliveryAddress?: Redux.IDeliveryAddress,
  session: Redux.IStoreSessionData
  cart: CartFragment | null
  checkoutInfo: Redux.ICheckoutInfo
}

type OwnProps = {
  onClose?: (value: any) => void  // received from ModalWrapper
}

type PropType = StateProps & OwnProps & DispatchProp

type SelectedState = {
  type: 'delivery' | 'personal'
  deliveryType: 'postcode' | 'savedAddress'
}

type StateType = {
  selected: SelectedState,
  zip: string,
  city: string,
  address: UserFragment_shipping_addresses | null,
  isLoading: boolean,
  postcodeError: string | null
}

function getDisplayedShippingAddress(shipping_addresses: UserFragment_shipping_addresses[] | null, selectedAddressId: string | null) {
  if (shipping_addresses) {
    return (
      shipping_addresses.find(address => selectedAddressId ? address.id === selectedAddressId : address.is_default)
      || shipping_addresses[0]
      || null
    )
  }
  return null
}

// TODO ARIA: https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
class ModalDeliveryType extends React.Component<PropType, StateType> {
  state: StateType = {
    selected: {
      type: 'delivery',
      deliveryType: 'postcode'
    },
    zip: '',
    city: '',
    address: null,
    isLoading: false,
    postcodeError: null
  }

  constructor(props: PropType) {
    super(props)
    if (props.deliveryAddress && props.deliveryAddress.displayValue) {
      const selectedAddressId = props.deliveryAddress.value.addressId
      const selectedAddress = props.session.loggedIn
        ? getDisplayedShippingAddress(props.session.customerData.shipping_addresses, selectedAddressId)
        : null

      this.state = {
        selected: {
          ...props.deliveryAddress.selected,
          deliveryType: props.deliveryAddress.selected.type === 'personal'
            ? (selectedAddress ? 'savedAddress' : 'postcode')
            : props.deliveryAddress.selected.deliveryType
        },
        zip: props.deliveryAddress.value.zip || '',
        city: props.deliveryAddress.value.city || '',
        address: selectedAddress,
        isLoading: false,
        postcodeError: null
      }
    } else if (props.session.loggedIn && props.session.customerData.shipping_addresses) {
      const selectedAddress = getDisplayedShippingAddress(props.session.customerData.shipping_addresses, null)
      if (selectedAddress) {
        this.state.selected.type = 'delivery'
        this.state.selected.deliveryType = 'savedAddress'
        this.state.zip = selectedAddress.postcode
        this.state.city = selectedAddress.city
        this.state.address = selectedAddress
      }
    }
  }
  
  componentDidMount() {
    const { checkoutInfo } = this.props
    const isPersonalDisabled = checkoutInfo.shippingMethods && !CheckoutHelper.getMethodStatus("ShippingMethodGPoint", checkoutInfo.shippingMethods).isAvailable
    if (isPersonalDisabled) {
      this.handleDeliveryOrAddressClick("delivery")();
    }
  }

  componentDidUpdate (_: PropType, prevState: StateType) {
    if (
      !isEqual(prevState.selected, this.state.selected)   &&
      this.state.selected.type === 'delivery'             &&
      this.state.selected.deliveryType === 'savedAddress' &&
      this.state.address
    ) {
      this.setState({
        zip: this.state.address.postcode,
        city: this.state.address.city
      })
    }

    if (this.state.selected.deliveryType === 'savedAddress' && !this.props.session.loggedIn) {
      this.setState({ selected: { type: 'delivery', deliveryType: 'postcode' } })
    }
  }

  resolve = async () => {
    const { cart } = this.props
    const { zip, city, selected, address } = this.state

    let result: SetDeliveryAddressType
    if (selected.type === 'personal') {
      result = { selected: { type: 'personal' }, value: { zip: null, city: null, addressId: null }}
    } else if (selected.deliveryType === 'savedAddress') {
      if (!address) {
        return
      }
      result = { selected: { type: 'delivery', deliveryType: 'savedAddress' }, value: { zip, city, addressId: address.id } }
    } else {
      result = { selected: { type: 'delivery', deliveryType: 'postcode' }, value: { zip, city, addressId: null } }
    }

    this.props.dispatch(setDeliveryAddress(result, true))
    
    if (cart && cart.shipping_address) {  // If user modifies delivery address, delete shipping_address from cart
      try {
          const result = await _app.apolloClient.mutate<updateCart, updateCartVariables>({
              mutation: updateCartMutation,
              variables: {
                  input: {
                      id: cart.id,
                      shipping_address: null
                  }
              } 
          })
  
          if (result && result.data && result.data.updateCart) {
              this.props.dispatch(setCart(result.data.updateCart))
          } else {
              throw new Error('No data')
          }
      } catch (e) { 
          SentryLogger.logException(e, LoggerSeverity.Warning, {
              extras: {
                  location: 'ModalDeliveryType deleteAddress',
                  serializedError: JSON.stringify(e)
              }
          })
      }
  }

    if (this.props.onClose) {
      this.props.onClose(result)
    }
  }

  loadCity = async () => {
    try {
      this.setState({ isLoading: true })
      const result = await _app.apolloClient.query<postcodes, postcodesVariables>({
        query: postCodesQuery,
        variables: {
          filters: { postcode: Number(this.state.zip) }
        }
      })

      if (result.data.postcodes.length > 0) {
        const { cities } = processPostcodes(result.data.postcodes)
        const city = cities.join(', ')
        this.setState({ city, isLoading: false })
      } else {
        throw 'Postcode not found'
      }
    } catch (e) {
      this.setState({ isLoading: false, city: '', postcodeError: 'Nem létező irányítószám!' })
    }
  }

  onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length === 4) {
      this.setState({
        zip: e.currentTarget.value,
        postcodeError: null
      }, this.loadCity)
    } else if (e.currentTarget.value.length < 4) {
      this.setState({
        zip: e.currentTarget.value,
        city: '',
        postcodeError: null
      })
    }
  }

  selectAddress = (address: UserFragment_shipping_addresses) => {
    this.setState({ zip: address.postcode, city: address.city, address })
  }

  handleSelectDeliveryClick = () => {
    this.setState(state => ({ selected: { ...state.selected, deliveryType: 'postcode' } }))
  }

  handleSelectSavedAddressClick = (e: MouseEvent) => {
    this.setState(state => ({ selected: { ...state.selected, deliveryType: 'savedAddress' } }))
  }

  handleDeliveryOrAddressClick = (to: 'personal' | 'delivery') => () => {
    this.setState(state => ({ selected: { ...state.selected, type: to }}))
  }

  render() {
    const { session, checkoutInfo } = this.props
    const { selected, address, city } = this.state

    let okayEnabled =
      selected.type === 'personal'                          ||
      selected.deliveryType === 'postcode' && city.length   ||
      selected.deliveryType === 'savedAddress' && address
      
    const isPersonalDisabled = checkoutInfo.shippingMethods && !CheckoutHelper.getMethodStatus("ShippingMethodGPoint", checkoutInfo.shippingMethods).isAvailable

    return (
      <div className={cn(cl.modalDeliveryType)}>
        <h2 className={cl.modalDeliveryType__h}>
          Hova szeretnél rendelni?
        </h2>
        <p>
          <PageLink target="_blank" toPage="/szallitas" >
            <strong>A kiszálltható termékválaszték területenként eltérhet.</strong>
          </PageLink>
        </p>
        
        <div className={cl.modalDeliveryType__illustrationContainer} aria-hidden={true}>
          <div className={cl.modalDeliveryType__animationWrapper}>
            {/*<SVGCityscape width="269" height="53" />*/}
            <img src="/static/cityscape@3x.png" width="340" height="67" />
          </div>
        </div>
        <div className={cg.gridY} style={{position:'relative'}}>
          <div className={cn(cg.cell, cg.mediumOrder3)}>
            <PageLink target="_blank" toPage="/szallitas" className={cn(cg.linkWithIcon, cl.linkWithIcon)}>
              Miért van szükség erre?
              <span className={cg.linkWithIcon__iconContainer}>
                <SVGIconLink width={18} height={18} />
              </span>
            </PageLink>
          </div>
          <div className={cn(cg.cell, cg.mediumOrder1)}>
            <div className={cl.modalDeliveryType__radioGroup}>
              <div className={cg.gridX}>
                <div
                  className={cn(cg.cell, cg.mediumAuto, cl.labelCell, this.state.selected.type === 'delivery' ? cl.labelCellIsSelected : {})}
                  onClick={this.handleDeliveryOrAddressClick('delivery')}
                >
                  <div className={cn(cl.radioLabel, cl.labelHome)} >
                    {(session.loggedIn && this.state.selected.deliveryType === 'savedAddress' && session.customerData.shipping_addresses)
                      ? <>
                        <span className={cl.labelTitle}>
                          Házhoz rendelek
                        </span>

                        <DropdownSelect
                          name="addressSelector"
                          placeholder="Válassz címet!"
                          value={this.state.address}
                          items={(session.customerData.shipping_addresses || []).map((address) => {
                            const title = formatAddressWithName(address)
                            return { title, value: address, key: address.id }
                          })}
                          isNullable={false}
                          editingDisabled
                          onChange={(name, value) => {this.selectAddress(value)}}
                        />

                        <div className={cl.linkWithIcon__wrapper}>
                          <a className={cn(cg.linkWithIcon, cl.linkWithIcon)} onClick={this.handleSelectDeliveryClick}>
                            Máshova rendelek
                            <span className={cg.linkWithIcon__iconContainer}>
                              <SVGIconLink width={18} height={18} />
                            </span>
                          </a>
                        </div>
                      </>
                      : <>
                        <span className={cl.labelTitle}>
                          Házhozszállítás vagy Csomagpont átvétel
                        </span>

                        <span className={cn(cg.hideForSr, cl.labelInfo)}>
                          Adj meg irányítószámot!
                        </span>

                        <Input hideLabel disabled={this.state.isLoading} name="" label="Adj meg irányítószámot vagy települést!" type="text" value={this.state.zip} onChange={(e) => {this.onInputChange(e)}} />

                        <p style={{marginTop: '5px', marginBottom: '0'}}>{this.state.city}{this.state.postcodeError}</p>

                        { session.loggedIn && session.customerData.shipping_addresses && session.customerData.shipping_addresses.length > 0 &&
                          <a className={cn(cg.linkWithIcon, cl.linkWithIcon)} onClick={this.handleSelectSavedAddressClick}>
                            Címeim megjelenítése
                            <span className={cg.linkWithIcon__iconContainer}>
                              <SVGIconLink width={18} height={18} />
                            </span>
                          </a>
                        }
                      </>
                    }

                    <span className={cl.labelIconWrapper} aria-hidden={true}>
                      <SVGThickWhite width="15" height="15" />
                    </span>
                  </div>
                </div>

                <div className={cn(cg.cell, cg.mediumShrink, cl.separatorCell, cg.showForLarge)}>
                  vagy
                </div>

                <div
                  className={cn(cg.cell, cg.mediumAuto, cl.labelCell, this.state.selected.type === 'personal' ? cl.labelCellIsSelected : {}, isPersonalDisabled ? cl.labelCellIsDisabled : {})}
                  onClick={isPersonalDisabled ? undefined : this.handleDeliveryOrAddressClick('personal')}
                >
                  <div className={cn(cl.radioLabel, cl.labelShop)} >
                    <span className={cl.labelTitle}>
                      Személyesen veszem át a GRoby átvételi pontján
                    </span>

                    <span className={cl.labelInfo}>
                      1186 Budapest, Közdűlő út 46-50. (Ipacsfa utca sarok)
                    </span>

                    <span className={cl.labelIconWrapper} aria-hidden={true}>
                      <SVGThickWhite width="15" height="15" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cn(cg.cell, cg.mediumOrder2, cl.modalDeliveryType__buttonWrapper)}>
            <Button label="Rendben" disabled={!okayEnabled} onClick={this.resolve}/>
          </div>
        </div>
      </div>
    )
  }
}

function mapToStateProps({ deliveryAddress, session, cart, checkoutInfo }: Redux.IReduxState): StateProps  {
  return { deliveryAddress, session, cart, checkoutInfo }
}

export default connect(mapToStateProps)(ModalDeliveryType);
