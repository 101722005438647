import gql from 'graphql-tag'
import { ShippingMethodFragment } from '../fragments/ShippingMethodFragments'
import { PaymentMethodFragment } from '../fragments/PaymentMethodFragments'

export const getCheckoutInfoQuery = gql`
query getCheckoutInfo($cartId: String, $postcode: Int, $user_billing_address_id: ID, $orderId: ID) {
    shippingMethods(filters: { cart_id: $cartId, postcode: $postcode }) {
        ...ShippingMethodFragment
    }
    paymentMethods(filters: { user_billing_address_id: $user_billing_address_id, cart_id: $cartId, order_id: $orderId }) {
        ...PaymentMethodFragment
    }
}
${ShippingMethodFragment}
${PaymentMethodFragment}
`
