import gql from 'graphql-tag'
import CartFragment from '../fragments/CartFragment'

export const createCartMutation = gql`
mutation createCart {
    createCart (input: { products: [] }) {
        ...CartFragment
    }
}
${CartFragment}
`
