import React from 'react'
import PageLink from 'Components/PageLink'

import Button from 'Components/Button'
import {
  SVGClose,
  SVGIconDanger,
  SVGIconLink,
  SVGIconWarningLabel
} from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import btnCss from 'Components/Button/Button.scss'
import cl from './WeightProblems.scss'
import { connect } from 'react-redux'
import CheckoutHelper from 'Utils/helpers/checkoutHelper'

type StateType = {
  opened: boolean
}

type PropType = {
  deliveryAddress?: Redux.IDeliveryAddress
  checkoutInfo: Redux.ICheckoutInfo
  orderData: Redux.IOrderData
}

// TODO: Aria
class WeightProblems extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props)
    this.state = {
      opened: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      opened: !prevState.opened,
    }))
  }

  render() {
    const { checkoutInfo: { shippingMethods }, deliveryAddress, orderData } = this.props
    const { opened } = this.state

    const preferredMethodCode: Redux.ShippingMethodCode = deliveryAddress
        ? CheckoutHelper.getPreferredShippingMethod(deliveryAddress.selected, shippingMethods)
        : 'ShippingMethodGPoint'

    const preferredMethod = shippingMethods.find(method => method.__typename === preferredMethodCode)

    if (!preferredMethod || !CheckoutHelper.methodHasWeightProblem(preferredMethod)) {
      return null
    }

    const maxWeight = `${(preferredMethod.max_weight || 0) / 1000} kg`

    return (
      <React.Fragment>
        {opened && <div className={cl.weightProblems__overlay} onClick={this.handleClick} />}

        <div className={cn(cl.weightProblems, opened && cl.weightProblemsIsOpened)}>
          <div className={cl.weightProblems__pane}>
            <div className={cl.weightProblems__buttonContainer}>
              <h2 className={cl.weightProblems__warningButton} onClick={this.handleClick}>
                <span className={cn(cg.gridX, cg.alignMiddle)}>
                  <span className={cn(cg.cell, cg.shrink)} aria-hidden={true}>
                    <SVGIconDanger width={24} height={24} />
                  </span>

                  <span className={cn(cg.cell, cg.auto)}>
                    Súlyprobléma
                  </span>
                </span>
              </h2>
            </div>

            <div className={cl.weightProblems__innerContainer}>
              <div className={cn(cg.hideForSr, cl.weightProblems__illustrationContainer)} aria-hidden={true}>
                <SVGIconWarningLabel width={56} height={56} />
              </div>

              <p>
                <strong>
                  A kosarad tömege meghaladja a {preferredMethod.name} rendelésenkénti,
                <em> {maxWeight}</em>
                  -os maximumát.
              </strong>
              </p>

              <p>
                Ezért javasoljuk, hogy csökkentsd le a kosárad összsúlyát: vegyél el, vagy törölj belőle termékeket.
              </p>

              <div className={cl.weightProblems__cartButtonContainer}>
                <PageLink toPage="/kosar" className={cn(btnCss.button, btnCss.secondary, cg.textTransformUppercase)}>
                  Kosár megtekintése
                </PageLink>
              </div>

              <div>
                <PageLink toPage="/szallitas" className={cg.linkWithIcon}>
                  További információ

                  <span className={cg.linkWithIcon__iconContainer}>
                    <SVGIconLink width={18} height={18} />
                  </span>
                </PageLink>
              </div>

              <div className={cl.weightProblems__closeButtonContainer} onClick={this.handleClick}>
                <Button
                  className={cn(btnCss.hollow, btnCss.flat)}
                  renderIcon={{
                    ariaLabel: 'Bezárás',
                    Icon: <SVGClose width={24} height={24} />,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(({ deliveryAddress, checkoutInfo, orderData }: Redux.IReduxState) => ({
  deliveryAddress,
  checkoutInfo,
  orderData
}))(WeightProblems)
