import gql from 'graphql-tag'

export const linkableOrdersQuery = gql`
query linkableOrders($cartId: ID!) {
    linkableOrders(cart_id: $cartId) {
        id

        shipping_name
        shipping_postcode
        shipping_city
        shipping_street
        shipping_house_number
        shipping_telephone
        shipping_note

        created_at

        timetable_time_slot {
            range_date
            timeRange {
                time_from
                time_to
            }
        }

        shipping_method {
            id
            name
            is_active
            sort
            price_ranges {
                from
                price
            }
            discount_price
            max_weight
            description
            short_description
            delivery_time
        }

        payment_method {
            id
            name
            is_active
            sort
            short_description
        }
    }
}
`
