import uniq from 'lodash/uniq'

import { ShippingAddressFragment, UserShippingAddressInput, UserFragment_shipping_addresses, BillingAddressFragment, postcodes_postcodes, UserFragment_billing_addresses, UserBillingAddressInput, CartShippingAddressInput, CartBillingAddressInput, postPoints_postPoints_data, CartFragment_shipping_address, CartDataInput, updateMyUserShippingAddress_updateMyUserShippingAddress, updateMyUserBillingAddress_updateMyUserBillingAddress, updateMyUserShippingAddress, updateMyUserShippingAddressVariables, createMyUserShippingAddressVariables, createMyUserShippingAddress, createMyUserShippingAddress_createMyUserShippingAddress, createMyUserBillingAddress_createMyUserBillingAddress, updateMyUserBillingAddress, updateMyUserBillingAddressVariables, createMyUserBillingAddressVariables, createMyUserBillingAddress, OrderFragment, OrderFragment_timetable_time_slot, CartFragment_billing_address, CartFragment, CartFragment_linked_order, me_me_shipping_addresses } from "Utils/api/gql/types"
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import updateMyUserShippingAddressMutation from 'Utils/api/gql/mutations/updateMyUserShippingAddress'
import createMyUserShippingAddressMutation from 'Utils/api/gql/mutations/createMyUserShippingAddress'
import { updateMyUserBillingAddressMutation } from 'Utils/api/gql/mutations/updateMyUserBillingAddress'
import { createMyUserBillingAddressMutation } from 'Utils/api/gql/mutations/createMyUserBillingAddress'
import { validateForm } from 'Utils/helpers/errorHelper'
import { format } from 'date-fns'
import { ShippingMethodId } from './constants'
import { SetDeliveryAddressType } from 'Utils/redux/actions';

export const NEW_ADDRESS_ID = '__NEW_ADDRESS__'

export const emptyShippingAddress = (mergeAddress?: Partial<ShippingAddressFragment>): ShippingAddressFragment => ({
    __typename: "UserShippingAddress",
    id: NEW_ADDRESS_ID,
    name: '',
    postcode: '',
    city: '',
    street: '',
    house_number: '',
    is_default: false,
    is_goodid: false,
    note: '',
    telephone: '',
    ...mergeAddress
})

export const gPointShippingAddress = (): CartShippingAddressInput => ({
    name: 'GRoby NETSHOP raktár',
    postcode: 1186,
    city: 'Budapest',
    street: 'Közdűlő út',
    house_number: '46-50.',
    note: '',
    telephone: '36301231234'
})

export const emptyBillingAddress = (mergeAddress?: Partial<BillingAddressFragment>): BillingAddressFragment => ({
    __typename: 'UserBillingAddress',
    id: NEW_ADDRESS_ID,
    is_company: false,
    is_goodid: false,
    vat_number: null,
    name: '',
    postcode: '',
    city: '',
    street: '',
    house_number: '',
    bank_transfer_enabled: false,
    is_default: false,
    ...mergeAddress
})

export function convertShippingAddressFragmentToShippingAddressInput(a: ShippingAddressFragment): UserShippingAddressInput {
    const { id, __typename, is_goodid, ...addressInput } = {
      ...a,
      telephone: a.telephone || ''
    }

    return addressInput
}

export function convertShippingAddressFragmentToCheckoutShippingAddressInput(a: ShippingAddressFragment, defaultTelephone?: string): CartShippingAddressInput {
    const { id, is_default, is_goodid, __typename, ...addressInput } = {
        ...a,
        postcode: parseInt(a.postcode, 10),
        telephone: a.telephone || defaultTelephone || ''
    }
    return addressInput
}

export function convertShippingAddressInputToShippingAddressFragment(i: CartShippingAddressInput): ShippingAddressFragment {
    return {
        ...i,
        __typename: 'UserShippingAddress',
        house_number: i.house_number || '',
        street: i.street || '',
        city: i.city || '',
        id: NEW_ADDRESS_ID,
        is_goodid: false,
        is_default: false,
        postcode: i.postcode ? i.postcode.toString() : '',
        note: i.note || ''
    }
}

export function convertBillingAddressFragmentToCheckoutBillingAddressInput(a: BillingAddressFragment): CartBillingAddressInput {
    const { is_default, bank_transfer_enabled, __typename, is_goodid, ...addressInput } = a
    return addressInput
}

export function getDeliveryDisplayValue(type: 'delivery' | 'personal', zip: string, city: string) {
    switch (type) {
        case 'delivery':
          return `${zip} ${city}`
        case 'personal':
          return 'Személyes átvétel (1186 Budapest)'
      }
}

export function convertShippingAddressToShippingAddressForm(a: UserFragment_shipping_addresses): ShippingAddressFragment {
    return {
        ...a
    }
}

export function convertBillingAddressFragmentToBillingAddressInput(a: BillingAddressFragment): UserBillingAddressInput {
    const { id, __typename, bank_transfer_enabled, is_goodid, ...addressInput } = a
    return addressInput
}

export function convertCartShippingAddressToCheckoutShippingAddressInput(a: CartFragment_shipping_address): CartShippingAddressInput {
    const { __typename, ...obj } = {
        ...a,
        postcode: Number(a.postcode)
    }

    return obj
}

export function convertCartShippingAddressToShippingAddressInput(a: CartFragment_shipping_address, is_default = false): UserShippingAddressInput {
    const { __typename, ...obj } = {
        ...a,
        is_default: !!is_default
    }

    return obj
}

export function convertCartBillingAddressToCheckoutBillingAddressInput(a: CartFragment_billing_address): CartBillingAddressInput {
    const { __typename, ...obj } = {
        ...a
    }

    return obj
}

export function convertShippingAddressToBillingAddress(a: ShippingAddressFragment): BillingAddressFragment {
    return {
        __typename: 'UserBillingAddress',
        id: NEW_ADDRESS_ID,
        bank_transfer_enabled: false,
        city: a.city,
        house_number: a.house_number,
        is_company: false,
        is_default: false,
        is_goodid: false,
        name: a.name,
        postcode: a.postcode,
        street: a.street,
        vat_number: null
    }
}

export function convertCartShippingAddressInputToUserShippingAddressInput(a: CartShippingAddressInput, is_default = false): UserShippingAddressInput {
    return {
        ...a,
        city: a.city || '',
        street: a.street || '',
        house_number: a.house_number || '',
        note: a.note || '',
        postcode: (a.postcode || 0).toString(),
        is_default
    }
}

export function convertCartBillingAddressInputToUserBillingAddressInput(a: CartBillingAddressInput, is_default = false): UserBillingAddressInput {
    const { id, ...address } = a
    return {
        ...address,
        is_default
    }
}

export function convertCartBillingAddressToUserBillingAddressInput(a: CartFragment_billing_address, is_default = false): UserBillingAddressInput {
    return {
        ...a,
        is_default
    }
}

export function postaPontToShippingAddressInput(postPont: postPoints_postPoints_data): CartShippingAddressInput {
    return {
      city: postPont.city,
      name: postPont.name,
      house_number: postPont.house_number || '',
      street: `${postPont.street_name} ${postPont.street_type || ''}`,
      postcode: postPont.postcode,
      telephone: '36300000000'
    }
  }


export function convertShippingAddressInputToBillingAddressInput(a: CartShippingAddressInput): CartBillingAddressInput {
    const { telephone, note, ...addressInput } = {
        ...a,
        is_company: false,
        postcode: a.postcode ? a.postcode.toString() : '',
        city: a.city || '',
        street: a.street || '',
        house_number: a.house_number || ''
    }

    return addressInput
}

export function convertBillingAddressToBillingAddressForm(a: UserFragment_billing_addresses): BillingAddressFragment {
    return { ...a }
}

export function convertBillingAddressInputToBillingAddressFragment(a: CartBillingAddressInput): BillingAddressFragment {
    return {
        ...a,
        __typename: 'UserBillingAddress',
        is_default: false,
        is_goodid: false,
        bank_transfer_enabled: false,
        id: a.id || NEW_ADDRESS_ID,
        vat_number: a.vat_number || null
    }
}

type DisplayAddress = Partial<Pick<ShippingAddressFragment | BillingAddressFragment | CartShippingAddressInput, 'postcode' | 'city' | 'street' | 'house_number'>>

export function convertOrderAddressToDisplayAddress(data: Pick<OrderFragment, 'shipping_city' | 'shipping_postcode' | 'shipping_street' | 'shipping_house_number'>): DisplayAddress {
    return {
        city: data.shipping_city,
        house_number: data.shipping_house_number,
        postcode: data.shipping_postcode,
        street: data.shipping_street
    }
}

export function formatAddress(address: DisplayAddress) {
    return `${address.postcode || ''} ${address.city || ''}, ${address.street || ''} ${address.house_number || ''}`
}

export function formatAddressWithName(address: Pick<BillingAddressFragment | ShippingAddressFragment, 'name' | 'postcode' | 'city' | 'street' | 'house_number'>) {
    return address.name
        ? `${address.name} - ${address.postcode || ''} ${address.city || ''}, ${address.street || ''} ${address.house_number || ''}`
        : `${address.postcode || ''} ${address.city || ''}, ${address.street || ''} ${address.house_number || ''}`
}

export function formatTimetableSlot(slot: OrderFragment_timetable_time_slot): string {
    return `${format(slot.range_date, 'YYYY. MM. DD.')} ${slot.timeRange.time_from} — ${slot.timeRange.time_to}`
}

export function formatOrderOneLine(order: CartFragment_linked_order) {
    let formatted = order.shipping_method.id === ShippingMethodId.GPOINT
        ? `${order.id}. számú megrendelés - Személyes átvétel (Megrendelés dátuma: ${order.created_at})`
        : `${order.id}. számú megrendelés - ${formatAddress(convertOrderAddressToDisplayAddress(order))}`

    if (order.timetable_time_slot) {
        formatted += `, ${formatTimetableSlot(order.timetable_time_slot)}`
    }
    return formatted
}

export function formatPostPointAddress(postaPont: postPoints_postPoints_data) {
    return formatAddress({ city: postaPont.city, street: `${postaPont.street_name || ''} ${postaPont.street_type || ''}`, house_number: postaPont.house_number || '', postcode: postaPont.postcode.toString() })
}

export function processPostcodes(postcodes: postcodes_postcodes[]): { cities: string[], streets: string[] } {
    const huCmp = new Intl.Collator('hu').compare

    const cities = uniq(postcodes.map(pc => pc.city)).sort(huCmp)
    const streets = uniq(postcodes.map(pc => pc.street || '').filter(street => street !== '')).sort(huCmp)

    return { cities, streets }
}


export async function createShippingAddress(addressInput: UserShippingAddressInput, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<createMyUserShippingAddress_createMyUserShippingAddress> {
    const result = await apolloClient.mutate<createMyUserShippingAddress, createMyUserShippingAddressVariables>({
        mutation: createMyUserShippingAddressMutation,
        variables: {
            addressInput
        }
    })
    if (result.data) {
        return result.data.createMyUserShippingAddress
    } else {
        // console.log('debug', result)
        throw 'Unexpected error'
    }
}

export async function updateShippingAddress(addressInput: UserShippingAddressInput, id: string, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<updateMyUserShippingAddress_updateMyUserShippingAddress> {
    const result = await apolloClient.mutate<updateMyUserShippingAddress, updateMyUserShippingAddressVariables>({
        mutation: updateMyUserShippingAddressMutation,
        variables: {
            id: id,
            addressInput
        }
    })

    if (result.data) {
        return result.data.updateMyUserShippingAddress
    } else {
        // console.log('debug', result)
        throw 'Unexpected error'
    }
}


export async function createOrUpdateShippingAddress(address: ShippingAddressFragment, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<createMyUserShippingAddress_createMyUserShippingAddress | updateMyUserShippingAddress_updateMyUserShippingAddress> {
    const addressInput = convertShippingAddressFragmentToShippingAddressInput(address)

    if (address.id !== NEW_ADDRESS_ID) {
        return await updateShippingAddress(addressInput, address.id, apolloClient)
    } else {
        return await createShippingAddress(addressInput, apolloClient)
    }
}

export async function createBillingAddress(addressInput: UserBillingAddressInput, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<createMyUserBillingAddress_createMyUserBillingAddress> {
    const result = await apolloClient.mutate<createMyUserBillingAddress, createMyUserBillingAddressVariables>({
        mutation: createMyUserBillingAddressMutation,
        variables: {
            addressInput
        }
    })
    if (result.data) {
        return result.data.createMyUserBillingAddress
    } else {
        // console.log(result)
        throw 'Unexpected error'
    }
}

export async function createOrUpdateBillingAddress(address: BillingAddressFragment, apolloClient: ApolloClient<NormalizedCacheObject>): Promise<createMyUserBillingAddress_createMyUserBillingAddress | updateMyUserBillingAddress_updateMyUserBillingAddress> {
    const addressInput = convertBillingAddressFragmentToBillingAddressInput(address)

    if (address.id !== NEW_ADDRESS_ID) {
        const result = await apolloClient.mutate<updateMyUserBillingAddress, updateMyUserBillingAddressVariables>({
            mutation: updateMyUserBillingAddressMutation,
            variables: {
                id: address.id,
                addressInput
            }
        })
        if (result.data) {
            return result.data.updateMyUserBillingAddress
        } else {
            // console.log(result)
            throw 'Unexpected error'
        }
    } else {
        return await createBillingAddress(addressInput, apolloClient)
    }
}

export function validateBillingAddress(address: Pick<Partial<BillingAddressFragment>, 'city' | 'house_number' | 'is_company' | 'name' | 'postcode' | 'street' | 'vat_number'>) {
    return validateForm(
        address,
        ['city', 'house_number', 'name', 'postcode', 'street', ...(address.is_company ? ['vat_number' as 'vat_number'] : [])]
    )
}

export function isCartShippingAddressEmpty(address: CartShippingAddressInput): boolean {
    return !address.name && !address.city && !address.street && !address.postcode && !address.telephone
}

export function findUserAddressByPostcode(shipping_addresses: me_me_shipping_addresses[], zip: string | null): me_me_shipping_addresses | null {
    const matchingAddresses = shipping_addresses.filter(address => address.postcode === zip)
    if (matchingAddresses.length) {
        let foundAddress = matchingAddresses[0]
        const matchingAndDefault = matchingAddresses.find(address => address.is_default)
        if (matchingAndDefault) {
            foundAddress = matchingAndDefault
        }
        return foundAddress
    }
    
    return null
}

export function getDeliveryAddressUpdateFromUserAddress(address: me_me_shipping_addresses): SetDeliveryAddressType {
    return {
        selected: { type: 'delivery', deliveryType: 'savedAddress' },
        value: { zip: address.postcode, city: address.city, addressId: address.id }
    }
}
