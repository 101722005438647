import gql from 'graphql-tag'
import ProductAllData from '../fragments/ProductAllData'

export const productsByIdQuery = gql`
query searchProduct($page: Int!, $count: Int!, $ids: [ID!]) {
    products (page: $page, first: $count, filters: { ids: $ids, productStatus: AVAILABLE } ) {
        products {
            ...ProductAllData
        }
    }
}

${ProductAllData}
`
