import PageLink from 'Components/PageLink'

import Button from 'Components/Button'
import DropdownSelect from 'Components/Dropdown/DropdownSelect'
import InputWrapper from 'Components/InputWrapper'
import { SVGIconLink, SVGLink } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import btnCss from 'Components/Button/Button.scss'
import grbModalCss from 'Components/Checkout/DeliveryGrobyModal/DeliveryGrobyModal.scss'
import cl from './LinkingOrders.scss'
import { linkableOrders_linkableOrders, updateCart, updateCartVariables, CartFragment } from 'Utils/api/gql/types'
import { OptionType } from 'Components/Dropdown/DropdownSelect/DropdownSelect'
import { formatOrderOneLine } from 'Utils/helpers/addressHelper'
import { useState } from 'react'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'
import _app from 'Pages/_app'
import { updateCartMutation } from 'Utils/api/gql/mutations/updateCart'
import { DispatchProp, connect } from 'react-redux'
import { setCart } from 'Utils/redux/actions'
import { showMessage } from 'Utils/helpers/modalHelper'
import { parse } from 'date-fns'
import Spinner from 'Components/Spinner';
import { collectAllErrorsForModal } from 'Utils/helpers/errorHelper';

type PropType = {
  onFinished: (newCart: CartFragment | null) => void
  cart: CartFragment | null
  linkableOrders: linkableOrders_linkableOrders[]
} & DispatchProp

const LinkingOrders: React.FunctionComponent<PropType> = ({ linkableOrders, cart, dispatch, onFinished }) => {
  const items = linkableOrders
    .sort((a, b) => parse(b.created_at).getTime() - parse(a.created_at).getTime() )
    .map<OptionType<linkableOrders_linkableOrders>>(order => ({
      title: formatOrderOneLine(order),
      value: order,
      key: order.id
    }))

  const [selectedOrder, setSelectedOrder] = useState(linkableOrders[0])
  const [isFetching, setFetching] = useState(false)

  const linkOrder = async () => {
    if (!cart) return
    try {
      setFetching(true)
      const result = await _app.apolloClient.mutate<updateCart, updateCartVariables>({
        mutation: updateCartMutation,
        variables: {
          input: {
            id: cart.id,
            linked_order_id: selectedOrder.id
          }
        }
      })

      if (result.data && result.data.updateCart) {
        dispatch(setCart(result.data.updateCart))
        onFinished(result.data.updateCart)
      }

      uiEventEmitter.emit(UIEvents.CLOSE_MODAL)
    } catch (e) {
      const errorMessages = collectAllErrorsForModal(e)
      showMessage(<>Hiba történt a megrendelések összekapcsolása közben! {errorMessages}</>)
    } finally {
      setFetching(false)
    }
  }

  return (
    <div className={cn(grbModalCss.deliveryGroby__modal, cl.linkingOrders)}>
      {isFetching && <Spinner overlay />}
      <div className={cn(grbModalCss.deliveryGroby__modalIllustrationWrapper)} aria-hidden={true}>
        <SVGLink width={80} height={80} />
      </div>

      <h1 className={grbModalCss.h1}>
        Rendszerünk szerint már van folyamatban lévő megrendelésed nálunk.
      </h1>

      {linkableOrders.length > 1
        ? <InputWrapper
          name="selectedOrder"
          label="Választott megrendelés:"
        >
          <DropdownSelect
            name="selectedOrder"
            value={selectedOrder}
            items={items}
            isNullable={false}
            editingDisabled
            onChange={(_, value) => { setSelectedOrder(value) }}
          />
        </InputWrapper>
        : <dl>
          <dt>
            Szállítási cím és időpont:
          </dt>
          <dd>
            {items[0].title}
          </dd>
        </dl>
      }

      <p className={grbModalCss.lead}>
        Mostani rendelésedet hozzákapcsolhatod az előzőhöz, ebben az esetben a már megadott címre és időpontban kapod meg ezt a rendelést is.
      </p>

      <p className={grbModalCss.lead}>
        <strong>
          Ezzel időt és szállítási költséget spórolsz magadnak!
        </strong>
      </p>

      <div className={cn(grbModalCss.deliveryGroby__buttonContainer, cl.linkingOrders__buttonContainer)}>
        <Button
          label="Összekapcsolom"
          onClick={linkOrder}
          className={cn(btnCss.expanded, grbModalCss.expanded)}
        />
        <Button
          label="Köszönöm, nem"
          onClick={() => { uiEventEmitter.emit(UIEvents.CLOSE_MODAL); onFinished(null) }}
          className={cn(btnCss.hollow, btnCss.expanded, grbModalCss.expanded)}
        />
      </div>

      <div>
        <PageLink toPage="/cikk/megrendeles-osszekapcsolas" target="_blank" className={cn(cg.linkWithIcon, cl.linkWithIcon)}>
          További információk a rendelések összekapcsolásáról

          <span className={cg.linkWithIcon__iconContainer}>
            <SVGIconLink width={18} height={18} />
          </span>
        </PageLink>
      </div>
    </div>
  )
}

export default connect(({ cart, checkoutInfo: { linkableOrders } }: Redux.IReduxState) => ({
  cart,
  linkableOrders
}))(LinkingOrders)
