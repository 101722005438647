import gql from 'graphql-tag'

export const PostPointFragment = gql`
fragment PostPointFragment on PostPoint {
    id
    name
    ext_id
    postcode
    city
    street_name
    street_type
    house_number
    latitude
    longitude
    service_point_type
    working_hours
}
`
