import React from 'react'
import PageLink from 'Components/PageLink'

type PropType = {
  children: any,
  className?: string,
  href: string,
  title?: string,
}

class ExternalLink extends React.PureComponent<PropType> {
  render () {
    const { children, href, ...other } = this.props
    return (
      <PageLink target="_blank" rel="noopener noreferrer" toPage={href} {...other} >
        {children}
      </PageLink>
    )
  }
}

export default ExternalLink
