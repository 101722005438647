import React from 'react'

import cn from 'classnames'

import cg from 'Scss/app.scss'
import cl from './MenuButton.scss'

type MenuButtonProps = {
  onClick?: () => void
  isOpenedMenu: boolean
}

const MenuButton: React.SFC<MenuButtonProps> = ({ isOpenedMenu, ...otherProps }) => {
  const classNames = `${cl.menuButton} ${isOpenedMenu === true ? cl.opened : ''}`.trim()
  return (
    <div className={classNames} {...otherProps}>
      <span />
      <span />
      <span />
      <span className={cn(cg.showForLarge)}>TERMÉKEK</span>
    </div>
  )
}

export default MenuButton
