import { createStore, applyMiddleware, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { grobySaga } from './sagas'
import { noError } from 'Utils/helpers/errorHelper'
import { getCheckoutInfo_shippingMethods, getCheckoutInfo_paymentMethods } from 'Utils/api/gql/types'
import { emptyShippingAddress, convertShippingAddressFragmentToCheckoutShippingAddressInput, convertBillingAddressFragmentToCheckoutBillingAddressInput, emptyBillingAddress } from 'Utils/helpers/addressHelper';

export const sessionDefaults: Redux.IStoreSessionData = {
    token: '',
    lastUpdated: 0,
    isFetching: false,
    loggedIn: false,
    loginErrors: noError(),
    registrationErrors: noError()
}

export const defaultOrderData: Redux.IOrderData = {
    __hydrated: false,
    contact_person: {
        contact_email: '',
        contact_name: '',
        contact_telephone: ''
    },
    shipping_address_id: null,
    shipping_address: convertShippingAddressFragmentToCheckoutShippingAddressInput(emptyShippingAddress()),
    billing_address: convertBillingAddressFragmentToCheckoutBillingAddressInput(emptyBillingAddress({ id: '' })),
    selected_method: null,
    payment_method: null,
    selected_postapont: null,
    timeslot_id: null,
    lyoness_card_number: '',
    save_billing_address: true,
    save_shipping_address: true
}

const defaultShoppingListsState: Redux.IShoppingListState = {
  isFetched: false,
  isFetching: false,
  lists: []
}

const initialStateValues: Redux.IReduxState = {
    session: sessionDefaults,
    productCategories: [],
    promotionCategories: [],
    blogEntries: [],
    footerBlocks: [],
    scheduledData: {
        productAttributes: [],
        productCounts: { mpl: null, all: null },
        banners: [],
        blogEntries: [],
        categories: [],
        footerBlocks: [],
        promotionCategories: [],
        openMapData: {
            Hungary: {},
            GRobyDeliveryArea: {}
        },
        urlAliases: []
    },
    cart: null,
    cartStatus: {
        isFetching: false
    },
    isMobile: false,
    isTablet: false,
    isOpenedCartPanel: true,
    cartPanelCookie: '',
    checkoutInfo: {
        shippingMethods: [],
        paymentMethods: [],
        linkableOrders: []
    },
    orderData: defaultOrderData,
    orderedProductIds: null,
    shoppingLists: defaultShoppingListsState,
    redirectLockCount: 0,
    redirecting: false
}

function isShippingMethod(x: getCheckoutInfo_shippingMethods | null): x is getCheckoutInfo_shippingMethods {
    return x != null
}

function isPaymentMethod(x: getCheckoutInfo_paymentMethods | null): x is getCheckoutInfo_paymentMethods {
    return x != null
}

function getCartPanelOpened(cookieValue: string, defaultValue: boolean): boolean {
    if (cookieValue === 'false') return false
    if (cookieValue === 'true') return true
    return defaultValue
}

const reducer = (state: Redux.IReduxState = initialStateValues, action: Redux.IAction): Redux.IReduxState => {
    switch (action.type) {
        case 'REGISTRATION_STARTED':
        case 'LOGIN_STARTED':
            return { ...state, session: { ...sessionDefaults, isFetching: true } }
        case 'REGISTRATION_SUCCEEDED':
            return { ...state, session: { ...state.session, isFetching: false } }
        case 'UPDATE_SESSION':
            return { ...state, session: { ...sessionDefaults, ...state.session, loggedIn: true, isFetching: false, ...action.payload } }
        case 'TOKEN_LOGIN':
            return { ...state, session: action.payload.customerData
                ? { ...sessionDefaults, loggedIn: true, token: action.payload.token, customerData: action.payload.customerData}
                : { ...sessionDefaults, token: action.payload.token}
            }
        case 'LOGIN_ERROR':
            return { ...state, session: { ...sessionDefaults, loginErrors: action.payload } }
        case 'REGISTRATION_ERROR':
            return { ...state, session: { ...sessionDefaults, registrationErrors: action.payload } }
        case 'DESTROY_SESSION_AND_COOKIE':
            return { ...state, session: { ...sessionDefaults } }
        case 'SET_CART':
            return { ...state, cart: action.payload, cartStatus: { isFetching: false }, isOpenedCartPanel: getCartPanelOpened(state.cartPanelCookie, !!(action.payload && action.payload.cart_products.length)) }
        case 'REQUEST_CART_PRODUCT_UPDATE':
            return { ...state, cartStatus: { isFetching: true, affectedProductId: action.payload.product.id } }
        case 'REQUEST_CART_DELETE_ALL_ITEMS':
            return { ...state, cartStatus: { isFetching: true } }
        case 'SET_DELIVERY_ADDRESS':
            return { ...state, deliveryAddress: action.payload}
        case 'TOGGLE_CART_PANEL':
            return { ...state, isOpenedCartPanel: !state.isOpenedCartPanel, cartPanelCookie: !state.isOpenedCartPanel ? 'true' : 'false' }
        case 'REHYDRATE_ORDER':
        case 'UPDATE_ORDER':
            return { ...state, orderData: { ...state.orderData, ...action.payload } }
        case 'CART_ERROR':
            return { ...state, cartStatus: { isFetching: false } }
        case 'UPDATE_CHECKOUT_INFO':
            return { ...state, checkoutInfo: {
                ...state.checkoutInfo,
                shippingMethods: action.payload.shippingMethods.filter(isShippingMethod),
                paymentMethods: action.payload.paymentMethods.filter(isPaymentMethod)
            }}
        case 'UPDATE_LINKABLE_ORDERS':
            return { ...state, checkoutInfo: { ...state.checkoutInfo, linkableOrders: action.payload }}
        case 'ACQUIRE_REDIRECT_LOCK':
            return { ...state, redirectLockCount: state.redirectLockCount + 1}
        case 'RELEASE_REDIRECT_LOCK':
            return { ...state, redirectLockCount: state.redirectLockCount > 0 ? state.redirectLockCount - 1 : 0 }
        case 'CREATE_ORDER':
            return { ...state, cartStatus: { isFetching: true } }
        case 'BLOCK_REDIRECTS':
            return { ...state, redirecting: true }
        case 'UNBLOCK_REDIRECTS':
            return { ...state, redirecting: false }
        case 'SET_ORDERED_PRODUCT_IDS':
            return { ...state, orderedProductIds: action.payload }
        case 'SET_SHOPPINGLISTS_FETCHING': {
            return { ...state, shoppingLists: { ...state.shoppingLists, isFetching: action.payload }}
        }
        case 'SET_SHOPPINGLISTS': {
            return { ...state, shoppingLists: { ...state.shoppingLists, isFetched: true, isFetching: false, lists: action.payload }}
        }
        default:
            return state
    }
};

export function initializeStore (initialState: Redux.IReduxState = initialStateValues): Store<Redux.IReduxState> {
    const sagaMiddleware = createSagaMiddleware()

    const store = createStore(
      reducer,
      initialState,
      process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(sagaMiddleware)) : applyMiddleware(sagaMiddleware)
    )

    sagaMiddleware.run(grobySaga)

    return store
  }
