import gql from 'graphql-tag'
import CategoryDataFragment from '../fragments/CategoryDataFragment'

export default gql`
query allProductCategories {
    productCategories {
        ...CategoryDataFragment
        children {
            ...CategoryDataFragment
            children {
                ...CategoryDataFragment
                children {
                    ...CategoryDataFragment
                }
            }
        }
    }
}
${CategoryDataFragment}
`
