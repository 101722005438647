import gql from 'graphql-tag'
import ProductAttributeFragment from './ProductAttributeFragment';

/*
    id

# Régi webshop termék id
    previous_id
    name

# Termék státusza/elérhetősége
    status

# Új url
    url_key

# Régi url
    smarturl

    default_category_id
    price

# Az egységár egysége
    price_unit

# Az egységár szorzója
    price_per_unit

# Egységár
    unit_price

# Akciós egységár
    special_unit_price

# ÁFA
    vat

# Akciós ár, bruttó
    special_price

# Az akciós ár ettől a dátumtól érvényes
    special_from_date

# Az akciós ár eeddig a dátumig érvényes
    special_to_date

# Ettől a dátumtól "új" a termék
    news_from_date

# Eddig a dátumig "új" a termék
    news_to_date

    personal_discount_price
    personal_discount_unit_price

# Alternatív mennyiség neve
    alternative_quantity_name

# Alternatív mennyiség legkisebb választható egsége
    alternative_quantity_minimum

# Alternatív mennyiség léptetési egysége
    alternative_quantity_scale

# Eredeti/alternatív egység átváltási arány
    alternative_quantity_exchange_rate

# Alapértelmezett mennyiség neve
    default_quantity_name

# Alapértelmezett mennyiség legkisebb választható egysége
    default_quantity_minimum

# Alapértelmezett mennyiség léptetési egysége
    default_quantity_scale

# Ajándékkártyával rendelhető
    gift_message_available

# Csomagfutárral (MPL) szállítható
    is_mpl

# Minimum szavatosság
    min_expiry_days

# A termék tényleges tömege
    package_official_g

# A termék tényleges űrtartalma
    package_official_ml

# Szeletelve rendelhető
    sliceable

    empties {
        id
        name
        price
        price_unit
        price_per_unit
        unit_price
        special_unit_price
        special_price
        special_from_date
        special_to_date
        personal_discount_price
        personal_discount_unit_price
        news_to_date
        news_to_date
        vat
        alternative_quantity_name
        alternative_quantity_minimum
        alternative_quantity_exchange_rate
        alternative_quantity_scale
        default_quantity_name
        default_quantity_minimum
        default_quantity_scale

        package_official_g
        package_official_ml
        sliceable
        weight

        images {
            conversions {
                thumbnail
                list
                full
            }
        }
    }

    attributes {
        ...ProductAttributeFragment
    }

    images {
        conversions {
            thumbnail
            list
            full
        }
    }
# Tömeg grammban
    weight

 #   created_at
 #   updated_at
 */

export default gql`
fragment ProductListData on Product {
    id
    ext_id
    previous_id
    name
    status
    url_key
    smarturl
    default_category_id
    price
    price_unit
    price_per_unit
    unit_price
    special_unit_price
    vat
    special_price
    special_from_date
    special_to_date
    news_from_date
    news_to_date
    personal_discount_price
    personal_discount_unit_price
    alternative_quantity_name
    alternative_quantity_minimum
    alternative_quantity_scale
    alternative_quantity_exchange_rate
    default_quantity_name
    default_quantity_minimum
    default_quantity_scale
    gift_message_available
    is_mpl
    min_expiry_days
    package_official_g
    package_official_ml
    sliceable

    empties {
        id
        name
        price
        price_unit
        price_per_unit
        unit_price
        special_unit_price
        special_price
        special_from_date
        special_to_date
        personal_discount_price
        personal_discount_unit_price
        news_to_date
        news_to_date
        vat
        alternative_quantity_name
        alternative_quantity_minimum
        alternative_quantity_exchange_rate
        alternative_quantity_scale
        default_quantity_name
        default_quantity_minimum
        default_quantity_scale

        package_official_g
        package_official_ml
        sliceable
        weight

        images {
            conversions {
                thumbnail
                list
                full
            }
        }
    }

    images {
        conversions {
            thumbnail
            list
            full
        }
    }

    attributes {
        ...ProductAttributeFragment
    }

    weight
}
${ProductAttributeFragment}`
