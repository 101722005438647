import React, { ReactElement } from 'react'
import SVGCollection, { SVGProdAlapvetoelelmiszer } from 'Components/SVGCollection'
import { allProductCategories_productCategories } from '../api/gql/types';
import categoryIcons from '../data/categoryImages'

export const getCategoryIcon = (category: allProductCategories_productCategories) => {
    const image = (categoryIcons.find(iconEntry => iconEntry.id === Number(category.id)) || { image: '' }).image
    const Element = SVGCollection[`SVG${image}`] || SVGProdAlapvetoelelmiszer
    return <Element />
}
