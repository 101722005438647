import * as React from 'react'
import { SVGUser, SVGArrow, SVGLogin } from 'Components/SVGCollection'
import cn from 'classnames'
import cl from './ProfileButton.scss'
import cg from 'Scss/app.scss'
import PageLink from 'Components/PageLink';
import {menuConfigs, MenuItem} from "Components/Profile/ProfileMenu";
import { connect, DispatchProp } from 'react-redux';
import { logout } from 'Utils/redux/actions';
import { UserFragment } from 'Utils/api/gql/types';

type PropType = {
  customerData?: UserFragment,
  className?: string,
  onClick: () => void
} & DispatchProp

const ProfileButton: React.SFC<PropType> = (props: PropType) => {
  const { customerData, onClick, dispatch, className } = props
  return (
    <div className={`${cl.headProfileSection} ${className}`}>

      {customerData ? (
        <div className={cn(cl.headProfileMenu)}>
          <div className={cn(cl.headProfileMenuContent)}>
            <ul>
              {Object.keys(menuConfigs).map(page => {
                const config = menuConfigs[page as MenuItem]
                return (
                <li key={config.slug}>
                  <PageLink toPage={`/profil/${config.slug}`}>
                    <span dangerouslySetInnerHTML={{ __html: config.title }} />
                  </PageLink>
                </li>)})}
              <li key="logout">
                <a onClick={() => dispatch(logout())}>Kijelentkezés</a>
              </li>
            </ul>
          </div>
        </div>
      ) : (null)}

      <a className={cn(cl.profileButton)} onClick={onClick}>
        <span className={cn(cg.gridX, cg.alignMiddle)} aria-hidden="true">
          <span className={cn(cg.cell, cg.shrink)} style={{ color: '#00A3E0' }}>
            {customerData ? <SVGUser width={25} height={25} /> : <SVGLogin width={20} height={20} style={{marginRight: '10px'}} />}
          </span>
          <span className={cn(cg.cell, cg.shrink, cl.profileName)}>{customerData ? `${customerData.name}` : 'Belépés / Regisztráció'}</span>
          <span className={cn(cg.cell, cg.shrink)} style={{ color: '#00A3E0' }}><SVGArrow width={16} height={16} /></span>
        </span>
      </a>

    </div>
  )
}

export default connect()(ProfileButton)
