import { GraphQLError } from "graphql"

export interface IServerErrorData {
    message: string,
    parameters?: {
        fieldName: string,
        fieldValue: string | number
    },
    errors?: Array<IServerErrorData>
}

interface ValidationField {
    [key: string]: ValidationField | string[]
}

interface GRobyGraphQLError extends GraphQLError {
    extensions: {
        validation?: ValidationField
    }
}

export function hasFieldErrors(errors: Redux.IFormErrors): boolean {
    return Object.values(errors.fieldErrors).some(e => typeof e === 'string')
}

export function hasErrors(errors: Redux.IFormErrors): boolean {
    return !!errors.general || Object.values(errors.fieldErrors).some(e => typeof e === 'string')
}

export function validateForm<T extends Redux.FormJSON = Redux.FormJSON>(obj: T, mandatoryFields: Array<keyof T>): Redux.IFormErrors<T> {
  const errors: Redux.IFormErrors<T> = noError()

  for (let field of mandatoryFields) {
    const value = obj[field]
    if (typeof value === 'string' && !value.length) {
      errors.fieldErrors[field] = 'Kötelező mező!'
    }
  }

  return errors
}

// Recursively finds values for keys `keyToFind` in `obj`, returning an array with the found values for the keys
function searchKey(obj: any, keyToFind: string): any[] {
    if (typeof obj !== 'object') return []
    let results: any[] = []

    for (const key in obj) {
        if (key === keyToFind) {
            results.push(obj[key])
        }
        results = [...results, ...searchKey(obj[key], keyToFind)]
    }

    return results
}

function isFormError(e: any): e is Redux.IFormErrors {
    return typeof e === 'object' && typeof e.fieldErrors === 'object'
}

// Ha nem lennének össze-vissza a kapott error message-ek, ez a függvény se lenne ilyen okádék
// validationKey: A hiba objektum 'validation' objektumán belül keresett kulcs.
//                Általában a mutation/query neve, vagy az azon belüli visszakapott mező neve, ha több mező is jön vissza
//                (tehát pl updateAddress.address esetén 'address', de pl login esetén 'login', nem 'user')
export function parseErrors<T extends Redux.FormJSON = Redux.FormJSON>(e: { graphQLErrors: GRobyGraphQLError[] } | Redux.IFormErrors, validationKey: string | null = null): Redux.IFormErrors<T> {
    if (isFormError(e)) {
        return e
    }

    try {
        const formErrors: Redux.IFormErrors<T> = noError()
        let generalErrors: string[] = []

        e.graphQLErrors.forEach(error => {
            if (error.extensions && error.extensions.validation) {
                const allServerErrors: Array<{ [s: string]: string[] }> = validationKey
                    ? searchKey(error.extensions.validation, validationKey)
                    : Object.values(error.extensions.validation)

                    allServerErrors.forEach(serverErrors => {
                    Object.entries(serverErrors).forEach(([field, message]) => {
                        let fieldErrorMessage: string = 'Ismeretlen hiba'
                        if (typeof message === 'string') {
                            fieldErrorMessage = message
                        } else if (typeof message === 'object' && typeof message.join === 'function') {
                            fieldErrorMessage = message.join(' ')
                        }
                        formErrors.fieldErrors[field as keyof T] = fieldErrorMessage
                    })
                })
            } else if (error.message) {
                generalErrors.push(error.message)
            }
        })

        if (generalErrors.length) {
            formErrors.general = generalErrors.join(', ')
        }

        return formErrors
    } catch (e) {
        return generalError<T>('Ismeretlen hiba történt!')
    }
}

function collectErrorsFromValidation(v: ValidationField): string[] {
    let collectedErrors: string[] = []
    for (const field of Object.values(v)) {
        if (Array.isArray(field)) {
            collectedErrors = [...collectedErrors, ...field]
        } else if (typeof field === 'object') {
            collectedErrors = [...collectedErrors, ...collectErrorsFromValidation(field)]
        }
    }
    
    return collectedErrors
}

export function collectAllErrorsForModal(e: { graphQLErrors: GRobyGraphQLError[] }): React.ReactNode {
    if (typeof e !== 'object' || !Array.isArray(e.graphQLErrors)) {
        return 'Ismeretlen hiba történt!'
    }

    let allErrors: string[] = []

    for (const error of e.graphQLErrors) {
        if (!error.extensions || !error.extensions.validation) {
            allErrors.push(error.message)
        } else {
            allErrors = [...allErrors, ...collectErrorsFromValidation(error.extensions.validation)]
        }
    }
    
    return allErrors
        .filter(s => s && !s.includes('validation'))
        .map(errorText => <p>{errorText}</p>)
}

export function formatErrorsForModal<T extends Redux.FormJSON>(e: Redux.IFormErrors<T>) {
    return [e.general, ...Object.values(e.fieldErrors)]
        .filter(s => s && !s.includes('validation'))
        .map(errorText => <p>{errorText}</p>)
}

export function generalError<T = Redux.FormJSON>(message: string): Redux.IFormErrors<T> {
    return { general: message, fieldErrors: {} }
}

export function noError<T = Redux.FormJSON>(): Redux.IFormErrors<T> {
    return { fieldErrors: {} }
}
