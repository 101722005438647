import React from 'react'
import { connect, DispatchProp } from 'react-redux'

import MenuButton from 'Components/AppBar/components/MenuButton'
import ProfileButton from 'Components/AppBar/components/ProfileButton'
import SearchInput from 'Components/AppBar/components/SearchInput'
import MegaMenu from 'Components/AppBar/components/MegaMenu'
const { Router } = require('Utils/routes')

import PageLink from 'Components/PageLink'

import { SVGGrobyLogoBlack, SVGGrobyLogoCheckout, SVGNavHome, SVGNavicon1, SVGNavicon2, SVGNavicon3, SVGBasketIcon, SVGBasketIconBlue, SVGArrowBack, SVGMagnifierGlass, SVGCalendar } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './AppBar.scss'
import gtmClient, { GtmPageType, GtmPageView } from 'Utils/gtmClient'
import { CartFragment } from 'Utils/api/gql/types'
import { numberFormatter } from 'Utils/helpers/formatters'
import AddressDisplay from 'Components/Cart/AddressDisplay'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'
import { goBack, goToLogin } from 'Utils/helpers/routerHelper'
import CartHelper from 'Utils/helpers/cartHelper';
import CheckoutHelper from 'Utils/helpers/checkoutHelper';

const SHOW_LOADING_TIMEOUT = 120

type OwnProps = {
  initialSearchTerm?: string
  pageTitle?: string
  viewType?: ViewType
  className?: string
  onBackClick?: () => void
  pageType: GtmPageType | null
  breadcrumb: string
}

export enum ViewType {
  IndexPage,
  SubPage,
  CheckoutPage,
  ProfilePage
}

type ReduxProps = {
  categories: Webshop.ICategory[]
  session: Redux.IStoreSessionData
  cart: CartFragment | null
  isMobile: boolean
  deliveryAddress: Redux.IDeliveryAddress | undefined
}

type PropType = OwnProps & ReduxProps & DispatchProp

type StateType = {
  searchTerm: string
  isOpenedMenu: boolean
  shouldRenderMenu: boolean
  searchView: boolean
  isLoading: boolean
  isMobile: boolean
  headerSticky: boolean
}

function isMobile(): boolean {
  return window.matchMedia('(max-width: 1024px)').matches
}

export class AppBar extends React.Component<PropType, StateType> {
  lastSentRoute: string | null = null
  hideTimer?: number
  loadingTimer?: number

  constructor(props: PropType){
    super(props)
    this.state = {
      searchTerm: props.initialSearchTerm || '',
      isOpenedMenu: false,
      shouldRenderMenu: false,
      searchView: false,
      isLoading: false,
      isMobile: false,
      headerSticky:  false
    }
  }

  componentDidMount () {
    if (window.loadPrefixBoxes) {
      window.loadPrefixBoxes()
    }

    gtmClient.customerData = this.props.session.customerData
    gtmClient.categories = this.props.categories


    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)

    uiEventEmitter.on(UIEvents.START_LOADING, this.startLoading)
    uiEventEmitter.on(UIEvents.END_LOADING, this.endLoading)

    Router.events.on('routeChangeStart', this.handleRouteChangeStart)
    Router.events.on('routeChangeComplete', this.handleRouteChangeEnd)
    Router.events.on('routeChangeError', this.handleRouteChangeEnd)
    Router.events.on('beforeHistoryChange', this.handleRouteChangeEnd)
    Router.events.on('hashChangeComplete', this.handleRouteChangeEnd)

    this.handleResize()
  }

  componentDidUpdate() {
    gtmClient.customerData = this.props.session.customerData
  }

  componentWillUnmount () {
    uiEventEmitter.off(UIEvents.START_LOADING, this.startLoading)
    uiEventEmitter.off(UIEvents.END_LOADING, this.endLoading)

    Router.events.off('routeChangeStart', this.handleRouteChangeStart)
    Router.events.off('routeChangeComplete', this.handleRouteChangeEnd)
    Router.events.off('routeChangeError', this.handleRouteChangeEnd)
    Router.events.off('beforeHistoryChange', this.handleRouteChangeEnd)
    Router.events.off('hashChangeComplete', this.handleRouteChangeEnd)

    window && window.removeEventListener('scroll', this.handleScroll)
    window && window.removeEventListener('resize', this.handleResize)
  }

  handleSearchTermChange = (searchTerm: string) => {
    this.setState({ searchTerm })
  }

  // sendGtmVirtualPageView = () => {
  //   gtmClient.virtualPageView(window.location.href, this.props.pageType, this.props.breadcrumb)
  // }

  onProfileButtonClick = () => {
    const { session } = this.props
    if (session.loggedIn) {
      Router.pushRoute('/profil')
    } else {
      goToLogin()
    }
  }

  startLoading = () => {
    if (!this.loadingTimer && !this.state.isLoading) {
      this.loadingTimer = window.setTimeout(() => {
        this.setState({ isLoading: true })
        this.loadingTimer = undefined
      }, SHOW_LOADING_TIMEOUT)
    }
  }

  endLoading = () => {
    if (this.loadingTimer) {
      window.clearTimeout(this.loadingTimer)
      this.loadingTimer = undefined
    }
    if (this.state.isLoading) {
      this.setState({ isLoading: false })
    }
  }

  handleResize = () => {
    const mobile = isMobile()
    if (mobile !== this.state.isMobile) {
      this.setState({ isMobile: mobile }, () => { window.loadPrefixBoxes && window.loadPrefixBoxes() })
    }
  }

  handleRouteChangeStart = (url: string) => {
    if (url !== window.location.pathname && url !== window.location.href) {
      this.startLoading()
    }
  }

  handleRouteChangeEnd = () => {
    this.endLoading()
  }

  renderMainNavigation = () => {
    const navItems = [
      { route: '/cikk/aruhazunk-mukodese', title: 'Áruházunk működése' },
      { route: '/uzletek', title: 'Üzleteink' },
    ]

    return (
      <ul className={cn(cg.gridX, cl.nav)}>
        {navItems.map((navItem, index) => (
          <li key={`nav-${index}`} className={cn(cg.cell, cg.shrink)}>
            <PageLink toPage={navItem.route}>
              {navItem.title}
            </PageLink>
          </li>
        ))}
      </ul>
    )
  }

 renderSubNavigation = () => {
    const subNavItems = [
      { route: '/', icon: <SVGNavHome width={24} height={24} />, title: 'Home' },
      { route: '/profil/bevasarlolistaim', icon: <SVGNavicon2 width={24} height={24} />, title: 'Listáim' },
      { route: '/ajanlatok', icon: <SVGCalendar width={24} height={24} />, title: 'Ajánlatok' },
      { route: '/szallitas', icon: <SVGNavicon3 width={24} height={24} />, title: 'Szállítási információk' },
    ]

    return (
      <ul className={cn(cg.gridX, cl.subNav, cg.alignCenter)}>
        {subNavItems.map((subNavItem, index) => (
          <li key={`subnav-${index}`} className={cn(cg.cell, cg.shrink)}>
            <PageLink toPage={subNavItem.route}>
              {subNavItem.icon}
              <span className={cn(cg.showForXlarge, cl.subText)}>{subNavItem.title}</span>
            </PageLink>
          </li>
        ))}
      </ul>
    )
  }

  showMenu = () => {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer)
      this.hideTimer = undefined
    }
    this.setState({ isOpenedMenu: true, shouldRenderMenu: true })
  }

  hideMenu = () => {
    this.setState({ isOpenedMenu: false })
    this.hideTimer = window.setTimeout(() => { this.setState({ shouldRenderMenu: false }); this.hideTimer = undefined }, 500)
  }

  toggleMenu = () => {
    if (this.state.isOpenedMenu) {
      this.hideMenu()
    } else {
      this.showMenu()
    }
  }

  handleScroll = () => {
    let e = Math.max(window.pageYOffset, (document.documentElement && document.documentElement.scrollTop) || 0, document.body.scrollTop)

    const OPEN_AT_PX = this.props.isMobile
      ? 100
      : 200

    const CLOSE_AT_PX = this.props.isMobile
      ? 10
      : 80

    if (e > OPEN_AT_PX && !this.state.headerSticky) {
      this.setState({ headerSticky: true }, () => { window.loadPrefixBoxes && window.loadPrefixBoxes()  })
    }

    if (e < CLOSE_AT_PX && this.state.headerSticky) {
      this.setState({ headerSticky: false }, () => { window.loadPrefixBoxes && window.loadPrefixBoxes()  })
    }
  }

  renderCart = () => {
    const { cart } = this.props
    if (!cart) {
      return null
    }
    const cartBadge = cart.cart_products.length
      ? <span className={cn(cl.counter)}>{cart.cart_products.length}</span>
      : ''
    return (
      <React.Fragment>
        <PageLink toPage="/kosar">
          <div className={cn(cl.cart)}>
            <span className={cn(cl.price)}>{numberFormatter(CartHelper.getCartTotalPrice(cart))}&nbsp;Ft</span>
            <SVGBasketIcon width={38} height={38} />
            <SVGBasketIconBlue width={38} height={38} />
            {cartBadge}
          </div>
        </PageLink>
      </React.Fragment>
    )
  }

  renderBackButton = () => {
    const {pageTitle} = this.props
    return (
      <React.Fragment>
        <div className={`${cg.cell} ${cg.auto} ${cl.linkBack}`}>
          <a onClick={this.props.onBackClick || goBack}>
            <SVGArrowBack width={32} height={32} />
          </a>
          <span>{pageTitle}</span>
        </div>
      </React.Fragment>
    )
  }

  renderHeaderTop = () => {
    const { session: { customerData } } = this.props
    return (
      <React.Fragment>
        <div className={cn(cl.headerTop)}>
          <div className={cn(cg.gridContainer, cl.gridContainer)}>
            <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
              <div className={cn(cg.cell, cl.firstColl, cl.logo)}>
                <PageLink toPage="/">
                  <span style={{ color: '#00A3E0' }}><SVGGrobyLogoBlack width={110} height={40} /></span>
                </PageLink>
              </div>
              <div className={cn(cg.cell, cg.auto)}>
              {(!this.state.headerSticky || this.state.isMobile) && <SearchInput
                  key={this.state.isMobile ? 'mobile' : 'desktop'}
                  id={this.state.isMobile ? "pf-main-seach-input-mobil" : "pf-main-seach-input"}
                  onChange={this.handleSearchTermChange}
                  searchTerm={this.state.searchTerm}
                />}
              </div>
              <div className={cn(cg.cell, cl.lastColls, cg.showForLarge)}>
                {this.renderMainNavigation()}
              </div>
              <div className={cn(cg.cell, cg.shrink, cl.userCol, cg.textRight, cg.showForLarge)}>
                <ProfileButton className={cl.profileBtn} customerData={customerData} onClick={this.onProfileButtonClick} />
              </div>
              <div className={cn(cg.cell, cg.shrink, cg.textRight, cg.hideForLarge)}>
                {this.renderCart()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderHeaderBottom = () => {
    const { isOpenedMenu } = this.state
    const viewType = this.props.viewType
    const { isMobile } = this.props
    return (
      <React.Fragment>

        <div className={`${cg.cell} ${cg.largeOrder2} ${this.state.headerSticky && !isMobile? cg.shrink : cg.auto} ${viewType === ViewType.CheckoutPage  ? cl.hideSticky : ''}`}>
          {this.renderSubNavigation()}
        </div>

        <div className={`${cg.cell} ${cg.showForLarge} ${cg.largeOrder3} ${this.state.headerSticky ? cg.auto : cg.shrink}`}>

          <AddressDisplay view='AppBar' />

          <div className={`${cl.seconderSearch} ${viewType === ViewType.CheckoutPage ? cl.hideSticky : ''}`}>
            <SearchInput
              id="pf-main-seach-input-sticky"
              onChange={this.handleSearchTermChange}
              searchTerm={this.state.searchTerm}
            />
          </div>

        </div>
        <div className={cn(cg.cell, cl.lastColls, cg.textRight, cg.showForLarge, cg.largeOrder4)}>
          {this.renderCart()}
        </div>
        <div className={`${cg.cell} ${cl.firstColl} ${cg.largeOrder1} ${viewType === ViewType.CheckoutPage ? cl.hideSticky : ''}`}>
          <MenuButton isOpenedMenu={isOpenedMenu} onClick={this.toggleMenu} />
        </div>
      </React.Fragment>
    )
  }

  renderForIndexPage = () => {
    const { isOpenedMenu, shouldRenderMenu } = this.state
    const { className } = this.props
    return (
      <React.Fragment>
        <div className={`${cl.appBar} ${cl.home} ${this.state.headerSticky ? cl.sticky : ""} ${className ? className : ""}`}>

          {this.renderHeaderTop()}

          <div className={cn(cl.headerBottom)}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                {this.renderHeaderBottom()}

              </div>
            </div>
          </div>
          <div className={`${cl.menuHolder} ${isOpenedMenu === true ? cl.opened : ''}`}>

            {shouldRenderMenu && <><div className={cn(cl.overlay)} onClick={this.hideMenu}></div><MegaMenu onLinkClick={this.hideMenu} /></>}

          </div>
        </div>
        <div className={cn(cl.appBarPlaceHolder)} />
      </React.Fragment>
    )
  }
  renderForSubPage = () => {
    const { isOpenedMenu, shouldRenderMenu, searchView } = this.state
    const { isMobile, className } = this.props
    return (
      <React.Fragment>

        <div className={`${cl.appBar} ${this.state.headerSticky ? cl.sticky : ""} ${isMobile ? cl.mobileActPage : ""} ${className ? className : ""}`}>

          {this.renderHeaderTop()}


          <div className={`${cl.mobileNavBar} ${cg.hideForLarge} ${searchView ? cl.opened : ""}`}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                {this.renderBackButton()}

                <div className={`${cg.cell} ${cg.shrink}`}>
                  <div className={`${cl.seconderSearch}`}>
                    <SVGMagnifierGlass onClick={() => this.setState({searchView: !this.state.searchView})}/>
                  </div>
                </div>

                <div className={cn(cg.cell, cg.shrink, cl.lastColls, cg.textRight)}>
                  {this.renderCart()}
                </div>
              </div>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
                <div className={`${cg.cell} ${cg.auto}`}>
                {(this.state.isMobile || !this.state.headerSticky) && <SearchInput
                    key={this.state.isMobile ? 'mobile' : 'desktop'}
                    id={this.state.isMobile ? "pf-main-seach-input-mobil-sticky" : "pf-main-seach-input"}
                    onChange={this.handleSearchTermChange}
                    searchTerm={this.state.searchTerm}
                  />}
                </div>
              </div>
            </div>
          </div>


          <div className={cn(cl.headerBottom)}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                {this.renderHeaderBottom()}

              </div>
            </div>
          </div>
          <div className={`${cl.menuHolder} ${isOpenedMenu === true ? cl.opened : ''}`}>
            {shouldRenderMenu && <><div className={cn(cl.overlay)} onClick={this.hideMenu}></div><MegaMenu onLinkClick={this.hideMenu} /></>}
          </div>
        </div>
        <div className={cn(cl.appBarPlaceHolder)} />
      </React.Fragment>
    )
  }

  renderForProfilePage = () => {
    const { isOpenedMenu, shouldRenderMenu, searchView } = this.state
    const { isMobile, className } = this.props
    return (
      <React.Fragment>
        <div className={`${cl.appBar} ${cl.profile} ${this.state.headerSticky ? cl.sticky : ""} ${isMobile ? cl.mobileActPage : ""} ${className ? className : ""}`}>

          {this.renderHeaderTop()}


          <div className={`${cl.mobileNavBar} ${cg.hideForMedium} ${searchView ? cl.opened : ""}`}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                {this.renderBackButton()}

                <div className={`${cg.cell} ${cg.shrink}`}>
                  <div className={`${cl.seconderSearch}`}>
                    <SVGMagnifierGlass onClick={() => this.setState({searchView: !this.state.searchView})}/>
                  </div>
                </div>

                <div className={cn(cg.cell, cg.shrink, cl.lastColls, cg.textRight)}>
                  {this.renderCart()}
                </div>
              </div>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>
                <div className={`${cg.cell} ${cg.auto}`}>
                  {(this.state.isMobile || !this.state.headerSticky) && <SearchInput
                    key={this.state.isMobile ? 'mobile' : 'desktop'}
                    id={this.state.isMobile ? "pf-main-seach-input-mobil-sticky" : "pf-main-seach-input"}
                    onChange={this.handleSearchTermChange}
                    searchTerm={this.state.searchTerm}
                  />}
                </div>
              </div>
            </div>
          </div>


          <div className={cn(cl.headerBottom)}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                {this.renderHeaderBottom()}

              </div>
            </div>
          </div>
          <div className={`${cl.menuHolder} ${isOpenedMenu === true ? cl.opened : ''}`}>
            {shouldRenderMenu && <><div className={cn(cl.overlay)} onClick={this.hideMenu}></div><MegaMenu onLinkClick={this.hideMenu} /></>}
          </div>
        </div>
        <div className={cn(cl.appBarPlaceHolder)} />
      </React.Fragment>
    )
  }

  renderForCheckoutPage = () => {
    const { isMobile } = this.props
    return (
      <React.Fragment>
        <div className={`${cl.appBar} ${cl.checkout } `}>

          {isMobile ? (
            <div className={cn(cl.mobileNavBar, cg.hideForLarge)}>
              <div className={cn(cg.gridContainer, cl.gridContainer)}>
                <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                  <div className={cn(cg.cell, cg.auto)}>
                    <PageLink toPage="/">
                      <SVGGrobyLogoCheckout width={82} height={30} />
                    </PageLink>
                  </div>

                  <div className={cn(cg.cell, cg.shrink, cl.lastColls, cg.textRight)}>
                    {this.renderCart()}
                  </div>
                </div>
              </div>
            </div>
          ) : ('')}

          <div className={cn(cl.headerBottom)}>
            <div className={cn(cg.gridContainer, cl.gridContainer)}>
              <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

                <div className={cn(cg.cell, cg.auto)}>
                  <PageLink toPage="/">
                    <SVGGrobyLogoCheckout width={82} height={30} />
                  </PageLink>
                </div>

                <div className={cn(cg.cell, cl.lastColls, cg.textRight)}>
                  {this.renderCart()}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className={cn(cl.appBarPlaceHolder)} />
      </React.Fragment>
    )
  }

  renderForViewType = () => {
    const viewType = this.props.viewType || ViewType.IndexPage
    switch (viewType) {
      case ViewType.IndexPage: return this.renderForIndexPage()
      case ViewType.SubPage: return this.renderForSubPage()
      case ViewType.CheckoutPage: return this.renderForCheckoutPage()
      case ViewType.ProfilePage: return this.renderForProfilePage()
      default: return null
    }
  }

  render () {
    return (
      <>
        {this.props.pageType !== null && <GtmPageView
          pageType={this.props.pageType}
          breadcrumb={this.props.breadcrumb}
          deliveryAddress={this.props.deliveryAddress}
          isExpress={CheckoutHelper.isExpressCheckout(this.props.deliveryAddress, this.props.session, this.props.cart)}
        />}
        {<div className={`${cg.loadingLayer} ${this.state.isLoading ? cg.open : ''}`}>
          <span><img src="/static/groby_loader.gif" /></span>
        </div>}
        {this.renderForViewType()}
      </>
    )
  }

}

function mapToStateProps({ session, cart, isMobile, isTablet, deliveryAddress, productCategories }: Redux.IReduxState): ReduxProps  {
  return { session, cart, isMobile: isMobile || isTablet, deliveryAddress, categories: productCategories }
}

export default connect<ReduxProps, {}, OwnProps, Redux.IReduxState>(mapToStateProps)(AppBar);
