import gql from 'graphql-tag'

export default gql`
query postcodes($filters: FilterPostcodeDataInput!) {
  postcodes (filters: $filters) {
    postcode
    city
    street
  }
}
`
