import gql from 'graphql-tag'
import BillingAddressFragment from './BillingAddressFragment'
import ShippingAddressFragment from './ShippingAddressFragment'
import { SavedCreditCardFragment } from './SavedCreditCardFragment'

export default gql`
fragment UserFragment on User {
    id
    ext_id
    last_login
    email
    name
    telephone
    default_billing_address {
        ...BillingAddressFragment
    }
    default_shipping_address {
        ...ShippingAddressFragment
    }
    shipping_addresses {
        ...ShippingAddressFragment
    }
    billing_addresses {
        ...BillingAddressFragment
    }
    saved_credit_cards {
        ...SavedCreditCardFragment
    }
    good_id_diff {
        diff
    }
    disable_donations
    created_at
}
${BillingAddressFragment}
${ShippingAddressFragment}
${SavedCreditCardFragment}
`
