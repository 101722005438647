import gql from 'graphql-tag'
import CartFragment from '../fragments/CartFragment';

export const logoutMutation = gql`
mutation logout {
    logout {
        ...CartFragment
    }
}
${CartFragment}
`
