import { UserFragment, RegistrationDataInput, AuthenticationType, CartFragment, getCheckoutInfo, linkableOrders_linkableOrders, ProductListData } from 'Utils/api/gql/types'
import { getDeliveryDisplayValue } from 'Utils/helpers/addressHelper'

type ActionMap = { [s in Redux.ActionType]: s }
export const Actions: ActionMap = {
    LOGIN: 'LOGIN',
    LOGIN_STARTED: 'LOGIN_STARTED',
    LOGIN_ERROR: 'LOGIN_ERROR',
    TOKEN_LOGIN: 'TOKEN_LOGIN',
    UPDATE_SESSION: 'UPDATE_SESSION',
    LOGOUT: 'LOGOUT',
    REGISTER: 'REGISTER',
    REGISTRATION_ERROR: 'REGISTRATION_ERROR',
    REGISTRATION_STARTED: 'REGISTRATION_STARTED',
    REGISTRATION_SUCCEEDED: 'REGISTRATION_SUCCEEDED',
    SOCIAL_LOGIN: 'SOCIAL_LOGIN',
    CHECK_EMAIL: 'CHECK_EMAIL',
    SET_CART: 'SET_CART',
    REQUEST_CART_PRODUCT_UPDATE: 'REQUEST_CART_PRODUCT_UPDATE',
    REQUEST_CART_DELETE_ALL_ITEMS: 'REQUEST_CART_DELETE_ALL_ITEMS',
    REFRESH_USER: 'REFRESH_USER',
    TOGGLE_CART_PANEL: 'TOGGLE_CART_PANEL',
    UPDATE_ORDER: 'UPDATE_ORDER',
    REHYDRATE_ORDER: 'REHYDRATE_ORDER',
    SET_DELIVERY_ADDRESS: 'SET_DELIVERY_ADDRESS',
    DESTROY_SESSION_AND_COOKIE: 'DESTROY_SESSION_AND_COOKIE',
    CART_ERROR: 'CART_ERROR',
    REFRESH_CHECKOUT_INFO: 'REFRESH_CHECKOUT_INFO',
    UPDATE_CHECKOUT_INFO: 'UPDATE_CHECKOUT_INFO',
    UPDATE_LINKABLE_ORDERS: 'UPDATE_LINKABLE_ORDERS',
    CREATE_ORDER: 'CREATE_ORDER',
    PUSH_ROUTE: 'PUSH_ROUTE',
    REDIRECT: 'REDIRECT',
    ACQUIRE_REDIRECT_LOCK: 'ACQUIRE_REDIRECT_LOCK',
    RELEASE_REDIRECT_LOCK: 'RELEASE_REDIRECT_LOCK',
    GO_BACK: 'GO_BACK',
    UNBLOCK_REDIRECTS: 'UNBLOCK_REDIRECTS',
    BLOCK_REDIRECTS: 'BLOCK_REDIRECTS',
    SET_ORDERED_PRODUCT_IDS: 'SET_ORDERED_PRODUCT_IDS',
    GO_TO_CHECKOUT: 'GO_TO_CHECKOUT',
    REFRESH_SHOPPINGLISTS: 'REFRESH_SHOPPINGLISTS',
    SET_SHOPPINGLISTS_FETCHING: 'SET_SHOPPINGLISTS_FETCHING',
    SET_SHOPPINGLISTS: 'SET_SHOPPINGLISTS',
    REFRESH_CART: 'REFRESH_CART',
    CART_HANDLE_PRODUCT_WARNINGS: 'CART_HANDLE_PRODUCT_WARNINGS'
}


export const login = (email: string | undefined, password: string, authType: AuthenticationType = AuthenticationType.password, stayLoggedIn?: boolean, showRegSuccessModal = false, callback?: () => void): Redux.IAction => ({
    type: Actions.LOGIN,
    payload: { email, authType, password, stayLoggedIn },
    showRegSuccessModal,
    callback
})

export const updateSession = (customerData: UserFragment, token?: string): Redux.IAction => ({
    type: Actions.UPDATE_SESSION,
    payload: token
        ? { customerData, token, lastUpdated: Date.now() }
        : { customerData, lastUpdated: Date.now() }
})

export const destroySessionAndCookie = (): Redux.IAction => ({
    type: Actions.DESTROY_SESSION_AND_COOKIE
})

export const cartError = (error: Error | string | JSX.Element): Redux.IAction => ({
    type: Actions.CART_ERROR,
    payload: error
})

export const refreshUser = (callback?: () => void, skipCheckoutInfo?: boolean): Redux.IAction => ({
    type: Actions.REFRESH_USER,
    skipCheckoutInfo,
    callback
})

export const refreshCart = (): Redux.IAction => ({
    type: Actions.REFRESH_CART
})

export const loginStarted = (): Redux.IAction => ({
    type: Actions.LOGIN_STARTED
})

export const registrationStarted = (): Redux.IAction => ({
    type: Actions.REGISTRATION_STARTED
})

export const registrationSucceeded = (): Redux.IAction => ({
    type: Actions.REGISTRATION_SUCCEEDED
})

export const tokenLogin = (token: string, customerData?: UserFragment): Redux.IAction => ({
    type: Actions.TOKEN_LOGIN,
    payload: { token, customerData }
})

export const toggleCart = (): Redux.IAction => ({
    type: Actions.TOGGLE_CART_PANEL
})

export const loginError = (e: Redux.IFormErrors<Redux.LoginForm>): Redux.IAction => ({
    type: Actions.LOGIN_ERROR,
    payload: e
})

export const registrationError = (e: Redux.IFormErrors<Redux.RegistrationForm>): Redux.IAction => ({
    type: Actions.REGISTRATION_ERROR,
    payload: e
})

export const logout = (callback?: () => void): Redux.IAction => ({
    type: Actions.LOGOUT,
    callback
})

export const register = (registrationInput: RegistrationDataInput, password: string, callback?: () => void): Redux.IAction => ({
    type: Actions.REGISTER,
    payload: registrationInput,
    callback
})

export const socialLogin = (loginData: Redux.ISocialLoginData): Redux.IAction => ({
    type: Actions.SOCIAL_LOGIN,
    payload: loginData
})

export const checkEmail = (email: string): Redux.IAction => ({
    type: Actions.CHECK_EMAIL,
    payload: email
})

export const setCart = (cartData: CartFragment | null, findAddressIdByPostcode?: boolean): Redux.IAction => ({
    type: Actions.SET_CART,
    payload: cartData,
    findAddressIdByPostcode
})

const quantityMinimum = (input: Redux.CartSetProductInput): number =>
     (input.quantityType === 'alternative' ? (input.product.alternative_quantity_minimum || input.product.alternative_quantity_scale) : (input.product.default_quantity_minimum || input.product.default_quantity_scale)) || 1


export const cartRequestProductUpdate = (input: Redux.CartSetProductInput): Redux.IAction => ({
    type: Actions.REQUEST_CART_PRODUCT_UPDATE,
    payload: {
        product: input.product,
        quantityType: input.quantityType || 'default',
        newQuantity: typeof input.newQuantity === 'number'
            ? input.newQuantity === 0 ? 0 : Math.max(input.newQuantity, quantityMinimum(input))
            : quantityMinimum(input),
        sliced: input.sliced,
        analytics: input.analytics,
        additional_info: input.additional_info
    }
})

export const cartRequestDeleteAllItems = (): Redux.IAction => ({
    type: Actions.REQUEST_CART_DELETE_ALL_ITEMS
})

export const updateOrder = (orderData: Partial<Redux.IOrderData>, verifyOrder?: boolean): Redux.IAction => ({
    type: Actions.UPDATE_ORDER,
    payload: orderData,
    verifyOrder
})

// Run the updateOrder saga which will check if cart is over limit, or if any products are disallowed, and show the appropriate error modals
export const verifyOrder = (): Redux.IAction => updateOrder({}, true)

export const rehydrateOrder = (orderData: Partial<Redux.IOrderData>): Redux.IAction => ({
    type: Actions.REHYDRATE_ORDER,
    payload: orderData
})

export type SetDeliveryAddressType = Pick<Redux.IDeliveryAddress, Exclude<keyof Redux.IDeliveryAddress, 'displayValue'>> | undefined
export const setDeliveryAddress = (deliveryAddress: SetDeliveryAddressType, runUpdateOrderSaga = false): Redux.IAction => ({
    type: Actions.SET_DELIVERY_ADDRESS,
    payload: deliveryAddress
        ? {
            ...deliveryAddress,
            displayValue: getDeliveryDisplayValue(deliveryAddress.selected.type, deliveryAddress.value.zip || '', deliveryAddress.value.city || '')
        }
        : undefined,
    updateOrder: runUpdateOrderSaga
})

export const refreshCheckoutInfo = (): Redux.IAction => ({
    type: Actions.REFRESH_CHECKOUT_INFO
})

export const cartHandleProductErrors = (): Redux.IAction => ({
    type: Actions.CART_HANDLE_PRODUCT_WARNINGS
})

export const updateCheckoutInfo = (payload: getCheckoutInfo): Redux.IAction => ({
    type: Actions.UPDATE_CHECKOUT_INFO,
    payload
})

export const updateLinkableOrders = (payload: linkableOrders_linkableOrders[]): Redux.IAction => ({
    type: Actions.UPDATE_LINKABLE_ORDERS,
    payload
})

export const createOrder = (payload: Redux.ICreateOrderPayload): Redux.IAction => ({
    type: Actions.CREATE_ORDER,
    payload
})


export const redirectSafe = (url: string, isExternal = false): Redux.IAction => ({
    type: Actions.REDIRECT,
    payload: url,
    isExternal
})

export const pushRouteSafe = (url: string, isExternal = false): Redux.IAction => ({
    type: Actions.PUSH_ROUTE,
    payload: url,
    isExternal
})

export const goBackSafe = (): Redux.IAction => ({
    type: Actions.GO_BACK
})

export const acquireRedirectLock = (): Redux.IAction => ({
    type: Actions.ACQUIRE_REDIRECT_LOCK
})

export const releaseRedirectLock = (): Redux.IAction => ({
    type: Actions.RELEASE_REDIRECT_LOCK
})

export const blockRedirects = (): Redux.IAction => ({
    type: Actions.BLOCK_REDIRECTS
})

export const unblockRedirects = (): Redux.IAction => ({
    type: Actions.UNBLOCK_REDIRECTS
})

export const setOrderedProductIDs = (ids: Redux.OrderedProductEntry[]): Redux.IAction => ({
    type: Actions.SET_ORDERED_PRODUCT_IDS,
    payload: ids
})

export const refreshShoppingLists = (fetchProductDetailsInLists: string[] = []): Redux.IAction => ({
    type: Actions.REFRESH_SHOPPINGLISTS,
    payload: fetchProductDetailsInLists
})

export const setShoppingListsFetching = (fetching: boolean) => ({
    type: Actions.SET_SHOPPINGLISTS_FETCHING,
    payload: fetching
})

export const setShoppingLists = (lists: ShoppingList.IShoppingList[]): Redux.IAction => ({
    type: Actions.SET_SHOPPINGLISTS,
    payload: lists
})
