import gql from 'graphql-tag'

export default gql`
fragment ShippingAddressFragment on UserShippingAddress {
    id
    name
    postcode
    city
    street
    house_number
    is_default
    is_goodid
    note
    telephone
}
`
