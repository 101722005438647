const isServer = typeof window === 'undefined'

export enum LoggerSeverity {
    Fatal = "fatal",
    Error = "error",
    Warning = "warning",
    Log = "log",
    Info = "info",
    Debug = "debug",
    Critical = "critical"
}

interface LoggerOptions {
  extras?: any
  fingerPrint?: any
}

export default class SentryLogger {
  static instance: SentryLogger | null = null
  _Sentry: any = null
  
  public get Sentry() : any {
    if (isServer) { return null }
    if (this._Sentry === null) {
      // Try to init
      // @ts-ignore
      if (typeof Sentry !== 'undefined') {
        try {
          // @ts-ignore
          Sentry.init({
            dsn: "https://ea175cdef6944b57ba5e965343d4fbd1@sentry.dev.bigfish.hu/33",
          })
          // @ts-ignore
          this._Sentry = Sentry
        } catch {}
      }
    } 
    return this._Sentry
  }
  

  static getInstance() {
    if (SentryLogger.instance === null) {
      SentryLogger.instance = new SentryLogger()
    }
    return SentryLogger.instance
  }

  constructor() {
    console.log(`Initializing sentry with mode ${isServer ? 'server' : 'browser'}`)
  }

  static logException = (exception: any, level: LoggerSeverity = LoggerSeverity.Info, options: LoggerOptions = {}) => {
    const Sentry = SentryLogger.getInstance().Sentry
    if (Sentry === null) { return }
    Sentry.withScope((scope: any) => {
      options.fingerPrint && scope.setFingerprint(options.fingerPrint)
      options.extras && scope.setExtras(options.extras)
      scope.setLevel(level)
      Sentry.captureException(exception)
    })
  }

  static logMessage = (message: string, level: LoggerSeverity = LoggerSeverity.Info, options: LoggerOptions = {}) => {
    const Sentry = SentryLogger.getInstance().Sentry
    if (Sentry === null) { return }
    Sentry.withScope((scope: any) => {
      options.fingerPrint && scope.setFingerprint(options.fingerPrint)
      options.extras && scope.setExtras(options.extras)
      scope.setLevel(level)
      Sentry.captureMessage(message)
    })
  }

  logException = (exception: any, fingerPrint: any = null, extras: any = null, level: LoggerSeverity = LoggerSeverity.Debug) => {
    if (this.Sentry === null) { return }
    this.Sentry.withScope((scope: any) => {
      fingerPrint && scope.setFingerprint(fingerPrint)
      extras && scope.setExtras(extras)
      scope.setLevel(level)
      this.Sentry.captureException(exception)
    })
  }
}