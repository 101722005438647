import gql from 'graphql-tag'
import CartFragment from '../fragments/CartFragment';

export const updateCartMutation = gql`
mutation updateCart($input: CartDataInput!) {
    updateCart(input: $input) {
        ...CartFragment
    }
}
${CartFragment}
`
