import cookie from 'cookie'
import { IncomingMessage, ServerResponse } from 'http'
import { ProductOrder } from './api/gql/types';
import ListClient from "Utils/api/ListClient";

function oneYearFromNow () {
    return new Date(new Date().valueOf() + 1000*60*60*24*365)
}

export function setPlainCookie(cookie: string, res?: ServerResponse) {
    if (res) {
        const prevCookieHeader = res.getHeader('Set-Cookie')
        let prevCookies: string[] = []
        if (Array.isArray(prevCookieHeader)) {
            prevCookies = prevCookieHeader
        } else if (typeof prevCookieHeader === 'string') {
            prevCookies = [prevCookieHeader]
        }
        res.setHeader('Set-Cookie', [...prevCookies, cookie])
    }
    if (typeof window !== 'undefined') {
        window.document.cookie = cookie
    }
}

export function setCookie(cookieName: string, cookieContent: string, res?: ServerResponse, options?: cookie.CookieSerializeOptions) {
    setPlainCookie(cookie.serialize(cookieName, cookieContent, { path: '/', ...options }), res)
}

export function setSortOrderCookie(sortOrder: ProductOrder) {
    setPlainCookie(cookie.serialize('grby_sort_order', JSON.stringify(sortOrder), { path: '/' }))
}

export function getSortOrderFromCookie(req?: IncomingMessage): ProductOrder | null {
    try {
        return JSON.parse(getCookie('grby_sort_order', req))
    } catch(e) {
        return null
    }
}

export function setJSONCookie(name: string, data: any) {
    setPlainCookie(cookie.serialize(name, JSON.stringify(data), { path: '/' }))
}

export function getJSONCookie<T>(name: string, req?: IncomingMessage): T | null {
    try {
        return JSON.parse(getCookie(name, req)) as T;
    } catch(e) {
        return null
    }
}

export function setTokenCookie (token: string, stayLoggedIn?: boolean, res?: ServerResponse): void {
    let expires
    if (stayLoggedIn) {
        expires = oneYearFromNow()
    }

    setPlainCookie(cookie.serialize('grby_token', token, { path: '/', expires }), res)
    // console.log('Token cookie set', token)
}

export function setListClientToken (token: string | null): void {
  ListClient.authToken = token
}

export function deleteTokenCookie (): void {
    setTokenCookie('')
}

export function getCookie(id: string, req?: IncomingMessage): string {
    const savedCookie = req ? (req.headers.cookie as string || '') : (typeof document !== 'undefined' && document.cookie) || ''
    const cookies = cookie.parse(savedCookie)
    return cookies[id] || ''
}

export function getTokenFromCookie(req?: IncomingMessage): string {
    return getCookie('grby_token', req)
}

export function getCartOpenedCookie (req?: IncomingMessage): string {
    return getCookie('grby_cart_opened', req)
}

export function setCartOpenedCookie (opened: boolean) {
    document.cookie = cookie.serialize('grby_cart_opened', opened.toString(), { path: '/' })
}

export function setGuestCartIdCookie (token: string, res?: ServerResponse): void {
    setPlainCookie(cookie.serialize('grby_cart_id', token, { path: '/' }), res)
}

export function setDeliveryAddressCookie (deliveryAddress: Redux.IDeliveryAddress | undefined, res?: ServerResponse): void {
    const cookieValue = deliveryAddress
        ? JSON.stringify(deliveryAddress)
        : ''
    setCookie('grby_delivery_address', cookieValue, res, { path: '/' })
}

export function getDeliveryAddressCookie(req?: IncomingMessage) {
    const cookie = getCookie('grby_delivery_address', req)
    let address: Redux.IDeliveryAddress | undefined = undefined
    try {
        address = JSON.parse(cookie)
    } catch (e) { }
    return address
}

export function deleteCartIdCookie (): void {
    setGuestCartIdCookie('')
}

export function getGuestCartIdFromCookie(req?: any): string {
    const savedCookie = req
        ? (req.headers.cookie || '')
        : (typeof window !== 'undefined' && document.cookie) || ''
    const cookies = cookie.parse(savedCookie)
    return cookies['grby_cart_id'] || ''
}
