import { BreadcrumbData } from 'Components/Breadcrumb/Breadcrumb'
import { brandSlug } from './categoryDetailsHelper'
import { ProductAttributeFragment_options, products_products_aggregations_categories } from '../api/gql/types'
import { CategoryPageInfo } from 'Pages/category'

export const _findCategory = (categories: Array<Webshop.ICategory>, cb: (category: Webshop.ICategory) => boolean): Webshop.ICategory | undefined => {
  return categories.reduce<Webshop.ICategory | undefined>((foundCategory, category) => {
    if (foundCategory) return foundCategory
    if (cb(category)) return category

    return _findCategory(category.children || [], cb)
  }, undefined)
}

export const findCategory = (id: string | null | undefined, categories: Array<Webshop.ICategory>): Webshop.ICategory | undefined => {
  if (id === null || typeof id === 'undefined') {
    return
  }

  const category = _findCategory(categories, cat => cat.id === id)
  return category
}

export const findCategoryByUrl = (url: string, categories: Array<Webshop.ICategory>): Webshop.ICategory | undefined => {
  return _findCategory(categories, cat => cat.url === url)
}

export function getCategoryPath(id: string, categories: Array<Webshop.ICategory>): Array<Webshop.ICategory> {
  let currentId: string | null = id
  let path: Array<Webshop.ICategory> = []
  let category
  do {
    category = findCategory(currentId, categories)
    if (!category) {
      return path
    }
    path = [category, ...path]
    currentId = category.parent_id
  } while (currentId)

  return path
}

export const subCategoryIdsFromCategoryListItem = (category: Webshop.ICategoryListItemJSON, includeParent = true): Array<number> => {
  const IDs = includeParent ? [category.id] : []
  if (category.children != '') {
    return [...IDs, ...category.children.split(',').map(n => Number(n))]
  }

  return IDs
}

export const mainPageCrumbItem: BreadcrumbData = { title: 'Főoldal', href: '/', gtmName: 'Home' }

export const breadcrumbForCategory = (categoryId: string, allCategories: Array<Webshop.ICategory>): Array<BreadcrumbData> =>
  [
    mainPageCrumbItem,
    ...getCategoryPath(categoryId, allCategories).map(category => ({ href: category.url, title: category.name, gtmName: category.url_key }))
  ]

export const breadcrumbForSearchTerm = (searchTerm: string): Array<BreadcrumbData> =>
  [
    mainPageCrumbItem,
    { href: `/kereses/${encodeURIComponent(searchTerm)}`, title: 'Keresés', gtmName: 'Search' }
  ]

export const breadcrumbForBrandPage = (brand: ProductAttributeFragment_options): Array<BreadcrumbData> =>
  [
    mainPageCrumbItem,
    { title: `${brand.label} termékek`, href: `/brands/${brandSlug(brand)}`, gtmName: `List / Brand / ${brand.label}` }
  ]
export const getBreadcrumbForPromotionPage = (category: Webshop.ICategory): BreadcrumbData[] =>
  [
    mainPageCrumbItem,
    { title: 'Termékválogatások', href: '/termekvalogatasok', gtmName: 'List / Promotions' },
    { title: category.name, href: category.url, gtmName: category.url_key }
  ]

export const breadcrumbFromPageInfo = (pageInfo: CategoryPageInfo, productCategories: Array<Webshop.ICategory>, filters: Webshop.IProductFilters): Array<BreadcrumbData> => {
  const category = filters.category
  switch (pageInfo.type) {
    case 'category':
      return breadcrumbForCategory(pageInfo.category.id, productCategories)
    case 'promotion':
      return getBreadcrumbForPromotionPage(pageInfo.category)
    case 'search':
      const bc = breadcrumbForSearchTerm(pageInfo.searchTerm)
      return category
        ? [...bc, { title: category.name, href: bc[1].href, gtmName: category.name }]
        : bc
    case 'brand':
      return breadcrumbForBrandPage(pageInfo.brand.option)
    case 'discounted':
      return category
        ? [mainPageCrumbItem, { title: 'Ajánlatok', href: '/ajanlatok', gtmName: 'List / Discounted' }, { title: category.name, gtmName: category.name }]
        : [mainPageCrumbItem, { title: 'Ajánlatok', href: '/ajanlatok', gtmName: 'List / Discounted' }]
    case 'news':
      return category
        ? [mainPageCrumbItem, { title: 'Újdonságok', href: '/ujdonsagok', gtmName: 'List / News' }, { title: category.name, gtmName: category.name }]
        : [mainPageCrumbItem, { title: 'Újdonságok', href: '/ujdonsagok', gtmName: 'List / News' }]
    default:
      return [mainPageCrumbItem]
  }
}

export const flattenCategories = (categories: Array<Webshop.ICategory>, depth = 1): Array<Webshop.ICategory> => {
  let flattened: Array<Webshop.ICategory> = []

  categories.forEach(cat => {
    if (depth > 0) {
      flattened = [...flattened, cat, ...flattenCategories(cat.children, depth - 1)]
    } else {
      flattened.push(cat)
    }
  })

  return flattened
}

export const aggregateProductCountsInSubtreeAndFilterEmpty = (categories: Webshop.ICategory[], aggregation: products_products_aggregations_categories[]): Webshop.ICategory[] => {
  return categories
    .map(category => {
      const aggregatedChildren =
        aggregateProductCountsInSubtreeAndFilterEmpty(category.children, aggregation)

      const childrenSum = aggregatedChildren
        .reduce((sum, child) => sum + child.product_count, 0)

      const currentAggregation = aggregation.find(cat => cat.id === category.id)
      const currentCount = currentAggregation
        ? currentAggregation.product_count
        : 0

      return {
        ...category,
        children: aggregatedChildren,
        product_count: childrenSum + currentCount
      }
    })
    .filter(category => category.product_count > 0)
}
