import gql from 'graphql-tag'
import UserFragment from '../fragments/UserFragment'

export default gql`
query me {
    me {
        ...UserFragment
    }
}
${UserFragment}
`
