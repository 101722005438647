import deburr from 'lodash/deburr'
import { AnimateScrollToOptions } from 'animated-scroll-to'

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const animationFrame = () => new Promise(resolve => requestAnimationFrame(resolve))

export const isEqual = <T>(a: T, b: T) => {
    if (a === b) return true

    try {
        return JSON.stringify(a) === JSON.stringify(b)
    } catch (e) {
        return false
    }
}

// Compare strings without diacritics and case
export const uniformIndexOf = (haystack: string, needle: string): number => {
    haystack = deburr(haystack).toUpperCase()
    needle = deburr(needle).toUpperCase()

    return haystack.indexOf(needle)
}

export const SCROLL_OPTIONS: AnimateScrollToOptions = {
    minDuration: 200,
    offset: -50,
}

export function generateRandomId(length = 40): string {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    return [...Array(length)].map(_ => chars[Math.floor(Math.random() * chars.length)]).join('')
}

type DeploymentEnv = 'testing' | 'local' | 'production'

const DeploymentEnvs: { [s: string]: DeploymentEnv | undefined } = {
    'local': 'local',
    'production': 'production',
    'testing': 'testing'
}

export function getDeploymentEnv(): DeploymentEnv {
    return DeploymentEnvs[process.env.DEPLOYMENT_ENV || 'production'] || 'production'
}

type Falsy = 0 | "" | false | null | undefined;

export function filterFalsy<T>(item: T | Falsy): item is T {
    return !!item;
}

export function removeTrailingSlash(path: string): string {
    return path[path.length - 1] === "/" ? path.substr(0, path.length - 1) : path;
}

