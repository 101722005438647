import { CartFragment, CartFragment_cart_products, CartProductDataInput, ProductListData, CartDataInput, order_me_orders_data_order_products } from "Utils/api/gql/types"
import { removeTypenameFromObject } from "./apiHelper"

const FLOAT_EPSILON = 0.0001    // Higher than necessary value, but low enough for our purposes

export default class CartHelper {
    static getInCartItem (id: string, cart: CartFragment | null | undefined): CartFragment_cart_products | undefined | null {
        if (!cart) {
            return null
        }
        const item = cart.cart_products.find(product => product.product.id === id)
        return item
    }

    static isShippingDiscounted(cart: CartFragment): boolean {
        return !!cart.shipping_method && typeof cart.shipping_method.discount_price === "number"
    }

    static getCartDeliveryFee(cart: CartFragment): number {
        return cart.shipping_method && (CartHelper.isShippingDiscounted(cart) ? cart.shipping_method.discount_price : cart.shipping_method.price) || 0
    }

    static getCartPaymentFee(cart: CartFragment): number {
        return (cart.payment_method && cart.payment_method.__typename === "PaymentMethodCashOnDelivery") ? cart.payment_method.cost : 0;
    }

    static getCartBoxingFee(cart: CartFragment): number | undefined {
        return (cart.payment_method && cart.payment_method.__typename !== "PaymentMethodCashOnDelivery") && cart.shipping_method && (cart.shipping_method.__typename === "ShippingMethodMpl" || cart.shipping_method.__typename === "ShippingMethodPostPoint") ? cart.payment_method.cost : undefined;
    }

    static getCartTotalPrice(cart: CartFragment): number {
        return typeof cart.discount_price === 'number' ? cart.discount_price : cart.total_price
    }

    static getCartTotalWithShippingAndPayment(cart: CartFragment): number {
        return CartHelper.getCartTotalPrice(cart) + CartHelper.getCartDeliveryFee(cart) + CartHelper.getCartPaymentFee(cart) + (CartHelper.getCartBoxingFee(cart) || 0)
    }

    static getCartTotalWeight(cartProducts: CartFragment_cart_products[]): number {
        return cartProducts.reduce((totalWeight, { product, piece }) => {
            const productWeight = product.weight + (product.empties ? product.empties.weight : 0)
            return totalWeight + piece * productWeight
        }, 0)
    }

    static aggregateEmptiesFromCartProducts(cartProducts: CartFragment_cart_products[]): Webshop.EmptiesCartProduct[] {
        const aggregatedEmpties: Webshop.EmptiesCartProduct[] = []

        for (const product of cartProducts) {
            const currentEmpty = product.product.empties
            if (currentEmpty) {
                const foundEmpty = aggregatedEmpties.find(empty => empty.empties.id === currentEmpty.id)
                if (foundEmpty) {
                    foundEmpty.piece += product.piece
                    foundEmpty.total_price += product.piece * foundEmpty.empties.price
                } else {
                    aggregatedEmpties.push({
                        __typename: 'EmptiesCartProduct',
                        empties: currentEmpty,
                        piece: product.piece,
                        total_price: product.piece * currentEmpty.price
                    })
                }
            }
        }

        return aggregatedEmpties
    }

    static cartToCartProductInput (cart: CartFragment): Webshop.DefiniteCartDataInput {
        return {
            id: cart.id,
            products: cart.cart_products
                .map<CartProductDataInput>(CartHelper.cartProductToCartProductDataInput)
        }
    }

    static cartProductToCartProductDataInput (cartProduct: CartFragment_cart_products): CartProductDataInput {
        const productInput: CartProductDataInput = {
            product_id: cartProduct.product.id,
            piece: cartProduct.piece,
            sliced: cartProduct.sliced || false
        }
        if (cartProduct.alternative_quantity_piece) {
            productInput.alternative_quantity_piece = cartProduct.alternative_quantity_piece
        }
        return productInput
    }

    static orderProductToCartProductDataInput (orderProduct: order_me_orders_data_order_products): CartProductDataInput {
        const productInput: CartProductDataInput = {
            product_id: orderProduct.product.id,
            piece: orderProduct.default_quantity_piece,
            sliced: orderProduct.sliced || false
        }
        if (orderProduct.alternative_quantity_piece) {
            productInput.alternative_quantity_piece = orderProduct.alternative_quantity_piece
        }
        return productInput
    }

    // erre megsincs igy szukseg, mivel lehet fieldenkent is bekuldeni a cart modositasokat, nem vesznek el azok, amiket nem kuldunk be

    // static cartToCartInput (cart: CartFragment): Webshop.DefiniteCartDataInput {
    //     return {
    //         id: cart.id,
    //         products: cart.cart_products
    //             .map<CartProductDataInput>(cartProduct => {
    //                 const productInput: CartProductDataInput = {
    //                     product_id: cartProduct.product.id,
    //                     piece: cartProduct.piece,
    //                     sliced: cartProduct.sliced
    //                 }
    //                 if (cartProduct.alternative_quantity_piece) {
    //                     productInput.alternative_quantity_piece = cartProduct.alternative_quantity_piece
    //                 }
    //                 return productInput
    //             }),
    //         billing_address: cart.billing_address ? removeTypenameFromObject(cart.billing_address) : undefined,
    //         shipping_address: cart.shipping_address
    //             ? removeTypenameFromObject({ ...cart.shipping_address, postcode: Number(cart.shipping_address.postcode) })
    //             : undefined,
    //         coupon_code: cart.coupon_code || undefined,
    //         post_point_id: cart.post_point ? Number(cart.post_point.id) : undefined,
    //         payment_method_data: cart.payment_method_data ? removeTypenameFromObject(cart.payment_method_data) : undefined,
    //         payment_method_id: cart.payment_method ? cart.payment_method.id : undefined,
    //         shipping_method_id: cart.shipping_method ? cart.shipping_method.id : undefined,
    //         timetable_time_slot_id: cart.timetable_time_slot ? Number(cart.timetable_time_slot.id) : undefined
    //     }
    // }

    static convertProductUpdatePayloadToProductDataInput(payload: Redux.CartSetProductPayload): CartProductDataInput {
        const scale = payload.quantityType === 'default'
            ? payload.product.default_quantity_scale || 1
            : payload.product.alternative_quantity_scale || 1

        const newQuantityFloat = payload.newQuantity !== 0
            ? Math.max(scale, Math.round(payload.newQuantity / scale) * scale)
            : 0

        const newQuantity = parseFloat(newQuantityFloat.toFixed(5));

        const piece = payload.quantityType === 'default'
            ? newQuantity
            : newQuantity * (payload.product.alternative_quantity_exchange_rate || 1)

        const alternative_quantity_piece = payload.quantityType === 'alternative'
            ? newQuantity
            : undefined

        const newProductInput: CartProductDataInput = {
            product_id: payload.product.id,
            piece,
            alternative_quantity_piece,
            sliced: payload.sliced || false
        }

        return newProductInput
    }

    static replaceProductInCartInputProducts(cartInputProducts: CartProductDataInput[], newProductInput: CartProductDataInput): CartProductDataInput[] {
        return cartInputProducts.map(
            productInput => productInput.product_id === newProductInput.product_id
                ? newProductInput
                : productInput
        )
    }

    static isProductFetching(product: Pick<ProductListData, 'id'>, status: Redux.ICartStatus): boolean {
        return status.isFetching && (!status.affectedProductId || status.affectedProductId === product.id)
    }

    static getCartItemQtyType(cartItem: CartFragment_cart_products): Redux.QtyType {
        const isAlternativePieceValid =
            cartItem.alternative_quantity_piece                     &&
            cartItem.product.alternative_quantity_exchange_rate     &&
            Math.abs((cartItem.piece / cartItem.product.alternative_quantity_exchange_rate) - cartItem.alternative_quantity_piece) < FLOAT_EPSILON

        return isAlternativePieceValid
            ? 'alternative'
            : 'default'
    }

    static getCartItemQuantity(cartItem: CartFragment_cart_products, qtyType: Redux.QtyType): number {
        return qtyType === 'default'
            ? cartItem.piece
            : cartItem.alternative_quantity_piece || cartItem.piece / (cartItem.product.alternative_quantity_exchange_rate || 1)
    }

    static convertCartItemToCartInputProduct(cartItem: CartFragment_cart_products): Redux.CartSetProductInput {
        const qtyType = CartHelper.getCartItemQtyType(cartItem)
        return {
            product: cartItem.product,
            newQuantity: CartHelper.getCartItemQuantity(cartItem, qtyType),
            quantityType: qtyType,
            sliced: cartItem.sliced || false
        }
    }
}
