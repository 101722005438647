import { SyntheticEvent, ReactNode } from 'react'

import { SVGIconDanger, SVGTick } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import nptwrppr from 'Components/InputWrapper/InputWrapper.scss'
import cl from './Checkbox.scss'

type PropType = {
  label: string | JSX.Element,
  error?: ReactNode,
  name: string,
  className?: string,
  checked?: boolean,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void
  value?: string
  disabled?: boolean
  // [key: string]: any
}

const Checkbox: React.SFC<PropType> = ({ onChange, checked, className, label, name, error, value, disabled }) => (
  <label className={`${cl.checkboxWrapper} ${className ? className : ""} `}>
    <div className={cn(cg.gridX, cg.alignMiddle)}>
      <div className={cn(cg.cell, cg.shrink)}>
        <input type="checkbox" name={name} onChange={onChange} checked={checked} value={value} disabled={disabled} />
        <span
          className={cl.checkbox}
          role="checkbox"
          aria-checked={checked}
          tabIndex={0}
        >
          <span className={cl.iconWrapper} aria-hidden="true">
            <SVGTick width="100%" height="100%" />
          </span>
        </span>
      </div>

      <span className={cn(cg.cell, cg.auto, cl.label)}>
        {label}
      </span>

      {error && (
        <span className={cg.cell}>
          <span className={cn(cg.gridX, nptwrppr.inputWrapper__errorWrapper)}>
            <span className={cn(cg.cell, cg.shrink)} aria-hidden={true}>
              <SVGIconDanger width={18} height={18} />
            </span>

            <span className={cn(cg.cell, cg.auto)}>
              {error}
            </span>
          </span>
        </span>
      )}
    </div>
  </label>
)

export default Checkbox
