import React, { ReactNode } from 'react'

import { SVGIconDanger } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './InputWrapper.scss'
import { nominalTypeHack } from '../../node_modules/@types/prop-types';

type PropType = {
  children: ReactNode,
  hideLabel?: boolean,
  noMargin?: boolean,
  phone?: boolean,
  label: ReactNode,
  name?: string,
  error?: ReactNode
}

const InputWrapper: React.SFC<PropType> = (props) => {
  const { children, error, hideLabel, label, phone, noMargin } = props

  return (
    <div className={cn(cl.inputWrapper, error ? cl.inputWrapperHasError : {}, noMargin ? cl.noMargin : {})}>
      <label>
        <span className={hideLabel ? cg.showForSr : cl.label}>{label}</span>

        {phone ? (
          <div className={cn(cg.gridX, cg.alignCenter, cg.alignMiddle)}>
            <span className={cn(cg.cell, cg.shrink, cl.phone)}>+36 </span>
            <span className={cn(cg.cell, cg.auto)}>{children}</span>
          </div>
        ):(
          <>{children}</>
        )}

        {error && (
          <span className={cn(cg.gridX, cl.inputWrapper__errorWrapper)}>
            <span className={cn(cg.cell, cg.shrink)} aria-hidden={true}>
              <SVGIconDanger width={18} height={18} />
            </span>

            <span className={cn(cg.cell, cg.auto)}>
              {error}
            </span>
          </span>
        )}
      </label>
    </div>
  )
}

export default InputWrapper
