import { SVGArrow, SVGPinGrey } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './AddressDisplay.scss'
import appBarCss from 'Components/AppBar/AppBar.scss'
import { connect, DispatchProp } from 'react-redux'
import { showDeliveryTypeModal } from 'Utils/helpers/modalHelper'
import { CartFragment } from 'Utils/api/gql/types'
import { formatAddress } from 'Utils/helpers/addressHelper'


const renderAppBar = (text: string, addressExtra: string, hasAddress = false, disabled = false) => {
  return (
    <div className={cn(cg.gridX, cg.alignMiddle, appBarCss.address)} onClick={() => !disabled && showDeliveryTypeModal(false)}>
      <div className={cn(cg.cell, cg.shrink)}>
        <span style={{ color: '#f06f13' }}><SVGPinGrey width={24} height={24} /></span>
      </div>
      {hasAddress && (<div className={cn(cg.cell, cg.auto)}>
        <strong>{text}</strong>
        <br />
        {!disabled && <>Nem ide rendelsz? <span className={cn(appBarCss.link)}>
          Módosítás
        </span></>}
        {disabled && 'Megrendeléshez kapcsolva'}
        <span className={cn(cg.hideForLarge, appBarCss.arrow)}><SVGArrow  width={30} height={30} /></span>
      </div>)}
      {!hasAddress && (<div className={cn(cg.cell, cg.auto)}>
        <strong>{text}</strong>
        <span className={cn(cg.hideForLarge, appBarCss.arrow)}><SVGArrow  width={30} height={30} /></span>
      </div>)}
      {disabled && <div className={cl.disabledOverlay}>&nbsp;</div>}
    </div>

  )
}

const renderCartPage = (text: string, addressExtra: string, hasAddress = false, disabled = false) => (
  <div className={cn(cg.gridX, cg.alignMiddle, cl.addressDisplay)}>
      <div className={cn(cg.cell, cg.auto)}>
        <div className={cn(cg.gridX, cg.alignMiddle)}>
          <div className={cn(cg.cell, cg.shrink, cl.iconWrapper)} aria-hidden={true}>
            <SVGPinGrey width={33} height={33} />
          </div>

          <div className={cn(cg.cell, cg.auto)}>
            <div className={cn(cg.gridX, cg.alignMiddle)}>
              <div className={cn(cg.cell, cg.largeAuto)}>
                <strong>
                  {text}

                  {addressExtra && <span className={cg.showForLarge}>
                    {addressExtra}
                  </span>}
                </strong>
              </div>

              <div className={cn(cg.cell, cg.largeShrink)}>
                {!disabled && <>Nem ide rendelsz? <a className={cn(cg.linkWithIcon, cl.linkWithIcon)} onClick={() => !disabled && showDeliveryTypeModal(false)}>
                  Módosítás
                </a></>}
                {disabled && 'Megrendeléshez kapcsolva'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={cn(cg.cell, cg.shrink, cg.hideForLarge)}>
        <a className={cg.isBlock} onClick={() => !disabled && showDeliveryTypeModal(false)}>
          <span className={cg.showForSr}>Módosítás</span>

          <span aria-hidden={true} style={{display: 'inline-block', marginRight: '10px'}}>
            <SVGArrow width={24} height={24} />
          </span>
        </a>
      </div>
      {disabled && <div className={cl.disabledOverlay}>&nbsp;</div>}
    </div>
)

type PropType = { 
  deliveryAddress?: Redux.IDeliveryAddress, 
  view: 'AppBar' | 'CartPage', 
  cart: CartFragment | null, 
  session: Redux.IStoreSessionData 
} & DispatchProp

const AddressDisplay: React.FunctionComponent<PropType> = ({ deliveryAddress, view, cart, session, dispatch }) => {
  const isDisabled = !!cart && !!cart.linked_order
  let address = deliveryAddress && deliveryAddress.displayValue
    ? deliveryAddress.displayValue
    : 'Még nem választottál címet'
    
  if (deliveryAddress && deliveryAddress.selected.type === 'delivery' && deliveryAddress.selected.deliveryType === 'savedAddress' && session.loggedIn) {
    const foundAddress = (session.customerData.shipping_addresses || []).find(address => address.id === deliveryAddress.value.addressId)
    if (foundAddress) {
      address = formatAddress(foundAddress)
    }
  }
  
  if (cart && cart.linked_order) {
    const o = cart.linked_order
    address = formatAddress({ 
      city: o.shipping_city,
      postcode: o.shipping_postcode,
      house_number: o.shipping_house_number,
      street: o.shipping_street
    })
  }
  
  const hasAddress = !!deliveryAddress

  switch (view) {
    case 'AppBar':
      return renderAppBar(address, '', hasAddress, isDisabled)
    case 'CartPage':
      return renderCartPage(address, '', hasAddress, isDisabled)
    default:
      throw `AddressDisplay: Unknown view (view: ${view})`
  }
}

export default connect(({ deliveryAddress, cart, session }: Redux.IReduxState) => ({ deliveryAddress, cart, session }))(AddressDisplay)
